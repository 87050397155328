import React, { useCallback, useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';
import { useNavigate } from 'react-router-dom';

import { FollowUserBoxContinaer, UserIcon, UserId, UserInformation, UserManageButton, UserNickname } from './styles';
import { FollowStatus, FollowUserProps } from './types';

import { APIError, followUser, unfollowingUser, unfollowUser } from 'apis/index';
import useModal from 'hooks/useModal';
import { tokenAtom, userAtom } from 'src/atoms';

export const followStatusText = {
  follow: '팔로우',
  following: '팔로잉',
  follower: '삭제',
  none: '',
} as const;

function FollowUserBox({ tabType, userImage, userNickname, userName, spaceUserName, isFollowing }: FollowUserProps) {
  const navigate = useNavigate();
  const [followingStatus, setFollowingStatus] = useState<FollowStatus>();
  const token = useAtomValue(tokenAtom);
  const user = useAtomValue(userAtom);
  const { openAlertModal } = useModal();

  useEffect(() => {
    if (user?.username !== spaceUserName) {
      setFollowingStatus(isFollowing ? 'following' : 'follow');
    } else if (tabType) {
      setFollowingStatus(tabType);
    }
  }, [tabType, user, spaceUserName]);

  const onFollowUserBoxClick = useCallback(() => {
    navigate(`/u/${userName}`);
  }, []);

  const onUserManageButtonClick = useCallback(
    async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();

      let status = 200;

      if (token) {
        try {
          switch (followingStatus) {
            case 'follow':
              status = await followUser(token, userName);

              if (status === 200) {
                setFollowingStatus('following');
              }

              break;
            case 'following':
              status = await unfollowUser(token, userName);

              if (status === 200) {
                setFollowingStatus('follow');
              }

              break;
            case 'follower':
              status = await unfollowingUser(token, userName);

              if (status === 200) {
                setFollowingStatus('none');
              }

              break;
            default:
              break;
          }
        } catch (error) {
          let errorMessage;

          if (error instanceof APIError) {
            errorMessage = error.issues.detail;
          } else {
            errorMessage = error as string;
          }

          openAlertModal([{ type: 'NORMAL', message: errorMessage }]);
        }
      }
    },
    [token, tabType, followingStatus, setFollowingStatus]
  );

  if (!followingStatus || followingStatus === 'none') return <></>;

  return (
    <FollowUserBoxContinaer onClick={onFollowUserBoxClick}>
      <UserIcon imageURL={userImage} />
      <UserInformation>
        <UserNickname>{userNickname}</UserNickname>
        <UserId>@{userName}</UserId>
      </UserInformation>
      <UserManageButton onClick={onUserManageButtonClick}>{followStatusText[followingStatus]}</UserManageButton>
    </FollowUserBoxContinaer>
  );
}

export default FollowUserBox;
