import { Dispatch, useRef, useState } from 'react';
import Modal from 'react-modal';
import { SetStateAction, useAtomValue } from 'jotai';

import { ConfirmUserBlock } from 'pages/Home/replys';
import Alert from 'components/Commons/alert';
import { HelpBlack, Close, MoreVerticalBlack, Post2D, Report, Share02Black, Ban } from 'components/Commons/icons';
import { APIError, reportUser, Token } from 'apis/index';

import { languageModeAtom, userAtom } from 'src/atoms';
import useModal from 'hooks/useModal';

const scripts = {
  KR: [
    '공유',
    '클립보드에 복사되었습니다.',
    '복사에 실패했습니다.',
    '복사',
    '2D Profile',
    '신고하기',
    '유저 신고',
    '신고사유',
    '저작권 침해',
    '욕설/폭언',
    '음란물',
    '기타',
    '신고사유를 작성해주세요.',
    '신고 보내기',
    '차단',
  ],
  EN: [
    'Shared',
    'Copied to the clipboard',
    'Copy Failed',
    'Copy',
    '2D Profile',
    'Report',
    'User Report',
    'Reporting Reason',
    'Copyright Infringement',
    'Abusive language',
    'Pornography',
    'Other',
    'Please Write Reasons for Report',
    'Send Report',
    'Block',
  ],
  JP: [
    '共有',
    'クリップボードにコピーされました',
    'コピーに失敗しました',
    'コピー',
    '2D Profile',
    '申告する',
    'ユーザー 申告',
    '申告事由',
    '著作権侵害',
    '悪口/暴言',
    'わいせつ物',
    'その他',
    '申告事由を作成してください',
    '申告する',
    'ブロック',
  ],
};

export default function ProfileLayout({
  userId,
  token,
  setIs3D,
}: {
  userId: string;
  token: Token | null;
  setIs3D: Dispatch<SetStateAction<boolean>>;
}) {
  let [isViewerModalOpen, setIsViewerModalOpen] = useState<boolean>(false);
  let [play, setPlay] = useState(false);
  let audioRef = useRef<HTMLAudioElement>(null);
  let audioUrl1 = new URL(
    '../../../static/bgms/CD1-01_Bach-Violin_Concerto_No1-Oistrakh1962-Track01.mp3',
    import.meta.url
  );
  let audioUrl2 = new URL(
    '../../../static/bgms/CD1-02_Bach-Violin_Concerto_No1-Oistrakh1962-Track02.mp3',
    import.meta.url
  );
  let audioUrl3 = new URL(
    '../../../static/bgms/CD1-03_Bach-Violin_Concerto_No1-Oistrakh1962-Track03.mp3',
    import.meta.url
  );
  let audioUrl4 = new URL(
    '../../../static/bgms/CD1-06_Bach-Violin_Concerto_No2-Oistrakh1962-Track3.mp3',
    import.meta.url
  );
  let audioUrl5 = new URL(
    '../../../static/bgms/CD1-09-Bach-Concerto_for_2_Violins_BWV1043-Oistrakh1961-Track3.mp3',
    import.meta.url
  );
  let audioUrl6 = new URL(
    '../../../static/bgms/CD1-11-Beethoven-Violin_Romance_No2-Oistrakh1962.mp3',

    import.meta.url
  );
  let audioUrls: string[] = [];
  audioUrls.push(
    audioUrl1.href,

    audioUrl2.href,

    audioUrl3.href,

    audioUrl4.href,

    audioUrl5.href,

    audioUrl6.href
  );

  let toggleBgm = () => {
    if (play) {
      audioRef.current?.pause();
    } else {
      audioRef.current?.play();
    }
    setPlay((play) => !play);
  };

  let languageMode = useAtomValue(languageModeAtom);
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
    },
  };
  const [isOptionOpen, setIsOptionOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<number>(1);
  const [desc, setDesc] = useState<string>('');
  let [blockingAlert, setBlockingAlert] = useState<boolean>(false);
  return (
    <>
      {/*
      뷰어모달 주석처리
      <div
        className='flex justify-end absolute top-0 right-0'
        onClick={() => {
          setIsViewerModalOpen(true);
        }}>
        <div className='m-4 bg-zinc-300 p-2 rounded-lg hover:bg-zinc-600'>
          <span className='float-left inline-block items-center'>
            <Eye />
          </span>
          <span className='float-right text-xs inline-block items-center py-1 pl-1 text-white'>990K명</span>
        </div>
      </div>

      {isViewerModalOpen && <ViewerModal setIsViewerModalOpen={setIsViewerModalOpen} />} */}

      <div className='absolute bottom-0 flex items-end w-full pl-4 pb-4 pr-2 pt-6 text-white'>
        <div className='h-full grid grid-row-2 content-end flex-1'></div>
        <div className='flex flex-col gap-2 grow-0 justify-center items-center'>
          <div className='h-6'>
            <ProfileURLModal userId={userId} />
          </div>
          <div className='h-6'>
            <button
              onClick={() => {
                window.open('https://adlercs.oopy.io/');
              }}>
              <HelpBlack />
            </button>
          </div>
          <Modal isOpen={isOptionOpen} style={customStyles}>
            <div className='flex flex-col items-center'>
              <div className='w-full flex items-center pb-4'>
                <div className='grow basis-0 justify-end' />
                <div className='flex items-center'>{scripts[languageMode][6]}</div>
                <div className='flex grow basis-0 justify-end'>
                  <button className='flex justify-end items-center' onClick={() => setIsOptionOpen(false)}>
                    <Close />
                  </button>
                </div>
              </div>
              <div className='w-full'>
                <label className='flex items-center gap-1.5 rounded border-solid border border-zinc-300 px-4 py-2 h-8 mb-2'>
                  <div className='text-sm text-zinc-600 w-fit shrink-0'>{scripts[languageMode][7]}</div>
                  <div className='text-sm grow flex justify-center items-center'>
                    <select
                      className='w-full'
                      value={selectedOption}
                      onChange={(e) => setSelectedOption(Number(e.target.value))}>
                      <option value={1}>{scripts[languageMode][8]}</option>
                      <option value={2}>{scripts[languageMode][9]}</option>
                      <option value={3}>{scripts[languageMode][10]}</option>
                      <option value={4}>{scripts[languageMode][11]}</option>
                    </select>
                  </div>
                </label>
                <textarea
                  className='h-28 w-full rounded border-solid border border-zinc-300 placeholder:text-zinc-400 px-3 py-2 text-sm mb-3'
                  name='description'
                  placeholder={scripts[languageMode][12]}
                  value={desc}
                  onChange={(e) => setDesc(e.currentTarget.value)}></textarea>
              </div>
              <button
                className='bg-pink-600 w-full rounded py-2 text-white'
                onClick={() => {
                  if (token) reportUser(userId, token, selectedOption, desc);
                  setIsOptionOpen(false);
                }}>
                {scripts[languageMode][13]}
              </button>
            </div>

            <div></div>
          </Modal>
          <div>
            <ProfileInfoModal userId={userId} token={token} setIs3D={setIs3D} setBlockingAlert={setBlockingAlert} />
          </div>
        </div>
      </div>
      {blockingAlert && <Alert text={['차단되었습니다.']} />}
    </>
  );
}

function ProfileURLModal({ userId }: { userId: string }) {
  let languageMode = useAtomValue(languageModeAtom);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const URLInputRef = useRef<HTMLInputElement>(null);
  const { openAlertModal } = useModal();
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '85%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  };
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div id='URLModalPart' className='text-center'>
      <button id='URLModalBtn' onClick={openModal}>
        <Share02Black />
      </button>
      <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
        {/*TODO: 아 왜 스타일태그 에러뜨는지 모르겠다 - 용민*/}
        <div className='mb-5'>{scripts[languageMode][0]}</div>
        <div className=' flex border border-zinc-300 bg-zinc-100 rounded h-8 gap-3 w-full'>
          <input
            className='text-xs pl-3 bg-zinc-100'
            style={{ flex: 1 }}
            type='text'
            defaultValue={window.location.origin + '/u/' + userId}
            ref={URLInputRef}
          />
          <button
            className='text-xs text-info pr-2'
            onClick={async () => {
              if (URLInputRef.current) {
                try {
                  await navigator.clipboard.writeText(URLInputRef.current.value);
                  openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][1] }]);
                } catch (error) {
                  let errorMessage;

                  if (error instanceof APIError) {
                    errorMessage = error.issues.detail;
                  } else {
                    errorMessage = error as string;
                  }

                  openAlertModal([{ type: 'NORMAL', message: errorMessage }]);
                }
              }
            }}>
            {scripts[languageMode][3]}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function ProfileInfoModal({
  userId,
  token,
  setIs3D,
  setBlockingAlert,
}: {
  userId: string;
  token: Token | null;
  setIs3D: Dispatch<SetStateAction<boolean>>;
  setBlockingAlert: Dispatch<SetStateAction<boolean>>;
}) {
  let languageMode = useAtomValue(languageModeAtom);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOptionOpen, setIsOptionOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<number>(1);
  const mediaMatch = window.matchMedia('(min-width: 1024px)');

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <>
      <button id='URLModalBtn' onClick={openModal}>
        <MoreVerticalBlack />
      </button>
      {!mediaMatch.matches ? (
        isOpen && (
          <MobileProfileInfoModal
            setIsOpen={setIsOpen}
            setIsOptionOpen={setIsOptionOpen}
            setIs3D={setIs3D}
            userId={userId}
            setBlockingAlert={setBlockingAlert}
          />
        )
      ) : (
        <PCProfileInfoModal
          closeModal={closeModal}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setIsOptionOpen={setIsOptionOpen}
          setIs3D={setIs3D}
          userId={userId}
          setBlockingAlert={setBlockingAlert}
        />
      )}
      <ProfileReportModal
        userId={userId}
        token={token}
        setIs3D={setIs3D}
        isOptionOpen={isOptionOpen}
        closeModal={closeModal}
        setIsOptionOpen={setIsOptionOpen}
      />
    </>
  );
}

function MobileProfileInfoModal({
  setIs3D,
  setIsOpen,
  setIsOptionOpen,
  userId,
  setBlockingAlert,
}: {
  setIs3D: Dispatch<SetStateAction<boolean>>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsOptionOpen: Dispatch<SetStateAction<boolean>>;
  userId: string;
  setBlockingAlert: Dispatch<SetStateAction<boolean>>;
}) {
  let user = useAtomValue(userAtom);

  let languageMode = useAtomValue(languageModeAtom);
  let [userBlockProcess, setUserBlockProcess] = useState<number | null>(null);
  return (
    <div className='flex flex-col items-center fixed bottom-0 left-0 z-10 bg-white w-screen text-zinc-600 rounded-t-lg font-light'>
      <button
        className='pt-4'
        onClick={() => {
          setIsOpen(false);
        }}>
        <svg width='41' height='4' viewBox='0 0 41 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <rect x='0.356201' width='40' height='4' rx='2' fill='#808080' />
        </svg>
      </button>
      {userBlockProcess === 2 ? (
        <ConfirmUserBlock
          setUserBlockProcess={setUserBlockProcess}
          blockingUser={userId}
          setIsReplyOpen={setIsOpen}
          setBlockingAlert={setBlockingAlert}
        />
      ) : (
        <>
          <button
            className='flex gap-1.5 px-3 py-3 border-b border-zinc-100 w-full'
            onClick={() => {
              setIs3D(false);
            }}>
            <Post2D />
            <div>{scripts[languageMode][4]}</div>
          </button>
          <button
            className='flex gap-1.5 px-3 py-3 border-b border-zinc-100 w-full'
            onClick={() => {
              setIsOpen(false);
              setIsOptionOpen(true);
            }}>
            <Report />
            <div className='text-error'>{scripts[languageMode][5]}</div>
          </button>
          {userId !== user?.username ? (
            <button
              className='flex gap-1.5 px-3 py-3 border-b border-zinc-100 w-full'
              onClick={() => {
                setUserBlockProcess(2);
              }}>
              <Ban />
              <div className='text-error'>{scripts[languageMode][14]}</div>
            </button>
          ) : null}
        </>
      )}
    </div>
  );
}

function PCProfileInfoModal({
  closeModal,
  setIs3D,
  setIsOpen,
  setIsOptionOpen,
  isOpen,
  userId,
  setBlockingAlert,
}: {
  closeModal: () => void;
  setIs3D: Dispatch<SetStateAction<boolean>>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsOptionOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  userId: string;
  setBlockingAlert: Dispatch<SetStateAction<boolean>>;
}) {
  let languageMode = useAtomValue(languageModeAtom);
  let [userBlockProcess, setUserBlockProcess] = useState<number | null>(null);
  let user = useAtomValue(userAtom);

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
    },
  };
  return (
    <div className='hidden lg:flex'>
      <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
        <div className='flex flex-col items-center'>
          {userBlockProcess === 2 ? (
            <ConfirmUserBlock
              setUserBlockProcess={setUserBlockProcess}
              blockingUser={userId}
              setIsReplyOpen={setIsOpen}
              setBlockingAlert={setBlockingAlert}
            />
          ) : (
            <>
              <button
                className='flex gap-1.5 px-3 py-3 border-b border-zinc-100 w-full'
                onClick={() => {
                  setIs3D(false);
                }}>
                <Post2D />
                <div>{scripts[languageMode][4]}</div>
              </button>
              <button
                className='flex gap-1.5 px-3 py-3 border-b border-zinc-100 w-full'
                onClick={() => {
                  setIsOpen(false);
                  setIsOptionOpen(true);
                }}>
                <Report />
                <div className='text-error'>{scripts[languageMode][5]}</div>
              </button>
              {userId !== user?.username ? (
                <button
                  className='flex gap-1.5 px-3 py-3 border-b border-zinc-100 w-full'
                  onClick={() => {
                    setUserBlockProcess(2);
                  }}>
                  <Ban />
                  <div className='text-error'>{scripts[languageMode][14]}</div>
                </button>
              ) : null}
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}

function ProfileReportModal({
  userId,
  token,
  setIs3D,
  isOptionOpen,
  closeModal,
  setIsOptionOpen,
}: {
  userId: string;
  token: Token | null;
  setIs3D: Dispatch<SetStateAction<boolean>>;
  isOptionOpen: boolean;
  closeModal: () => void;
  setIsOptionOpen: Dispatch<SetStateAction<boolean>>;
}) {
  let languageMode = useAtomValue(languageModeAtom);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<number>(1);
  const [desc, setDesc] = useState<string>('');
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
    },
  };
  return (
    <Modal isOpen={isOptionOpen} onRequestClose={closeModal} style={customStyles}>
      <div className='flex flex-col items-center'>
        <div className='w-full flex items-center pb-4'>
          <div className='grow basis-0 justify-end' />
          <div className='flex items-center'>{scripts[languageMode][6]}</div>
          <div className='flex grow basis-0 justify-end'>
            <button className='flex justify-end items-center' onClick={() => setIsOptionOpen(false)}>
              <Close />
            </button>
          </div>
        </div>
        <div className='w-full'>
          <label className='flex items-center gap-1.5 rounded border-solid border border-zinc-300 px-4 py-2 h-8 mb-2'>
            <div className='text-sm text-zinc-600 w-fit shrink-0'>{scripts[languageMode][7]}</div>
            <div className='text-sm grow flex justify-center items-center'>
              <select
                className='w-full'
                value={selectedOption}
                onChange={(e) => setSelectedOption(Number(e.target.value))}>
                <option value={1}>{scripts[languageMode][8]}</option>
                <option value={2}>{scripts[languageMode][9]}</option>
                <option value={3}>{scripts[languageMode][10]}</option>
                <option value={4}>{scripts[languageMode][11]}</option>
              </select>
            </div>
          </label>
          <textarea
            className='h-28 w-full rounded border-solid border border-zinc-300 placeholder:text-zinc-400 px-3 py-2 text-sm mb-3'
            name='description'
            placeholder={scripts[languageMode][12]}
            value={desc}
            onChange={(e) => setDesc(e.currentTarget.value)}></textarea>
        </div>
        <button
          className='bg-pink-600 w-full rounded py-2 text-white'
          onClick={() => {
            if (token) reportUser(userId, token, selectedOption, desc);
            setIsOptionOpen(false);
          }}>
          {scripts[languageMode][13]}
        </button>
      </div>

      <div></div>
    </Modal>
  );
}
