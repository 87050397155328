import { Dispatch, useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { SetStateAction, useAtomValue } from 'jotai';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { ReplyReportModal } from './modals';
import FollowButton from './Components/FollowButton';

import { MoreHorizontalGray } from 'components/Commons/icons';
import { APIError, API_BASE_URL, blockUser, deleteReply, getReplys, handleOnInput, writeReply } from 'apis/index';
import useModal from 'hooks/useModal';
import { Reply, ReplySet, Space } from 'apis/types';

import { languageModeAtom, tokenAtom, userAtom } from 'src/atoms';

const scripts = {
  KR: [
    '댓글이 작성되지 않았습니다. 다시 시도해주세요.',
    '로그인이 필요합니다.',
    '댓글달기',
    '게시',
    '번역하기',
    '수정',
    '댓글이 삭제되었습니다.',
    '댓글이 삭제되지 않았습니다. 다시 시도해주세요.',
    '삭제',
    '신고',
    '댓글',
    '이 댓글 신고',
    '님 차단',
    '차단하시겠어요?',
    '상대방은 아들러에서 회원님의 프로필, 포스팅을 찾을 수 없게 됩니다.',
    '차단',
  ],
  EN: [
    'Comment not created. Please try again.',
    'Login required',
    'Post comment',
    'post',
    'translate',
    'Fix',
    'Comment deleted',
    'Comment not deleted. Please try again.',
    'Delete',
    'report',
    'Comment',
    'Report this reply',
    'block',
    'Would you like to block?',
    'The other party will not be able to find your profile or posts on Adler.',
    'Block',
  ],
  JP: [
    'コメントが作成されていません。 もう一度試してください。',
    'ログインが必要です',
    'コメント',
    '掲示',
    '翻訳する',
    '修正',
    'コメントが削除されました。',
    'コメントは削除されていません。 もう一度試してください。',
    '削除',
    '報告',
    'コメント',
    'ブこのコメントを報告',
    'ブロック',
    'ブロックしますか？',
    '相手はアドラーであなたのプロフィール、投稿を見つけることができません。',
    'ブロック',
  ],
};

export function ReplyInput({
  spaceId,
  replys,
  setReplys,
  currentURL,
}: {
  spaceId: string;
  replys: ReplySet | null;
  setReplys: Dispatch<SetStateAction<ReplySet | null>>;
  currentURL: string;
}) {
  const token = useAtomValue(tokenAtom);
  const user = useAtomValue(userAtom);
  const languageMode = useAtomValue(languageModeAtom);
  const [reply, setReply] = useState<string>('');
  const navigate = useNavigate();
  const { openAlertModal } = useModal();
  return (
    <form
      className='flex h-12 w-full items-center border-y border-zinc-100 shrink-0 lg:grow-0 lg:bg-white'
      onSubmit={async (e) => {
        e.preventDefault();
        if (reply.length !== 0) {
          let formData = new FormData(e.currentTarget);
          let reply = formData.get('reply-content') as string;
          let spaceID = spaceId;
          if (token) {
            try {
              let result = await writeReply(token, spaceID, reply);
              if (!result) {
                openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][0] }]);
              } else {
                setReply('');
                let newReplys = await getReplys(currentURL);
                setReplys({
                  previous: newReplys.previous,
                  count: newReplys.count,
                  results: replys
                    ? [...replys.results, newReplys.results[newReplys.results.length - 1]]
                    : [newReplys.results[newReplys.results.length - 1]],
                  next: newReplys.next,
                });
              }
            } catch (error) {
              let errorMessage;

              if (error instanceof APIError) {
                errorMessage = error.issues.detail;
              } else {
                errorMessage = error as string;
              }

              openAlertModal([{ type: 'NORMAL', message: errorMessage }]);
            }
          } else {
            openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][1] }]);
            navigate('/login');
          }
        }
      }}>
      <div id='profile_image_area' className='shrink-0 px-2'>
        <img src={user?.image} className='h-8 w-8 rounded-full object-cover aspect-square' />
      </div>
      <input
        type='text'
        name='reply-content'
        className='flex-1 text-base placeholder:text-zinc-300'
        placeholder={scripts[languageMode][2]}
        value={reply}
        onChange={
          setReply
            ? (e) => {
                handleOnInput(e, 150);
                setReply(e.target.value);
              }
            : undefined
        }
      />
      <div id='profile_image_area' className='shrink-0 px-2'>
        <button type='submit' className={'text-sm ' + (reply.length === 0 ? 'text-zinc-400' : 'text-pink-600')}>
          {scripts[languageMode][3]}
        </button>
      </div>
    </form>
  );
}

export function SingleReply({
  reply,
  index,
  setIsModalOpen,
  setReplyIndex,
  replys,
  setReplys,
  setUserBlockProcess,
  setBlockingUser,
}: {
  reply: Reply;
  index: number;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setReplyIndex: Dispatch<SetStateAction<number>>;
  replys: ReplySet | null;
  setReplys: Dispatch<SetStateAction<ReplySet | null>>;
  setUserBlockProcess: Dispatch<SetStateAction<number | null>>;
  setBlockingUser: Dispatch<SetStateAction<string | null>>;
}) {
  let user = useAtomValue(userAtom);
  let token = useAtomValue(tokenAtom);
  let languageMode = useAtomValue(languageModeAtom);
  let navigate = useNavigate();
  const { openAlertModal } = useModal();

  const onHashTagClick = useCallback((hashTag: string) => {
    const tagText = hashTag.replace('#', '');

    navigate(`/search/tag/${tagText}`);
  }, []);

  const isMyPost = useMemo(() => user?.username === reply.user, [user, reply]);

  // 댓글 생성 일자 번역
  let replyDate = reply.created;
  const dateScripts = {
    KR: ['년 전', '달 전', '주 전', '일 전', '시간 전', '분 전'],
    EN: [' years ago', ' months ago', ' weeks ago', ' days ago', ' hours ago', ' minutes ago'],
    JP: [' 年前', ' 月前', ' 週間前', ' 日前', ' 時間前', ' 分前'],
  };
  function dateTranslate(qwe: string) {
    for (let i = 0; i < 6; i++) {
      if (strExtract == dateScripts.KR[i]) {
        switch (i) {
          case 0:
            return replyDate.replace('년 전', dateScripts[languageMode][0]);
          case 1:
            return replyDate.replace('달 전', dateScripts[languageMode][1]);
          case 2:
            return replyDate.replace('주 전', dateScripts[languageMode][2]);
          case 3:
            return replyDate.replace('일 전', dateScripts[languageMode][3]);
          case 4:
            return replyDate.replace('시간 전', dateScripts[languageMode][4]);
          case 5:
            return replyDate.replace('분 전', dateScripts[languageMode][5]);
        }
      }
    }
  }
  let regex = /[^0-9]/g;
  const arrMix = [replyDate.match(regex)];
  let strExtract = arrMix.join('').replace(/,/g, '');

  return (
    <div className='flex py-2'>
      <div id='profile_image_area' className='shrink-0 px-4 pt-1' onClick={() => navigate(`/u/${reply.user}`)}>
        <img
          src={reply.profileImgURL ?? new URL('../../../static/imgs/default-profile.png', import.meta.url).href}
          className='h-8 w-8 rounded-full object-cover aspect-square'
        />
      </div>
      <div id='reply_area' className='pr-4'>
        <div id='text_area' className='text-sm pb-1'>
          <div id='nickname' className='inline font-semibold' onClick={() => navigate(`/u/${reply.user}`)}>
            {reply.user + ' · '}
          </div>
          {user && user.username !== reply.user && <FollowButton username={reply.user} isFollowing={false} />}
          <div id='text' className='inline break-all'>
            {' ' + reply.noHashTagReply + ' '}
          </div>
          {/* <button type='button' className='inline text-zinc-400'>
            [{scripts[languageMode][4]}]
          </button> */}
        </div>
        <div id='hashtag_area' className='text-sm text-tag pb-1'>
          {reply.hashTags.map((content) => {
            return (
              <div className='cursor-pointer' onClick={() => onHashTagClick(content)}>
                {content + ' '}
              </div>
            );
          })}
        </div>
        <div id='button_area' className='flex gap-4 text-xs items-center'>
          {/* <button type='button' className='text-zinc-400'>
            {scripts[languageMode][5]}
          </button> */}
          <div className='text-gray-500 text-sx'>{dateTranslate(strExtract)}</div>
          {reply.user === user?.username && (
            <button
              type='button'
              className='text-gray-500 text-sx'
              onClick={async () => {
                if (token && replys) {
                  try {
                    let result = await deleteReply(token, reply.id);
                    if (result) {
                      openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][6] }]);
                      setReplys({
                        previous: replys.previous,
                        count: replys.count,
                        results: replys.results.filter((el) => el !== reply),
                        next: replys.next,
                      });
                    } else {
                      openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][7] }]);
                    }
                  } catch (error) {
                    let errorMessage;

                    if (error instanceof APIError) {
                      errorMessage = error.issues.detail;
                    } else {
                      errorMessage = error as string;
                    }

                    openAlertModal([{ type: 'NORMAL', message: errorMessage }]);
                  }
                }
              }}>
              {scripts[languageMode][8]}
            </button>
          )}
          {!isMyPost && (
            <button
              onClick={() => {
                setReplyIndex(index);
                setBlockingUser(reply.user);
                setUserBlockProcess(1);
              }}>
              <MoreHorizontalGray />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default function Replys({
  space,
  isReplyOpen,
  setIsReplyOpen,
  setBlockingAlert,
  onCloseHandler,
}: {
  space: Space;
  isReplyOpen: boolean;
  setIsReplyOpen: Dispatch<SetStateAction<boolean>>;
  setBlockingAlert: Dispatch<SetStateAction<boolean>>;
  onCloseHandler?: () => void;
}) {
  let token = useAtomValue(tokenAtom);
  let languageMode = useAtomValue(languageModeAtom);
  let [replys, setReplys] = useState<ReplySet | null>(null);
  let [currentURL, setCurrentURL] = useState<string>(API_BASE_URL + '/spaces/' + space.id + '/replys/');
  let [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  let [replyIndex, setReplyIndex] = useState<number>(0);
  let [userBlockProcess, setUserBlockProcess] = useState<number | null>(null);
  let [blockingUser, setBlockingUser] = useState<string | null>(null);
  const { openAlertModal } = useModal();

  let getMoreReplys = async () => {
    if (replys) {
      let moreReplys = await getReplys(replys.next);
      setCurrentURL(replys.next);
      setReplys({
        previous: moreReplys.previous,
        count: moreReplys.count,
        results: [...replys.results, ...moreReplys.results],
        next: moreReplys.next,
      });
    }
  };
  useEffect(() => {
    let isCancelled = false;
    (async () => {
      try {
        let replys = await getReplys(API_BASE_URL + '/spaces/' + space.id + '/replys/');
        if (isCancelled) {
          return;
        }
        setReplys(replys);
      } catch (error) {
        let errorMessage;

        if (error instanceof APIError) {
          errorMessage = error.issues.detail;
        } else {
          errorMessage = error as string;
        }

        openAlertModal([{ type: 'NORMAL', message: errorMessage }]);
      }
    })();
    return () => {
      isCancelled = true;
    };
  }, [space]);
  let replysRef = useRef<HTMLDivElement>(null);

  let ScrollToBottom = () => {
    if (replysRef.current) {
      replysRef.current.scrollTop = replysRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    ScrollToBottom();
  }, [replys]);

  let BlockProcessContent = null;
  if (userBlockProcess) {
    if (userBlockProcess === 1) {
      BlockProcessContent = (
        <ReportOrBlock
          setIsModalOpen={setIsModalOpen}
          blockingUser={blockingUser}
          setUserBlockProcess={setUserBlockProcess}
        />
      );
    } else {
      BlockProcessContent = (
        <ConfirmUserBlock
          setUserBlockProcess={setUserBlockProcess}
          blockingUser={blockingUser}
          setIsReplyOpen={setIsReplyOpen}
          setBlockingAlert={setBlockingAlert}
        />
      );
    }
  }
  return (
    <>
      <div
        className='fixed top-0 left-0 right-0 bottom-12 bg-black/30 lg:hidden'
        onClick={() => {
          setIsReplyOpen(false);
          onCloseHandler && onCloseHandler();
        }}></div>
      <div
        className='flex flex-col items-center fixed bottom-0 left-0 z-20 bg-white w-screen text-zinc-600 rounded-t-lg lg:w-full lg:h-full lg:relative lg:grow-0'
        style={{ maxHeight: '80vh' }}>
        <button
          className='pt-4 lg:hidden'
          onClick={() => {
            setIsReplyOpen(false);
            onCloseHandler && onCloseHandler();
          }}>
          <svg width='41' height='4' viewBox='0 0 41 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect x='0.356201' width='40' height='4' rx='2' fill='#808080' />
          </svg>
        </button>
        {userBlockProcess ? (
          BlockProcessContent
        ) : (
          <>
            <div className='py-4'>
              {replys?.results.length} {scripts[languageMode][10]}
            </div>
            <div className='pb-2 w-full overflow-y-auto flex flex-col lg:grow' id='reply-box' ref={replysRef}>
              <InfiniteScroll
                loader
                dataLength={replys ? replys.results.length : 15}
                next={getMoreReplys}
                style={{ display: 'flex', flexDirection: 'column' }}
                hasMore={replys?.next != ''}
                scrollableTarget='reply-box'>
                {replys?.results.map((result, index) => (
                  <SingleReply
                    key={index}
                    index={index}
                    reply={result}
                    setIsModalOpen={setIsModalOpen}
                    setReplyIndex={setReplyIndex}
                    replys={replys}
                    setReplys={setReplys}
                    setUserBlockProcess={setUserBlockProcess}
                    setBlockingUser={setBlockingUser}
                  />
                ))}
              </InfiniteScroll>
            </div>
            <ReplyInput spaceId={space.id} replys={replys} setReplys={setReplys} currentURL={currentURL} />
          </>
        )}
        {replys?.results[replyIndex] && token && (
          <ReplyReportModal
            reply={replys.results[replyIndex]}
            token={token}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        )}
      </div>
    </>
  );
}

function ReportOrBlock({
  setIsModalOpen,
  blockingUser,
  setUserBlockProcess,
}: {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  blockingUser: string | null;
  setUserBlockProcess: Dispatch<SetStateAction<number | null>>;
}) {
  let languageMode = useAtomValue(languageModeAtom);
  return (
    <>
      <div className='py-2 w-full border-b border-zinc-200 text-center font-semibold'>{blockingUser}</div>
      <div
        className='py-2 max-h-full w-full overflow-y-auto flex flex-col items-start lg:grow px-4 mb-24'
        id='reply-box'>
        <button
          className='py-2'
          onClick={() => {
            setIsModalOpen(true);
          }}>
          {scripts[languageMode][11]}
        </button>
        <button
          className='py-2 text-error'
          onClick={() => {
            setUserBlockProcess(2);
          }}>
          {blockingUser + ' ' + scripts[languageMode][12]}
        </button>
      </div>
    </>
  );
}

export function ConfirmUserBlock({
  setUserBlockProcess,
  blockingUser,
  setIsReplyOpen,
  setBlockingAlert,
}: {
  setUserBlockProcess: Dispatch<SetStateAction<number | null>>;
  blockingUser: string | null;
  setIsReplyOpen: Dispatch<SetStateAction<boolean>>;
  setBlockingAlert: Dispatch<SetStateAction<boolean>>;
}) {
  let token = useAtomValue(tokenAtom);
  let navigate = useNavigate();
  let languageMode = useAtomValue(languageModeAtom);
  const { openAlertModal } = useModal();

  return (
    <>
      <div className='flex w-full items-center py-2 border-b border-zinc-200'>
        <div className='grow basis-0 justify-end'>
          <button className='flex-initial p-2.5' onClick={() => setUserBlockProcess(1)}>
            <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <g clipPath='url(#clip0_553_9851)'>
                <path d='M18 23.3281L6 12.3281L18 1.32812' stroke='black' strokeWidth='2' strokeLinecap='round' />
              </g>
              <defs>
                <clipPath id='clip0_553_9851'>
                  <rect width='24' height='24' fill='white' transform='translate(0 0.328125)' />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
        <div className='flex flex-col items-center font-normal'>
          <div>
            <div className='inline font-semibold'>{blockingUser + (languageMode === 'KR' ? '님을' : '')}</div>
          </div>
          <div>{scripts[languageMode][13]}</div>
        </div>
        <div className='flex grow basis-0 justify-end'></div>
      </div>
      <div className='p-4'>
        <div className='text-zinc-300 pb-4 text-center mb-16'>{scripts[languageMode][14]}</div>
        <button
          className='w-full bg-pink-600 text-white py-2 rounded'
          onClick={async () => {
            if (token && blockingUser) {
              let result = await blockUser(token, blockingUser);
              if (result) {
                setBlockingAlert(true);
                setTimeout(() => {
                  setBlockingAlert(false);
                }, 2000);
              } else {
                openAlertModal([{ type: 'NORMAL', message: '일시적인 오류가 발생했습니다. 다시 진행해주세요' }]);
              }
              setIsReplyOpen(false);
            }
          }}>
          {scripts[languageMode][15]}
        </button>
      </div>
    </>
  );
}
