import { UseLoginResources } from './types';

import { Scripts } from 'src/types';

const scripts: Scripts = {
  KR: [
    '아이디 입력',
    '비밀번호 입력',
    '로그인',
    '회원가입',
    '비밀번호를 잊으셨나요?',
    '아이디 또는 비밀번호가',
    '일치하지 않습니다.',
    '계정이 없으신가요?',
    '회원가입',
    '아이디',
    '비밀번호',
    '이용약관, ',
    '개인정보 수집 및 이용, ',
    '개인정보 제공',
    '내용을 확인하였고 동의합니다.',
  ],
  EN: [
    'Enter ID',
    'Enter Password',
    'Login',
    'Membership',
    'Find Password',
    'ID or Password',
    'Mismatch',
    "Don't have an account?",
    'Sign Up',
    'ID',
    'Password',
    'Terms of service, ',
    'Collection and use of personal information, ',
    'Provision of personal information',
    'I have checked the contents and I agree.',
  ],
  JP: [
    'ID入力',
    'パスワード入力',
    'ログイン',
    '会員登録',
    'パスワード検索',
    'IDまたはパスワードが',
    '一致しません。',
    'アカウントがありませんか？',
    '会員登録',
    'ID',
    'パスワード',
    '利用規約, ',
    '個人情報の収集と利用, ',
    '個人情報提供',
    '内容を確認して同意します。',
  ],
};

const useLoginResources = (): UseLoginResources => {
  return { scripts };
};

export default useLoginResources;
