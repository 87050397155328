import React, { useEffect, useRef, useState } from 'react';

import { Checked, Unchecked, PlayButton, Pause } from 'components/Commons/icons';

// const calculateTime = (secs:number) => {
//     if(!secs){
//         return '0:00';
//     }
//     const minutes = Math.floor(secs / 60);
//     const seconds = Math.floor(secs % 60);
//     const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
//     return `${minutes}:${returnedSeconds}`;
// }

export default function BgmPlayer({
  audioUrl,
  bgmName,
  prodName,
  index,
  isChecked,
  setCurrentAudio,
  setBgm,
  isPlaying,
  setIsPlaying,
}: {
  audioUrl: string;
  bgmName: string;
  prodName: string;
  index: number;
  isChecked: boolean;
  setCurrentAudio: React.Dispatch<React.SetStateAction<number>>;
  setBgm: React.Dispatch<React.SetStateAction<number>>;
  isPlaying: { index: number; play: boolean };
  setIsPlaying: React.Dispatch<React.SetStateAction<{ index: number; play: boolean }>>;
}) {
  const [isPlay, setIsPlay] = useState<boolean>(false);
  const [durationText, setDurationText] = useState<string>('0:00');
  const audioRef = useRef<HTMLAudioElement>(null);
  const timeLineRef = useRef<HTMLInputElement>(null);
  const times = ['7:31', '4:04', '4:02', '3:00', '5:28', '8:47'];
  function changeTimeline() {
    if (audioRef.current && timeLineRef.current) {
      const percentagePosition = (100 * audioRef.current.currentTime) / audioRef.current.duration;
      timeLineRef.current.style.backgroundSize = `${percentagePosition}% 100%`;
      timeLineRef.current.value = percentagePosition.toString();
    }
  }
  function changeSeek() {
    if (audioRef.current && timeLineRef.current) {
      const time = (parseInt(timeLineRef.current.value) * audioRef.current.duration) / 100;
      audioRef.current.currentTime = time;
    }
  }
  useEffect(() => {
    if (audioRef.current && timeLineRef.current) {
      audioRef.current.ontimeupdate = changeTimeline;
      audioRef.current.onended = () => {
        setIsPlay(false);
      };
      setDurationText(times[index]);
    }
  }, [audioRef, timeLineRef]);
  useEffect(() => {
    if (isPlaying.index === index && isPlaying.play === true) {
      audioRef.current?.play();
      setIsPlay(true);
    } else {
      audioRef.current?.pause();
      setIsPlay(false);
    }
  }, [isPlaying]);
  return (
    <div className='bgm-item'>
      <audio src={audioUrl} preload='metadata' ref={audioRef} loop></audio>
      <div className='bgm-box'>
        <div className='bgm-name-box'>
          <div className='bgm-name'>{bgmName}</div>
          <div className='bgm-prod'>{prodName}</div>
        </div>
        <div className='time'>{durationText}</div>
        <div className='icon-box'>
          <button
            className='bgm-icon'
            onClick={() => {
              if (isPlay) {
                setIsPlaying({ index: index, play: false });
              } else {
                setIsPlaying({ index: index, play: true });
              }
              setIsPlay(!isPlay);
            }}>
            {isPlay ? <Pause /> : <PlayButton />}
          </button>
        </div>
        <div className='icon-box'>
          <button
            className='bgm-icon'
            onClick={() => {
              setCurrentAudio(index);
              setBgm(index + 1);
            }}>
            {isChecked ? <Checked /> : <Unchecked />}
          </button>
        </div>
      </div>
      <input type='range' className='timeline' max='100' value='0' ref={timeLineRef} onChange={changeSeek} />
    </div>
  );
}
