import styled from 'styled-components';

import { BreakPoint, Color } from 'src/constants';

export const CertificateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  background-color: ${Color.white};
  color: ${Color.black};

  .clickable:hover {
    cursor: pointer;
  }

  ${BreakPoint.mobile} {
    justify-content: flex-start;
  }
`;

export const CertificateForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 29.25rem;
  margin: 0 0 17.5rem;

  ${BreakPoint.mobile} {
    width: 87.2%;
    margin: 2.75rem 0 10.125rem 0;
  }
`;

export const CertificateTitle = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 2rem;
  letter-spacing: -0.015rem;

  ${BreakPoint.mobile} {
    margin-top: 9.375rem;
  }
`;

export const CertificateSubtitle = styled.div`
  width: 100%;
  margin-top: 1rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.015rem;
  color: #808080;
`;

const Button = styled.div.attrs({ className: 'clickable' })<{
  isActive: boolean;
}>`
  width: 100%;
  font-weight: 700;
  line-height: 1.375rem;
  letter-spacing: -0.025rem;
  text-align: center;
  padding: 0.875rem 0;
  border-radius: 0.25rem;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};

  &:hover {
    opacity: ${({ isActive }) => (isActive ? 0.8 : 0.5)};
  }
`;

export const SignUpCompleteButton = styled(Button)`
  margin-top: 0.875rem;
  background-color: #f4bcd5;
  color: ${Color.white};

  ${BreakPoint.mobile} {
    margin-top: 0.375rem;
  }
`;
