import { Dispatch, useState, useRef, useEffect } from 'react';
import { SetStateAction, useAtomValue } from 'jotai';
import { Canvas } from '@react-three/fiber';

import {
  FrameSetting,
  LightSetting,
  ColorSetting,
  ToLeftGray,
  ToRight,
} from 'components/Commons/icons';
import { uploadFile, Space, APIError } from 'apis/index';
import Objects from 'react3fiber/r3f-space/objects';

import { tokenAtom, languageModeAtom } from 'src/atoms';
import useModal from 'hooks/useModal';
import { Gradient } from 'lamina';
import styled from 'styled-components';

const scripts = {
  KR: ['공간 설정', '자르기', '액자', '조명', '테마', '상세설정', '확인', '색'],
  EN: ['Space setting', 'Crop', 'Frame', 'Light', 'Theme', 'Details', 'Confirm'],
  JP: ['スペース設定', '切り取り', '額縁', '照明', 'テーマ', '詳細設定', '確認'],
};

const CustomInputHueRange = styled.input.attrs({ type: 'range' })`
  width: 20rem;
  height: 1rem;
  cursor: pointer;
  -webkit-appearance: none;
  background: linear-gradient(
    270deg,
    #ff0000 0%,
    #ff5c00 8.33%,
    #faff00 16.67%,
    #8fff00 27.08%,
    #05ff00 36.46%,
    #00ff85 44.27%,
    #00ffff 50%,
    #0094ff 58.33%,
    #001aff 67.19%,
    #7000ff 73.96%,
    #cc00ff 82.29%,
    #ff008a 88.54%,
    #ff0000
  );
  border-radius: 5px;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 13px;
    height: 1.5rem;
    background-color: #333333;
    border-radius: 5px;
  }
`;

const CustomInputSaturationRange = styled(CustomInputHueRange).attrs({ type: 'range' })`
  background: linear-gradient(90deg, #a7a5a2 0%, #d98e5a 100%);
`;

const CustomInputIntensityRange = styled(CustomInputHueRange).attrs({ type: 'range' })`
  background: linear-gradient(90deg, #000000 0%, #ffffff 100%);
`;

export default function SpaceDetails({
  setProgress,
  preSpace,
  themeValue,
  setThemeValue,
  intensity,
  setIntensity,
  frameValue,
  setFrameValue,
  capturedImg,
  setCapturedImg,
  setThumbId,
  hue,
  setHue,
  saturation,
  setSaturation,
  brightness,
  setBrightness,
}: {
  setProgress: Dispatch<SetStateAction<number>>;
  preSpace: Space;
  themeValue: number;
  setThemeValue: Dispatch<SetStateAction<number>>;
  intensity: number;
  setIntensity: Dispatch<SetStateAction<number>>;
  hue: number;
  setHue: Dispatch<SetStateAction<number>>;
  saturation: number;
  setSaturation: Dispatch<SetStateAction<number>>;
  brightness: number;
  setBrightness: Dispatch<SetStateAction<number>>;

  frameValue: number;
  setFrameValue: Dispatch<SetStateAction<number>>;
  capturedImg: File | null;
  setCapturedImg: Dispatch<SetStateAction<File | null>>;
  setThumbId: React.Dispatch<React.SetStateAction<string | null>>;
}) {
  let token = useAtomValue(tokenAtom);
  let languageMode = useAtomValue(languageModeAtom);
  let [mode, setMode] = useState<string>('theme');
  let [settingField, setSettingField] = useState<string>('default');
  let defaultTheme = new URL('../../../static/imgs/DE.jpg', import.meta.url);
  let cartoonTheme = new URL('../../../static/imgs/cartoon.png', import.meta.url);
  let louvreTheme = new URL('../../../static/imgs/louvre4.png', import.meta.url);
  let laserTheme = new URL('../../../static/imgs/mirror2.png', import.meta.url);
  let flowerTheme = new URL('../../../static/imgs/flower2.jpg', import.meta.url);
  let diagonalTheme = new URL('../../../static/imgs/diagonar2.jpg', import.meta.url);
  let spaceTheme = new URL('../../../static/imgs/space.jpg', import.meta.url);
  let cityTheme = new URL('../../../static/imgs/city.png', import.meta.url);
  let noFrame = new URL('../../../static/imgs/noframe.png', import.meta.url);
  let defaultFrame = new URL('../../../static/imgs/defaultF.jpg', import.meta.url);
  let [checkItemLoading, setCheckItemLoading] = useState<boolean | null>(false);
  const { openAlertModal } = useModal();
  let CanvasElRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    if (CanvasElRef.current) {
      let capture = CanvasElRef.current;
      capture.toBlob(function (blob: Blob | null) {
        if (blob) {
          let c = new File([blob], 'captured.jpeg', {
            type: 'image/jpeg',
          });
          setCapturedImg(c);
        }
      }, 'image/jpeg');
    }
  }, [preSpace, themeValue, setCapturedImg]);

  let getThumbId = async () => {
    if (capturedImg && token) {
      try {
        let id = await uploadFile(token, capturedImg);
        setThumbId(id.id);
      } catch (error) {
        let errorMessage;

        if (error instanceof APIError) {
          errorMessage = error.issues.detail;
        } else {
          errorMessage = error as string;
        }

        openAlertModal([{ type: 'NORMAL', message: errorMessage }]);
        setProgress(1);
      }
    }
  };
  return (
    <div className='h-full flex flex-col'>
      <div className='flex justify-center h-11 shrink-0 bg-white'>
        <div className='grow basis-0 justify-end'>
          <button
            className='p-2.5'
            onClick={() => {
              setProgress(2);
            }}>
            <ToLeftGray />
          </button>
        </div>
        <div className='flex items-center text-xl font-semibold'>{scripts[languageMode][0]}</div>
        <div className='flex grow basis-0 justify-end'>
          <button
            className='p-2.5'
            onClick={() => {
              setProgress(4);
              getThumbId();
            }}>
            <ToRight />
          </button>
        </div>
      </div>
      <div className='h-1/2'>
        <Canvas
          ref={CanvasElRef}
          gl={{ preserveDrawingBuffer: true }}
          shadows
          camera={{ fov: 75 }}
          onCreated={({ camera }) => {
            camera.position.set(0, 0, 5);
            camera.lookAt(0, 0, 0);
          }}>
          <Objects space={preSpace} device={null} displayStatus={null} />
        </Canvas>
      </div>
      {settingField === 'default' ? (
        <div className='flex flex-col grow relative'>
          {mode === 'theme' && (
            <div className='grow flex items-center'>
              <div className='flex gap-6 px-6 overflow-x-scroll'>
                <div
                  className='mb-4'
                  onClick={() => {
                    setThemeValue(1);
                  }}>
                  <div
                    className={
                      'h-36 w-36 rounded mb-4 drop-shadow-lg ' +
                      (themeValue === 1 && 'border-2 border-pink-500')
                    }>
                    <img src={defaultTheme.href} />
                  </div>
                  <div className='text-xs text-center'>theme1</div>
                </div>
                <div
                  className='mb-4'
                  onClick={() => {
                    setThemeValue(2);
                  }}>
                  <div
                    className={
                      'h-36 w-36 rounded mb-4 drop-shadow-lg ' +
                      (themeValue === 2 && 'border-2 border-pink-500')
                    }>
                    <img src={preSpace.items[0].type === 1 ? cartoonTheme.href : laserTheme.href} />
                  </div>
                  <div className='text-xs text-center'>theme2</div>
                </div>
                <div
                  className='mb-4'
                  onClick={() => {
                    setThemeValue(3);
                  }}>
                  <div
                    className={
                      'h-36 w-36 rounded mb-4 drop-shadow-lg ' +
                      (themeValue === 3 && 'border-2 border-pink-500')
                    }>
                    <img src={louvreTheme.href} />
                  </div>
                  <div className='text-xs text-center'>theme3</div>
                </div>
                <div
                  onClick={() => {
                    setThemeValue(5);
                  }}>
                  <div
                    className={
                      'h-36 w-36 rounded mb-4 drop-shadow-lg ' +
                      (themeValue === 5 && 'border-2 border-pink-500')
                    }>
                    <img src={diagonalTheme.href} />
                  </div>
                  <div className='text-xs text-center'>theme4</div>
                </div>
                <div
                  onClick={() => {
                    setThemeValue(4);
                  }}>
                  <div
                    className={
                      'h-36 w-36 rounded mb-4 drop-shadow-lg ' +
                      (themeValue === 4 && 'border-2 border-pink-500')
                    }>
                    <img src={flowerTheme.href} />
                  </div>
                  <div className='text-xs text-center'>theme5</div>
                </div>
                <div
                  onClick={() => {
                    setThemeValue(6);
                  }}>
                  <div
                    className={
                      'h-36 w-36 rounded mb-4 drop-shadow-lg ' +
                      (themeValue === 6 && 'border-2 border-pink-500')
                    }>
                    <img src={spaceTheme.href} />
                  </div>
                  <div className='text-xs text-center'>theme6</div>
                </div>
                <div
                  onClick={() => {
                    setThemeValue(7);
                  }}>
                  <div
                    className={
                      'h-36 w-36 rounded mb-4 drop-shadow-lg ' +
                      (themeValue === 7 && 'border-2 border-pink-500')
                    }>
                    <img src={cityTheme.href} />
                  </div>
                  <div className='text-xs text-center'>theme7</div>
                </div>
              </div>
            </div>
          )}
          {mode === 'settings' && (
            <div className='grow flex items-center'>
              <div className='p-4 flex gap-4'>
                {preSpace.items[0].type == 1 && (
                  <button
                    onClick={() => {
                      setSettingField('Frame');
                    }}>
                    <div className='p-4 border border-zinc-200 rounded mb-2'>
                      <FrameSetting />
                    </div>
                    <div className='text-xs'>{scripts[languageMode][2]}</div>
                  </button>
                )}
                <button
                  onClick={() => {
                    setSettingField('Light');
                  }}>
                  <div className='p-4 border border-zinc-200 rounded mb-2'>
                    <LightSetting />
                  </div>
                  <div className='text-xs'>{scripts[languageMode][3]}</div>
                </button>
                {/* <button
                  onClick={() => {
                    setSettingField('Color');
                  }}>
                  <div className='p-4 border border-zinc-200 rounded mb-2'>
                    <ColorSetting />
                  </div>
                  <div className='text-xs'>{scripts[languageMode][7]}</div>
                </button> */}
              </div>
            </div>
          )}

          <div className='w-full flex h-10 shrink-0'>
            <button
              type='button'
              className={
                mode === 'theme'
                  ? 'flex-1 text-sm border-b-2 border-black'
                  : 'flex-1 text-sm border-b-2 text-zinc-300 border-white'
              }
              onClick={() => {
                setMode('theme');
              }}>
              {scripts[languageMode][4]}
            </button>
            <button
              type='button'
              className={
                mode === 'settings'
                  ? 'flex-1 text-sm border-b-2 border-black'
                  : 'flex-1 text-sm border-b-2 text-zinc-300 border-white'
              }
              onClick={() => {
                setMode('settings');
              }}>
              {scripts[languageMode][5]}
            </button>
          </div>
        </div>
      ) : (
        <div className='flex flex-col grow relative'>
          {settingField === 'Light' && (
            <div className='grow flex flex-col items-center justify-center'>
              <div className='text-xs mb-4'>{intensity}</div>
              <input
                className='w-80 mb-12'
                type='range'
                id='size'
                name='intensity'
                min='10'
                max='100'
                step='1'
                value={intensity}
                onChange={(e) => setIntensity(Number(e.target.value))}
              />
            </div>
          )}
          {/* {settingField === 'Color' && (
            <div className='grow flex flex-col items-center justify-center'>
              <div className='flex flex-col'>
                <div className='text-xs my-1'>색</div>
                <CustomInputHueRange
                  min='0'
                  max='360'
                  step='30'
                  value={hue}
                  onChange={(e) => setHue(Number(e.target.value))}
                />
              </div>
              <div className='flex flex-col'>
                <div className='text-xs my-1'>채도</div>
                <CustomInputSaturationRange
                  min='1'
                  max='100'
                  step='1'
                  value={saturation}
                  onChange={(e) => setSaturation(Number(e.target.value))}
                />
              </div>
              <div className='flex flex-col items-start'>
                <div className='text-xs my-1'>명도</div>
                <CustomInputIntensityRange
                  min='1'
                  max='100'
                  step='1'
                  value={brightness}
                  onChange={(e) => setBrightness(Number(e.target.value))}
                />
              </div>
            </div>
          )} */}
          {settingField === 'Frame' && (
            <div className='grow flex items-center justify-center'>
              <div className='flex grow gap-6 px-6'>
                <div
                  onClick={() => {
                    setFrameValue(0);
                  }}>
                  <div
                    className={
                      'h-36 w-36 rounded mb-4 drop-shadow-lg ' +
                      (frameValue === 0 && 'border-2 border-pink-500')
                    }>
                    <img src={noFrame.href} />
                  </div>
                  <div className='text-xs text-center'>None</div>
                </div>
                <div
                  onClick={() => {
                    setFrameValue(1);
                  }}>
                  <div
                    className={
                      'h-36 w-36 rounded mb-4 drop-shadow-lg ' +
                      (frameValue === 1 && 'border-2 border-pink-500')
                    }>
                    <img src={defaultFrame.href} />
                  </div>
                  <div className='text-xs text-center'>Frame1</div>
                </div>
              </div>
            </div>
          )}
          <div className='w-full flex h-10 shrink-0'>
            <button
              className='flex-1'
              onClick={() => {
                setSettingField('default');
              }}>
              {scripts[languageMode][6]}
            </button>
          </div>
        </div>
      )}
      {/* <input
        type='number'
        value={themeValue}
        onChange={(e) => {
          setThemeValue(Number(e.target.value));
        }}
      /> */}
      {/* <input
        className='w-80'
        type='range'
        id='size'
        name='intensity'
        min='0'
        max='1'
        step='0.01'
        value={intensity}
        onChange={(e) => setIntensity(Number(e.target.value))}
      />
      <input
        type='number'
        value={frameValue}
        onChange={(e) => {
          setFrameValue(Number(e.target.value));
        }}
      /> */}
    </div>
  );
}
