import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAtomValue, useSetAtom } from 'jotai';

import {
  CertificateContainer,
  CertificateForm,
  CertificateTitle,
  CertificateSubtitle,
  SignUpCompleteButton,
} from './styles';
import { InputData } from './types';
import useCertificateResources from './resources';
import PhoneInput from './components/PhoneInput';
import MarketingFlag from './components/MarketingFlag';
import CertificateCodeVerifyInput from './components/CertificateCodeVerifyInput';

import Footer from 'components/Footer';
import Header from 'components/Header';
import useTimer from 'hooks/useTimer';
import useModal from 'hooks/useModal';
import { APIError, register } from 'apis/index';
import { apiErrorCodeToMessage } from 'utils/errorUtils';

import { languageModeAtom, navbarTypeAtom } from 'src/atoms';
import { ErrorCode } from 'src/types';
import channelService from 'src/channel-service';

function Certificate(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const setNavbarType = useSetAtom(navbarTypeAtom);
  const languageMode = useAtomValue(languageModeAtom);
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState(82);
  const [phoneNumberInputData, setPhoneNumberInputData] = useState<InputData>();
  const [certificateCodeButtonClickCount, setCertificateCodeButtonClickCount] = useState(0);
  const [verifyCodeNumberInputData, setVerifyCodeNumberInputData] = useState<InputData>();
  const [certificateKey, setCertificateKey] = useState<string>();
  const [marketingFlag, setMarketingFlag] = useState(false);
  const [phoneToken, setPhoneToken] = useState<string>();
  const { scripts } = useCertificateResources();
  const mediaMatch = useMediaQuery({ maxWidth: 1224 });
  const { openAlertModal } = useModal();
  const { time, isFinished, run, reload } = useTimer({
    hours: 0,
    minutes: 5,
    seconds: 0,
    formatType: 'h:m:ss',
  });

  /*  
    INFO

    Sign Up 첫번째 단계에서 넘어오는 필수 데이터가 없을 경우, 다시 이전 페이지로 보내준다.
  */
  useEffect(() => {
    if (location) {
      if (!location.state || !location.state.id || !location.state.email || !location.state.password) {
        navigate('/signup');
      }
    }
  }, [location]);

  useEffect(() => {
    setNavbarType('certificate');
    channelService.boot({
      pluginKey: '2d7dd802-484a-413a-adb6-9f2b6e9c8dc7',
    });

    return () => {
      channelService.shutdown();
      setNavbarType('feed');
    };
  }, []);

  const isSignUpCompleteButtonActive = useMemo(() => !!phoneToken, [phoneToken]);

  const isCertificateCodeVerifyInput = useMemo(() => !!certificateKey && !phoneToken, [certificateKey, phoneToken]);

  const onSignUpCompleteButtonClick = useCallback(async () => {
    if (!isSignUpCompleteButtonActive) return;

    if (phoneToken) {
      try {
        const response = await register(
          location.state.id,
          phoneToken,
          location.state.password,
          location.state.email,
          marketingFlag
        );

        if (response.result) {
          navigate('/login?register=true');
        } else {
          openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][19] }]);
        }
      } catch (error) {
        if (error instanceof APIError) {
          setVerifyCodeNumberInputData((prev) => ({
            ...prev,
            value: prev?.value ? prev.value : undefined,
            isError: true,
            errorMessage: apiErrorCodeToMessage((error as APIError).issues.code as ErrorCode, scripts[languageMode][2])[
              languageMode
            ][0],
          }));
        }
      }
    }
  }, [location, phoneToken, languageMode]);

  return (
    <CertificateContainer>
      <CertificateForm>
        {mediaMatch && <Header />}
        <CertificateTitle>{scripts[languageMode][0]}</CertificateTitle>
        <CertificateSubtitle>
          {scripts[languageMode][1]}
          <br />
          {scripts[languageMode][2]}
        </CertificateSubtitle>
        <PhoneInput
          label={scripts[languageMode][3]}
          placeholder={scripts[languageMode][4]}
          phoneToken={phoneToken}
          certificateCodeButtonClickCount={certificateCodeButtonClickCount}
          phoneNumberCountryCode={phoneNumberCountryCode}
          phoneNumberInputData={phoneNumberInputData}
          setCertificateCodeButtonClickCount={setCertificateCodeButtonClickCount}
          setPhoneNumberCountryCode={setPhoneNumberCountryCode}
          setPhoneNumberInputData={setPhoneNumberInputData}
          setCertificateKey={setCertificateKey}
          timerRun={run}
          timerReload={reload}
        />
        {isCertificateCodeVerifyInput && (
          <CertificateCodeVerifyInput
            placeholder={scripts[languageMode][5]}
            time={time}
            isTimerFinished={isFinished}
            phoneNumberCountryCode={phoneNumberCountryCode}
            phoneNumberInputData={phoneNumberInputData}
            verifyCodeNumberInputData={verifyCodeNumberInputData}
            setVerifyCodeNumberInputData={setVerifyCodeNumberInputData}
            setPhoneToken={setPhoneToken}
            certificateKey={certificateKey}
          />
        )}
        <MarketingFlag isChecked={marketingFlag} setIsChecked={setMarketingFlag} text={scripts[languageMode][6]} />
        <SignUpCompleteButton isActive={isSignUpCompleteButtonActive} onClick={onSignUpCompleteButtonClick}>
          {scripts[languageMode][7]}
        </SignUpCompleteButton>
      </CertificateForm>
      <Footer />
    </CertificateContainer>
  );
}

export default Certificate;
