import { useAnimations, Environment } from '@react-three/drei';
import { useMemo, useEffect } from 'react';
import { Vector3, Euler } from '@react-three/fiber';
import * as THREE from 'three';
import { SkeletonUtils } from 'three-stdlib';
import Grass2 from './grass2';

import { useCompressedGLTF } from 'hooks/hooks';

const url = new URL('static/models/Adler_009.min.glb', import.meta.url);
const url2 = new URL('static/imgs/syfer_fontein8k.hdr', import.meta.url);

type SpaceMode = 'multiplay' | 'scroll';

export default function StoneTheme({
  position,

  rotation,
}: {
  position: Vector3;

  rotation: Euler;
}) {
  const { scene: scene1, animations } = useCompressedGLTF(url.href);
  const { ref, actions, names } = useAnimations(animations);

  const clonedScene1 = useMemo(() => SkeletonUtils.clone(scene1), [scene1]);
  const disposeMesh: THREE.Mesh[] = [];
  const disposeTexture: THREE.Texture[] = [];

  clonedScene1.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      const mesh = object as THREE.Mesh;
      mesh.material = mesh.material as THREE.Material;

      if (['mesh_0'].includes(mesh.name)) {
        mesh.visible = false;
      }
      const mat = mesh.material as THREE.Material;
      disposeMesh.push(mesh);
      if (mat.map !== null) {
        disposeTexture.push(mat.map);
      }
    }
  });

  useEffect(() => {
    for (let i = 0; i < names.length; i++) {
      actions[names[i]]?.reset().play();
    }
  }, [actions, names]);
  useEffect(() => {
    return () => {
      for (let i = 0; i < disposeMesh.length; i++) {
        if (disposeMesh[i]) disposeMesh[i].geometry.dispose();
      }
      for (let i = 0; i < disposeTexture.length; i++) {
        if (disposeTexture[i]) disposeTexture[i].dispose();
      }
    };
  }, []);
  return (
    <>
      <group
        ref={ref}
        position={[position[0], position[1] + 1, position[2] - 5]}
        scale={1}
        rotation={rotation}>
        <primitive object={clonedScene1} />
      </group>

      <Environment files={url2.href} background />
      {/* <group
        position={[position[0], position[1] - 1.2, position[2] - 5]}
        scale={0.2}
        rotation={[0, 0, 0]}>
        <Grass2 />
      </group> */}
    </>
  );
}
