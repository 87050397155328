import { useCallback } from 'react';
import { useAtomCallback } from 'jotai/utils';

import { customModalAtom, CustomModalAtom } from 'src/atoms';

import AlertModal from 'components/CustomModal/components/AlertModal';
import { MessageInfo } from 'components/CustomModal/components/AlertModal/types';
import { ConfirmModalProps } from 'components/CustomModal/components/ConfirmModal/types';
import ConfirmModal from 'components/CustomModal/components/ConfirmModal';
import { InstanceMessageModalProps } from 'components/CustomModal/components/InstanceMessageModal/types';
import InstanceMessageModal from 'components/CustomModal/components/InstanceMessageModal';

interface UseModal {
  openModal: (arg: CustomModalAtom) => void | Promise<void>;
  openAlertModal: (arg: MessageInfo[]) => void | Promise<void>;
  openConfirmModal: (arg: ConfirmModalProps) => void | Promise<void>;
  openInstanceMessageModal: (arg: InstanceMessageModalProps) => void | Promise<void>;
  closeModal: (arg?: boolean | undefined) => void | Promise<void>;
  closeAllModals: (arg?: unknown) => void | Promise<void>;
}

/*
  INFO

  useCallback은 atom state의 변화를 정확히 인지하지 못할 때가 있으므로, useAtomCallback을 사용하여 확실히 감지.
*/
function useModal(): UseModal {
  const openModal = useAtomCallback(
    useCallback((_get, set, modal: CustomModalAtom) => {
      set(customModalAtom, (prev) => {
        return [...prev, modal];
      });
    }, [])
  );

  const openAlertModal = useAtomCallback(
    useCallback((_get, set, messageInfos: MessageInfo[]) => {
      set(customModalAtom, (prev) => {
        return [
          ...prev,
          {
            component: <AlertModal messageInfos={messageInfos} />,
            options: {
              align: { alignItems: 'center', justifyContent: 'center' },
              isCloseOnESCKeyDown: true,
              isCloseOnOverlayClick: true,
              isOverlayed: true,
            },
          },
        ];
      });
    }, [])
  );

  const openConfirmModal = useAtomCallback(
    useCallback((_get, set, modalOptions: ConfirmModalProps) => {
      set(customModalAtom, (prev) => {
        return [
          ...prev,
          {
            component: <ConfirmModal {...modalOptions} />,
            options: {
              align: { alignItems: 'center', justifyContent: 'center' },
              isCloseOnESCKeyDown: true,
              isClosedOnOverlayClick: true,
              isOverlayed: true,
            },
          },
        ];
      });
    }, [])
  );

  const openInstanceMessageModal = useAtomCallback(
    useCallback((_get, set, modalOptions: InstanceMessageModalProps) => {
      set(customModalAtom, (prev) => {
        return [
          ...prev,
          {
            component: <InstanceMessageModal {...modalOptions} />,
            options: {
              align: { alignItems: 'center', justifyContent: 'center' },
            },
          },
        ];
      });
    }, [])
  );

  const closeModal = useAtomCallback(
    useCallback((_get, set, hasAction?: boolean) => {
      set(customModalAtom, (prev) => {
        if (prev.length === 0) return prev;

        const activeModal = prev.pop();
        activeModal && activeModal.onClosed && activeModal.onClosed(hasAction);
        return [...prev];
      });
    }, [])
  );

  const closeAllModals = useAtomCallback(
    useCallback((_get, set) => {
      set(customModalAtom, (prev) => {
        if (prev.length === 0) return prev;

        prev.map((prevItem) => prevItem.onClosed && prevItem.onClosed());
        return [];
      });
    }, [])
  );

  return {
    openModal,
    openAlertModal,
    openConfirmModal,
    openInstanceMessageModal,
    closeModal,
    closeAllModals,
  };
}

export default useModal;
