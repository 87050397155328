import { useRef, useEffect, useMemo, useState } from 'react';
import { useXR, useController } from '@react-three/xr';
import * as THREE from 'three';
import { useCylinder } from '@react-three/cannon';
import { useFrame } from '@react-three/fiber';
import { useSetAtom, useAtom } from 'jotai';
import { useTexture } from '@react-three/drei';

import Vrcharacter from './vrcharacter';

import { spacechannel, bgmModeAtom } from 'src/atoms';
// mapping
// 1: Trigger
// 2: Grip
// 4: Stick Buttons
// 5: A/X
// 6: B/Y

// axes
// 2: XStick
// 3: YStick
let prev = false;
let next = false;
let bgm = false;
let rbutton = false;
let url = new URL('../../../static/imgs/Slide 16_9 - 1.png', import.meta.url);
export default function MovementController({
  hand = 'right',
  rotationSensitivity = 0.05,
  deadzone = 0.05,
  horizontalAxis = 2,
  forwardAxis = 3,
  rotationAxis = 2,
  applyRotation = true,
  count,
  spacelength,
}: {
  hand: XRHandedness;
  rotationSensitivity: number;
  deadzone: number;
  horizontalAxis: number;
  forwardAxis: number;
  rotationAxis: number;
  applyRotation: boolean;
  count: number;
  spacelength: number;
}) {
  let { player } = useXR();
  let controller = useController(hand);
  let forward = useRef(new THREE.Vector3());
  let [bgmMode, setBgmMode] = useAtom(bgmModeAtom);
  let horizontal = useRef(new THREE.Vector3());
  let velocity = useRef([0, 0, 0]);
  let [guide, setGuide] = useState(true);
  let [bgmon, setBgmon] = useState(true);
  let direction = useMemo(() => new THREE.Vector3(), []);
  let [ref, api] = useCylinder(() => ({
    mass: 1,
    type: 'Dynamic',
    position: [hand == 'left' ? 15 : 150000, 15, 0],
    scale: [1, 1, 1],
    args: [1, 1, 10],
  }));

  let setCount = useSetAtom(spacechannel);
  let texture = useTexture(url.href);
  const handleKeyPress = (e) => {
    if (e.code === 'Enter') {
      count++;
      setCount(count);
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);
  useEffect(() => api.velocity.subscribe((v) => (velocity.current = v)), [api.velocity]);
  useFrame(() => {
    if (controller && player) {
      let { axes } = controller.inputSource.gamepad;
      let buttons = controller.inputSource.gamepad?.buttons;
      if (hand == 'left') {
        ref.current?.getWorldPosition(player.position);
        horizontal = axes[horizontalAxis];
        forward = axes[forwardAxis];
        direction.set(horizontal, 0, forward).multiplyScalar(5).applyEuler(player.rotation);
        api.velocity.set(direction.x, 0, direction.z);
        api.rotation.set(0, player.rotation.y, 0);
      }

      if (buttons[4].pressed == true && hand == 'left') {
        //buttonX
        if (next == false) {
          if (count < spacelength - 1) {
            count++;
            setCount(count);
            api.position.set(15, 15, 0);
            next = true;
          }
        }
      } else if (buttons[4].pressed == false && hand == 'left') {
        if (hand == 'left' && next == true) {
          next = false;
        }
      }

      if (buttons[4].pressed == true && hand == 'right') {
        //buttonX
        if (bgm == false && hand == 'right') {
          bgm = true;
          setBgmon(bgmon == true ? false : true);
          setBgmMode(bgmon);
        }
      } else if (buttons[4].pressed == false && hand == 'right') {
        if (bgm == true) {
          bgm = false;
        }
      }

      if (buttons[5].pressed == true && hand == 'left') {
        //buttonX
        if (prev == false) {
          count--;
          if (count < 0) {
            count = 0;
          }
          setCount(count);
          api.position.set(15, 15, 0);
          prev = true;
        }
      } else if (buttons[5].pressed == false && hand == 'left') {
        if (prev == true) {
          prev = false;
        }
      }
      if (buttons[5].pressed == true && hand == 'right') {
        //buttonX
        if (rbutton == false) {
          rbutton = true;
          setGuide(guide == true ? false : true);
        }
      } else if (buttons[5].pressed == false && hand == 'right') {
        if (rbutton == true) {
          rbutton = false;
        }
      }

      if (applyRotation) {
        player.rotation.y -= (Math.abs(axes[rotationAxis]) > deadzone ? axes[rotationAxis] : 0) * rotationSensitivity;
      }
    }

    //ref.current.getWorldPosition()
  });

  return (
    <>
      <mesh ref={ref}>{hand == 'left' ? <Vrcharacter /> : null}</mesh>
      <mesh position={[15, 15, -20]}>
        {hand == 'right' ? (
          guide == true ? (
            <>
              <planeGeometry args={[32, 18]} />
              <meshStandardMaterial map={texture} toneMapped={false} emissiveIntensity={0.0} emissive='#ffffff' />
            </>
          ) : null
        ) : null}
      </mesh>
    </>
  );
}
