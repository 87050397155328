import { useInfiniteQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useErrorHandler } from 'react-error-boundary';
import { Link, useNavigate } from 'react-router-dom';

import {
  Account,
  searchByKeyword,
  searchByTag,
  searchNickname,
  searchUsername,
  Space,
} from 'apis/index';

import { tokenAtom } from 'src/atoms';

export function SearchResultText({
  users,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}: {
  users: Account[];
  fetchNextPage: () => {};
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean | undefined;
}) {
  let token = useAtomValue(tokenAtom);
  let handleError = useErrorHandler();
  let navigate = useNavigate();
  function handleScroll(e: React.UIEvent) {
    if (token && hasNextPage && !isFetchingNextPage) {
      if (
        e.currentTarget.scrollTop + e.currentTarget.clientHeight >=
        e.currentTarget.scrollHeight - 100
      )
        //100px은 임의의 값
        fetchNextPage();
    }
  }

  return (
    <div className='search-result' onScroll={handleScroll}>
      {users.length === 0 ? (
        <div className='h-full w-full flex justify-center items-center text-zinc-300 text-sm'>
          <div>검색 결과가 없습니다</div>
          <div className='h-11' />
        </div>
      ) : (
        <ul>
          {users.map((user, index) => (
            <li
              className='flex pl-2 my-2'
              onClick={() => navigate(`/u/${user.username}`)}
              key={index}>
              <div className='w-11 h-11 bg-gray-200 rounded-full border'>
                <img src={user.image} className='bg-cover h-full w-full rounded-full' />
              </div>
              <div className='pl-2 flex flex-col justify-center'>
                <h4 className='text-base text-gray-700'>{user.nickname}</h4>
                <h6 className='text-xs text-gray-400'>@{user.username}</h6>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export function SearchResultImage({
  spaces,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}: {
  spaces: Space[];
  fetchNextPage: () => {};
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean | undefined;
}) {
  let token = useAtomValue(tokenAtom);
  function handleScroll(e: React.UIEvent) {
    if (token && hasNextPage && !isFetchingNextPage) {
      if (
        e.currentTarget.scrollTop + e.currentTarget.clientHeight >=
        e.currentTarget.scrollHeight - 100
      )
        //100px은 임의의 값
        fetchNextPage();
    }
  }

  return (
    <div className='search-result' onScroll={handleScroll}>
      {spaces.length === 0 ? (
        <div className='h-full w-full flex justify-center items-center text-zinc-300 text-sm'>
          <div>검색 결과가 없습니다</div>
          <div className='h-11' />
        </div>
      ) : (
        <ul className='grid gap-1 grid-cols-3 md:grid-cols-6 lg:grid-cols-3'>
          {spaces.map(
            (space, index) =>
              !(space.items[0].type === 2 && !space.thumbImage) && (
                <li
                  className='border bg-gray-300 w-full h-32 md:w-48 md:h-48 lg:w-[300] lg:h-[300]'
                  key={index}>
                  <Link to={'/s/' + space.id} key={index}>
                    <img
                      src={space.items[0].type === 1 ? space.items[0].file : space.thumbImage}
                      alt=''
                      className='object-cover w-full h-full'
                    />
                  </Link>
                </li>
              )
          )}
        </ul>
      )}
    </div>
  );
}

export function SearchResultUsername({ keywords }: { keywords: string }) {
  let users: Account[] = [];
  let token = useAtomValue(tokenAtom);
  let handleError = useErrorHandler();
  let { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    [token, 'searchUsers', keywords],
    async ({ pageParam }) => {
      try {
        if (token) {
          return await searchUsername(token, keywords, pageParam);
        } else {
          return await { users: [], cursor: null };
        }
      } catch (error) {
        // throw error;
        // await handleError(error);
        console.log(error);
        return await { users: [], cursor: null };
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.cursor,
    }
  );
  if (data) {
    users = data.pages.flatMap((page) => page.users);
  }
  return (
    <SearchResultText
      users={users}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
}
export function SearchResultNickname({ keywords }: { keywords: string }) {
  let users: Account[] = [];
  let token = useAtomValue(tokenAtom);
  let handleError = useErrorHandler();
  let { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    [token, 'searchNicks', keywords],
    async ({ pageParam }) => {
      try {
        if (token) {
          return await searchNickname(token, keywords, pageParam);
        } else {
          return await { users: [], cursor: null };
        }
      } catch (error) {
        // throw error;
        // await handleError(error);
        console.log(error);
        return await { users: [], cursor: null };
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.cursor,
    }
  );
  if (data) {
    users = data.pages.flatMap((page) => page.users);
  }
  return (
    <SearchResultText
      users={users}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
}
export function SearchResultKeyword({ keywords }: { keywords: string }) {
  let spaces: Space[] = [];
  let token = useAtomValue(tokenAtom);
  let handleError = useErrorHandler();
  let { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    [token, 'searchKeywords', keywords],
    async ({ pageParam }) => {
      try {
        if (token) {
          return await searchByKeyword(token!, keywords, pageParam);
        } else {
          return { spaces: [], cursor: null };
        }
      } catch (error) {
        // throw error;
        // await handleError(error);
        console.log(error);
        return { spaces: [], cursor: null };
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.cursor,
    }
  );
  if (data) {
    spaces = data.pages.flatMap((page) => page.spaces);
  }
  return (
    <SearchResultImage
      spaces={spaces}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
}
export function SearchResultTag({ keywords }: { keywords: string }) {
  let spaces: Space[] = [];
  let token = useAtomValue(tokenAtom);
  let handleError = useErrorHandler();
  let { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    [token, 'searchTags', keywords],
    async ({ pageParam }) => {
      try {
        if (token) {
          return await searchByTag(token!, keywords, pageParam);
        } else {
          return { spaces: [], cursor: null };
        }
      } catch (error) {
        // throw error;
        // await handleError(error);
        return { spaces: [], cursor: null };
      }
    },
    {
      getNextPageParam: (lastPage) => (lastPage ? lastPage.cursor : undefined),
    }
  );
  if (data) {
    spaces = data.pages.flatMap((page) => (page ? page.spaces : []));
  }
  return (
    <SearchResultImage
      spaces={spaces}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
}
