import { useEffect, useState } from 'react';

export default function Alert({ text }: { text: string[] }) {
  const [alert, setAlert] = useState<boolean>(true);
  useEffect(() => {
    let timer = setTimeout(() => {
      setAlert(false);
    }, 2000);
  });
  return alert ? (
    <div
      className='bg-zinc-700 text-xs text-white text-center py-4 px-16 whitespace-pre-wrap'
      style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
      <div className='w-28'>
        {text.length <= 1
          ? text[0]
          : text.map((sentence, index) => {
              if (index === text.length - 1) {
                return sentence;
              } else {
                return sentence + '\n';
              }
            })}
      </div>
    </div>
  ) : (
    <></>
  );
}
