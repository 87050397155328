import styled from 'styled-components';

import { Color } from 'src/constants';

import modalCloseIcon from 'static/icons/modal_close.svg';

export const AlertModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 20.625rem;
  box-sizing: border-box;
  padding: 1rem;
  background-color: ${Color.white};
  box-shadow: 0px 0px 0.25rem rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;

  .clickable:hover {
    cursor: pointer;
  }
`;

export const TitleArea = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: -0.02rem;
  margin-bottom: 2rem;
  color: ${Color.grayScale1};
`;

export const CloseIcon = styled.img.attrs({ src: modalCloseIcon, className: 'clickable' })`
  position: absolute;
  top: 0;
  right: 0;
  width: 1.5rem;
  height: 1.5rem;
`;

export const Message = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: -0.005rem;
  text-align: center;
  color: ${Color.grayScale1};

  span {
    display: inline-block;
    color: ${Color.error};
  }
`;
