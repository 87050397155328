import { useCallback, useEffect, useState } from 'react';
import { useAtom } from 'jotai';

import {
  ApplyButton,
  CloseIcon,
  Header,
  LanguageModalContainer,
  LanguageSelectButton,
  LanguageSelectButtonFlexArea,
} from './styles';
import useLanguageModalResources from './resources';

import useModal from 'hooks/useModal';

import { languageModeAtom } from 'src/atoms';
import { LanguageType } from 'src/types';
import channelService from 'src/channel-service';

function LanguageModal(): JSX.Element {
  const [initialLanguageType, setInitialLanguageType] = useState<LanguageType>();
  const [languageMode, setLanguageMode] = useAtom(languageModeAtom);
  const { closeModal } = useModal();
  const { scripts } = useLanguageModalResources();

  useEffect(() => {
    channelService.shutdown();
    setInitialLanguageType(languageMode);

    return () => channelService.boot({ pluginKey: '2d7dd802-484a-413a-adb6-9f2b6e9c8dc7' });
  }, [languageMode]);

  const onCloseIconClick = useCallback(() => {
    if (initialLanguageType !== languageMode) {
      setLanguageMode(initialLanguageType ?? languageMode);
    }

    closeModal();
  }, [closeModal, initialLanguageType, languageMode]);

  const onLanguageSelectButtonClick = (languageType: LanguageType) => {
    setLanguageMode(languageType);
  };

  const onApplyButtonClick = useCallback(() => {
    closeModal(true);
  }, [closeModal]);

  return (
    <LanguageModalContainer>
      <Header>
        {scripts[languageMode][0]}
        <CloseIcon onClick={onCloseIconClick} />
      </Header>
      <LanguageSelectButtonFlexArea>
        <LanguageSelectButton
          isChecked={languageMode === 'KR'}
          onClick={() => onLanguageSelectButtonClick('KR')}>
          한국어
        </LanguageSelectButton>
        <LanguageSelectButton
          isChecked={languageMode === 'EN'}
          onClick={() => onLanguageSelectButtonClick('EN')}>
          English
        </LanguageSelectButton>
        <LanguageSelectButton
          isChecked={languageMode === 'JP'}
          onClick={() => onLanguageSelectButtonClick('JP')}>
          日本語
        </LanguageSelectButton>
      </LanguageSelectButtonFlexArea>
      <ApplyButton onClick={onApplyButtonClick}>{scripts[languageMode][1]}</ApplyButton>
    </LanguageModalContainer>
  );
}

export default LanguageModal;
