import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import {
  CertificationArea,
  CertificationFormContainer,
  LockIconContainer,
  LoginButton,
  MainTextContainer,
  PasswordResetContainer,
  PWDispenserButton,
  SubTextContainer,
  TextContainer,
} from './style';

import { Lock } from 'components/Commons/icons';
import PhoneCerification from 'components/Commons/phone-certification';
import { APIIssues, getTemporaryPW } from 'apis/index';

import { languageModeAtom, navbarTypeAtom } from 'src/atoms';

const scripts = {
  KR: [
    '등록하신 휴대전화 번호를 입력해주세요.',
    '임시 비밀번호를 보내 드립니다.',
    '휴대전화로 비밀번호 초기화',
    '아이디',
    '전화번호',
    '임시 비밀번호가 발송되었습니다.',
    '아이디, 휴대전화 번호를 확인해주세요.',
    '임시 비밀번호 발급',
    '비밀번호를 잊으셨나요?',
    '로그인하러 가기',
  ],
  EN: [
    'Please enter your phone number.',
    "We'll send you a temporary password",
    'Initialize your password with your phone',
    'ID',
    'Phone number',
    'Temporary password has been sent',
    'please confirm your ID and phone number.',
    'Temporary password',
    'Forgot your password?',
    'Go to log in',
  ],
  JP: [
    '登録された携帯電話番号を入力してください',
    '臨時パスワードをお送りします。',
    '携帯電話でパスワード初期化',
    'ID',
    '電話番号',
    '臨時パスワードが送信されました。',
    'ID',
    '携帯電話番号を確認してください。',
    '臨時パスワード発行',
    'パスワードを忘れましたか？',
    'ログインに行く',
  ],
};

export default function PasswordReset() {
  let setNavbarType = useSetAtom(navbarTypeAtom);
  let languageMode = useAtomValue(languageModeAtom);
  let [phone, setPhone] = useState<string>('');
  let [phoneToken, setPhoneToken] = useState<string | null>(null);
  let [codeNumber, setCodeNumber] = useState<string>('82');
  let [isVerified, setIsVerified] = useState<boolean>(false);
  let [validateError, setValidateError] = useState<APIIssues | null>(null);
  let navigate = useNavigate();

  useEffect(() => {
    setNavbarType('password-reset');
  });

  const getTemporaryPassword = useCallback(async () => {
    if (!validateError && isVerified && phoneToken) {
      try {
        await getTemporaryPW('+' + codeNumber + phone, phoneToken);
        alert(scripts[languageMode][5]);
      } catch (error) {
        alert(scripts[languageMode][6]);
      }
    }
  }, [phoneToken]);

  return (
    <PasswordResetContainer>
      <LockIconContainer>
        <Lock />
      </LockIconContainer>
      <TextContainer>
        <MainTextContainer>{scripts[languageMode][8]}</MainTextContainer>
        <SubTextContainer>{scripts[languageMode][0]}</SubTextContainer>
        <SubTextContainer>{scripts[languageMode][1]}</SubTextContainer>
      </TextContainer>

      <CertificationArea>
        <CertificationFormContainer>
          {scripts[languageMode][2]}
          <PhoneCerification
            type={2}
            phone={phone}
            setPhone={setPhone}
            codeNumber={codeNumber}
            setCodeNumber={setCodeNumber}
            phoneToken={phoneToken}
            setPhoneToken={setPhoneToken}
            isVerified={isVerified}
            setIsVerified={setIsVerified}
            validateError={validateError}
            setValidateError={setValidateError}
          />
        </CertificationFormContainer>
        <PWDispenserButton
          type='button'
          onClick={getTemporaryPassword}
          validateError={validateError}
          isVerified={isVerified}>
          {scripts[languageMode][7]}
        </PWDispenserButton>
        <LoginButton type='button' onClick={() => navigate('/login')}>
          {scripts[languageMode][9]}
        </LoginButton>
      </CertificationArea>
    </PasswordResetContainer>
  );
}
