import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useScroll from 'hooks/useScroll';

function ScrollToTop(): JSX.Element {
  const { pathname } = useLocation();
  const { goToTop } = useScroll({});

  useEffect(() => {
    goToTop();
  }, [pathname]);

  return <></>;
}

export default ScrollToTop;
