import { useCallback, useEffect, useState } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { useMediaQuery } from 'react-responsive';
import { Navigate, useNavigate } from 'react-router-dom';

import {
  LoginButton,
  LoginContainer,
  LoginForm,
  LoginLeftArea,
  LoginRightImage,
  LoginTitle,
  ManageButtonFlexArea,
  ResetPasswordButton,
  SignUpButton,
} from './styles';
import useLoginResources from './resources';

import Header from 'components/Header';
import ValidationInput from 'components/ValidationInput';
import { InputData } from 'components/ValidationInput/types';
import { APIError, login } from 'apis/index';
import useModal from 'hooks/useModal';
import { apiErrorCodeToMessage } from 'utils/errorUtils';
import { ErrorCode } from 'src/types';
import channelService from 'src/channel-service';
import { languageModeAtom, navbarTypeAtom, tokenAtom } from 'src/atoms';

function Login(): JSX.Element {
  const [usernameInputData, setUsernameInputData] = useState<InputData>();
  const [passwordInputData, setPasswordInputData] = useState<InputData>();
  const { scripts } = useLoginResources();
  const [token, setToken] = useAtom(tokenAtom);
  const languageMode = useAtomValue(languageModeAtom);
  const [navbarType, setNavbarType] = useAtom(navbarTypeAtom);
  const navigate = useNavigate();
  const mediaMatch = useMediaQuery({ maxWidth: 1224 });
  const { openInstanceMessageModal, openAlertModal } = useModal();

  useEffect(() => {
    setNavbarType('login');
    channelService.boot({
      pluginKey: '2d7dd802-484a-413a-adb6-9f2b6e9c8dc7',
    });

    return () => {
      channelService.shutdown();
      setNavbarType('feed');
    };
  }, [navbarType]);

  const onResetPasswordButtonClick = () => {
    navigate('/password');
  };

  const onSignUpButtonClick = () => {
    navigate('/signup');
  };

  const onLoginButtonClick = useCallback(async () => {
    try {
      if (usernameInputData?.value && passwordInputData?.value) {
        const username = usernameInputData.value;
        const password = passwordInputData.value;
        const userToken = await login(username, password);

        setToken(userToken);
      }
    } catch (error) {
      openInstanceMessageModal({
        texts: [
          { type: 'NORMAL', message: scripts[languageMode][5] },
          { type: 'NEW_LINE' },
          { type: 'NORMAL', message: scripts[languageMode][6] },
        ],
      });

      if (error instanceof APIError) {
        switch (error.issues.field) {
          case 'username':
            return setUsernameInputData((prev) => ({
              ...prev,
              value: prev?.value ? prev.value : '',
              isError: true,
              errorMessage: apiErrorCodeToMessage(
                (error as APIError).issues.code as ErrorCode,
                scripts[languageMode][1]
              )[languageMode][0],
            }));
          case 'password':
            return setPasswordInputData((prev) => ({
              ...prev,
              value: prev?.value ? prev.value : '',
              isError: true,
              errorMessage: apiErrorCodeToMessage(
                (error as APIError).issues.code as ErrorCode,
                scripts[languageMode][4]
              )[languageMode][0],
            }));
          default:
            return;
        }
      }
    }
  }, [usernameInputData, passwordInputData]);

  return (
    <LoginContainer>
      <LoginLeftArea>
        <LoginForm>
          <Header />
          <LoginTitle>{scripts[languageMode][2]}</LoginTitle>
          <ValidationInput
            label={scripts[languageMode][9]}
            placeholder='james_007'
            value={usernameInputData}
            setValue={setUsernameInputData}
            margin={mediaMatch ? `2rem 0 0 0` : `1.25rem 0 0 0`}
          />
          <ValidationInput
            label={scripts[languageMode][10]}
            placeholder='********'
            value={passwordInputData}
            setValue={setPasswordInputData}
            type='password'
            margin={`1.25rem 0 0 0`}
          />
          <ManageButtonFlexArea>
            <ResetPasswordButton onClick={onResetPasswordButtonClick}>{scripts[languageMode][4]}</ResetPasswordButton>
            <SignUpButton onClick={onSignUpButtonClick}>{scripts[languageMode][3]}</SignUpButton>
          </ManageButtonFlexArea>
          <LoginButton onClick={onLoginButtonClick}>{scripts[languageMode][2]}</LoginButton>
        </LoginForm>
      </LoginLeftArea>
      <LoginRightImage />
      {token && <Navigate to='/' />}
    </LoginContainer>
  );
}

export default Login;
