import styled from 'styled-components';

import { BreakPoint, Color } from 'src/constants';

import closeIcon from 'static/icons/close.svg';

export const LanguageModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
  padding: 2rem;
  box-sizing: border-box;
  background-color: ${Color.white};
  border-radius: 0.25rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.75rem;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.25rem;
  letter-spacing: -0.02rem;
`;

export const CloseIcon = styled.img.attrs({ src: closeIcon })`
  width: 1.375rem;
  height: 1.375rem;

  &:hover {
    cursor: pointer;
  }
`;

export const LanguageSelectButtonFlexArea = styled.div`
  display: flex;
  width: 26.125rem;
  justify-content: space-between;
  margin-bottom: 1.875rem;

  ${BreakPoint.mobile} {
    width: 100%;
    margin-bottom: 1.5rem;
  }
`;

export const LanguageSelectButton = styled.div<{ isChecked: boolean }>`
  width: calc((100% - 0.75rem) / 3);
  padding: 1rem 0;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: -0.02rem;
  text-align: center;
  color: ${({ isChecked }) => (isChecked ? Color.grayScale1 : Color.grayScale4)};
  border: 1px solid ${({ isChecked }) => (isChecked ? Color.grayScale1 : Color.grayScale8)};
  border-radius: 0.25rem;

  &:hover {
    cursor: pointer;
    border: 1px solid ${Color.grayScale1};
    color: ${Color.grayScale1};
  }
`;

export const ApplyButton = styled.div`
  width: 100%;
  padding: 0.625rem 0;
  background-color: #db1f72;
  font-weight: 500;
  line-height: 1.375rem;
  letter-spacing: -0.025rem;
  text-align: center;
  color: ${Color.white};
  border-radius: 0.25rem;
`;
