import { Color } from 'src/constants';
import styled from 'styled-components';

export const InstanceMessageModalContainer = styled.div`
  display: flex;
  min-width: 15rem;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
  background-color: ${Color.grayScale1};
  border-radius: 0.25rem;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${Color.white};
  text-align: center;
`;
