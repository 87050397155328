import { Suspense, useRef } from 'react';
import tunnel from 'tunnel-rat';
import { Html } from '@react-three/drei';
import ImageItem from './image-item';
import ModelItem from './model-item';
import { useFrame, useThree } from '@react-three/fiber';
import { SpaceDisplayStatus } from 'pages/Home/space';
import OsstemTheme from 'react3fiber/theme/osstem-theme';
import StoneTheme from 'react3fiber/theme/stone-theme';
import ForestThema from 'react3fiber/theme/forest-theme';
import CyberTheme from 'react3fiber/theme/cyber-theme';
import FlowerThema from 'react3fiber/theme/flower-theme';
import DiagonalThema from 'react3fiber/theme/diagonal-theme';
import SpaceTheme from 'react3fiber/theme/space-theme';
import CityTheme from 'react3fiber/theme/city-theme';
import Thema from 'react3fiber/theme/thema';
import CartoonThema from 'react3fiber/theme/cartoon-thema';
import MirrorTheme from 'react3fiber/theme/mirror-theme';
import WoodThema from 'react3fiber/theme/wood-theme';
import { Space } from 'apis/index';

const t = tunnel();
import loadingIcon from 'static/icons/loading.svg';
export default function Objects({
  space,
  device = null,
  displayStatus = null,
}: {
  space: Space;
  device: String | null;
  displayStatus: SpaceDisplayStatus | null;
}) {
  const mediaMatch = window.matchMedia('(min-width: 1024px)');
  if (space.lightBright < 10) {
    space.lightBright = 10;
  }

  let post = 0;
  if (
    space.id == '20755000-fc99-4a00-a162-39b3055c3f26' ||
    space.id == '3c3804e5-0daa-4b83-a945-beb54c14fc92'
  ) {
    post = 1;
  } else if (
    space.id == '644c32b1-af2f-4797-8c55-414cee9a306d' ||
    space.id == 'f7b07e4c-9533-419f-9b58-c7411321c176'
  ) {
    post = 2;
  } else if (
    space.id == '118bc2c6-5b9d-4798-b885-7369c9158b9a' ||
    space.id == '019a3574-0d57-43ff-8814-580129b4bf70'
  ) {
    post = 3;
  } else if (
    space.id == '91ba0773-c292-4d9c-be51-7c734a29ac40' ||
    space.id == '41eea043-3049-4d6b-bdf3-a16094df0abc'
  ) {
    post = 4;
  }

  return (
    <Suspense
      fallback={
        displayStatus === 'current' &&
        mediaMatch.matches == false && (
          <>
            <Html fullscreen prepend>
              <img className='h-full w-full object-cover' src={space.thumbImage} />
            </Html>
            <Html transform scale={1}>
              <div
                className='grow'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <img src={loadingIcon} style={{ height: '30px' }} />
                {/* <div style={{ height: '30px', fontSize: '10px' }}>loading...</div> */}
              </div>
            </Html>
          </>
        )
      }>
      {space.items.map((item, index) => (
        <group key={index}>
          {item.type == 1 ? (
            space.items[0]?.file == undefined ? null : (
              <group rotation={[0, 0, 0]} position={[0, 0, 0]}>
                <ImageItem item={item} />
              </group>
            )
          ) : space.items[0]?.file == undefined ? null : (
            <ModelItem item={item} />
          )}
        </group>
      ))}
      <ambientLight color='#ffffff' intensity={space.lightBright / 200} />

      {space.theme === 7 && (
        <>
          <directionalLight
            shadow-mapSize-width={4096}
            shadow-mapSize-height={4096}
            shadow-camera-far={60}
            shadow-camera-near={1}
            shadow-camera-left={-2}
            shadow-camera-right={2}
            shadow-camera-top={2}
            shadow-camera-bottom={-8}
            castShadow
            color='#FAE0D4'
            intensity={space.lightBright / 75}
            position={[0, 31, 15]}
          />

          <CityTheme />
        </>
      )}
      {space.theme === 6 && (
        <>
          <directionalLight
            shadow-mapSize-width={4096}
            shadow-mapSize-height={4096}
            shadow-camera-far={60}
            shadow-camera-near={1}
            shadow-camera-left={-2}
            shadow-camera-right={2}
            shadow-camera-top={2}
            shadow-camera-bottom={-8}
            castShadow
            color='#FF7200'
            intensity={space.lightBright / 25}
            position={[-400, 31, 15]}
          />
          <directionalLight
            color='white'
            intensity={space.lightBright / 25}
            position={[400, 31, 15]}
          />

          <SpaceTheme />
        </>
      )}
      {space.theme === 5 && (
        <>
          <directionalLight
            shadow-mapSize-width={4096}
            shadow-mapSize-height={4096}
            shadow-camera-far={60}
            shadow-camera-near={1}
            shadow-camera-left={-2}
            shadow-camera-right={2}
            shadow-camera-top={2}
            shadow-camera-bottom={-8}
            castShadow
            intensity={space.lightBright / 100}
            position={[0, 31, 15]}
          />
          <DiagonalThema />
        </>
      )}
      {space.theme === 4 && (
        <>
          <directionalLight
            shadow-mapSize-width={4096}
            shadow-mapSize-height={4096}
            shadow-camera-far={60}
            shadow-camera-near={1}
            shadow-camera-left={-4}
            shadow-camera-right={4}
            shadow-camera-top={4}
            shadow-camera-bottom={-10}
            castShadow
            intensity={space.lightBright / 200}
            position={[0, 31, 15]}
          />

          <FlowerThema />
        </>
      )}
      {space.theme === 3 && (
        <>
          <directionalLight
            shadow-mapSize-width={4096}
            shadow-mapSize-height={4096}
            shadow-camera-far={60}
            shadow-camera-near={1}
            shadow-camera-left={-4}
            shadow-camera-right={4}
            shadow-camera-top={2}
            shadow-camera-bottom={-10}
            castShadow
            intensity={space.lightBright / 100}
            position={[0, 31, 15]}
          />
          <WoodThema />
        </>
      )}
      {space.theme === 2 && (
        <>
          <directionalLight
            shadow-mapSize-width={4096}
            shadow-mapSize-height={4096}
            shadow-camera-far={60}
            shadow-camera-near={1}
            shadow-camera-left={-2}
            shadow-camera-right={2}
            shadow-camera-top={2}
            shadow-camera-bottom={-8}
            castShadow
            color='white'
            intensity={space.lightBright / 100}
            position={[0, 31, 15]}
          />
          <CartoonThema />
        </>
      )}
      {/* {space.theme === 2 && space.items[0].type === 2 && (
        <>
          <pointLight args={['white', space.lightBright / 100]} position={[18, 32, -18]} />
          <MirrorTheme tableScale={space.items[0].scale} device={device} />
        </>
      )} */}
      {space.theme === 1 && (
        <>
          <directionalLight
            shadow-mapSize-width={4096}
            shadow-mapSize-height={4096}
            shadow-camera-far={60}
            shadow-camera-near={1}
            shadow-camera-left={-2}
            shadow-camera-right={2}
            shadow-camera-top={2}
            shadow-camera-bottom={-8}
            castShadow
            intensity={post == 4 ? 3 : space.lightBright / 100}
            position={[0, 31, 15]}
            color={post == 4 ? '#a19c5e' : 'white'}
          />
          {post == 0 && (
            <Thema
              color={space.themeColor}
              saturation={space.themeSaturation}
              brightness={space.themeBrightness}
            />
          )}

          {post == 1 && <OsstemTheme />}
          {post == 2 && <StoneTheme position={[0, -1.8, 0.9]} rotation={[0, 0, 0]} />}
          {post == 3 && <CyberTheme position={[18, 17.5, -6]} />}
          {post == 4 && <ForestThema position={[0, -1.8, 0.9]} rotation={[0, Math.PI / 27, 0]} />}
        </>
      )}
    </Suspense>
  );
}
