import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import {
  ApplyButton,
  LogoIcon,
  Header,
  LoginModalContainer,
  LoginTextArea,
  PinkText,
  Bottom,
} from './styles';
import useLanguageModalResources from './resource';

import { languageModeAtom } from 'src/atoms';
import channelService from 'src/channel-service';

function LoginModal(): JSX.Element {
  const [languageMode] = useAtom(languageModeAtom);
  const { scripts } = useLanguageModalResources();
  const navigate = useNavigate();

  useEffect(() => {
    channelService.shutdown();

    return () => channelService.boot({ pluginKey: '2d7dd802-484a-413a-adb6-9f2b6e9c8dc7' });
  }, []);

  return (
    <LoginModalContainer>
      <Header>
        <LogoIcon />
      </Header>
      <LoginTextArea>
        {scripts[languageMode][0]}
        <br />
        {scripts[languageMode][1]}
      </LoginTextArea>
      <ApplyButton onClick={() => navigate('/login')}>{scripts[languageMode][2]}</ApplyButton>
      <Bottom>
        {scripts[languageMode][3]}
        <PinkText onClick={() => navigate('/signup')}>{scripts[languageMode][4]}</PinkText>
      </Bottom>
    </LoginModalContainer>
  );
}

export default LoginModal;
