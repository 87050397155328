import { useAtom, useAtomValue } from 'jotai';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import ItemReply from './item-reply';
import ItemReplyPC from './item-reply-pc';

import { InfoModalFrame } from 'pages/Home/modals';
import { BallonBlack, EditPost, HeartBlack, HeartFill, Music, Report, ThreeD } from 'components/Commons/icons';
import { getIsLike, getSpace, likeSpace, Space, unlikeSpace } from 'apis/index';

import { languageModeAtom, navbarTypeAtom, tokenAtom, userAtom } from 'src/atoms';

const scripts = {
  KR: ['요약하기', '더 보기', '댓글', '수정', '3D POST', '신고'],
  EN: ['See Less', 'More', 'Comment', 'Fix', '3D POST', 'Report'],
  JP: ['要約する', 'もっと見る', 'コメント', '修正', '3D POST', '申告'],
};

export default function ItemPage() {
  let { spaceId } = useParams() as { spaceId: string };
  let [space, setSpace] = useState<Space | null>(null);
  let [isLike, setIsLike] = useState<boolean>(false);
  let [isReplyOpen, setIsReplyOpen] = useState<boolean>(false);
  let token = useAtomValue(tokenAtom);
  let user = useAtomValue(userAtom);
  let [isDescOpen, setIsDescOpen] = useState<boolean>(false);
  let [navbarType, setNavbarType] = useAtom(navbarTypeAtom);
  let languageMode = useAtomValue(languageModeAtom);
  useEffect(() => {
    setNavbarType('2dFeed');
  }, [setNavbarType]);
  let descBox: object | null = {
    display: '-webkit-inline-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
  };
  let descFrame = 'flex';
  if (isDescOpen) {
    descBox = {};
    descFrame += ' flex-col justify-start';
  }
  let [show, setShow] = useState<boolean>(false);
  let navigate = useNavigate();

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      try {
        let space = await getSpace(spaceId);
        if (!isCancelled) {
          setSpace(space);
        }
      } catch (error) {}
    })();
    if (token && user) {
      (async () => {
        try {
          let isLike = await getIsLike(token, spaceId, user.username);
          setIsLike(isLike);
        } catch (error) {}
      })();
    }
    return () => {
      isCancelled = true;
    };
  }, [spaceId, token, user, isLike]);
  return (
    <>
      {space && isReplyOpen && <ItemReply space={space} setIsReplyOpen={setIsReplyOpen} />}
      <div className='w-full sm:block lg:hidden'>
        <div className='flex justify-between'>
          <div className='pl-3'>
            {/* 여기서부터 */}
            <div className='flex my-2 items-center'>
              <div
                className='h-10 w-10 rounded-full border bg-center bg-no-repeat'
                style={{ backgroundImage: `url(${space?.profileImgURL})`, backgroundSize: 'cover' }}></div>
              <div className='ml-2 flex flex-col'>
                <h4 className='text-base'>{space?.username}</h4>
                <Link className='text-xs' to={'/u/' + space?.username}>
                  @{space?.userNickname}
                </Link>
              </div>
            </div>
            {/* 여기까지 */}
          </div>
          {/* <div className='flex items-center' onClick={clickShow}>
          <MoreVerticalBlack />
        </div> */}
        </div>
        <div className='w-full'>
          <div
            className='imgBox bg-center bg-no-repeat'
            style={{
              backgroundImage: `url(${space?.items[0].type == 1 ? space?.items[0].file : space?.thumbImage})`,
              width: '100%',
            }}></div>
          <div className='flex justify-between'>
            <div>
              <button
                className='px-2'
                onClick={() => {
                  setIsLike(!isLike);
                  if (token && user && space) {
                    if (isLike) {
                      unlikeSpace(token, space.id);
                    } else {
                      likeSpace(token, space.id);
                    }
                  }
                }}>
                {isLike ? <HeartFill /> : <HeartBlack />}
              </button>
              <button
                className='m-2'
                onClick={() => {
                  setIsReplyOpen(true);
                }}>
                <BallonBlack />
              </button>
            </div>
          </div>
        </div>
        <div className='px-4 mt-2'>
          <div>
            {space?.description.length !== 0 && (
              <div className={descFrame + ' break-all'}>
                <div style={descBox}>
                  {space?.noHashTagDescription}
                  <div id='hashtag_area' className='text-tag'>
                    {space?.hashTags.map((content) => {
                      return content + ' ';
                    })}
                  </div>
                </div>
                <button
                  className='text-sm text-zinc-300 inline shrink-0 w-fit'
                  type='button'
                  onClick={() => {
                    setIsDescOpen(!isDescOpen);
                  }}>
                  {isDescOpen ? scripts[languageMode][0] : scripts[languageMode][1]}
                </button>
              </div>
            )}
          </div>
          <div className='flex mt-1 items-center gap-1'>
            <Music width='18' height='18' />
            {space?.bgm}
          </div>
          <div
            className='mt-1 flex flex-col text-gray-400'
            onClick={() => {
              setIsReplyOpen(true);
            }}>
            <h5 className='text-base'>
              {scripts[languageMode][2]} {space?.replyNum}
            </h5>
            {/* <div className='text-xs'>8일 전</div> */}
          </div>
          <div className='flex-col'>
            <div className='text-sm text-zinc-300 '>{space?.created}</div>
          </div>
        </div>

        <div className={show ? 'block' : 'hidden'}>
          <div className='w-full border fixed bottom-0 bg-white rounded-t-xl pt-4'>
            <div
              className='flex justify-center w-full'
              onClick={() => {
                setShow(false);
              }}>
              <svg width='41' height='4' viewBox='0 0 41 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <rect x='0.356445' width='40' height='4' rx='2' fill='#808080' />
              </svg>
            </div>
            <ul className='w-full'>
              <li className='px-3 py-3 flex border-b'>
                <EditPost />
                <span className='pl-2'>{scripts[languageMode][3]}</span>
              </li>
              <li className='px-3 py-3 flex border-b'>
                <ThreeD /> <span className='pl-2'>{scripts[languageMode][4]}</span>
              </li>
              <li className='px-3 py-3 flex text-red-500'>
                <Report /> <span className='pl-2'>{scripts[languageMode][5]}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className='w-[940] m-auto flex flex-row mt-4 hidden lg:flex h-[98%] bg-white border border-gray-300'>
        <div className='w-[580px] bg-black flex'>
          <div className='w-[580px] flex justify-items-center content-center'>
            <div
              className='imgBox bg-center bg-no-repeat self-center h-[580px]'
              style={{
                backgroundImage: `url(${space?.items[0].type == 1 ? space?.items[0].file : space?.thumbImage})`,
              }}></div>
          </div>
        </div>
        <div className='w-[360px] px-3 h-full flex flex-col'>
          <div className=' flex-1 grow-0'>
            <div className='flex justify-between border-b pb-2'>
              <div className='flex my-2 items-center'>
                <div
                  className='h-10 w-10 rounded-full border bg-center bg-no-repeat'
                  style={{ backgroundImage: `url(${space?.profileImgURL})`, backgroundSize: 'cover' }}></div>
                <div className='ml-2 flex flex-col'>
                  <h4 className='text-base'>{space?.username}</h4>
                  <Link className='text-xs' to={'/u/' + space?.username}>
                    @{space?.userNickname}
                  </Link>
                </div>
              </div>
              <div className='item-center'>
                {space && <InfoModalFrame space={space} token={token} isWhite={false} />}
              </div>
            </div>
            <div className='pt-2'>
              <div
                id='text'
                className='break-al
              l'>
                {space?.noHashTagDescription + ' '}
              </div>
              <div className='flex items-center gap-1'>
                <Music width='18' height='18' />
                {space?.bgm}
              </div>
            </div>
          </div>
          {space && <ItemReplyPC space={space} setIsReplyOpen={setIsReplyOpen} />}
        </div>
      </div>
    </>
  );
}
