import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useAtom } from 'jotai';

import { AdlerLogo, HeaderContainer, LanguageTypeText } from './styles';
import useHeaderResources from './resources';

import LanguageModal from 'components/CustomModal/components/LanguageModal';
import useModal from 'hooks/useModal';

import { languageModeAtom } from 'src/atoms';
import { LanguageType } from 'src/types';

function Header(): JSX.Element {
  const [savedLanguageMode, setSavedLanguageMode] = useState<LanguageType>();
  const [languageMode, setLanguageMode] = useAtom(languageModeAtom);
  const { scripts } = useHeaderResources();
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { pathname } = useLocation();
  const mediaMatch = useMediaQuery({ maxWidth: 1224 });

  const onAdlerLogoClick = useCallback(() => {
    if (pathname === '/') return;

    navigate('/');
  }, [pathname]);

  const onLanguageTypeTextClick = useCallback(() => {
    setSavedLanguageMode(languageMode);

    openModal({
      component: <LanguageModal />,
      options: {
        align: { alignItems: mediaMatch ? 'flex-end' : 'center', justifyContent: 'center' },
        isCloseOnESCKeyDown: true,
        isCloseOnOverlayClick: true,
        isOverlayed: true,
      },
      onClosed: (hasAction) => hasAction ?? setLanguageMode(savedLanguageMode ?? languageMode),
    });
  }, [openModal, savedLanguageMode]);

  return (
    <HeaderContainer>
      <AdlerLogo onClick={onAdlerLogoClick} />
      <LanguageTypeText onClick={onLanguageTypeTextClick}>{scripts[languageMode][0]}</LanguageTypeText>
    </HeaderContainer>
  );
}

export default Header;
