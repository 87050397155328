import { UseSignUpResources } from './types';

import { Scripts } from 'src/types';

const scripts: Scripts = {
  KR: [
    '회원가입',
    '아이디',
    '영문 소문자 / 숫자 / _ 를 포함한 6자~20자',
    '이메일',
    '비밀번호',
    '영문 대문자/소문자 구분 없이 숫자와 조합, 8자~16자',
    '비밀번호 확인',
    '동의하고 회원가입',
    '이용약관, 개인정보 수집 및 이용, 개인정보 제공',
    ' 내용을 확인하였고 동의합니다.',
    '카카오로 시작하기',
    '네이버로 시작하기',
    '페이스북으로 시작하기',
    '구글로 시작하기',
    '애플로 시작하기',
    '아이디를 입력해주세요.',
    '아이디는 영문 소문자, 숫자, 언더바(_)를 포함한 6자 ~ 20자만 가능합니다.',
    '이메일을 입력해주세요.',
    '올바른 이메일이 아닙니다.',
    '비밀번호를 입력해주세요.',
    '비밀번호는 영문 대소문자, 숫자, 특수문자 각 하나 이상을 포함한 8자 ~ 32자만 가능합니다.',
    '비밀번호 확인을 입력해주세요.',
    '비밀번호 확인이 비밀번호와 일치하지 않습니다.',
  ],
  EN: [
    'Sign Up',
    'ID',
    '6-20 characters including lowercase English letters / numbers / _',
    'Email',
    'Password',
    'Numbers and combinations, 8 to 16 characters, regardless of uppercase/lowercase letters',
    'Confirm Password',
    'Agree and sign up',
    'Terms of use, collection and use of personal information, provision of personal information',
    ' checked and agree with the contents',
    'Start with kakao',
    'Start with naver',
    'Start with facebook',
    'Start with google',
    'Start with appple',
    'Please enter your ID.',
    'ID is limited to 6-20 characters including lowercase English letters, numbers, and underscores (_).',
    'Please enter your e-mail.',
    'This is not a valid email.',
    'Please enter a password.',
    'Password must be between 8 and 32 characters including at least one uppercase and lowercase letter, number, and special character each.',
    'Please enter confirm password.',
    'Confirm password does not match password.',
  ],
  JP: [
    '会員登録',
    'ID',
    '英小文字/数字/ _を含む6〜20文字',
    'Eメール',
    'パスワード',
    '英文大文字/小文字区別なしで数字と組み合わせ、8～16文字',
    'パスワード確認',
    '同意して会員登録',
    '利用規約、個人情報の収集及び利用、個人情報提供',
    '内容を確認して同意します。',
    'Kakao で始める',
    'Naver で始める',
    'Facebook で始める',
    'Google で始める',
    'Apple で始める',
    'IDを入力してください。',
    'IDは英字小文字、数字、アンダーバー（_）を含む6〜20文字のみ可能です',
    'メールを入力してください。',
    '正しいメールではありません。',
    'パスワードを入力してください。',
    'パスワードは、英大文字、小文字、数字、特殊文字の1つ以上を含む8〜32文字のみ可能です。',
    'パスワード確認を入力してください。',
    'パスワードの確認がパスワードと一致しません。',
  ],
};

// const scripts: Scripts = {
//   KR: [
//     '인증시간이 만료되었습니다.',
//     '필수 입력 정보입니다',
//     '아이디를 입력해주세요.',
//     '6-20자, 영문 소문자, 숫자, 중복되지 않은 언더바(_)',
//     '비밀번호를 입력해주세요.',
//     '8-16자, 영문 대 소문자, 숫자, 특수문자',
//     '이메일을 입력해주세요.',
//     '유효하지 않은 이메일 주소입니다.',
//     '국가선택',
//     '형식이 맞지 않거나 이미 가입된 전화번호입니다.',
//     '인증번호 전송',
//     '확인',
//     '재전송',
//     '인증이 완료되지 않았습니다. 인증번호를 다시 발급해주세요.',
//     '인증완료',
//     '서비스 정책',
//     '서비스 이용약관 동의 (필수)',
//     '내용보기',
//     '이용약관에 동의해 주세요.',
//     '회원가입에 실패했습니다. 다시 시도해주세요.',
//     '회원가입',
//     '계정이 있으신가요?',
//     '로그인',
//     '유효하지 않은 전화번호입니다.',
//     '입력하신 전화번호로 등록된 계정이 있는지 확인해주세요',
//     '중복된 아이디입니다.',
//   ],
//   EN: [
//     'Authentication time expired',
//     'Required input information',
//     'Please enter ID',
//     '6-20 characters, lowercase English, number, non-overlapping underbar(_)',
//     'Please enter your password',
//     '8-16 characters, uppercase English, number, special characters',
//     'Please enter your email',
//     'Invalid email address',
//     'Select country',
//     'Invalid or already registered phone number',
//     'Send authentication number',
//     'Confirm',
//     'Resend',
//     'Authentication not completed. Please issue the authentication number again.',
//     'Authentication completed',
//     'Service Policy',
//     'Service Terms and Conditions Agreement (Required)',
//     'View contents',
//     'Please accept the terms and conditions',
//     'Registration failed. Please try again.',
//     'Registration',
//     'Do you have already have an account?',
//     'Log in',
//     'Invalid phone number',
//     'Please check if there is an account registered with the phone number you entered',
//     'Duplicated Id',
//   ],
//   JP: [
//     '認証時間が満了しました。',
//     '必須入力情報です',
//     'IDを入力してください。',
//     '6-20文字、英文小文字、数字、重複しないアンダーバー(_)',
//     'パスワードを入力してください。',
//     '8-16文字、英語大小文字、数字、特殊文字',
//     'Eメールを入力してください。',
//     '無効なEメールアドレスです。',
//     '国家選択',
//     'すでに登録されている電話番号です。',
//     '認証番号転送',
//     '確認',
//     '再送信',
//     '認証が完了していません。 認証番号をもう一度発行してください。',
//     '認証完了',
//     'サービス政策',
//     'サービス利用約款に同意(必須)',
//     '内容を見る',
//     '利用約款に同意してください。',
//     '会員登録に失敗しました。 もう一度試してください。',
//     '会員登録',
//     'アカウントがありますか？',
//     'ログイン',
//     '有効でない電話番号です。',
//     '入力した電話番号に登録されたアカウントがあるか確認してください',
//   ],
// };

const useSignUpResources = (): UseSignUpResources => {
  return {
    scripts,
  };
};

export default useSignUpResources;
