import { usePlane, useConvexPolyhedron, useTrimesh } from '@react-three/cannon';
import { useMemo } from 'react';
import * as THREE from 'three';
import { ConvexGeometry } from 'three-stdlib';
import { BufferGeometryUtils } from 'three';
import { Geometry } from 'three-stdlib';

import { useCompressedGLTF } from 'hooks/hooks';

let url = new URL('static/models/gilmak2.glb', import.meta.url);

/**
 * Returns legacy geometry vertices, faces for ConvP
 * @param {THREE.BufferGeometry} bufferGeometry
 */
function toConvexProps(bufferGeometry) {
  const geo = new Geometry().fromBufferGeometry(bufferGeometry);
  // Merge duplicate vertices resulting from glTF export.
  // Cannon assumes contiguous, closed meshes to work
  geo.mergeVertices();
  return [geo.vertices.map((v) => [v.x, v.y, v.z]), geo.faces.map((f) => [f.a, f.b, f.c]), []]; // prettier-ignore
}
function Plane(props) {
  let [ref] = usePlane(() => ({ rotation: props.rotation, position: props.position }));
  return <mesh ref={ref} />;
}

function Osstem(props) {
  let { nodes } = useCompressedGLTF(url.href);

  const geo = useMemo(() => toConvexProps(nodes.Sphere002.geometry), [nodes]);
  const [ref] = useConvexPolyhedron(() => ({ type: 'Static', mass: 1000, ...props, args: geo }));
  return (
    <mesh geometry={nodes.Sphere002.geometry} {...props}>
      <meshStandardMaterial attach='material' visible={false} />
    </mesh>
  );
}
export default function OsstemPrimitive({
  THREE_WIDTH,
  THREE_HEIGHT,
  THREE_DEPTH,
}: {
  THREE_WIDTH: number;
  THREE_HEIGHT: number;
  THREE_DEPTH: number;
}) {
  return (
    <group>
      <Plane position={[THREE_WIDTH / 2, -1, 0]} args={[THREE_WIDTH, THREE_DEPTH]} rotation={[-Math.PI / 2, 0, 0]} />
      <Osstem position={[2, -2, -2]} scale={1} rotation={[0, -Math.PI / 2, 0]} />
    </group>
  );
}
