import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import DefaultprofileImg from 'static/imgs/default-profile.png';
import { Space } from 'apis/index';
import exp from 'constants';
import { Button } from 'components/Commons/buttons';
import { button } from 'leva';

export const PostLink = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
`;

export const PostLinkContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  align-items: center;
`;

export const UserImage = styled(Link)<{ space: Space }>`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${({ space }) =>
    space.profileImgURL ? `url(${space.profileImgURL})` : `url(${DefaultprofileImg})`};
`;

export const UserNameContainer = styled(Link)``;

export const UserName = styled.h3`
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const SpaceDescriptionContainer = styled.div<{ isDescOpen: boolean }>`
  display: flex;
  word-break: break-all;
  ${({ isDescOpen }) =>
    isDescOpen
      ? css`
          flex-direction: column;
          justify-content: flex-start;
        `
      : css``};
`;

export const SpaceDescriptionStyle = styled.div<{ isDescOpen: boolean }>`
  font-size: 0.875rem;
  line-height: 1.25rem;
  ${({ isDescOpen }) =>
    isDescOpen
      ? css``
      : css`
          display: -webkit-inline-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        `};
`;

export const DescriptionButton = styled(Button)`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgb(212, 212, 216);
  display: inline;
  flex-shrink: 0;
  width: fit-content;
`;
export const Hashtag = styled.div`
  color: rgb(0, 55, 107, 1);
`;

export const HashtagButton = styled(Button)``;

export const MusicContainer = styled.div`
  display: flex;
  margin-top: 0.5rem;
`;

export const MusicStyle = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const MusicName = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 0.25rem;
`;

export const DateTranslate = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
`;
