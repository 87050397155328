import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAtomValue, useSetAtom } from 'jotai';

import { FollowContainer, Tab, TabBody, TabContainer } from './styles';
import { FollowerDatas, FollowingDatas, FollowProps, TabType } from './types';
import FollowHeader from './components/FollowHeader';
import FollowUserSearch from './components/FollowUserSearch';
import FollowUserBox from './components/FollowUserBox';

import Loading from 'components/Loading';
import { APIError, getFollowers, getFollowings } from 'apis/index';
import useModal from 'hooks/useModal';

import { navbarTypeAtom, tokenAtom, languageModeAtom } from 'src/atoms';

const scripts = {
  KR: { 팔로워: '팔로워', 팔로잉: '팔로잉' },
  EN: { 팔로워: 'follower', 팔로잉: 'following' },
  JP: { 팔로워: 'フォロワー', 팔로잉: 'フォロー' },
} as const;

export const tabType = {
  follower: '팔로워',
  following: '팔로잉',
} as const;

function Follow({ userName, initialTabType }: FollowProps): JSX.Element {
  const token = useAtomValue(tokenAtom);
  const setNavBarType = useSetAtom(navbarTypeAtom);
  const { username, mobileTabType } = useParams<{ username: string; mobileTabType: TabType }>();
  const [followerDatas, setFollowerDatas] = useState<FollowerDatas>();
  const [followingDatas, setFollowingDatas] = useState<FollowingDatas>();
  const [tab, setTab] = useState<TabType>('follower');
  const mediaMatch = useMediaQuery({ maxWidth: 1224 });
  const [currentUserName, setCurrentUserName] = useState<string>();
  const languageMode = useAtomValue(languageModeAtom);
  const { openAlertModal } = useModal();

  /*  
    INFO

    1. PC버전 일 때 props로 전달 된 userName, Mobile버전일 때 param으로 전달 된 username기준으로 검색이 이뤄진다.
    2. 컴포넌트 마운트 시, 초기 활성화 될 탭 설정.
  */
  useEffect(() => {
    setNavBarType('follow');
    setCurrentUserName(userName ?? username);
    setTab(initialTabType ? initialTabType : mobileTabType ? mobileTabType : 'follower');
  }, []);

  /*
    INFO
    
    컴포넌트 마운트 시 필요 Parameter있는지 검사 후 Follower / Following API request
  */
  useEffect(() => {
    let isCancelled = false;

    if (currentUserName && token) {
      if (isCancelled) return;

      (async () => {
        try {
          const [followerDatas, followingDatas] = await Promise.all([
            getFollowers(`/users/${currentUserName}/followers/`, token),
            getFollowings(`/users/${currentUserName}/followings/`),
          ]);

          setFollowerDatas(followerDatas);
          setFollowingDatas(followingDatas);
        } catch (error) {
          let errorMessage;

          if (error instanceof APIError) {
            errorMessage = error.issues.detail;
          } else {
            errorMessage = error as string;
          }

          openAlertModal([{ type: 'NORMAL', message: errorMessage }]);
        }
      })();
    }

    return () => {
      isCancelled = true;
    };
  }, [currentUserName, token]);

  const isFollowerTab = useMemo(() => tab === 'follower', [tab]);

  const renderFollowTabBody = useMemo(() => {
    return (isFollowerTab ? followerDatas : followingDatas)?.users.map((followerData, followerDataIndex) => (
      <FollowUserBox
        key={followerDataIndex}
        tabType={tab}
        userImage={followerData.image ?? undefined}
        userNickname={followerData.username}
        userName={followerData.username}
        spaceUserName={currentUserName ?? ''}
        isFollowing={followerData.isFollowing}
      />
    ));
  }, [isFollowerTab, tab, followerDatas, followingDatas]);

  const getMoreFollowerDatas = useCallback(async () => {
    let isCancelled = false;

    if (token && followerDatas?.next_url) {
      if (isCancelled) return;

      try {
        const newFollowerDatas = await getFollowers(followerDatas.next_url, token);

        setFollowerDatas((prev) => ({
          total_length: newFollowerDatas.total_length,
          next_url: newFollowerDatas.next_url,
          users: [...(prev?.users ?? []), ...newFollowerDatas.users],
        }));
      } catch (error) {
        let errorMessage;

        if (error instanceof APIError) {
          errorMessage = error.issues.detail;
        } else {
          errorMessage = error as string;
        }

        openAlertModal([{ type: 'NORMAL', message: errorMessage }]);
      }
    }

    return () => {
      isCancelled = true;
    };
  }, [followerDatas, token]);

  const getMoreFollowingDatas = useCallback(async () => {
    let isCancelled = false;

    if (token && followingDatas?.next_url) {
      if (isCancelled) return;
      try {
        const newFollowingDatas = await getFollowings(followingDatas.next_url);

        setFollowingDatas((prev) => ({
          total_length: newFollowingDatas.total_length,
          next_url: newFollowingDatas.next_url,
          users: [...(prev?.users ?? []), ...newFollowingDatas.users],
        }));
      } catch (error) {
        let errorMessage;

        if (error instanceof APIError) {
          errorMessage = error.issues.detail;
        } else {
          errorMessage = error as string;
        }

        openAlertModal([{ type: 'NORMAL', message: errorMessage }]);
      }
    }

    return () => {
      isCancelled = true;
    };
  }, [followerDatas, token]);

  const onTabClick = useCallback(
    async (tabKey: TabType) => {
      if (token && tab !== tabKey) {
        setTab(tabKey);

        try {
          if (isFollowerTab) {
            const followerDatas = await getFollowers(`/users/${currentUserName}/followers/`, token);

            setFollowerDatas(followerDatas);
          } else {
            const followingDatas = await getFollowings(`/users/${currentUserName}/followings/`);

            setFollowingDatas(followingDatas);
          }
        } catch (error) {
          let errorMessage;

          if (error instanceof APIError) {
            errorMessage = error.issues.detail;
          } else {
            errorMessage = error as string;
          }

          openAlertModal([{ type: 'NORMAL', message: errorMessage }]);
        }
      }
    },
    [isFollowerTab, setTab, currentUserName, tab, token]
  );

  const onRefreshInfiniteScroll = useCallback(async () => {
    if (token && currentUserName) {
      try {
        if (isFollowerTab) {
          const followerDatas = await getFollowers(`/users/${currentUserName}/followers/`, token);

          setFollowerDatas(followerDatas);
        } else {
          const followingDatas = await getFollowings(`/users/${currentUserName}/followings/`);

          setFollowingDatas(followingDatas);
        }
      } catch (error) {
        let errorMessage;

        if (error instanceof APIError) {
          errorMessage = error.issues.detail;
        } else {
          errorMessage = error as string;
        }

        openAlertModal([{ type: 'NORMAL', message: errorMessage }]);
      }
    }
  }, [isFollowerTab, token, currentUserName, setFollowerDatas, setFollowingDatas]);

  return (
    <FollowContainer>
      {!mediaMatch && <FollowHeader />}
      <FollowUserSearch
        currentTab={tab}
        currentUserName={currentUserName ?? ''}
        setFollowerDatas={setFollowerDatas}
        setFollowingDatas={setFollowingDatas}
      />
      <TabContainer>
        {Object.entries(tabType).map(([tabKey, tabName], tabIndex) => (
          <Tab key={tabIndex} isActive={tabKey === tab} onClick={() => onTabClick(tabKey as TabType)}>
            {scripts[languageMode][tabName]}
          </Tab>
        ))}
      </TabContainer>
      <TabBody id='follow-infinite-scroll'>
        <InfiniteScroll
          dataLength={(isFollowerTab ? followerDatas?.users.length : followingDatas?.users.length) ?? 0}
          next={isFollowerTab ? getMoreFollowerDatas : getMoreFollowingDatas}
          hasMore={isFollowerTab ? followerDatas?.next_url !== null : followingDatas?.next_url !== null}
          scrollableTarget='follow-infinite-scroll'
          loader={<Loading />}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          refreshFunction={onRefreshInfiniteScroll}>
          {renderFollowTabBody}
        </InfiniteScroll>
      </TabBody>
    </FollowContainer>
  );
}

export default Follow;
