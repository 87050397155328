import { useState, Suspense, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useAtom, useAtomValue } from 'jotai';
import styled from 'styled-components';

import Alarm from 'pages/Alarm/alarm';
import { SpaceCreateModal } from 'pages/Home/modals';
import {
  Bell,
  BgmOnOff,
  ChannelTalk,
  TermsofService,
  Post2D,
  FocusArtFair,
  DownArrow,
  SelectBottom,
  HomeActivated,
  Home,
  SearchIcon,
  Posting,
  CharacterActivated,
  Character,
  UserActivated,
  User,
  Gear,
  GearFill,
} from 'components/Commons/icons';
import Goback from 'components/Commons/goback';
import useModal from 'hooks/useModal';

import channelService from 'src/channel-service';
import {
  bgmModeAtom,
  navbarTypeAtom,
  languageModeAtom,
  playModeAtom,
  tokenAtom,
  profileModeAtom,
  userAtom,
} from 'src/atoms';

import navbarAlarmOnIcon from 'static/icons/navbar_alarm_on.svg';
import navbarAlarmOffIcon from 'static/icons/navbar_alarm_off.svg';
import navbarBgmOnIcon from 'static/icons/navbar_bgm_on.svg';
import navbarBgmOffIcon from 'static/icons/navbar_bgm_off.svg';
import navbarChanneltalkIcon from 'static/icons/navbar_channeltalk.svg';
import navbarInfoIcon from 'static/icons/navbar_info.svg';
import navbarLeftArrowIcon from 'static/icons/navbar_arrow_left.svg';
import { BreakPoint } from 'src/constants';

const scripts = {
  KR: ['회원가입', '비밀번호 초기화', '알림', '포스트'],
  EN: ['Membership', 'Password Initialization', 'Notification', 'Post'],
  JP: ['会員登録', '暗証番号初期化', 'お知らせ', 'ポスト'],
};

const NavbarAlarmIcon = styled.img.attrs<{ isActive: boolean }>(({ isActive }) => ({
  src: isActive ? navbarAlarmOnIcon : navbarAlarmOffIcon,
}))<{ isActive: boolean }>`
  width: 1.5rem;
  height: 1.5rem;
  margin: 0.5rem;

  ${BreakPoint.mobile} {
    width: 1.375rem;
    height: 1.375rem;
  }

  &:hover {
    cursor: pointer;
  }
`;

const NavbarBgmIcon = styled.img.attrs<{ isActive: boolean }>(({ isActive }) => ({
  src: isActive ? navbarBgmOnIcon : navbarBgmOffIcon,
}))<{ isActive: boolean }>`
  width: 1.375rem;
  height: 1.375rem;
  margin: 0.5rem;

  &:hover {
    cursor: pointer;
  }
`;

const NavbarChanneltalkIcon = styled.img.attrs({ src: navbarChanneltalkIcon })`
  width: 1.375rem;
  height: 1.375rem;
  margin: 0.5rem;

  &:hover {
    cursor: pointer;
  }
`;

const NavbarInfoIcon = styled.img.attrs({ src: navbarInfoIcon })`
  width: 1.375rem;
  height: 1.375rem;
  margin: 0.5rem;

  &:hover {
    cursor: pointer;
  }
`;

const NavbarLeftArrowIcon = styled.img.attrs({ src: navbarLeftArrowIcon })`
  width: 1.375rem;
  height: 1.375rem;
  margin: 0.5rem;

  &:hover {
    cursor: pointer;
  }
`;

export default function Navbar() {
  let navbarType = useAtomValue(navbarTypeAtom);
  let [bgmMode, setBgmMode] = useAtom(bgmModeAtom);
  let languageMode = useAtomValue(languageModeAtom);
  let content = null;
  const { openModal, closeModal } = useModal();
  let [openChTalk, setOpenChTalk] = useState<boolean>(false);
  let location = useLocation();
  let navigate = useNavigate();
  let [isOpen, setIsOpen] = useState<boolean>(false);
  let token = useAtomValue(tokenAtom);
  let user = useAtomValue(userAtom);
  let [isSpaceCreateOpen, setIsSpaceCreateOpen] = useState<boolean>(false);
  let [isAlarmOpen, setIsAlarmOpen] = useState<boolean>(false);
  let [keyword, setKeyword] = useState<string>('');
  let profileMode = useAtomValue(profileModeAtom);
  let playMode = useAtomValue(playModeAtom);

  const mediaMatch = useMediaQuery({ maxWidth: 900 });

  const beta_logo = new URL('../../static/imgs/logo_beta.png', import.meta.url);
  const faf_logo = new URL('../../static/imgs/logo_faf.png', import.meta.url);
  let playmode = useAtomValue(playModeAtom);

  const onHomeIconClick = useCallback(() => {
    const currentPath = location.pathname;
    const scrollTargetElement = document.getElementById('scrollElement');

    if (scrollTargetElement && currentPath === '/') {
      try {
        scrollTargetElement.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } catch (error) {
        scrollTargetElement.scrollTo(0, 0);
      }
    } else {
      navigate('/');
    }
  }, [location]);

  const onAlarmClick = useCallback(() => {
    if (location.pathname === '/alarm') return closeModal();

    openModal({
      component: <Alarm />,
      onClosed: () => {
        setIsAlarmOpen(false);
      },
      options: { isCloseOnESCKeyDown: true, isCloseOnOverlayClick: true, isOverlayed: true },
    });
  }, [setIsAlarmOpen, location.pathname, closeModal]);

  if (mediaMatch) {
    if (
      navbarType === 'login' ||
      navbarType === 'signup' ||
      navbarType === 'certificate' ||
      navbarType === 'posting' ||
      navbarType === 'settings' ||
      navbarType === 'search' ||
      navbarType === 'character' ||
      navbarType === 'profile' ||
      playmode === 'multiplay'
    ) {
      content = <div></div>;
    } else if (navbarType === 'password-reset') {
      content = (
        <div className='flex'>
          <div className='grow basis-0 justify-end'>
            <Goback />
          </div>
          <div className='flex items-center text-xl font-semibold'>{scripts[languageMode][1]}</div>
          <div className='grow basis-0 justify-end' />
        </div>
      );
    } else if (navbarType === 'alarm') {
      content = (
        <div className='flex'>
          <div className='grow basis-0 justify-end'>
            <NavbarLeftArrowIcon onClick={() => navigate(-1)} />
          </div>
          <div className='flex items-center text-xl font-semibold'>{scripts[languageMode][2]}</div>
          <div className='flex grow basis-0 justify-end'>
            <NavbarBgmIcon isActive={bgmMode} onClick={() => setBgmMode((prev) => !prev)} />
            <NavbarAlarmIcon
              isActive={isAlarmOpen}
              onClick={() => {
                setIsAlarmOpen((prev) => !prev);
                navigate('/');
              }}
            />
          </div>
        </div>
      );
    } else if (navbarType === 'follow') {
      content = (
        <div className='flex'>
          <div className='grow basis-0 justify-end'>
            <Goback />
          </div>
          <div className='flex items-center text-xl font-normal'>ID</div>
          <div className='flex grow basis-0 justify-end'></div>
        </div>
      );
    } else if (navbarType === '2dFeed') {
      content = (
        <div className='flex'>
          <div className='grow basis-0 justify-end'>
            <Goback />
          </div>
          <div className='flex items-center text-xl font-normal'>{scripts[languageMode][3]}</div>
          <div className='flex grow basis-0 justify-end'></div>
        </div>
      );
    } else {
      content = (
        <div className='bg-white flex w-full'>
          {/* <Link to='/' className='flex-initial flex justify-center items-center'>
          <NavLogo activate={false} />
        </Link> */}
          <button className='flex-initial flex justify-center items-center ml-4' onClick={onHomeIconClick}>
            {location.pathname === '/focusartfair' ? (
              <img src={faf_logo.href} style={{ height: '18px' }} />
            ) : (
              <img src={beta_logo.href} style={{ height: '18px' }} />
            )}
          </button>
          <button
            className='py-1 px-3 flex items-center'
            onClick={() => {
              setIsOpen(!isOpen);
            }}>
            <SelectBottom />
          </button>

          {isOpen && (
            <div className='bg-white absolute top-12 left-4 z-50 rounded drop-shadow-lg font-en font-light text-zinc-600 tracking-tighter'>
              {location.pathname === '/focusartfair' ? (
                <button
                  className='w-full flex px-4 py-3 border-b gap-2'
                  onClick={() => {
                    setIsOpen(false);
                    navigate('/');
                  }}>
                  <div>
                    <Post2D />
                  </div>
                  <div>ADLER BETA</div>
                </button>
              ) : (
                <button
                  className='w-full flex px-4 py-3 gap-2'
                  onClick={() => {
                    setIsOpen(false);
                    navigate('/focusartfair');
                  }}>
                  <div>
                    <FocusArtFair />
                  </div>
                  <div>FOCUS ART FAIR</div>
                </button>
              )}
            </div>
          )}
          <div className='flex-auto justify-end' />
          <NavbarChanneltalkIcon
            onClick={() => {
              if (openChTalk) {
                channelService.shutdown();
              } else {
                channelService.boot({
                  pluginKey: '2d7dd802-484a-413a-adb6-9f2b6e9c8dc7',
                });
              }
              setOpenChTalk((prev) => !prev);
            }}
          />
          <NavbarBgmIcon isActive={bgmMode} onClick={() => setBgmMode((prev) => !prev)} />
          {token ? (
            <NavbarAlarmIcon
              isActive={isAlarmOpen}
              onClick={() => {
                setIsAlarmOpen((prev) => !prev);
                navigate('/alarm');
              }}
            />
          ) : (
            <NavbarInfoIcon
              onClick={() => {
                if (location.pathname === '/') {
                  navigate('/introduction/termsofservice');
                } else {
                  navigate('/');
                }
              }}
            />
          )}
        </div>
      );
    }
  } else {
    if (
      navbarType === 'login' ||
      navbarType === 'password-reset' ||
      navbarType === 'signup' ||
      playMode === 'multiplay'
    ) {
      content = <div></div>;
    } else {
      content = (
        <div className='w-full lg:bg-white shadow-sm'>
          <div className='flex justify-items-center w-[940px] m-auto items-center'>
            {/* <Link to='/' className='flex-auto flex justify-center items-center'>
            <NavLogo activate={false} />
          </Link> */}
            <button className='flex justify-items-center w-[940px] m-auto ' onClick={onHomeIconClick}>
              {location.pathname === '/focusartfair' ? (
                <img src={faf_logo.href} style={{ height: '18px' }} />
              ) : (
                <img src={beta_logo.href} style={{ height: '18px' }} />
              )}
            </button>
            <button
              className='py-1 px-3 flex items-center'
              onClick={() => {
                setIsOpen(!isOpen);
              }}>
              <SelectBottom />
            </button>
            {isOpen && (
              <div className='bg-white absolute top-12 left-50 z-50 rounded drop-shadow-lg font-en font-light text-zinc-600 tracking-tighter'>
                <div>
                  {location.pathname === '/focusartfair' ? (
                    <button
                      className='w-full flex px-4 py-3 border-b gap-2'
                      onClick={() => {
                        setIsOpen(false);
                        navigate('/');
                      }}>
                      <div>
                        <Post2D />
                      </div>
                      <div>ADLER BETA</div>
                    </button>
                  ) : (
                    <button
                      className='w-full flex px-4 py-3 gap-2'
                      onClick={() => {
                        setIsOpen(false);
                        navigate('/focusartfair');
                      }}>
                      <div>
                        <FocusArtFair />
                      </div>
                      <div>FOCUS ART FAIR</div>
                    </button>
                  )}
                </div>
              </div>
            )}
            <div className='min-w-[206]' />
            <div className='min-w-[228] flex w-72 my-3 mx-1 bg-[#EEEEEE] rounded-xl px-2 py-1'>
              {/* Fix me.. Better use react teleporter*/}
              <form
                className='flex flex-auto bg-[#EEEEEE] flex-row'
                onSubmit={(e) => {
                  e.preventDefault();
                  navigate(`/search/${keyword}`);
                }}>
                <input
                  type='text'
                  className='flex-auto grow bg-[#EEEEEE]'
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
                <button
                  style={{ width: '24px', height: '24px' }}
                  type='submit'
                  className='flex justify-center items-center grow-0'>
                  <SearchIcon />
                </button>
              </form>
            </div>
            <div className='min-w-[100]' />
            <div className='flex'>
              <button className='p-2 flex justify-center items-center' onClick={onHomeIconClick}>
                {navbarType === 'feed' ? <HomeActivated /> : <Home />}
              </button>
              <button
                className='p-2 flex justify-center items-center'
                onClick={() => {
                  if (openChTalk) {
                    setOpenChTalk(false);
                    channelService.shutdown();
                  } else {
                    setOpenChTalk(true);
                    channelService.boot({
                      pluginKey: '2d7dd802-484a-413a-adb6-9f2b6e9c8dc7',
                    });
                  }
                }}>
                <ChannelTalk />
              </button>
              <Link to={window.location.pathname} className='p-2 flex justify-center items-center'>
                <button
                  onClick={() => {
                    setBgmMode(!bgmMode);
                  }}>
                  <BgmOnOff sound={bgmMode} />
                </button>
              </Link>
              {navbarType == 'profile' ? (
                <Link to='/settings' className='p-2 flex justify-center items-center'>
                  <button>
                    <Gear />
                  </button>
                </Link>
              ) : navbarType == 'settings' ? (
                <Link to='/settings' className='p-2 flex justify-center items-center'>
                  <button>
                    <GearFill />
                  </button>
                </Link>
              ) : (
                <NavbarAlarmIcon
                  isActive={isAlarmOpen}
                  onClick={() => {
                    if (token && user) {
                      setIsAlarmOpen((prev) => !prev);
                      onAlarmClick();
                    } else {
                      navigate('/login');
                    }
                  }}
                />
              )}
              <button
                className='p-2 flex justify-center items-center'
                onClick={() => {
                  setIsSpaceCreateOpen(true);
                }}>
                <Posting />
              </button>
              <button
                className='p-2 flex justify-center items-center'
                onClick={() => {
                  if (token && user) {
                    navigate(`/avatar`);
                  } else {
                    navigate('/login');
                  }
                }}>
                {navbarType === 'character' ? <CharacterActivated /> : <Character />}
              </button>
              <button
                className='p-2 flex justify-center items-center'
                onClick={() => {
                  if (token && user) {
                    navigate(`/u/${user.username}`);
                  } else {
                    navigate('/login');
                  }
                }}>
                {navbarType === 'profile' ? <UserActivated /> : <User />}
              </button>
            </div>
            <SpaceCreateModal isOpen={isSpaceCreateOpen} setIsOpen={setIsSpaceCreateOpen} />
            <Suspense
              fallback={
                <div
                  className='absolute inset-0 flex'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}></div>
              }></Suspense>
          </div>
        </div>
      );
    }
  }

  return content;
}
