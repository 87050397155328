import MyPage from './my-page';
import { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import Account from './account';
import Language from './language';

import {
  ProfileIcon,
  SettingIcon,
  CharacterIcon,
  LanguageIcon,
  LogoutIcon,
  PageIcon,
  User,
} from 'components/Commons/icons';
import Goback from 'components/Commons/goback';

import { tokenAtom, navbarTypeAtom, languageModeAtom, userAtom } from 'src/atoms';
import styled from 'styled-components';
import { Color } from 'src/constants';

const scripts = {
  KR: [
    '설정',
    '프로필 설정',
    '계정 설정',
    '캐릭터 설정',
    '언어설정',
    '업데이트 노트',
    '로그아웃',
    '프로필 공간',
  ],
  EN: [
    'Settings',
    'Edit Profile',
    'Account Settings',
    'Character Settings',
    'Language Settings',
    'Update Notes',
    'Logout',
    'Profile Space',
  ],
  JP: [
    '設定',
    'プロフィール編集',
    'アカウント設定',
    'キャラクター設定',
    '言語設定',
    'アップデートノート',
    'ログアウト',
    'プロフィールスペース',
  ],
};

export default function Settings() {
  let [token, setToken] = useAtom(tokenAtom);
  let languageMode = useAtomValue(languageModeAtom);
  let [mode, setMode] = useState<string>('default');
  let setNavbarType = useSetAtom(navbarTypeAtom);
  let user = useAtomValue(userAtom);
  useEffect(() => {
    setNavbarType('settings');
  });
  const mediaMatch = window.matchMedia('(min-width: 1024px)');
  const [matches, setMatches] = useState<boolean>(mediaMatch.matches);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });
  let content = null;
  let settingBar = (
    <>
      <div className='flex justify-center h-11 shrink-0 lg:hidden'>
        <div className='grow basis-0 justify-end'>
          <Goback />
        </div>
        <div className='flex items-center text-xl font-semibold'>{scripts[languageMode][0]}</div>
        <div className='flex grow basis-0 justify-end'></div>
      </div>
      <div className={matches ? 'setting-bar' : ''}>
        <ul className='p-4 flex flex-col'>
          <li
            onClick={() => {
              setMode('profile');
            }}
            className='cursor-pointer flex pb-6'>
            <ProfileIcon />
            <span className='pl-2'>{scripts[languageMode][1]}</span>
          </li>
          <li
            onClick={() => {
              setMode('account');
            }}
            className='cursor-pointer flex pb-6'>
            <SettingIcon />
            <span className='pl-2'>{scripts[languageMode][2]}</span>
          </li>
          <Link to='/avatar'>
            <li className='cursor-pointer flex pb-6'>
              <CharacterIcon />
              <span className='pl-2'>{scripts[languageMode][3]}</span>
            </li>
          </Link>
          <Link to={`/u/${user?.username}`}>
            <li className='cursor-pointer flex pb-6'>
              <User />
              <span className='pl-2'>{scripts[languageMode][7]}</span>
            </li>
          </Link>
          <li
            onClick={() => {
              setMode('language');
            }}
            className='cursor-pointer flex pb-6'>
            <LanguageIcon />
            <span className='pl-2'> {scripts[languageMode][4]} </span>
          </li>
          <li
            className='cursor-pointer flex pb-6'
            onClick={() => window.open('https://adlerupdatenote.oopy.io/')}>
            <PageIcon />
            <span className='pl-2'>{scripts[languageMode][5]}</span>
          </li>
          <li onClick={() => setToken(null)} className='cursor-pointer flex pb-6'>
            <LogoutIcon />
            <span className='pl-2'>{scripts[languageMode][6]}</span>
          </li>
        </ul>
      </div>
    </>
  );

  if (!matches && mode === 'default') {
    content = settingBar;
  } else if (mode === 'profile' || (matches && mode === 'default')) {
    content = <MyPage setMode={setMode} />;
  } else if (mode === 'account') {
    content = <Account setMode={setMode} />;
  } else if (mode === 'language') {
    content = <Language setMode={setMode} />;
  }

  return (
    <div className='flex flex-col lg:items-center h-full'>
      <div className='lg:flex lg:bg-white lg:w-[940] lg:mt-4 grow'>
        {!token && <Navigate to='/login' />}
        {matches && settingBar}
        {content}
      </div>

      {mode === 'default' && (
        <div className='block lg:hidden px-4 text-xs text-zinc-400 mb-8'>
          Adler Inc. 아들러 주식회사 <br />
          서울특별시 관악구 남부순환로224길 25 2층 2F, 25, <br />
          Nambusunhwan-ro 224-gil, Gwanak-gu, Seoul, Republic of Korea
          <br />
          대표이사 한세진 | 사업자등록번호: 772-88-02469 <br />
          통신판매번호 2021-서울관악-2418 | 회사 전화번호 02-874-6182 <br />
          Contact : cs@adler3d.com
        </div>
      )}

      <div className='hidden lg:flex text-xs text-zinc-400 h-16 items-center gap-4'>
        <a href='introduction'>소개</a>
        <a href='introduction/termsofservice'>이용약관</a>
        <a href='introduction/privacy'>개인정보처리방침</a>
        <a>ⓒ 2022 ADLER</a>
      </div>
    </div>
  );
}
