import { ReactNode } from 'react';
import { atom, Getter } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import * as Colyseus from 'colyseus.js';

import { LanguageType } from './types';

import { CustomModalWrapperAlign } from 'components/CustomModal/types';
import { getTokenUser, Token, Account } from 'apis/index';
import { NavbarType } from 'apis/types';
import { KeyEventStatus } from 'hooks/useKeyLock';

function atomWithRefresh<T>(fn: (get: Getter) => T) {
  const refreshCounter = atom(0);

  return atom(
    (get) => {
      get(refreshCounter);
      return fn(get);
    },
    (_, set) => set(refreshCounter, (i) => i + 1)
  );
}

export let tokenAtom = atomWithStorage<Token | null>('token', null);

export let userAtom = atomWithRefresh<Promise<Account | null>>(async (get) => {
  let token = get(tokenAtom);
  if (!token) {
    return null;
  }
  let user = await getTokenUser(token);
  return user;
});

export let playModeAtom = atom<'scroll' | 'multiplay'>('scroll');

export let navbarTypeAtom = atom<NavbarType>('feed');

export let bgmModeAtom = atom<boolean>(false);

function getLanguageCode(): 'KR' | 'EN' | 'JP' {
  let language = navigator.language;
  if (language.includes('ko')) {
    return 'KR';
  } else if (language.includes('jp')) {
    return 'JP';
  } else {
    return 'EN';
  }
}

export let languageModeAtom = atomWithStorage<LanguageType>('language', getLanguageCode());

export let spacechannel = atom<number>(0);

export let profileModeAtom = atom<boolean>(false);

export let totalRoomsAtom = atom<any[] | null>([]);
export let currentRoomAtom = atom<Colyseus.Room | null>(null);
export let messageListAtom = atom<{ m: string; u: string; n: string; p: string; t: Date }[]>([]);
export let wheelDelta = atom<number>(0);

/*
  TODO

  추후에 type과 실제 atom사용을 위한 구조 개편이 필요할 듯 하다.
*/
interface CustomModalOptions {
  isOverlayed?: boolean;
  isCloseOnESCKeyDown?: boolean;
  isCloseOnOverlayClick?: boolean;
  align?: CustomModalWrapperAlign;
}

export interface CustomModalAtom {
  component: ReactNode;
  onClosed?: (hasAction?: boolean) => void;
  options?: CustomModalOptions;
}

export const customModalAtom = atom<CustomModalAtom[]>([]);

export const dirAtom = atom<string[]>([]);

export const isInteractiveAtom = atom(true);

export const keyEventStatusAtom = atom<KeyEventStatus>({
  TAB: false,
  SPACE: false,
  ARROWUP: false,
  ARROWDOWN: false,
});
