import { Sky, Environment } from '@react-three/drei';

import { useCompressedGLTF } from 'hooks/hooks';

let url = new URL('static/models/oss7.glb', import.meta.url);

export default function OsstemTheme() {
  let { scene, nodes, materials } = useCompressedGLTF(url.href);
  scene.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      let mesh = object as THREE.Mesh;
      mesh.material = mesh.material as THREE.Material;

      mesh.material.toneMapped = false;
    }
  });
  return (
    <group castShadow receiveShadow>
      <group position={[2, -1.9, -2]} scale={1.4} rotation={[0, -Math.PI / 2, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.실린더.geometry}
          material={materials['매테리얼.006']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.실린더001.geometry}
          material={materials['매테리얼.005']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve027.geometry}
          material={materials['매테리얼.002']}
          position={[-0.04884701, 0.64457113, 2.63018036]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={4.73398066}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve026.geometry}
          material={materials['매테리얼.002']}
          position={[-0.04884701, 0.66360271, 2.7189045]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={9.62077904}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve025.geometry}
          material={materials['매테리얼.002']}
          position={[-0.048847, 0.64457542, 2.81082487]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={9.62077904}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve022.geometry}
          material={materials['매테리얼.002']}
          position={[-0.048847, 0.6513406, 3.05098629]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={9.62077904}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve020.geometry}
          material={materials['매테리얼.002']}
          position={[-0.04898213, 0.66652435, 3.2958796]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={9.62077904}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve019.geometry}
          material={materials['매테리얼.002']}
          position={[-0.04884697, 0.63072366, 3.53631353]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={9.62077904}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve017.geometry}
          material={materials['매테리얼.002']}
          position={[-0.04884696, 0.64457428, 3.86887884]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={9.62077904}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve014.geometry}
          material={materials['매테리얼.002']}
          position={[-0.04884695, 0.66526973, 4.13275957]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={9.62077904}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve013.geometry}
          material={materials['매테리얼.002']}
          position={[-0.04884695, 0.66359508, 4.18974066]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={9.62077904}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve011.geometry}
          material={materials['매테리얼.002']}
          position={[-0.04884693, 0.64538622, 4.41724682]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={9.62077904}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve007.geometry}
          material={materials['매테리얼.002']}
          position={[-0.04884691, 0.68710393, 5.00348234]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={9.62077904}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve005.geometry}
          material={materials['매테리얼.002']}
          position={[-0.02535519, 1.11830449, 2.80143189]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={4.73398066}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve002.geometry}
          material={materials['매테리얼.002']}
          position={[-0.02535517, 1.05577219, 4.07572842]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={4.73398066}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve028.geometry}
          material={materials.매테리얼}
          position={[-0.05761981, 2.9008584, 0.50395477]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={3.74869299}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve029.geometry}
          material={materials.매테리얼}
          position={[-0.2473132, 2.8768115, -0.23636492]}
          rotation={[Math.PI / 2, -3e-8, -2.02141372]}
          scale={[3.74869323, 3.74869323, 3.74869299]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve024.geometry}
          material={materials['매테리얼.002']}
          position={[-0.048847, 0.66526973, 2.89411569]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={9.62077904}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve023.geometry}
          material={materials['매테리얼.002']}
          position={[-0.048847, 0.66360134, 2.951087]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={9.62077904}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve033.geometry}
          material={materials.매테리얼}
          position={[-0.10196369, 2.81790447, 1.03305829]}
          rotation={[Math.PI / 2, 0, -1.31069543]}
          scale={3.74869299}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve021.geometry}
          material={materials['매테리얼.002']}
          position={[-0.04884699, 0.64538682, 3.17860222]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={9.62077904}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve018.geometry}
          material={materials['매테리얼.002']}
          position={[-0.04529961, 0.66834116, 3.67096114]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={9.62077904}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve012.geometry}
          material={materials['매테리얼.002']}
          position={[-0.04884695, 0.65133661, 4.28962469]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={9.62077904}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve010.geometry}
          material={materials['매테리얼.002']}
          position={[-0.04898208, 0.6690284, 4.53607464]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={9.62077904}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve008.geometry}
          material={materials['매테리얼.002']}
          position={[-0.04884692, 0.64521486, 4.85564899]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={9.62077904}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve001.geometry}
          material={materials['매테리얼.002']}
          position={[-0.02535515, 1.04391456, 4.42939234]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={4.73398066}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031.geometry}
          material={materials.매테리얼}
          position={[-0.05761984, 2.87178254, 0.34022456]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={3.74869299}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve034.geometry}
          material={materials.매테리얼}
          position={[-0.24238609, 2.89159894, 1.38149035]}
          rotation={[Math.PI / 2, -1e-8, -1.09645945]}
          scale={[7.61839724, 7.61839724, 7.61839771]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sphere.geometry}
          material={materials['Material.003']}
          scale={0.66388899}
        />
        <mesh geometry={nodes.Sphere001.geometry} scale={0.66388899}>
          <meshPhysicalMaterial
            thickness={0}
            roughness={0.3}
            clearcoat={0}
            transmission={1}
            ior={1.25}
            envMapIntensity={25}
            color='white'
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve016.geometry}
          material={materials['매테리얼.002']}
          position={[-0.04884695, 0.66360682, 3.95754004]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={9.62077904}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve015.geometry}
          material={materials['매테리얼.002']}
          position={[-0.04884695, 0.64457452, 4.04946709]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={9.62077904}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve009.geometry}
          material={materials['매테리얼.002']}
          position={[-0.04884692, 0.65836042, 4.75605106]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={9.62077904}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve006.geometry}
          material={materials['매테리얼.002']}
          position={[-0.02535519, 1.14867187, 3.77677727]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={4.73398066}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve004.geometry}
          material={materials['매테리얼.002']}
          position={[-0.01442176, 1.04549909, 3.2844758]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={4.73398066}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve003.geometry}
          material={materials['매테리얼.002']}
          position={[-0.0253552, 1.11195362, 3.57001305]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={4.73398066}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve.geometry}
          material={materials['매테리얼.002']}
          position={[-0.02535516, 1.13697863, 4.86318254]}
          rotation={[Math.PI / 2, -3e-8, -Math.PI / 2]}
          scale={9.62077904}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve030.geometry}
          material={materials.매테리얼}
          position={[-0.11055074, 2.81915927, 0.1067258]}
          rotation={[Math.PI / 2, -2e-8, -1.86409092]}
          scale={[3.74869323, 3.74869323, 3.74869299]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve032.geometry}
          material={materials.매테리얼}
          position={[-0.05761978, 2.82729411, 0.74068493]}
          rotation={[Math.PI / 2, -6e-8, -1.50171282]}
          scale={3.74869299}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Sphere002.geometry}
          material={materials.TILE}
          scale={0.67319787}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3.geometry}
          material={materials.Color_M05}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_1.geometry}
          material={materials.Ntr_bl}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_2.geometry}
          material={nodes.Object_3_2.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_3.geometry}
          material={materials.Lamino}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_4.geometry}
          material={materials['매테리얼.007']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_5.geometry}
          material={materials.default_material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_6.geometry}
          material={materials.Color_M08}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_7.geometry}
          material={materials['매테리얼.003']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_8.geometry}
          material={materials.Carpet_A02_100cm1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_9.geometry}
          material={materials.Carpet_A02_100cm}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_10.geometry}
          material={materials.Carpet_A02_100cm1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_11.geometry}
          material={materials.Flooring_Parquet_Parallel_I01_120cm}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_12.geometry}
          material={materials.매테리얼}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_13.geometry}
          material={materials['매테리얼.005']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_14.geometry}
          material={materials.Titanium}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_15.geometry}
          material={materials.Leather_A01_Black_25cm}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_16.geometry}
          material={materials.Veneer_D01_120cm}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_17.geometry}
          material={materials.Glass_Frosted}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_18.geometry}
          material={materials.Color_I04}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_19.geometry}
          material={materials.pskovan_flie}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_20.geometry}
          material={materials.bl_koule}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_21.geometry}
          material={materials.white}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_22.geometry}
          material={materials.Material_2_33}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_23.geometry}
          material={materials['Babylon_pa.1']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_24.geometry}
          material={materials.Kashpo_Metal1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Object_3_25.geometry}
          material={materials.Material_3_36}
        />
      </group>
    </group>
  );
}
