import React, { useState, useCallback, useEffect, useRef } from 'react';

import { AutosizeTextareaContainer } from './styles';
import { AutosizeTextareaProps } from './types';

const DEFAULT_ROW_LINE_HEIGHT = 24;

function AutosizeTextarea({
  value = '',
  setValue,
  placeholder,
  minRows = 1,
  maxRows = 100,
  className,
  onChange,
  paddingAreaHeight,
}: AutosizeTextareaProps): JSX.Element {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [rows, setRows] = useState(minRows);

  useEffect(() => {
    if (value && value.length !== 0 && textAreaRef.current) {
      const currentRows = ~~((textAreaRef.current.scrollHeight - (paddingAreaHeight ?? 0)) / DEFAULT_ROW_LINE_HEIGHT);

      setRows(currentRows < maxRows ? currentRows : maxRows);
    }
  }, [textAreaRef.current]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (onChange) {
        onChange(event);
      }

      const previousRows = event.target.rows;
      event.target.rows = minRows;
      const currentRows = ~~((event.target.scrollHeight - (paddingAreaHeight ?? 0)) / DEFAULT_ROW_LINE_HEIGHT);

      if (currentRows >= previousRows) {
        event.target.rows = currentRows;
      }

      if (maxRows && currentRows >= maxRows) {
        event.target.rows = maxRows;
        event.target.scrollTop = event.target.scrollHeight;
      }

      setValue(event.target.value);
      setRows(currentRows < maxRows ? currentRows : maxRows);
    },
    [setValue, setRows]
  );

  return (
    <AutosizeTextareaContainer
      ref={textAreaRef}
      className={className}
      style={{ overflow: 'hidden', height: 'auto', lineHeight: '24px', resize: 'none' }}
      value={value}
      rows={rows}
      onChange={handleChange}
      placeholder={placeholder}
    />
  );
}

export default AutosizeTextarea;
