import { Sky, Clone } from '@react-three/drei';
import { useEffect } from 'react';
import { SkeletonUtils } from 'three-stdlib';
import * as THREE from 'three';

import ThemaFront from './thema-front';
import { useCompressedGLTF } from 'hooks/hooks';

const url = new URL('static/models/cartoon6.min.glb', import.meta.url);
const url2 = new URL('static/models/cartoon_bloom.min.glb', import.meta.url);
const url3 = new URL('static/models/cartoon_mat2.min.glb', import.meta.url);

export default function CartoonThema({}: {}) {
  const { scene: scene1, nodes } = useCompressedGLTF(url.href);
  const nodeNames: any[] = [];
  const modelArr = [];
  const disposeMesh: THREE.Mesh[] = [];
  const disposeTexture: THREE.Texture[] = [];
  scene1.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      const mesh = object as THREE.Mesh;

      mesh.receiveShadow = true;
      mesh.material = mesh.material as THREE.Material;
      mesh.material.toneMapped = false;
      const mat = mesh.material as THREE.Material;
      disposeMesh.push(mesh);
      if (mat.map !== null) {
        disposeTexture.push(mat.map);
      }
    }
  });

  Object.values(nodes).map((value) => {
    nodeNames.push(value);
  });

  for (let i = 1; i < nodeNames.length; i++) {
    modelArr.push(<Clone key={i} object={nodeNames[i]} />);
  }

  const { scene: scene2, nodes: nodes2 } = useCompressedGLTF(url2.href);
  const nodeNames2: any[] = [];
  const modelArr2 = [];

  scene2.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      const mesh = object as THREE.Mesh;

      mesh.material = (mesh.material as THREE.Material).clone();

      mesh.material.toneMapped = false;
      const mat = mesh.material as THREE.Material;
      disposeMesh.push(mesh);
      if (mat.map !== null) {
        disposeTexture.push(mat.map);
      }
    }
  });
  Object.values(nodes2).map((value) => {
    nodeNames2.push(value);
  });

  for (let i = 1; i < nodeNames2.length; i++) {
    modelArr2.push(<Clone key={i} object={nodeNames2[i]} />);
  }

  const { scene: scene3, nodes: nodes3 } = useCompressedGLTF(url3.href);
  const nodeNames3: any[] = [];
  const modelArr3 = [];

  scene3.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      const mesh = object as THREE.Mesh;
      mesh.material = (mesh.material as THREE.Material).clone();
      mesh.material.toneMapped = false;

      const mat = mesh.material as THREE.Material;
      disposeMesh.push(mesh);
      if (mat.map !== null) {
        disposeTexture.push(mat.map);
      }
    }
  });
  Object.values(nodes3).map((value) => {
    nodeNames3.push(value);
  });

  for (let i = 1; i < nodeNames3.length; i++) {
    modelArr3.push(<Clone key={i} object={nodeNames3[i]} />);
  }
  useEffect(() => {
    return () => {
      for (let i = 0; i < disposeMesh.length; i++) {
        if (disposeMesh[i]) disposeMesh[i].geometry.dispose();
      }
      for (let i = 0; i < disposeTexture.length; i++) {
        if (disposeTexture[i]) disposeTexture[i].dispose();
      }
    };
  }, []);
  return (
    <>
      <ThemaFront position={[0, 0.1, 4.44]} scale={[1, 1, 1]} rotation={[0, -Math.PI / 2, 0]} />
      <group position={[0, -1.9, 0.94]} scale={1.1} rotation={[0, Math.PI / 2, 0]}>
        <group position={[-1.79, 0, -1.9]} scale={0.00023}>
          {modelArr}
        </group>
        <group position={[0, 0, 0]}>{modelArr2}</group>
        <group position={[-1.79, 0, -1.9]} scale={0.00023}>
          {modelArr3}
        </group>
      </group>
    </>
  );
}
