import React, { useState, useEffect } from 'react';

let joyout = new URL('static/imgs/out.png', import.meta.url);
let joyin = new URL('static/imgs/in.png', import.meta.url);

function calcAngleDegrees(x: number, y: number) {
  let theta_x = (Math.atan2(y, x) * 180) / Math.PI;
  if (theta_x < 0) {
    return theta_x + 360;
  }
  return theta_x;
}

export default function Joy({
  setDir,
}: {
  setDir: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  let [xy, setXY] = useState({ x: innerWidth / 2, y: innerHeight });
  let [joyxy, setJoyxy] = useState({ x: innerWidth / 2, y: innerHeight });
  let [isjoy, setIsjoy] = useState(true);
  let [isMove, setIsMove] = useState(true);
  let clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

  let handleTouchStart = (e: React.TouchEvent) => {
    // setJoyxy({
    //   x: clamp(e.changedTouches[0].pageX, xy.x - 20, xy.x + 20),
    //   y: clamp(e.changedTouches[0].pageY, xy.y - 20, xy.y + 20),
    // });
    setJoyxy({
      x: clamp(e.changedTouches[0].pageX - 45, xy.x - 20, xy.x + 20),
      y: clamp(e.changedTouches[0].pageY + 100, xy.y - 20, xy.y + 20),
    });
  };

  let [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  useEffect(() => {
    setXY({ x: innerWidth / 10, y: innerHeight / 1.1 });
    setJoyxy({ x: innerWidth / 10 + 5, y: innerHeight / 1.1 });
  }, [windowSize]);

  let handleTouchMove = (e: React.TouchEvent) => {
    if (isMove == true) {
      setJoyxy({
        x: clamp(e.changedTouches[0].pageX - 45, xy.x - 20, xy.x + 20),
        y: clamp(e.changedTouches[0].pageY + 100, xy.y - 20, xy.y + 20),
      });

      if (
        calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) > 110 &&
        calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) < 195
      ) {
        setDir(['left', '']);
      }
      if (
        calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) > 195 &&
        calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) < 255
      ) {
        setDir(['left', 'forward']);
      }

      if (
        calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) < 170 &&
        calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) > 110
      ) {
        setDir(['left', 'backward']);
      }

      if (
        calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) < 110 &&
        calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) > 75
      ) {
        setDir(['', 'backward']);
      }
      if (
        calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) > 255 &&
        calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) < 295
      ) {
        setDir(['', 'forward']);
      }

      if (
        calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) > 295 &&
        calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) < 355
      ) {
        setDir(['right', 'forward']);
      }
      if (
        calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) > 15 &&
        calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) < 75
      ) {
        setDir(['right', 'backward']);
      }
      if (calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) > 355) {
        setDir(['right', '']);
      }
      if (calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) < 15) {
        setDir(['right', '']);
      }
    }
  };
  let handleMouseUp = () => {
    setJoyxy({ x: xy.x + 5, y: xy.y });
    setDir(['', '']);
  };

  return (
    isjoy && (
      <>
        <div
          id='joystick2'
          className='bg-transparent z-50'
          style={{
            position: 'absolute',
            backgroundImage: `url(${'"' + joyout + '"'})`,
            left: xy.x,
            top: xy.y - 150,
            width: 100,
            height: 100,
            backgroundSize: 'contain',
          }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleMouseUp}></div>
        <div
          id='joystick'
          className='bg-transparent z-50'
          style={{
            backgroundImage: `url("${joyin.href}")`,
            position: 'absolute',
            // backgroundColor: 'red',
            left: joyxy.x - 5,
            top: joyxy.y - 150,
            width: 100,
            height: 100,
            backgroundSize: 'contain',
          }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleMouseUp}></div>
        <div style={{ position: 'absolute', left: xy.x, top: xy.y }}></div>
      </>
    )
  );
}
