import styled from 'styled-components';

import { BreakPoint, Color } from 'src/constants';

import adlerLogo from 'static/imgs/login.png';

export const LoginContainer = styled.div`
  display: flex;
  width: 100%;

  .clickable:hover {
    cursor: pointer;
  }
`;

export const LoginLeftArea = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;

  ${BreakPoint.mobile} {
    width: 100%;
  }
`;

export const LoginRightImage = styled.img.attrs({ src: adlerLogo })`
  width: 50%;
  height: 100vh;

  ${BreakPoint.mobile} {
    display: none;
  }
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 23.5rem;
  margin-top: 5rem;

  ${BreakPoint.mobile} {
    width: 87.2%;
    margin-top: 2.75rem;
  }
`;

export const LoginTitle = styled.div`
  width: 100%;
  margin-top: 15.625rem;
  font-weight: 500;
  font-size: 2rem;
  letter-spacing: -0.015rem;
  color: ${Color.black};

  ${BreakPoint.mobile} {
    margin-top: 5.625rem;
  }
`;

export const ManageButtonFlexArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: -0.005rem;
  color: ${Color.grayScale1};
`;

export const ResetPasswordButton = styled.div.attrs({ className: 'clickable' })``;

export const SignUpButton = styled.div.attrs({ className: 'clickable' })``;

export const LoginButton = styled.div`
  margin-top: 1.25rem;
  width: 100%;
  padding: 0.75rem 0;
  font-weight: 500;
  line-height: 1.375rem;
  letter-spacing: -0.025rem;
  text-align: center;
  background-color: #db1f72;
  color: ${Color.white};
`;
