const environments = {
  development: 'development',
  staging: 'staging',
  production: 'production',
} as const;

function getEnvPath(): string {
  let envPath: string = '';

  switch (process.env.NODE_ENV) {
    case environments.development:
      envPath = `../.env.${environments.development}`;

      break;
    case environments.staging:
      envPath = `../.env.${environments.staging}`;

      break;
    case environments.production:
      envPath = `../.env.${environments.production}`;

      break;
    default:
      envPath = `../.env.${environments.development}`;
  }

  return envPath;
}

export default getEnvPath;
