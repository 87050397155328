import styled from 'styled-components';

import { Color } from 'src/constants';

import adlerLogo from 'static/imgs/logo.png';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`;

export const AdlerLogo = styled.img.attrs({ src: adlerLogo, className: 'clickable' })`
  width: 5.375rem;
  height: 1.625rem;
`;

export const LanguageTypeText = styled.div.attrs({ className: 'clickable' })`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.015rem;
  color: ${Color.grayScale2};
`;
