import { Dispatch, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import { SetStateAction, useAtomValue } from 'jotai';
import 'cropperjs/dist/cropper.css';

import { ToLeftGray, ToRight } from 'components/Commons/icons';
import { APIError } from 'apis/index';
import useModal from 'hooks/useModal';

import { languageModeAtom } from 'src/atoms';

const scripts = {
  KR: ['초기화', '자르기', '잘라내기'],
  EN: ['Initialize', 'Crop', 'Crop'],
  JP: ['初期化', '切る', '切り取り'],
};

export default function ImgCropper({
  file,
  setFile,
  setProgress,
}: {
  file: { element: File; itemtype: number; original?: File | null };
  setFile: Dispatch<SetStateAction<{ element: File; itemtype: number; original?: File | null } | null>>;
  setProgress: Dispatch<SetStateAction<number>>;
}) {
  let languageMode = useAtomValue(languageModeAtom);
  const cropperRef = useRef<HTMLImageElement>(null);
  const [croppedImg, setCroppedImg] = useState<File>(file.element);
  const [mode, setMode] = useState<string>('view');
  const { openAlertModal } = useModal();
  const getCroppedData = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    if (typeof cropper !== 'undefined') {
      cropper.getCroppedCanvas({ imageSmoothingQuality: 'high' }).toBlob(function (blob: Blob) {
        if (blob) {
          let c = new File([blob], 'cropped.jpeg', {
            type: 'image/jpeg',
          });
          setCroppedImg(c);
          setFile({ element: c, itemtype: 1, original: file.original });
        }
      }, 'image/jpeg');
    }
  };
  let cropperCoverRef = useRef<HTMLDivElement>(null);
  let canvasHeight = null;
  if (cropperCoverRef) {
    canvasHeight = cropperCoverRef.current?.clientHeight;
  }
  return (
    <>
      <div className='flex justify-center h-11 shrink-0 bg-white'>
        <div className='grow basis-0 justify-end'>
          <button
            className='p-2.5'
            onClick={() => {
              setProgress(1);
            }}>
            <ToLeftGray />
          </button>
        </div>
        <div className='flex items-center text-xl font-semibold'>{scripts[languageMode][2]}</div>
        <div className='flex grow basis-0 justify-end'>
          <button
            className='p-2.5'
            onClick={() => {
              try {
                getCroppedData();
                setMode('crop');
                setProgress(3);
              } catch (error) {
                let errorMessage;

                if (error instanceof APIError) {
                  errorMessage = error.issues.detail;
                } else {
                  errorMessage = error as string;
                }

                openAlertModal([{ type: 'NORMAL', message: errorMessage }]);
              }
            }}>
            <ToRight />
          </button>
        </div>
      </div>
      <div className='absolute h-full w-full bg-black' style={{ zIndex: '-100' }}></div>
      <div className='grow flex flex-col relative overflow-hidden justify-center' ref={cropperCoverRef}>
        <div className='flex justify-center items-center'>
          {mode === 'view' && (
            <Cropper
              style={{ height: canvasHeight ? Number(canvasHeight) : '100%' }}
              src={URL.createObjectURL(file.original ? file.original : file.element)}
              // Cropper.js options
              viewMode={1}
              initialAspectRatio={1 / 1}
              aspectRatio={1 / 1}
              background={false}
              movable={false}
              scalable={false}
              zoomable={false}
              guides={false}
              ref={cropperRef}
            />
          )}
          {mode === 'crop' && <img src={URL.createObjectURL(croppedImg)} />}
        </div>
        <div
          className='w-full absolute bottom-0 z-50 flex py-2 shrink-0'
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }}>
          <button
            className='flex-1 text-white'
            onClick={() => {
              setMode('view');
            }}>
            {scripts[languageMode][0]}
          </button>
          <button
            className='flex-1 text-white'
            onClick={() => {
              try {
                getCroppedData();
                setMode('crop');
              } catch (error) {
                let errorMessage;

                if (error instanceof APIError) {
                  errorMessage = error.issues.detail;
                } else {
                  errorMessage = error as string;
                }

                openAlertModal([{ type: 'NORMAL', message: errorMessage }]);
              }
            }}>
            {scripts[languageMode][1]}
          </button>
        </div>
      </div>
    </>
  );
}
