import { usePlane, useBox } from '@react-three/cannon';

function Plane(props) {
  let [ref] = usePlane(() => ({ rotation: props.rotation, position: props.position }));
  return <mesh ref={ref} />;
}

function Cube(props) {
  let [ref] = useBox(() => ({ type: 'Static', position: props.position, args: props.args, rotation: props.rotation }));
  return <mesh ref={ref} />;
}

export default function Theme3Primitiveform({
  THREE_WIDTH,
  THREE_HEIGHT,
  THREE_DEPTH,
}: {
  THREE_WIDTH: number;
  THREE_HEIGHT: number;
  THREE_DEPTH: number;
}) {
  return (
    <>
      <Plane position={[THREE_WIDTH / 2, -1, 0]} args={[THREE_WIDTH, THREE_DEPTH]} rotation={[-Math.PI / 2, 0, 0]} />
      <Cube
        position={[1, THREE_HEIGHT / 2, 0]}
        args={[THREE_DEPTH, THREE_WIDTH, 0.1]}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <Cube
        position={[THREE_WIDTH - 1, THREE_HEIGHT / 2, 0]}
        args={[THREE_DEPTH, THREE_WIDTH, 0.1]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <Cube
        position={[THREE_WIDTH / 2, THREE_HEIGHT / 2, -THREE_DEPTH / 2 + 1]}
        args={[THREE_WIDTH, THREE_HEIGHT, 0.1]}
        rotation={[0, 0, 0]}
      />
      <Cube
        position={[THREE_WIDTH / 2, THREE_HEIGHT / 2, THREE_DEPTH / 2]}
        args={[THREE_WIDTH, THREE_HEIGHT, 0.1]}
        rotation={[0, 0, 0]}
      />
    </>
  );
}
