import { UseAlertModalResources } from './types';

import { Scripts } from 'src/types';

const scripts: Scripts = {
  KR: ['알림'],
  EN: ['Notification'],
  JP: ['お知らせ'],
};

const useAlertModalResources = (): UseAlertModalResources => {
  return { scripts };
};

export default useAlertModalResources;
