import { useState, useEffect, Dispatch, useRef } from 'react';
import { SetStateAction, useAtomValue } from 'jotai';
import InfiniteScroll from 'react-infinite-scroll-component';

import { SingleReply, ReplyInput } from 'pages/Home/replys';
import { Music } from 'components/Commons/icons';
import { API_BASE_URL, getReplys } from 'apis/index';
import { ReplySet, Space } from 'apis/types';

import { languageModeAtom } from 'src/atoms';

const scripts = {
  KR: ['댓글'],
  EN: ['comment'],
  JP: ['コメント'],
};

export default function ItemReply({
  space,
  setIsReplyOpen,
}: {
  space: Space;
  setIsReplyOpen: Dispatch<SetStateAction<boolean>>;
}) {
  let languageMode = useAtomValue(languageModeAtom);
  let [replys, setReplys] = useState<ReplySet | null>(null);
  let [currentURL, setCurrentURL] = useState<string>(API_BASE_URL + '/spaces/' + space.id + '/replys/');
  let getMoreReplys = async () => {
    if (replys) {
      let moreReplys = await getReplys(replys.next);
      setCurrentURL(replys.next);
      setReplys({
        previous: moreReplys.previous,
        count: moreReplys.count,
        results: [...replys.results, ...moreReplys.results],
        next: moreReplys.next,
      });
    }
  };
  useEffect(() => {
    let isCancelled = false;
    (async () => {
      let replys = await getReplys(API_BASE_URL + '/spaces/' + space.id + '/replys/');
      if (isCancelled) {
        return;
      }
      setReplys(replys);
    })();
    return () => {
      isCancelled = true;
    };
  }, [space.id]);

  let replysRef = useRef<HTMLDivElement>(null);

  let ScrollToBottom = () => {
    if (replysRef.current) {
      replysRef.current.scrollTop = replysRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    ScrollToBottom();
  }, [replys]);

  return (
    <div className='flex flex-col fixed inset-0 bg-white'>
      <div className='flex bg-white h-11 w-full'>
        <div className='grow basis-0 justify-end lg:hidden'>
          <button className='flex-initial p-2.5' onClick={() => setIsReplyOpen(false)}>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M16 19.5L8 12L16 4.5' stroke='black' strokeLinecap='round' />
            </svg>
          </button>
        </div>
        <div className='flex items-center text-xl font-normal'>{scripts[languageMode][0]}</div>
        <div className='grow basis-0 justify-end' />
      </div>
      <div className='pb-2 w-full max-h-full overflow-y-auto flex flex-col grow' id='reply-box' ref={replysRef}>
        <div className='flex py-2'>
          <div id='profile_image_area' className='shrink-0 px-4 pt-1 lg:hidden'>
            <img
              src={space.profileImgURL ?? new URL('../../../../static/imgs/default-profile.png', import.meta.url).href}
              className='h-12 w-12 rounded-full object-cover aspect-square'
            />
          </div>
          <div id='desc_area' className='pr-4 break-all'>
            <div id='text_area' className='pb-1'>
              <div id='text' className='inline lg:hidden'>
                {space.noHashTagDescription + ' '}
              </div>
              {/* <button type='button' className='inline text-zinc-400'>
                [번역하기]
              </button> */}
            </div>
            <div id='hashtag_area' className='text-sm text-tag pb-1'>
              {space.hashTags.map((content) => {
                return content + ' ';
              })}
            </div>
            <div className='flex items-center gap-1'>
              <Music width='18' height='18' />
              {space.bgm}
            </div>
          </div>
        </div>
        <InfiniteScroll
          dataLength={replys ? replys.results.length : 15}
          next={getMoreReplys}
          className='flex flex-col'
          // style={{ display: 'flex', flexDirection: 'column' }}
          hasMore={replys?.next != ''}
          scrollableTarget='reply-box'>
          {replys?.results.map((result, index) => (
            <SingleReply key={index} reply={result} />
          ))}
        </InfiniteScroll>
      </div>
      <div className='w-full bg-white'>
        <ReplyInput spaceId={space.id} replys={replys} setReplys={setReplys} currentURL={currentURL} />
      </div>
    </div>
  );
}
