import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useAtomValue, useSetAtom } from 'jotai';

import {
  SignUpContainer,
  SignUpForm,
  SignUpTitle,
  SignUpButton,
  SignUpInformationCheckbox,
  SignUpInformation,
  SignUpInformationText,
  RequiredInformation,
} from './styles';
import useSignUpResources from './resources';

import ValidationInput from 'components/ValidationInput';
import { InputData, OnError } from 'components/ValidationInput/types';
import Footer from 'components/Footer';

import { APIError, registerCheck } from 'apis/index';
import { apiErrorCodeToMessage } from 'utils/errorUtils';

import { languageModeAtom, navbarTypeAtom } from 'src/atoms';
import { ErrorCode } from 'src/types';
import channelService from 'src/channel-service';
import Header from 'components/Header';

function SignUp(): JSX.Element {
  const [usernameInputData, setUsernameInputData] = useState<InputData>();
  const [emailInputData, setEmailInputData] = useState<InputData>();
  const [passwordInputData, setPasswordInputData] = useState<InputData>();
  const [passwordAcceptInputData, setPasswordAcceptInputData] = useState<InputData>();
  const [agreement, setAgreement] = useState(false);
  const languageMode = useAtomValue(languageModeAtom);
  const setNavbarType = useSetAtom(navbarTypeAtom);
  const navigate = useNavigate();
  const { scripts } = useSignUpResources();
  const mediaMatch = useMediaQuery({ maxWidth: 1224 });

  useEffect(() => {
    setNavbarType('signup');
    channelService.boot({
      pluginKey: '2d7dd802-484a-413a-adb6-9f2b6e9c8dc7',
    });

    return () => {
      channelService.shutdown();
      setNavbarType('feed');
    };
  }, []);

  useEffect(() => {
    setNavbarType('signup');
  }, []);

  const isSignUpButtonActive = useMemo(
    () =>
      usernameInputData?.isError === false &&
      emailInputData?.isError === false &&
      passwordInputData?.isError === false &&
      passwordAcceptInputData?.isError === false &&
      usernameInputData.value !== '' &&
      emailInputData.value !== '' &&
      passwordInputData.value !== '' &&
      passwordAcceptInputData.value !== '' &&
      agreement === true,
    [usernameInputData, emailInputData, passwordInputData, passwordAcceptInputData, agreement]
  );

  const onUsernameError: OnError = useCallback((data) => {
    if (data === '') return scripts[languageMode][15];

    if (data.length > 20 || data.length < 6) return scripts[languageMode][16];

    return;
  }, []);

  const onEmailError: OnError = useCallback((data) => {
    const emailRegex =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    if (data === '') return;

    if (!emailRegex.test(data)) return scripts[languageMode][18];

    return;
  }, []);

  const onPasswordError: OnError = useCallback((data) => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d~!@#$%^&*()+|=]{8,16}$/;

    if (data === '') return scripts[languageMode][19];

    if (!passwordRegex.test(data)) return scripts[languageMode][20];

    return;
  }, []);

  const onPasswordAcceptError: OnError = useCallback(
    (data) => {
      if (data === '') return scripts[languageMode][21];

      if (passwordInputData?.value !== data) return scripts[languageMode][22];

      return;
    },
    [passwordInputData]
  );

  const onSignUpInformationCheckboxClick = useCallback(() => {
    setAgreement((prev) => !prev);
  }, [agreement]);

  const onSignUpInformationClick = useCallback(() => {
    window.open('https://adler-inc.notion.site/adler-inc/a131945a6a1347bf87c15eba69227083');
  }, []);

  const onSignUpButtonClick = useCallback(async () => {
    if (!isSignUpButtonActive) return;

    if (usernameInputData?.value && emailInputData?.value && passwordInputData?.value) {
      try {
        const result = await registerCheck(usernameInputData.value, emailInputData.value, passwordInputData.value);

        if (result.result) {
          navigate('/certificate', {
            state: {
              id: usernameInputData.value,
              email: emailInputData.value,
              password: passwordInputData.value,
            },
          });
        } else {
          alert(scripts[languageMode][19]);
        }
      } catch (error) {
        if (error instanceof APIError) {
          switch (error.issues.field) {
            case 'username':
              return setUsernameInputData((prev) => ({
                ...prev,
                value: prev?.value ? prev.value : '',
                isError: true,
                errorMessage: apiErrorCodeToMessage(
                  (error as APIError).issues.code as ErrorCode,
                  scripts[languageMode][1]
                )[languageMode][0],
              }));
            case 'email':
              return setEmailInputData((prev) => ({
                ...prev,
                value: prev?.value ? prev.value : '',
                isError: true,
                errorMessage: apiErrorCodeToMessage(
                  (error as APIError).issues.code as ErrorCode,
                  scripts[languageMode][3]
                )[languageMode][0],
              }));
            case 'password':
              return setPasswordInputData((prev) => ({
                ...prev,
                value: prev?.value ? prev.value : '',
                isError: true,
                errorMessage: apiErrorCodeToMessage(
                  (error as APIError).issues.code as ErrorCode,
                  scripts[languageMode][4]
                )[languageMode][0],
              }));
            default:
              return;
          }
        }
      }
    }
  }, [isSignUpButtonActive, usernameInputData, emailInputData, passwordInputData, languageMode]);

  /*
    INFO

    추후 기능구현 시 활성화
  */
  // const onKakaoSignUpButtonClick = useCallback(() => {
  //   alert('추후 출시될 기능입니다 :)');
  // }, []);

  // const onNaverSignUpButtonClick = useCallback(() => {
  //   alert('추후 출시될 기능입니다 :)');
  // }, []);

  // const onFacebookSignUpButtonClick = useCallback(() => {
  //   alert('추후 출시될 기능입니다 :)');
  // }, []);

  // const onGoogleSignUpButtonClick = useCallback(() => {
  //   alert('추후 출시될 기능입니다 :)');
  // }, []);

  // const onAppleSignUpButtonClick = useCallback(() => {
  //   alert('추후 출시될 기능입니다 :)');
  // }, []);

  return (
    <SignUpContainer>
      <SignUpForm>
        {mediaMatch && <Header />}
        <SignUpTitle>{scripts[languageMode][0]}</SignUpTitle>
        <ValidationInput
          label={scripts[languageMode][1]}
          placeholder='james_007'
          required
          value={usernameInputData}
          setValue={setUsernameInputData}
          detailMessage={scripts[languageMode][2]}
          onError={onUsernameError}
          margin={'0 0 1rem 0'}
        />
        <ValidationInput
          label={scripts[languageMode][3]}
          placeholder='example@gmail.com'
          value={emailInputData}
          setValue={setEmailInputData}
          onError={onEmailError}
          margin={'0 0 1rem 0'}
        />
        <ValidationInput
          type='password'
          label={scripts[languageMode][4]}
          placeholder='********'
          required
          value={passwordInputData}
          setValue={setPasswordInputData}
          detailMessage={scripts[languageMode][5]}
          onError={onPasswordError}
          margin={'0 0 1rem 0'}
        />
        <ValidationInput
          type='password'
          label={scripts[languageMode][6]}
          placeholder='********'
          required
          value={passwordAcceptInputData}
          setValue={setPasswordAcceptInputData}
          onError={onPasswordAcceptError}
          margin={'0 0 1rem 0'}
        />
        <RequiredInformation>
          <span>*</span>필수 입력 정보입니다.
        </RequiredInformation>
        <SignUpButton isActive={isSignUpButtonActive} onClick={onSignUpButtonClick}>
          {scripts[languageMode][7]}
        </SignUpButton>
        <SignUpInformation onClick={onSignUpInformationCheckboxClick}>
          <SignUpInformationCheckbox isChecked={!!agreement} />
          <SignUpInformationText>
            <a onClick={onSignUpInformationClick}>{scripts[languageMode][8]}</a>
            {scripts[languageMode][9]}
          </SignUpInformationText>
        </SignUpInformation>
        {/*
          INFO
          추후 기능구현 시 활성화
         */}
        {/* <DivisionBorder />
        <KakaoSignUpButton isActive={false} onClick={onKakaoSignUpButtonClick}>
          카카오로 시작하기
        </KakaoSignUpButton>
        <NaverSignUpButton isActive={false} onClick={onNaverSignUpButtonClick}>
          네이버로 시작하기
        </NaverSignUpButton>
        <FacebookSignUpButton isActive={false} onClick={onFacebookSignUpButtonClick}>
          페이스북으로 시작하기
        </FacebookSignUpButton>
        <GoogleSignUpButton isActive={false} onClick={onGoogleSignUpButtonClick}>
          구글로 시작하기
        </GoogleSignUpButton>
        <AppleSignUpButton isActive={false} onClick={onAppleSignUpButtonClick}>
          애플로 시작하기
        </AppleSignUpButton> */}
      </SignUpForm>
      <Footer />
    </SignUpContainer>
  );
}

export default SignUp;
