import { useState, useEffect, useRef } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAtomValue, useAtom } from 'jotai';
import * as THREE from 'three';

import ImgCropper from './image-cropper-new';
import { ImageUpload } from './upload';
import SpaceDetails from './space-details';
import ModelingScaler from './modeling-scaler';
import BgmList from './bgm-list';

import { CheckMainColor, ToLeftGray, ToRight } from 'components/Commons/icons';
import AutosizeTextarea from 'components/AutosizeTextarea';
import useModal from 'hooks/useModal';

import { uploadFile, createSpace, Space, handleOnInput, APIError } from 'apis/index';

import { tokenAtom, navbarTypeAtom, languageModeAtom } from 'src/atoms';
const scripts = {
  KR: [
    '새로운 포스팅',
    '문구 입력',
    '잘라내기',
    '3D 모델 스케일 조정',
    '3D 포스트가 만들어지는 중 입니다.',
    '잠시만 기다려주세요.',
  ],
  EN: [
    'New Posting',
    'Enter description',
    'Crop',
    'Scale 3D Model',
    '3D POST is creating.',
    'Wait a second please.',
  ],
  JP: [
    '新しいポスティング',
    'フレーズ入力',
    '切り取り',
    '3Dモデルスケール調整',
    '3D POST 作成中です.',
    'ちょっと待ってください.',
  ],
};

export default function SpaceForm() {
  let token = useAtomValue(tokenAtom);
  let languageMode = useAtomValue(languageModeAtom);
  let [file, setFile] = useState<{
    element: File;
    itemtype: number;
    original?: File | null;
  } | null>(null);
  let [uploadId, setUploadId] = useState<string | null>(null);
  let navigate = useNavigate();
  let [navbarType, setNavbarType] = useAtom(navbarTypeAtom);
  let [description, setDescription] = useState<string>('');
  let [scale, setScale] = useState<number>(30);
  let [position, setPosition] = useState<THREE.Vector3>(new THREE.Vector3(50, 50, 50));
  let [rotation, setRotation] = useState<THREE.Euler>(new THREE.Euler(0, 0, 0));
  let [progress, setProgress] = useState<number>(1);
  let [themeValue, setThemeValue] = useState<number>(1);
  let [intensity, setIntensity] = useState<number>(80);
  let [hue, setHue] = useState<number>(0);
  let [saturation, setSaturation] = useState<number>(0);
  let [brightness, setBrightness] = useState<number>(100);
  let [frameValue, setFrameValue] = useState<number>(0);
  let [bgm, setBgm] = useState<number>(1);
  let [capturedImg, setCapturedImg] = useState<File | null>(null);
  let [thumbId, setThumbId] = useState<string | null>(null);
  let [fileURL, setFileURL] = useState<string | null>(null);
  let [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const mediaMatch = window.matchMedia('(min-width: 1024px)');
  const [matches, setMatches] = useState<boolean>(mediaMatch.matches);
  const { openAlertModal } = useModal();

  let preivewSpace: Space = {
    id: '',
    username: '',
    items: [
      {
        id: uploadId!,
        type: file ? file.itemtype : 1,
        file: fileURL ?? '',
        positionX: position.x,
        positionY: position.y,
        positionZ: position.z,
        rotationX: rotation.x,
        rotationY: rotation.y,
        rotationZ: rotation.z,
        scale: scale,
        frameType: frameValue,
      },
    ],
    description: description,
    hashTags: [],
    noHashTagDescription: '',
    userNickname: '',
    likeNum: 0,
    profileImgURL: '',
    thumbImage: '',
    replyNum: 0,
    bgm: 1,
    theme: themeValue,
    lightBright: intensity,
    themeColor: hue,
    themeSaturation: saturation,
    themeBrightness: brightness,
    isLike: false,
    created: '',
  };
  useEffect(() => {
    const handler = (e: { matches: boolean | ((prevState: boolean) => boolean) }) => {
      setMatches(e.matches);
    };
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  useEffect(() => {
    setNavbarType('posting');
  });

  useEffect(() => {
    if (file?.element) {
      setFileURL(URL.createObjectURL(file.element));
    } else {
      setFileURL(null);
    }
  }, [file?.element]);

  let creating = (
    <>
      <div className='flex justify-center h-11 shrink-0'>
        <div className='grow basis-0 justify-end'>
          <button
            className='p-2.5'
            onClick={() => {
              setProgress(3);
            }}>
            <ToLeftGray />
          </button>
        </div>
        <div className='flex items-center text-xl font-semibold'>{scripts[languageMode][0]}</div>
        <div className='flex grow basis-0 justify-end'>
          <button
            className='p-2.5'
            onClick={async () => {
              if (!isSubmitted && token && uploadId && thumbId) {
                try {
                  setIsSubmitted(true);
                  let space = await createSpace(
                    token,
                    [
                      {
                        uploadId: uploadId!,
                        positionX: position.x,
                        positionY: position.y,
                        positionZ: position.z,
                        rotationX: rotation.x,
                        rotationY: rotation.y,
                        rotationZ: rotation.z,
                        type: file ? file.itemtype : 1,
                        scale: scale,
                        frameType: frameValue,
                      },
                    ],
                    thumbId!,
                    description,
                    bgm,
                    themeValue,
                    intensity,
                    hue,
                    saturation,
                    brightness
                  );
                  navigate('/');
                  matches && location.reload();
                } catch (error) {
                  setIsSubmitted(false);
                  let errorMessage;

                  if (error instanceof APIError) {
                    errorMessage = error.issues.detail;
                  } else {
                    errorMessage = error as string;
                  }

                  openAlertModal([{ type: 'NORMAL', message: errorMessage }]);
                  setProgress(1);
                }
              }
            }}>
            <CheckMainColor />
          </button>
        </div>
      </div>
      <div className='px-6'>
        <div className='mt-6 w-full'>
          {/* <textarea
            className='text-base placeholer:text-zinc-300 p-4 border border-zinc-200 rounded w-full h-36'
            name='description'
            value={description}
            onChange={(e) => {
              handleOnInput(e, 280);
              setDescription(e.target.value);
            }}
            placeholder={scripts[languageMode][1]}></textarea> */}
          <AutosizeTextarea
            className={
              'text-base placeholer:text-zinc-300 p-4 border border-zinc-200 rounded w-full'
            }
            value={description}
            setValue={setDescription}
            onChange={(event) => handleOnInput(event, 280)}
            placeholder={scripts[languageMode][1]}
            paddingAreaHeight={32}
          />
        </div>
      </div>

      <BgmList bgm={bgm} setBgm={setBgm} />
    </>
  );
  let content = useRef<any>();
  useEffect(() => {
    if (file) {
      if (file.itemtype == 1) {
        if (file.element.name.includes('gif')) {
          if (progress == 2) {
            setProgress(3);
          }
        } else {
          content.current = (
            <div className='h-full flex flex-col'>
              <ImgCropper file={file} setFile={setFile} setProgress={setProgress} />
            </div>
          );
        }
      }
    }
  }, [progress, file]);
  useEffect(() => {
    if (file) {
      if (file.itemtype == 2) {
        content.current = (
          <div className='h-full flex flex-col'>
            <div className='flex justify-center h-11 shrink-0 bg-white'>
              <div className='grow basis-0 justify-end'>
                <button
                  className='p-2.5'
                  onClick={() => {
                    setProgress(1);
                  }}>
                  <ToLeftGray />
                </button>
              </div>
              <div className='flex items-center text-xl font-normal'>
                {scripts[languageMode][3]}
              </div>
              <div className='flex grow basis-0 justify-end'>
                <button
                  className='p-2.5'
                  onClick={() => {
                    setProgress(3);
                  }}>
                  <ToRight />
                </button>
              </div>
            </div>
            <ModelingScaler
              preSpace={preivewSpace}
              scale={scale}
              setScale={setScale}
              setCapturedImg={setCapturedImg}
              position={position}
              setPosition={setPosition}
            />
          </div>
        );
      }
    }
  }, [progress, preivewSpace, position, scale, file]);
  useEffect(() => {
    if (!token || !file?.element) {
      setUploadId(null);
      return;
    }
    let isCancelled = false;
    (async () => {
      try {
        if (file && token) {
          let id = await uploadFile(token, file.element);
          setUploadId(id.id);
        }
      } catch (error) {
        let errorMessage;

        if (error instanceof APIError) {
          errorMessage = error.issues.detail;
        } else {
          errorMessage = error as string;
        }

        openAlertModal([{ type: 'NORMAL', message: errorMessage }]); //여기는 왜 있는거죠..? => 여기에만 있는게 맞는거 같네 - 용민
        setProgress(1); //업로드 중 오류 발생시 처음부터 다시 시작하도록 함 - 용민
      }
    })();
    return () => {
      isCancelled = true;
    };
  }, [token, file]);
  return (
    <>
      {!token && <Navigate to='/login' />}
      {progress === 1 && <ImageUpload setFile={setFile} setProgress={setProgress} />}
      {progress === 2 && content.current}
      {progress === 3 && (
        <SpaceDetails
          capturedImg={capturedImg}
          setCapturedImg={setCapturedImg}
          setProgress={setProgress}
          preSpace={preivewSpace}
          themeValue={themeValue}
          setThemeValue={setThemeValue}
          frameValue={frameValue}
          setFrameValue={setFrameValue}
          intensity={intensity}
          setIntensity={setIntensity}
          hue={hue}
          setHue={setHue}
          saturation={saturation}
          setSaturation={setSaturation}
          brightness={brightness}
          setBrightness={setBrightness}
          setThumbId={setThumbId}
        />
      )}
      {progress === 4 && creating}
      {isSubmitted && (
        <div
          className='fixed inset-0 w-full h-full flex items-center justify-center text-white font-en text-center'
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.5' }}>
          {scripts[languageMode][4]}
          <br />
          {scripts[languageMode][5]}
        </div>
      )}
    </>
  );
}
