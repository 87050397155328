import { useCallback, useMemo, useState } from 'react';

import { Detail, Input, Label, ValidationInputContainer, WarningIcon, Error, InputArea, InputEyeIcon } from './styles';
import { ValidationInputProps } from './types';

function ValidationInput({
  type = 'text',
  label,
  placeholder,
  detailMessage,
  value,
  margin,
  required,
  setValue,
  onError,
}: ValidationInputProps): JSX.Element {
  const [inputValueVisible, setInputValueVisible] = useState<boolean>(false);

  const inputType = useMemo(() => {
    if (type === 'text') return 'text';
    else if (inputValueVisible) return 'text';
    else return 'password';
  }, [type, inputValueVisible]);

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const errorMessage = onError && onError(event.target.value);

      setValue((prev) => ({
        ...prev,
        value: event.target.value,
        isError: !!errorMessage,
        errorMessage,
      }));
    },
    [onError, setValue]
  );

  const onInputEyeIconClick = () => {
    setInputValueVisible((prev) => !prev);
  };

  return (
    <ValidationInputContainer margin={margin}>
      {label && (
        <Label>
          {label}
          {required && <span>*</span>}
        </Label>
      )}
      <InputArea>
        <Input type={inputType} placeholder={placeholder} onChange={onInputChange} />
        {type === 'password' && <InputEyeIcon isVisible={inputValueVisible} onClick={onInputEyeIconClick} />}
      </InputArea>

      {detailMessage && <Detail>{detailMessage}</Detail>}
      {value?.isError && <Error>{value?.errorMessage}</Error>}
    </ValidationInputContainer>
  );
}

export default ValidationInput;
