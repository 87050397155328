import styled from 'styled-components';

export const FollowHeaderContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const Title = styled.div`
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: -0.02rem;
  padding: 1.025rem 0;
  margin-left: calc(50% - 0.5rem);
`;

export const CloseIconContainer = styled.div`
  margin: 1rem 0 0 9.5rem;
`;
