import { InfoModalContainer, InfoModalStyle, StyleInfo } from './ScrollModeLayoutStyle';

import LayOutComponent from './LayoutComponent';
import ModalComponent from './Modals';
import { ViwerModal } from './ViwerModal';
import { Space } from 'apis/types';

export default function ScrollModeLayout({ space }: { space: Space }) {
  return (
    <>
      <ViwerModal space={space} />
      <InfoModalContainer>
        <StyleInfo>
          <LayOutComponent space={space}></LayOutComponent>
        </StyleInfo>
        <InfoModalStyle
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <ModalComponent space={space}></ModalComponent>
        </InfoModalStyle>
      </InfoModalContainer>
    </>
  );
}
