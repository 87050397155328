import styled from 'styled-components';

import { BreakPoint } from 'src/constants';

export const FollowContainer = styled.div`
  position: absolute;
  top: calc(50% + 6.25rem);
  left: calc(50% - 12.5rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25rem;
  height: 37.5rem;
  background-color: '#000000';
  border-radius: 0.625rem;
  background-color: #ffffff;

  ${BreakPoint.mobile} {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const TabContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const Tab = styled.div<{ isActive: boolean }>`
  width: 50%;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: -0.025rem;
  color: ${({ isActive }) => (isActive ? '#DB1F72' : '#B3B3B3')};
  border-bottom: 2px solid ${({ isActive }) => (isActive ? '#DB1F72' : '#B3B3B3')};
  padding: 1rem 0;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
`;

export const TabBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 31.25rem;
  overflow-y: auto;
`;
