import { Dispatch, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SetStateAction, useAtom, useAtomValue, useSetAtom } from 'jotai';

import { ChangeEmail, ChangePassword, APIIssues, deleteUser } from 'apis/index';

import { languageModeAtom, tokenAtom, userAtom } from 'src/atoms';
import useModal from 'hooks/useModal';

const scripts = {
  KR: [
    '계정 설정',
    '인플루언서 인증',
    '완료',
    '신청하기',
    '이메일이 변경되었습니다.',
    '유효하지 않은 이메일 주소이거나 중복된 이메일입니다. 이메일 주소를 확인해주세요.',
    '저장',
    '비밀번호 변경',
    '현재 비밀번호',
    '현재 비밀번호를 입력해 주세요.',
    '새 비밀번호',
    '새로운 비밀번호를 다시 입력해 주세요.',
    '비밀번호가 변경되었습니다.',
    '현재 비밀번호가 일치하지 않거나 새로운 비밀번호가 양식에 맞지 않습니다. 다시 확인해 주시기 바랍니다.',
    '입력하신 새 비밀번호가 일치하지 않습니다. 다시 확인해 주시기 바랍니다',
    '비밀번호 변경',
    '미완료',
    '회원탈퇴',
    '새 비밀번호 확인',
    '탈퇴하시겠습니까?',
    '취소',
    '탈퇴',
  ],
  EN: [
    'Account Settings',
    'influencer Authentication',
    'Completed',
    'Apply',
    'Your email has been changed.',
    'Invalid Email Address or Duplicate Email. Please check your email address.',
    'Save',
    'Change Password',
    'Current Password',
    'Please Enter Current Password',
    'New Password',
    'Please Re-enter New Password',
    'Password Changed',
    'The current password does not match or the new password does not fit the form. Please check again.',
    'The new password you entered does not match. Please check again.',
    'Change password',
    'Incomplete',
    'Withdrawal',
    'New Password Confirm',
    'Are you sure?',
    'Cancel',
    'Withdrawal',
  ],
  JP: [
    'アカウントの設定',
    'インフルエンサー認証',
    '完了',
    '申請する',
    'Eメールが変更されました',
    '無効なメールアドレスまたは重複しているメールアドレスです。 メールアドレスを確認してください。',
    '保存',
    'パスワードの変更',
    '現在のパスワード',
    '現在使用中のパスワードを入力してください。',
    '新しいパスワード',
    '新しいパスワードをもう一度入力してください。',
    '暗証番号が変更されました',
    '現在の暗証番号が一致しないか新しい暗証番号が様式に合いません。もう一度ご確認ください。',
    '入力された新しいパスワードが一致しません。 もう一度ご確認ください',
    'パスワード変更',
    '未完了',
    '会員脱退',
    '新しいパスワード 確認',
    '脱退しますか？',
    'キャンセル',
    '脱退',
  ],
};

export default function Account({ setMode }: { setMode: Dispatch<SetStateAction<string>> }) {
  let [token, setToken] = useAtom(tokenAtom);
  let user = useAtomValue(userAtom);
  let refreshUser = useSetAtom(userAtom);
  let languageMode = useAtomValue(languageModeAtom);
  let [password, setPassword] = useState<string>('');
  let [newPassword, setNewPassword] = useState<string>('');
  let [newPassword2, setNewPassword2] = useState<string>('');
  let [newEmail, setNewEmail] = useState<string>(user?.email ? user.email : '');
  let [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const { openAlertModal } = useModal();
  const mediaMatch = window.matchMedia('(min-width: 1024px)');
  const [matches, setMatches] = useState<boolean>(mediaMatch.matches);
  let [errors, setErrors] = useState<APIIssues>({});
  let navigate = useNavigate();
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
    },
  };
  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });
  return (
    <>
      <div className='flex justify-center h-11 shrink-0 lg:hidden'>
        <div className='grow basis-0 justify-end'>
          <button className='flex-initial p-2.5' onClick={() => setMode('default')}>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M16 19.5L8 12L16 4.5' stroke='black' strokeLinecap='round' />
            </svg>
          </button>
        </div>
        <div className='flex items-center text-xl font-normal'>{scripts[languageMode][0]}</div>
        <div className='flex grow basis-0 justify-end'></div>
      </div>
      <div className='px-12 mb-12 lg:grow'>
        <div className='flex my-8 gap-2 lg:gap-8 items-center'>
          <div className={'font-semibold text-zinc-700 ' + (matches ? 'setting-label' : '')}>
            {scripts[languageMode][1]}
          </div>
          <div className={matches ? 'setting-input' : ''}>
            {user?.grade === 1 ? (
              <div className='font-semibold text-pink-600'>{scripts[languageMode][2]}</div>
            ) : (
              <div className='font-semibold text-pink-600'>{scripts[languageMode][16]}</div>
              // <button
              //   className={'rounded bg-pink-600 text-white text-sm py-1.5 px-2 w-32 lg:w-[70]'}
              //   onClick={() => {
              //     window.open(
              //       'https://docs.google.com/forms/d/e/1FAIpQLSfsWMNcDTN1rAhLGrCru0SUQLecSiJy2gMeNXuK7cRGgPAXhQ/viewform'
              //     );
              //   }}>
              //   {scripts[languageMode][3]}
              // </button>
            )}
          </div>
        </div>
        <div className='w-full'>
          {user?.email !== '' ? (
            <div className='text-zinc-500'>Email: {user?.email}</div>
          ) : (
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                if (token) {
                  try {
                    let result = await ChangeEmail(token, newEmail);
                    if (result) {
                      openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][4] }]);
                    } else {
                      openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][5] }]);
                    }
                    refreshUser();
                  } catch (error) {
                    openAlertModal([{ type: 'NORMAL', message: 'failed to change email' }]);
                  }
                }
              }}>
              <label className='flex flex-col gap-1 mb-3 lg:gap-8 lg:my-4 lg:flex-row'>
                <span className={'text-sm text-zinc-700 lg:items-start lg:pt-2 ' + (matches ? 'setting-label' : '')}>
                  Email (선택)
                </span>
                <div className={'flex gap-4 lg:flex-col ' + (matches ? 'setting-input' : '')}>
                  <input
                    type='email'
                    name='email'
                    className='rounded border-solid border border-zinc-300 px-3 py-1 w-full grow'
                    value={newEmail}
                    onChange={(e) => {
                      setNewEmail(e.target.value);
                    }}
                  />
                  <button
                    type='submit'
                    className={
                      'rounded bg-pink-600 text-white text-sm py-1.5 px-2 w-12 lg:w-[70] ' +
                      (matches ? 'justify-start' : '')
                    }>
                    {scripts[languageMode][6]}
                  </button>
                </div>
              </label>
            </form>
          )}
        </div>
        <hr className='my-10' />
        <div className='text-zinc-700'>
          <div className='font-semibold mb-4 flex lg:gap-8'>
            <span className={matches ? 'setting-label' : ''}>{scripts[languageMode][7]}</span>
            <div className={matches ? 'setting-input' : ''}></div>
          </div>
          <form>
            <label className='flex flex-col gap-1 my-2 lg:gap-8 lg:my-4 lg:flex-row lg:items-center'>
              <span className={'text-sm ' + (matches ? 'setting-label' : '')}>{scripts[languageMode][8]}</span>
              <input
                type='password'
                name='current-password'
                className={
                  'rounded border-solid border px-3 py-1 placeholder:text-zinc-300 ' +
                  (matches ? 'setting-input' : '') +
                  (Object.keys(errors).includes('oldPassword') ? 'border-error' : 'border-zinc-300')
                }
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </label>
            <label className='flex flex-col gap-1 my-2 lg:gap-8 lg:my-4 lg:flex-row lg:items-center'>
              <span className={'text-sm ' + (matches ? 'setting-label' : '')}>{scripts[languageMode][10]}</span>
              <input
                type='password'
                name='new-password'
                className={
                  'rounded border-solid border px-3 py-1 placeholder:text-zinc-300 ' +
                  (matches ? 'setting-input' : '') +
                  (Object.keys(errors).includes('newPassword') ? 'border-error' : 'border-zinc-300')
                }
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
            </label>
            <label className='flex flex-col gap-1 my-2 lg:gap-8 lg:my-4 lg:flex-row lg:items-center'>
              <span className={'text-sm ' + (matches ? 'setting-label' : '')}>{scripts[languageMode][18]}</span>

              <input
                type='password'
                name='new-password2'
                className={
                  'rounded border-solid border px-3 py-1 placeholder:text-zinc-300 ' +
                  (matches ? 'setting-input' : '') +
                  (Object.keys(errors).includes('newPassword') ? 'border-error' : 'border-zinc-300')
                }
                value={newPassword2}
                onChange={(e) => {
                  setNewPassword2(e.target.value);
                }}
              />
            </label>
            <button
              type='button'
              className={'rounded bg-pink-600 text-white text-sm py-1.5 px-4 mt-6 w-full lg:w-28 lg:mt-0'}
              onClick={async (e) => {
                if (token && newPassword === newPassword2 && newPassword !== '') {
                  try {
                    let result = await ChangePassword(token, password, newPassword);
                    e.preventDefault();
                    if (result) {
                      e.preventDefault();
                      openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][12] }]);
                      setToken(null);
                    } else {
                      e.preventDefault();
                      openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][13] }]);
                    }
                  } catch (error) {
                    openAlertModal([{ type: 'NORMAL', message: 'failed to change password' }]);
                  }
                } else {
                  e.preventDefault();
                  openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][14] }]);
                }
              }}>
              {scripts[languageMode][15]}
            </button>
          </form>
          <div className='mt-4 w-full lg:text-right'>
            <button className='text-xs text-zinc-500 mt-12 lg:m-4' onClick={() => setIsConfirmed(true)}>
              {scripts[languageMode][17]}
            </button>
          </div>
        </div>
      </div>
      {isConfirmed && (
        <div
          className='fixed inset-0 flex flex-col justify-center items-center'
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <div className='bg-zinc-700 text text-white text-center py-4 px-16'>
            <div className='mb-2 text-center'>{scripts[languageMode][19]}</div>
            <div className='flex text-sm gap-4 justify-center'>
              <button className='text-zinc-400 px-2 py-1' onClick={() => setIsConfirmed(false)}>
                {scripts[languageMode][20]}
              </button>
              <button
                className='text-pink-600 px-2 py-1'
                onClick={async () => {
                  if (token && user) {
                    let result = await deleteUser(token, user.username);
                    if (result) {
                      navigate('/login');
                    } else {
                      openAlertModal([{ type: 'NORMAL', message: '일시적인 문제가 발생했습니다. 다시 시도해주세요.' }]);
                      navigate('/');
                    }
                  }
                }}>
                {scripts[languageMode][21]}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
