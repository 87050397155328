import { Physics, RigidBody } from '@react-three/rapier';
import { useGLTF } from '@react-three/drei';
import { useCompressedGLTF } from 'hooks/hooks';

const url = new URL('../../../static/colliders/uzoo3.gltf', import.meta.url);

export default function SpaceThemaPrimitive({}: {}) {
  const { nodes } = useCompressedGLTF(url.href);

  return (
    <RigidBody position={[0, -0.9, 3]} scale={0.8} type='fixed' colliders='trimesh'>
      <mesh geometry={nodes.Bus001.geometry} scale={2}>
        <meshStandardMaterial visible={false} />
      </mesh>
    </RigidBody>
  );
}
