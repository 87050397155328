import { Clone } from '@react-three/drei';
import { useMemo } from 'react';
import * as THREE from 'three';

import { useCompressedGLTF } from 'hooks/hooks';

let url = new URL('static/models/grass_profile3.glb', import.meta.url);
let url2 = new URL('static/models/grass_profile_crystal.min.glb', import.meta.url);

export default function FlowerBox() {
  let { scene: scene1, nodes } = useCompressedGLTF(url.href);
  let nodeNames: any[] = [];
  let modelArr = [];

  scene1.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      let mesh = object as THREE.Mesh;

      mesh.renderOrder = 2;
      mesh.receiveShadow = true;
      mesh.material = mesh.material as THREE.Material;
      mesh.material.toneMapped = true;
      mesh.material.stencilWrite = true;
      mesh.material.stencilRef = 1;

      mesh.material.stencilFunc = THREE.EqualStencilFunc;
      mesh.material.stencilFail = THREE.KeepStencilOp;
      mesh.material.stencilZFail = THREE.KeepStencilOp;
      mesh.material.stencilZPass = THREE.ReplaceStencilOp;
    }
  });

  Object.values(nodes).map((value) => {
    nodeNames.push(value);
  });

  for (let i = 1; i < nodeNames.length - 8; i++) {
    modelArr.push(<Clone key={i} renderOrder={2} object={nodeNames[i]} />);
  }

  let { scene: scene2, nodes: nodes2 } = useCompressedGLTF(url2.href);
  let nodeNames2: any[] = [];
  let modelArr2 = [];

  scene2.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      let mesh = object as THREE.Mesh;

      let glow = new THREE.MeshPhysicalMaterial({
        thickness: 1,
        roughness: 0.1,
        clearcoat: 0.1,
        clearcoatRoughness: 0,
        transmission: 0.8,
        ior: 2,
        color: '#ffffff',
      });
      object.renderOrder = 2;
      mesh.material = (mesh.material as THREE.Material).clone();
      mesh.material = glow;
      mesh.material.toneMapped = false;
      mesh.material.stencilWrite = true;
      mesh.material.stencilRef = 1;
      mesh.material.stencilFunc = THREE.EqualStencilFunc;
      mesh.material.stencilFail = THREE.KeepStencilOp;
      mesh.material.stencilZFail = THREE.KeepStencilOp;
      mesh.material.stencilZPass = THREE.ReplaceStencilOp;
    }
  });
  Object.values(nodes2).map((value) => {
    nodeNames2.push(value);
  });

  for (let i = 1; i < nodeNames2.length; i++) {
    modelArr2.push(<Clone key={i} renderOrder={2} object={nodeNames2[i]} />);
  }

  return (
    <group>
      <group position={[0, 0, 0]} scale={1} rotation={[0, Math.PI / 2, 0]}>
        {modelArr}
        {modelArr2}
      </group>
    </group>
  );
}
