import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { FooterContainer, FooterText } from './styles';

function Footer(): JSX.Element {
  const navigate = useNavigate();

  const onFooterTextClick = useCallback((path?: string) => {
    if (!path) return;

    navigate(path);
  }, []);

  return (
    <FooterContainer>
      <FooterText onClick={() => onFooterTextClick('/introduction')}>소개</FooterText>
      <FooterText onClick={() => onFooterTextClick('/introduction/termsofservice')}>이용약관</FooterText>
      <FooterText onClick={() => onFooterTextClick('/introduction/privacy')}>개인정보처리방침</FooterText>
      <FooterText>ⓒ 2022 ADLER</FooterText>
    </FooterContainer>
  );
}

export default Footer;
