import { useEffect, useMemo } from 'react';
import { SkeletonUtils } from 'three-stdlib';
import { useAnimations } from '@react-three/drei';
import { useGraph } from '@react-three/fiber';

import { useCompressedGLTF } from 'hooks/hooks';

let url = new URL('static/models/ghosttest3nokor.min.glb', import.meta.url);

export default function Vrcharacter() {
  let { scene, animations } = useCompressedGLTF(url.href);
  let clonedScene = useMemo(() => SkeletonUtils.clone(scene), [scene]);
  let { nodes } = useGraph(clonedScene);
  let { ref, actions, names } = useAnimations(animations);

  useEffect(() => {}, [actions, names]);
  return (
    <group ref={ref} rotation={[0, Math.PI / -1, 0]} scale={1.3} position={[-0.1, 0, 0]}>
      <primitive object={nodes.Bone} />
      <primitive object={nodes.Mesh004} />
      <primitive object={nodes.Mesh004_1} />
    </group>
  );
}
