import React, { useCallback, useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { AlertModalContainer, CloseIcon, Message, TitleArea } from './styles';
import { AlertModalProps } from './types';
import useAlertModalResources from './resources';

import useModal from 'hooks/useModal';

import { languageModeAtom } from 'src/atoms';

function AlertModal({ messageInfos }: AlertModalProps): JSX.Element {
  const languageMode = useAtomValue(languageModeAtom);
  const { scripts } = useAlertModalResources();
  const { closeModal } = useModal();

  const alertMessage = useMemo(
    () =>
      messageInfos.map((messageInfo, index) => {
        if (messageInfo.type === 'NORMAL') return <React.Fragment key={index}>{messageInfo.message}</React.Fragment>;
        else if (messageInfo.type === 'IMPORTANT') return <span key={index}>{messageInfo.message}</span>;
        else return <br key={index} />;
      }),
    [messageInfos]
  );

  const onCloseIconClick = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <AlertModalContainer>
      <TitleArea>
        {scripts[languageMode][0]}
        <CloseIcon onClick={onCloseIconClick} />
      </TitleArea>
      <Message>{alertMessage}</Message>
    </AlertModalContainer>
  );
}

export default AlertModal;
