import { Environment } from '@react-three/drei';
import { useMemo, useEffect } from 'react';
import * as THREE from 'three';
import { SkeletonUtils } from 'three-stdlib';

import { useCompressedGLTF } from 'hooks/hooks';

const url = new URL('static/models/ParisCity10.min.glb', import.meta.url);

const url2 = new URL('static/imgs/bending_city_hdr.hdr', import.meta.url);

export default function CityTheme() {
  const { scene, materials } = useCompressedGLTF(url.href);
  const clonedScene = useMemo(() => SkeletonUtils.clone(scene), [scene]);
  const matNames: string[] = [];
  const disposeMesh: THREE.Mesh[] = [];
  const disposeTexture: THREE.Texture[] = [];
  clonedScene.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      const mesh = object as THREE.Mesh;

      const mat = mesh.material as THREE.Material;
      matNames.push(mat.name);

      disposeMesh.push(mesh);
      if (mat.map !== null) {
        disposeTexture.push(mat.map);
      }
    }
  });
  for (let i = 0; i < matNames.length; i++) {
    materials[matNames[i]].toneMapped = true;
  }
  useEffect(() => {
    return () => {
      for (let i = 0; i < disposeMesh.length; i++) {
        if (disposeMesh[i]) disposeMesh[i].geometry.dispose();
      }
      for (let i = 0; i < disposeTexture.length; i++) {
        if (disposeTexture[i]) disposeTexture[i].dispose();
      }
    };
  }, []);
  return (
    <group position={[21, -218.1, 12.3]} scale={0.5} rotation={[0, -Math.PI / 6, 0]}>
      <primitive object={clonedScene} />
      <Environment background={true} files={url2.href} />
    </group>
  );
}
