import { SetStateAction, useAtomValue } from 'jotai';
import { useState, useEffect, Dispatch, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import { SingleReply, ReplyInput } from 'pages/Home/replys';
import { HeartBlack, HeartFill, ThreeD } from 'components/Commons/icons';
import { API_BASE_URL, getReplys, getIsLike, likeSpace, Space, unlikeSpace } from 'apis/index';
import { ReplySet } from 'apis/types';

import { languageModeAtom, tokenAtom, userAtom } from 'src/atoms';

const scripts = {
  KR: ['댓글'],
  EN: ['comment'],
  JP: ['コメント'],
};

export default function ItemReplyPC({
  space,
  setIsReplyOpen,
}: {
  space: Space;
  setIsReplyOpen: Dispatch<SetStateAction<boolean>>;
}) {
  let languageMode = useAtomValue(languageModeAtom);
  let [replys, setReplys] = useState<ReplySet | null>(null);
  let [currentURL, setCurrentURL] = useState<string>(API_BASE_URL + '/spaces/' + space.id + '/replys/');
  let [isLike, setIsLike] = useState<boolean>(false);
  let token = useAtomValue(tokenAtom);
  let user = useAtomValue(userAtom);
  let navigate = useNavigate();
  let getMoreReplys = async () => {
    if (replys) {
      let moreReplys = await getReplys(replys.next);
      setCurrentURL(replys.next);
      setReplys({
        previous: moreReplys.previous,
        count: moreReplys.count,
        results: [...replys.results, ...moreReplys.results],
        next: moreReplys.next,
      });
    }
  };
  useEffect(() => {
    let isCancelled = false;
    (async () => {
      let replys = await getReplys(API_BASE_URL + '/spaces/' + space.id + '/replys/');
      if (isCancelled) {
        return;
      }
      setReplys(replys);
    })();
    if (token && user) {
      (async () => {
        let isLike = await getIsLike(token, space.id, user.username);
        setIsLike(isLike);
      })();
    }
    return () => {
      isCancelled = true;
    };
  }, [space.id, token, user, isLike]);

  let replysRef = useRef<HTMLDivElement>(null);

  let ScrollToBottom = () => {
    if (replysRef.current) {
      replysRef.current.scrollTop = replysRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    ScrollToBottom();
  }, [replys]);

  return (
    <>
      <div className='flex-1 grow overflow-y-auto overflow-x-hidden' id='reply-box' ref={replysRef}>
        <InfiniteScroll
          dataLength={replys ? replys.results.length : 15}
          next={getMoreReplys}
          className='flex flex-col'
          // style={{ display: 'flex', flexDirection: 'column' }}
          hasMore={replys?.next != ''}
          scrollableTarget='reply-box'>
          {replys?.results.map((result, index) => (
            <SingleReply key={index} reply={result} />
          ))}
        </InfiniteScroll>
      </div>
      <div className='flex-1 grow-0 basis-[25px]'>
        <div className='flex justify-between pb-1'>
          <button
            className='px-2'
            onClick={() => {
              setIsLike(!isLike);
              if (token && user && space) {
                if (isLike) {
                  unlikeSpace(token, space.id);
                } else {
                  likeSpace(token, space.id);
                }
              }
            }}>
            {isLike ? <HeartFill /> : <HeartBlack />}
          </button>
          <button onClick={() => navigate(`/s/${space.id}`)}>
            <ThreeD />
          </button>
        </div>
        <ReplyInput spaceId={space.id} replys={replys} setReplys={setReplys} currentURL={currentURL} />
      </div>
    </>
  );
}
