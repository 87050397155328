import React, { Dispatch, useEffect, useRef, useState, useMemo, useCallback } from 'react';
import Modal, { Styles as ModalStyles } from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { SetStateAction, useAtom, useAtomValue } from 'jotai';
import * as Colyseus from 'colyseus.js';
import styled from 'styled-components';

import Replys from './replys';

import Alarm from 'pages/Alarm/alarm';
import SpaceForm from 'pages/New/space-form';
import {
  Close,
  Delete,
  MoreVerticalBlack,
  MoreVerticalWhite,
  Post2D,
  Report,
  Share02White,
} from 'components/Commons/icons';
import { reportSpace, Token, Space, deleteSpace, reportReply, APIIssues, APIError } from 'apis/index';
import { Reply } from 'apis/types';

import { languageModeAtom, userAtom } from 'src/atoms';

import shareIcon from 'static/icons/share.svg';
import moreIcon from 'static/icons/more.svg';
import useModal from 'hooks/useModal';

const ShareIcon = styled.img.attrs({ src: shareIcon })`
  width: 1.5rem;
  height: 1.5rem;

  &:hover {
    cursor: pointer;
  }
`;

const MoreIcon = styled.img.attrs({ src: moreIcon })`
  width: 1.5rem;
  height: 1.5rem;

  &:hover {
    cursor: pointer;
  }
`;

const scripts = {
  KR: [
    '공유',
    '복사',
    '클립보드에 복사되었습니다.',
    '복사에 실패했습니다.',
    '피드가 삭제되었습니다.',
    '삭제',
    '수정',
    '2D Post',
    '신고하기',
    '신고',
    '신고사유',
    '저작권침해',
    '욕설/폭언',
    '음란물',
    '기타',
    '신고사유를 작성해주세요',
    '신고 보내기',
    '뷰어',
    '로그인이 필요합니다.',
    '현재 채널',
    '전체 채널 목록',
    '채널',
    '신고되었습니다.',
  ],
  EN: [
    'share',
    'copy',
    'copied to the clipboard',
    'copy failed',
    'Feed has been deleted',
    'Delete',
    'Fix',
    '2D Post',
    'Report',
    'Report',
    'Reason for Report',
    'Copyright Infringement',
    'Abusive language',
    'Pornography',
    'Other',
    'Please write the reason for the report',
    'Send report',
    'viewer',
    'Login required',
    'Current Channel',
    'Channel List',
    'Channel',
    'Reported',
  ],
  JP: [
    'シェア',
    'コピー',
    'クリップボードにコピーされました。',
    'コピーに失敗しました',
    'フィードが削除されました',
    '削除',
    '修正',
    '2D Post',
    '報告する',
    '報告',
    '申告理由',
    '著作権侵害',
    '悪口/暴言',
    'ポルノ',
    'その他',
    '申告理由を作成してください',
    '申告を送信する',
    'ビューア',
    'ログインが必要です',
    '現在のチャンネル',
    'チャンネル一覧',
    'チャネル',
    '報告されました。',
  ],
};

export function URLModal({ space }: { space: Space }) {
  let languageMode = useAtomValue(languageModeAtom);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const URLInputRef = useRef<HTMLInputElement>(null);
  const mediaMatch = window.matchMedia('(min-width: 1024px)');
  const [matches, setMatches] = useState<boolean>(mediaMatch.matches);
  const { openAlertModal } = useModal();

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });
  const customStyles: ModalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: matches ? '50%' : '85%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div id='URLModalPart' className='text-center'>
      <ShareIcon onClick={openModal} />
      <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
        <div className='mb-5 flex w-full'>
          <div className='grow basis-0 justify-end' />
          <div className='flex items-center text-base'>{scripts[languageMode][0]}</div>
          <div className='flex grow basis-0 justify-end'>
            <button onClick={closeModal}>
              <Close />
            </button>
          </div>
        </div>
        <div className='flex border border-zinc-300 bg-zinc-100 rounded h-8 gap-3 w-full'>
          <input
            className='text-sm pl-3 bg-zinc-100'
            style={{ flex: 1 }}
            type='text'
            defaultValue={window.location.origin + '/s/' + space.id}
            ref={URLInputRef}
            readOnly
          />
          <button
            className='text-sm text-info pr-2'
            onClick={async () => {
              if (URLInputRef.current) {
                try {
                  await navigator.clipboard.writeText(URLInputRef.current.value);
                  openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][2] }]);
                } catch (error) {
                  openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][3] }]);
                }
              }
            }}>
            {scripts[languageMode][1]}
          </button>
        </div>
      </Modal>
    </div>
  );
}

export function SpaceReportModal({
  space,
  token,
  isOpen,
  setIsOpen,
}: {
  space: Space;
  token: Token | null;
  isOpen: boolean;
  setIsOpen: Dispatch<React.SetStateAction<boolean>>;
}) {
  let languageMode = useAtomValue(languageModeAtom);
  let windowWidth = window.matchMedia('screen and (min-width: 1024px)');
  const [selectedOption, setSelectedOption] = useState<number>(1);
  const [desc, setDesc] = useState<string>('');
  const [error, setError] = useState<APIIssues | null>(null);
  const { openAlertModal } = useModal();
  let customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: windowWidth.matches ? '40%' : '80%',
    },
  };
  return (
    <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} style={customStyles}>
      <div className='flex flex-col items-center'>
        <div className='w-full flex items-center pb-4'>
          <div className='grow basis-0 justify-end' />
          <div className='flex items-center'>{scripts[languageMode][9]}</div>
          <div className='flex grow basis-0 justify-end'>
            <button className='flex justify-end items-center' onClick={() => setIsOpen(false)}>
              <Close />
            </button>
          </div>
        </div>
        <div className='w-full'>
          <label className='flex items-center gap-1.5 rounded border-solid border border-zinc-300 px-4 py-2 h-8 mb-2'>
            <div className='text-sm text-zinc-600 w-fit shrink-0'>{scripts[languageMode][10]}</div>
            <div className='text-sm grow flex justify-center items-center'>
              <select
                className='w-full'
                value={selectedOption}
                onChange={(e) => setSelectedOption(Number(e.target.value))}>
                <option value={1}>{scripts[languageMode][11]}</option>
                <option value={2}>{scripts[languageMode][12]}</option>
                <option value={3}>{scripts[languageMode][13]}</option>
                <option value={4}>{scripts[languageMode][14]}</option>
              </select>
            </div>
          </label>
          <textarea
            className='h-28 w-full rounded border-solid border border-zinc-300 placeholder:text-zinc-400 px-3 py-2 text-sm mb-3'
            name='description'
            placeholder={scripts[languageMode][15]}
            value={desc}
            onChange={(e) => setDesc(e.currentTarget.value)}></textarea>
        </div>
        <button
          className='bg-pink-600 w-full rounded py-2 text-white'
          onClick={() => {
            try {
              if (token) reportSpace(space.id, token, selectedOption, desc);
              setIsOpen(false);
              openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][22] }]);
            } catch (error) {
              openAlertModal([{ type: 'NORMAL', message: 'report failed' }]);
            }
          }}>
          {scripts[languageMode][16]}
        </button>
      </div>

      <div></div>
    </Modal>
  );
}

export function InfoModalFrame({ space, token, isWhite }: { space: Space; token: Token | null; isWhite: boolean }) {
  let languageMode = useAtomValue(languageModeAtom);
  const user = useAtomValue(userAtom);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOptionOpen, setIsOptionOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<number>(1);
  const [desc, setDesc] = useState<string>('');
  const [error, setError] = useState<APIIssues | null>(null);
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '40%',
    },
  };
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  let navigate = useNavigate();
  const mediaMatch = window.matchMedia('(min-width: 1024px)');
  const [matches, setMatches] = useState<boolean>(mediaMatch.matches);

  return (
    <>
      <MoreIcon onClick={openModal} />
      {!matches ? (
        isOpen && (
          <MobileInfoModal
            space={space}
            closeModal={closeModal}
            setIsOpen={setIsOpen}
            setIsOptionOpen={setIsOptionOpen}
            token={token}
          />
        )
      ) : (
        <PCInfoModal
          space={space}
          closeModal={closeModal}
          isOpen={isOpen}
          token={token}
          setIsOpen={setIsOpen}
          setIsOptionOpen={setIsOptionOpen}
        />
      )}
      <SpaceReportModal space={space} token={token} isOpen={isOptionOpen} setIsOpen={setIsOptionOpen} />
    </>
  );
}

function MobileInfoModal({
  closeModal,
  space,
  setIsOpen,
  setIsOptionOpen,
  token,
}: {
  closeModal: () => void;
  space: Space;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsOptionOpen: Dispatch<SetStateAction<boolean>>;
  token: Token | null;
}) {
  let languageMode = useAtomValue(languageModeAtom);
  let navigate = useNavigate();
  const user = useAtomValue(userAtom);
  const isMyPost = useMemo(() => user?.username === space.username, [user, space]);
  const { openAlertModal } = useModal();

  return (
    <>
      <div
        className='fixed inset-0 bg-transparent z-10'
        onClick={() => {
          closeModal();
        }}
      />
      <div className='flex flex-col items-center fixed bottom-0 left-0 z-20 bg-white w-screen text-zinc-600 rounded-t-lg font-light lg:hidden'>
        <button
          className='pt-4'
          onClick={() => {
            closeModal();
          }}>
          <svg width='41' height='4' viewBox='0 0 41 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect x='0.356201' width='40' height='4' rx='2' fill='#808080' />
          </svg>
        </button>
        {isMyPost && (
          <button
            className='flex gap-1.5 px-3 py-3 border-b border-zinc-100 w-full'
            onClick={async () => {
              try {
                let result = await deleteSpace(space.id, token);
                if (result) {
                  openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][4] }]);
                  navigate('/');
                  closeModal();
                }
              } catch (error) {
                if (error instanceof APIError) {
                }
                openAlertModal([{ type: 'NORMAL', message: 'failed to delete space' }]);
              }
            }}>
            <Delete />
            <div className='jusitfy-self-start'>{scripts[languageMode][5]}</div>
          </button>
        )}
        {/* <button className='flex gap-1.5 px-3 py-3 border-b border-zinc-100 w-full'>
            <EditPost />
            <div className='jusitfy-self-start'>{scripts[languageMode][6]}</div>
          </button> */}
        <button
          className='flex gap-1.5 px-3 py-3 border-b border-zinc-100 w-full'
          onClick={() => (window.location.href = '/i/' + space.id)}>
          <Post2D />
          <div>{scripts[languageMode][7]}</div>
        </button>
        {!isMyPost && (
          <button
            className='flex gap-1.5 px-3 py-3 border-b border-zinc-100 w-full'
            onClick={() => {
              if (token) {
                setIsOpen(false);
                setIsOptionOpen(true);
              } else {
                openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][18] }]);
                navigate('/login');
              }
            }}>
            <Report />
            <div className='text-error'>{scripts[languageMode][8]}</div>
          </button>
        )}
      </div>
    </>
  );
}

function PCInfoModal({
  isOpen,
  closeModal,
  space,
  token,
  setIsOpen,
  setIsOptionOpen,
}: {
  isOpen: boolean;
  closeModal: () => void;
  space: Space;
  token: Token | null;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsOptionOpen: Dispatch<SetStateAction<boolean>>;
}) {
  let languageMode = useAtomValue(languageModeAtom);
  let navigate = useNavigate();
  const user = useAtomValue(userAtom);
  const { openConfirmModal, openAlertModal, closeModal: closeUseModal, closeAllModals } = useModal();
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '40%',
    },
  };
  const isMyPost = useMemo(() => user?.username === space.username, [user, space]);

  const onDeleteButtonClick = useCallback(() => {
    openConfirmModal({
      title: '게시물을 삭제하시겠어요?',
      contents: [{ type: 'NORMAL', message: '지금 나가면 수정 내용이 저장되지 않습니다.' }],
      firstButton: {
        text: '삭제',
        onClick: async () => {
          let result = await deleteSpace(space.id, token);
          if (result) {
            openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][4] }]);
            navigate(-1);
            closeAllModals();
          }
        },
      },
      secondButton: { text: '취소', onClick: closeUseModal },
    });
  }, [openConfirmModal, openAlertModal, closeAllModals, closeUseModal]);

  return (
    <div className='hidden lg:flex'>
      <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
        <div className='flex flex-col items-center'>
          {user?.username === space.username && (
            <button className='flex gap-1.5 px-3 py-3 border-b border-zinc-100 w-full' onClick={onDeleteButtonClick}>
              <Delete />
              <div className='jusitfy-self-start'>{scripts[languageMode][5]}</div>
            </button>
          )}
          {/* <button className='flex gap-1.5 px-3 py-3 border-b border-zinc-100 w-full'>
              <EditPost />
              <div className='jusitfy-self-start'>{scripts[languageMode][6]}</div>
            </button> */}
          <button
            className='flex gap-1.5 px-3 py-3 border-b border-zinc-100 w-full'
            onClick={() => (window.location.href = '/i/' + space.id)}>
            <Post2D />
            <div>{scripts[languageMode][7]}</div>
          </button>
          {!isMyPost && (
            <button
              className='flex gap-1.5 px-3 py-3 border-b border-zinc-100 w-full'
              onClick={() => {
                if (token) {
                  setIsOpen(false);
                  setIsOptionOpen(true);
                } else {
                  openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][18] }]);
                  navigate('/login');
                }
              }}>
              <Report />
              <div className='text-error'>{scripts[languageMode][8]}</div>
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
}

export function ViewerProfile({ username, nickname }: { username: string; nickname: string }) {
  let object = new URL('../../../static/imgs/default-profile.png', import.meta.url);
  let navigate = useNavigate();

  return (
    <div id='viewprofile' className='flex gap-4 px-4 py-1 items-center'>
      {/* <div className=''>
        <img src={object.href} className='block h-8 rounded-full object-cover aspect-square' />
      </div> */}

      <div className='flex flex-col pb-1'>
        <div className='text-xs' onClick={() => navigate(`/u/${username}`)}>
          {nickname}
        </div>
        <div className='font-light' style={{ fontSize: '0.65rem' }} onClick={() => navigate(`/u/${username}`)}>
          @{username}
        </div>
      </div>
    </div>
  );
}

export function ViewerList({
  mode,
  clientsList,
}: {
  mode: string;
  clientsList: { u: string; n: string; t: number }[];
}) {
  return (
    <>
      <div className='w-full border-b border-zinc-200'>
        <div className='flex py-1 px-4'>
          <div className='text-xs text-zinc-400'>{mode === 'scroll' ? mode : mode}</div>
          <div className='grow basis-0 justify-end' />
          <div className='flex text-xs text-pink-800'>{clientsList.length}</div>
        </div>
      </div>
      {clientsList.map((client, index) => {
        return <ViewerProfile key={index} username={client.u} nickname={client.n} />;
      })}
    </>
  );
}

export function ViewerModal({
  setIsViewerModalOpen,
  currentChannel,
  spaceChannels,
}: {
  setIsViewerModalOpen: Dispatch<SetStateAction<boolean>>;
  currentChannel: Colyseus.Room | null;
  spaceChannels: any[] | null;
}) {
  let languageMode = useAtomValue(languageModeAtom);
  let [mode, setMode] = useState<string>('current');
  let [scrollModeUsers, setScrollModeUsers] = useState<{ u: string; n: string; t: number }[]>([]);
  let [multiplayModeUsers, setMultiplayModeUsers] = useState<{ u: string; n: string; t: number }[]>([]);
  useEffect(() => {
    let s_temp: { u: string; n: string; t: number }[] = [];
    let m_temp: { u: string; n: string; t: number }[] = [];
    if (currentChannel && currentChannel.metadata) {
      Object.entries(currentChannel.metadata).map(([_, value]) => {
        if (value.t === 0) {
          s_temp.push(value);
        } else {
          m_temp.push(value);
        }
      });
      setScrollModeUsers(s_temp);
      setMultiplayModeUsers(m_temp);
    }
  }, [spaceChannels]);
  return (
    <div
      className='absolute w-5/6 h-5/6 top-20 right-4 bg-white z-10 rounded-lg drop-shadow-lg'
      style={{ height: '30rem', width: '20rem' }}
      onClick={(e) => {
        e.stopPropagation();
      }}>
      <div className='w-full flex items-center p-4 border-zinc-400'>
        <div className='grow basis-0 justify-end' />
        <div className='flex items-center'>{scripts[languageMode][17]}</div>
        <div className='flex grow basis-0 justify-end'>
          <button
            className='flex justify-end items-center'
            onClick={() => {
              setIsViewerModalOpen(false);
            }}>
            <Close />
          </button>
        </div>
      </div>
      <div className='flex text-xs w-full items-center'>
        <div
          className={
            'flex-1 flex justify-center pb-2 ' +
            (mode === 'current' ? 'text-pink-500 border-b border-pink-500' : 'text-zinc-400')
          }
          onClick={() => {
            setMode('current');
          }}>
          {scripts[languageMode][19]}
        </div>
        <div
          className={
            'flex-1 flex justify-center pb-2 ' +
            (mode === 'total' ? 'text-pink-500 border-b border-pink-500' : 'text-zinc-400')
          }
          onClick={() => {
            setMode('total');
          }}>
          {scripts[languageMode][20]}
        </div>
      </div>
      <div className='w-full border-t border-zinc-200'>
        {mode === 'current' && (
          <>
            <ViewerList mode='scroll' clientsList={scrollModeUsers} />
            <ViewerList mode='multiplay' clientsList={multiplayModeUsers} />
          </>
        )}
        {mode === 'total' &&
          spaceChannels?.map((channel, index) => (
            <div key={index} className='text-xs text-zinc-400 px-4 py-2 border-b border-zinc-200'>
              {scripts[languageMode][21] + ' ' + channel.roomId.split('_')[1] + ' ' + '(' + channel.clients + '/10)'}
            </div>
          ))}
      </div>
    </div>
  );
}

export function ReplyReportModal({
  reply,
  token,
  isModalOpen,
  setIsModalOpen,
}: {
  reply: Reply;
  token: Token;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<React.SetStateAction<boolean>>;
}) {
  const user = useAtomValue(userAtom);
  let languageMode = useAtomValue(languageModeAtom);
  const [selectedOption, setSelectedOption] = useState<number>(1);
  const [desc, setDesc] = useState<string>('');
  const { openAlertModal } = useModal();
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
    },
  };
  return (
    <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} style={customStyles}>
      <div className='flex flex-col items-center'>
        <div className='w-full flex items-center pb-4'>
          <div className='grow basis-0 justify-end' />
          <div className='flex items-center'>{scripts[languageMode][9]}</div>
          <div className='flex grow basis-0 justify-end'>
            <button className='flex justify-end items-center' onClick={() => setIsModalOpen(false)}>
              <Close />
            </button>
          </div>
        </div>
        <div className='w-full'>
          <label className='flex items-center gap-1.5 rounded border-solid border border-zinc-300 px-4 py-2 h-8 mb-2'>
            <div className='text-sm text-zinc-600 w-fit shrink-0'>{scripts[languageMode][10]}</div>
            <div className='text-sm grow flex justify-center items-center'>
              <select
                className='w-full'
                value={selectedOption}
                onChange={(e) => setSelectedOption(Number(e.target.value))}>
                <option value={1}>{scripts[languageMode][11]}</option>
                <option value={2}>{scripts[languageMode][12]}</option>
                <option value={3}>{scripts[languageMode][13]}</option>
                <option value={4}>{scripts[languageMode][14]}</option>
              </select>
            </div>
          </label>
          <textarea
            className='h-28 w-full rounded border-solid border border-zinc-300 placeholder:text-zinc-400 px-3 py-2 text-sm mb-3'
            name='description'
            placeholder={scripts[languageMode][15]}
            value={desc}
            onChange={(e) => setDesc(e.currentTarget.value)}></textarea>
        </div>
        <button
          className='bg-pink-600 w-full rounded py-2 text-white'
          onClick={() => {
            try {
              if (token) reportReply(reply.id, token, selectedOption, desc);
              setIsModalOpen(false);
              setSelectedOption(1);
              setDesc('');
              openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][22] }]);
            } catch (error) {
              if (error instanceof APIError) {
              }
              openAlertModal([{ type: 'NORMAL', message: 'failed to report reply' }]);
            }
          }}>
          {scripts[languageMode][16]}
        </button>
      </div>

      <div></div>
    </Modal>
  );
}

export function ReplyModal({
  space,
  isOpen,
  setIsOpen,
  setBlockingAlert,
  onCloseHandler,
}: {
  space: Space;
  isOpen: boolean;
  setIsOpen: Dispatch<React.SetStateAction<boolean>>;
  setBlockingAlert: Dispatch<React.SetStateAction<boolean>>;
  onCloseHandler: () => void;
}) {
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      height: '70%',
    },
  };
  return (
    <div className='hidden lg:flex'>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          onCloseHandler();
          setIsOpen(false);
        }}
        style={customStyles}>
        <Replys space={space} isReplyOpen={isOpen} setIsReplyOpen={setIsOpen} setBlockingAlert={setBlockingAlert} />
      </Modal>
    </div>
  );
}

export function SpaceCreateModal({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<React.SetStateAction<boolean>>;
}) {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      height: '70%',
      padding: '0',
    },
  };
  return (
    <div className='hidden lg:flex'>
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} style={customStyles}>
        <SpaceForm />
      </Modal>
    </div>
  );
}

export function AlarmModal({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<React.SetStateAction<boolean>>;
}) {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      height: '70%',
      padding: '0',
    },
  };
  return isOpen ? (
    <div className='alarm-modal flex flex-col bg-white w-screen text-zinc-600 rounded-lg font-light'>
      <Alarm />
    </div>
  ) : (
    <></>
  );
}
