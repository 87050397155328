import { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import Follow from 'pages/Follow';
import { TabType } from 'pages/Follow/types';
import useModal from 'hooks/useModal';
import { APIError, getFollowers, getFollowings, getUser, getUserSpaces, isFollow, User } from 'apis/index';

import { languageModeAtom, tokenAtom } from 'src/atoms';

const scripts = {
  KR: ['계정이 존재하지 않습니다.', '포스트', '팔로워', '팔로잉'],
  EN: ['Account does not exist.', 'Post', 'Followers', 'Following'],
  JP: ['アカウントが存在しません。', 'ポスト', 'フォロワー', 'フォローイング'],
};

export default function ProfileSpace2D({ username }: { username: string }) {
  let { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    ['user', username, 'spaces'],
    ({ pageParam }) => getUserSpaces(username, pageParam),
    {
      getNextPageParam: (lastPage) => lastPage.cursor,
    }
  );
  let [user, setUser] = useState<User | null>(null);
  let navigate = useNavigate();
  let token = useAtomValue(tokenAtom);
  let languageMode = useAtomValue(languageModeAtom);
  let [is_Follow, setIs_Follow] = useState<boolean>(false);
  let [follower_num, setFollower_num] = useState<number>(0);
  let [following_num, setFollowing_num] = useState<number>(0);
  const mediaMatch = useMediaQuery({ maxWidth: 1224 });
  const { openModal } = useModal();
  const [followerCount, setFollowerCount] = useState<number>();
  const [followingCount, setFollowingCount] = useState<number>();
  const { openAlertModal } = useModal();

  useEffect(() => {
    (async () => {
      try {
        let user = await getUser(username);
        setUser(user);
        if (token) {
          let init_follow = await isFollow(token, username, user.username);
          setIs_Follow(init_follow);
        }
      } catch {
        navigate('/');
      }
    })();
  }, [token, username, navigate]);

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      if (token) {
        try {
          let user = await getUser(username);
          if (isCancelled) {
            return;
          }
          setUser(user);
        } catch {
          openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][0] }]);
          navigate('/');
          return;
        }
        let follower_result = await getFollowers(`/users/${username}/followers/`, token);
        setFollower_num(follower_result.total_length);
        let following_result = await getFollowings(`/users/${username}/followings/`);
        setFollowing_num(following_result.total_length);
      }
    })();
    return () => {
      isCancelled = true;
    };
  }, [username, is_Follow, navigate]);

  if (!data) return null; // data is always defined because we use suspense

  let spaces = data.pages.flatMap((page) => page.spaces);

  const refreshFollowCounts = useCallback(async () => {
    if (token) {
      try {
        const [followerDatas, followingDatas] = await Promise.all([
          getFollowers(`/users/${username}/followers/`, token),
          getFollowings(`/users/${username}/followings/`),
        ]);

        setFollowerCount(followerDatas.total_length);
        setFollowingCount(followingDatas.total_length);
      } catch (error) {
        let errorMessage;

        if (error instanceof APIError) {
          errorMessage = error.issues.detail;
        } else {
          errorMessage = error as string;
        }

        openAlertModal([{ type: 'NORMAL', message: errorMessage }]);
      }
    }
  }, [username, token, setFollowerCount, setFollowingCount]);

  const onFollowClick = useCallback(
    (userName?: string, tabType?: TabType) => {
      if (userName) {
        /*
          INFO

          Mobile
        */
        if (mediaMatch) {
          return navigate(`/u/${userName}/follow/${tabType}`);
        }

        /*
          INFO

          PC
        */
        openModal({
          component: <Follow userName={userName} initialTabType={tabType} />,
          options: { isCloseOnESCKeyDown: true, isOverlayed: true, isCloseOnOverlayClick: true },
          onClosed: () => {
            refreshFollowCounts();
          },
        });
      }
    },
    [mediaMatch]
  );

  return (
    <div className='lg:w-[940px] m-auto border lg:p-5 bg-white border border-gray-300'>
      <div className='m-4'>
        <div className='flex mb-2 items-start'>
          <div className='grow-0'>
            <div
              className='h-[50px] w-[50px] rounded-full border bg-center bg-no-repeat'
              style={{ backgroundImage: `url(${user?.image})`, backgroundSize: 'cover' }}></div>
          </div>
          <div className='ml-2 flex flex-col ml-3 items-start grow'>
            <h4 className='text-base'>{user?.nickname}</h4>
            <div className='text-sm text-gray-500'>@{user?.username}</div>
            <div className='text-sm'>{user?.bio}</div>
          </div>
        </div>
      </div>
      <div className='py-4 border-t '>
        <ul className='flex justify-evenly text-center'>
          <li>
            <h3 className='text-base'>{spaces.length}</h3>
            <span className='text-gray-500 text-sm'>{scripts[languageMode][1]}</span>
          </li>
          <li className='text-base' onClick={() => onFollowClick(username, 'follower')}>
            <h3>{followerCount ?? follower_num}</h3>
            <span className='text-gray-500 text-sm'>{scripts[languageMode][2]}</span>
          </li>
          <li>
            <h3 className='text-base' onClick={() => onFollowClick(username, 'follower')}>
              {followingCount ?? following_num}
            </h3>
            <span className='text-gray-500 text-sm'>{scripts[languageMode][3]}</span>
          </li>
        </ul>
      </div>

      <div className='grid grid-cols-3 gap-3 auto-cols-auto w-full m-auto lg:grid-cols-3'>
        {spaces.map((space, index) => (
          <div
            key={index}
            className='w-full h-[132px] lg:h-[290px]'
            // style={{ width: '134px', height: '134px' }}
            onClick={() => (window.location.href = '/i/' + space.id)}>
            <div
              className='w-full h-full bg-cover bg-center'
              style={{
                backgroundImage: `url(${space.items[0].type == 1 ? space.items[0].file : space.thumbImage})`,
              }}></div>
          </div>
        ))}
      </div>
      {hasNextPage && (
        <button className='px-2 text-xs text-zinc-400' onClick={() => fetchNextPage()} disabled={isFetchingNextPage}>
          {isFetchingNextPage ? 'Loading...' : 'Load more'}
        </button>
      )}
    </div>
  );
}
