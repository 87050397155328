import * as THREE from 'three';
import { useEffect } from 'react';
import { useAnimations } from '@react-three/drei';

import { Item } from 'apis/index';

import { useCompressedGLTF } from 'hooks/hooks';

export default function ModelItem({ item }: { item: Item }) {
  const { scene: scene1, animations } = useCompressedGLTF(
    item.file.includes('blob') ? item.file : item.file + '?'
  );
  // const clonedScene1 = useMemo(() => SkeletonUtils.clone(scene1), [scene1]);
  const { ref, actions, names } = useAnimations(animations);
  const disposeMesh: THREE.Mesh[] = [];
  const disposeTexture: THREE.Texture[] = [];

  scene1.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      const mesh = object as THREE.Mesh;
      const mat = mesh.material as THREE.Material;
      mat.toneMapped = false;
      mat.stencilWrite = false;
      mesh.castShadow = true;

      disposeMesh.push(mesh);

      if (mat.map !== null) {
        disposeTexture.push(mat.map);
      }
    }

    if ((object as THREE.Light).isLight) {
      const light = object as THREE.Light;
      light.intensity = 0;
    }
  });

  const sceneExtent = new THREE.BoxGeometry(item.scale / 20, item.scale / 20, item.scale / 20);
  const cube = new THREE.Mesh(sceneExtent);
  const sceneBounds = new THREE.Box3().setFromObject(cube);
  const meshBounds = new THREE.Box3().setFromObject(scene1);

  // Calculate side lengths of scene (cube) bounding box
  const lengthSceneBounds = {
    x: Math.abs(sceneBounds.max.x - sceneBounds.min.x),
    y: Math.abs(sceneBounds.max.y - sceneBounds.min.y),
    z: Math.abs(sceneBounds.max.z - sceneBounds.min.z),
  };

  // Calculate side lengths of glb-model bounding box
  const lengthMeshBounds = {
    x: Math.abs(meshBounds.max.x - meshBounds.min.x),
    y: Math.abs(meshBounds.max.y - meshBounds.min.y),
    z: Math.abs(meshBounds.max.z - meshBounds.min.z),
  };

  // Calculate length ratios

  const lengthRatios = [
    lengthSceneBounds.x / lengthMeshBounds.x,
    lengthSceneBounds.y / lengthMeshBounds.y,
    lengthSceneBounds.z / lengthMeshBounds.z,
  ];

  // Select smallest ratio in order to contain the model within the scene
  let minRatio = Math.min(...lengthRatios);

  // If you need some padding on the sides
  const padding = 0;
  minRatio -= padding;

  // Use smallest ratio to scale the model
  scene1.scale.multiplyScalar(minRatio);

  useEffect(() => {
    // Reset and fade in animation after an index has been changed
    actions[names[0]]?.reset().fadeIn(0.5).play();
    // In the clean-up phase, fade it out
    return () => {
      actions[names[0]]?.fadeOut(0.5);
    };
  }, [actions, names]);
  useEffect(() => {
    return () => {
      for (let i = 0; i < disposeMesh.length; i++) {
        if (disposeMesh[i]) disposeMesh[i].geometry.dispose();
      }
      for (let i = 0; i < disposeTexture.length; i++) {
        if (disposeTexture[i]) disposeTexture[i].dispose();
      }
    };
  }, []);
  return (
    <group
      ref={ref}
      position={[
        item.positionX / 50 - 1 + (item.positionX == 1800 ? -35 : 0),
        item.positionY / 50 - 1.5 + (item.positionY == 1800 ? -35.8 : 0),
        item.positionZ / 50 - 1 + (item.positionZ == -3600 ? 74 : 0),
      ]}
      rotation={[0, 0, 0]}>
      <primitive object={scene1} />
    </group>
  );
}
