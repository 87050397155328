import { Suspense, useEffect, useRef, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import { useAtomValue, useSetAtom } from 'jotai';
import { useInfiniteQuery } from '@tanstack/react-query';

import {
  SearchResultUsername,
  SearchResultNickname,
  SearchResultKeyword,
  SearchResultTag,
} from './search-result';

import { SearchIcon } from 'components/Commons/icons';
import { getRecommendedSpaces } from 'apis/index';

import { languageModeAtom, navbarTypeAtom, tokenAtom } from 'src/atoms';

import loadingIcon from 'static/icons/loading.svg';

const scripts = {
  KR: ['검색', '아이디', '닉네임', '해시태그', '포스트 글'],
  EN: ['Search', 'ID', 'Nickname', 'Hashtag', 'Posting caption'],
  JP: ['検索', 'ID', 'ニックネーム', 'ハッシュタグ', 'ポストカード'],
};

function SearchResult({ openTab, keywords }: { openTab: number; keywords: string }) {
  switch (openTab) {
    case 1:
      return <SearchResultUsername keywords={keywords}></SearchResultUsername>;
    case 2:
      return <SearchResultNickname keywords={keywords}></SearchResultNickname>;
    case 3:
      return <SearchResultTag keywords={keywords}></SearchResultTag>;
    case 4:
      return <SearchResultKeyword keywords={keywords}></SearchResultKeyword>;
    default:
      return (
        <div className='h-full w-full flex justify-center items-center text-zinc-300 text-sm'>
          <div>검색 결과가 없습니다</div>
          <div className='h-11' />
        </div>
      );
  }
}

function RecommendedSpace() {
  let { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    ['feeds'],
    async ({ pageParam }) => {
      return getRecommendedSpaces(pageParam);
    },
    {
      getNextPageParam: (lastPage) => (lastPage ? lastPage.cursor : undefined),
    }
  );
  let spaces = data!.pages.flatMap((page) => (page ? page.spaces : []));
  function handleScroll(e: React.UIEvent) {
    if (hasNextPage && !isFetchingNextPage) {
      if (
        e.currentTarget.scrollTop + e.currentTarget.clientHeight >=
        e.currentTarget.scrollHeight - 100
      )
        //100px은 임의의 값
        fetchNextPage();
    }
  }

  let recommendSpacesDivRef = useRef<HTMLDivElement>(null);
  //디바이스가 길 경우
  useEffect(() => {
    if (
      recommendSpacesDivRef.current?.clientHeight === recommendSpacesDivRef.current?.scrollHeight
    ) {
      fetchNextPage();
    }
  }, []);

  return (
    <div
      className='search-result lg:w-[940] lg:m-auto lg:mt-8'
      onScroll={handleScroll}
      ref={recommendSpacesDivRef}>
      <ul className='grid gap-1 grid-cols-3 md:grid-cols-6'>
        {spaces.map(
          (space, index) =>
            !(space.items[0].type === 2 && !space.thumbImage) && (
              <Link to={'/s/' + space.id} key={index}>
                <li className='border bg-gray-300 w-full h-32 md:w-48 md:h-48'>
                  <img
                    src={space.items[0].type === 1 ? space.items[0].file : space.thumbImage}
                    alt=''
                    className='object-cover w-full h-full'
                  />
                </li>
              </Link>
            )
        )}
      </ul>
    </div>
  );
}
export default function Search() {
  let { tag } = useParams() as { tag: string };
  let { keyword } = useParams() as { keyword: string };
  let token = useAtomValue(tokenAtom);
  let setNavbarType = useSetAtom(navbarTypeAtom);
  let languageMode = useAtomValue(languageModeAtom);
  let [keywords, setKeywords] = useState<string>('');
  let [openTab, setOpenTab] = useState<number>(1);
  let [isReadyPageOpen, setIsReadyPageOpen] = useState<boolean>(false);

  useEffect(() => {
    if (tag !== undefined) {
      setOpenTab(3);
      setKeywords(tag);
    }
  }, [tag]);

  useEffect(() => {
    if (keyword !== undefined) {
      setKeywords(keyword);
    }
  }, [keyword]);

  useEffect(() => {
    setNavbarType('search');
  }, [setNavbarType]);

  return (
    <div className='h-full overflow-y-hidden'>
      {!token && <Navigate to='/login' />}
      <form action='' className='flex lg:hidden' onSubmit={(e) => e.preventDefault()}>
        <label className='border bg-gray-200 py-1 px-3 my-2 mx-3 w-full rounded-full flex justify-between'>
          <input
            type='text'
            className='w-full bg-transparent'
            placeholder={scripts[languageMode][0]}
            value={keywords}
            onChange={(e) => {
              setKeywords(e.target.value);
            }}
            onFocus={() => {
              setIsReadyPageOpen(true);
            }}
            onBlur={() => {
              setIsReadyPageOpen(false);
            }}
          />
          <button style={{ width: '24px', height: '24px' }}>
            <SearchIcon />
          </button>
        </label>
      </form>

      {/* 하단 부분 기본 검색 화면 */}
      {/* <section className="flex flex-col h-5/6 justify-center items-center " >
        <div className="">
          <h3 className='text-base text-center'>키워드를 검색해 보세요.</h3>
          <p className='text-xs mt-1 text-center text-gray-500'>아이디, 닉네임, 해시태그, <br />
            포스트 글 키워드로 검색이 가능합니다.</p>
        </div>
        </section> */}
      {keywords === '' ? (
        <Suspense
          fallback={
            <div
              className='grow'
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <img src={loadingIcon} style={{ height: '30px' }} />
              <div style={{ height: '30px' }}>loading...</div>
            </div>
          }>
          {isReadyPageOpen ? (
            <div className='h-full w-full flex flex-col justify-center items-center text-center'>
              <div className='text-zinc-500 text-sm mb-2'>키워드를 검색해 보세요.</div>
              <div className='text-zinc-300 text-xs'>
                아이디, 닉네임, 해시태그,
                <br /> 포스트 글 키워드로 검색이 가능합니다
              </div>
              <div className='h-11' />
            </div>
          ) : (
            <RecommendedSpace />
          )}
          {/* <SearchResult openTab={openTab} keywords={keywords} /> */}
        </Suspense>
      ) : (
        <section className='section lg:w-[940] lg:m-auto lg:bg-white'>
          <ul className='grid gap-0 grid-cols-4 text-center mt-2 mb-6 text-base'>
            <li>
              <a
                className={
                  'cursor-pointer py-2 inline-block w-full border-b border-gray-300' +
                  (openTab === 1
                    ? 'border-b-3 border-red-600 text-red-600'
                    : 'border-b border-gray-300 text-gray-500')
                }
                onClick={() => setOpenTab(1)}>
                {scripts[languageMode][1]}
              </a>
            </li>
            <li>
              <a
                className={
                  'cursor-pointer py-2 inline-block w-full border-b border-gray-300' +
                  (openTab === 2
                    ? 'border-b-3 border-red-600 text-red-600'
                    : 'border-b border-gray-300 text-gray-500')
                }
                onClick={() => setOpenTab(2)}>
                {scripts[languageMode][2]}
              </a>
            </li>
            <li>
              <a
                className={
                  'cursor-pointer py-2 inline-block w-full border-b border-gray-300' +
                  (openTab === 3
                    ? 'border-b-3 border-red-600 text-red-600'
                    : 'border-b border-gray-300 text-gray-500')
                }
                onClick={() => setOpenTab(3)}>
                {scripts[languageMode][3]}
              </a>
            </li>
            <li>
              <a
                className={
                  'cursor-pointer py-2 inline-block w-full border-b border-gray-300' +
                  (openTab === 4
                    ? 'border-b-3 border-red-600 text-red-600'
                    : 'border-b border-gray-300 text-gray-500')
                }
                onClick={() => setOpenTab(4)}>
                {scripts[languageMode][4]}
              </a>
            </li>
          </ul>
          {/* 검색 결과 없을 경우 */}
          {/* <div className="flex flex-col h-5/6 justify-center items-center ">
              <h3 className='text-gray-300 text-lg'>검색결과가 없습니다.</h3>
            </div> */}
          {/* 아래는 닉네임 해시태그인 경우 결과 화면  */}
          <Suspense
            fallback={
              <div
                className='grow'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <img src={loadingIcon} style={{ height: '30px' }} />
                <div style={{ height: '30px' }}>loading...</div>
              </div>
            }>
            <SearchResult openTab={openTab} keywords={keywords} />
          </Suspense>
        </section>
      )}
      <div className='hidden lg:block lg:flex lg:justify-center text-xs text-zinc-400 lg:gap-4 lg:my-4'>
        <a href='/introduction'>소개</a>
        <a href='/introduction/termsofservice'>이용약관</a>
        <a href='/introduction/privacy'>개인정보처리방침</a>
        <a>ⓒ 2022 ADLER</a>
      </div>
    </div>
  );
}
