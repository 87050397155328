import { UseLanguageModalResources } from './types';

import { Scripts } from 'src/types';

const scripts: Scripts = {
  KR: ['언어 설정', '적용하기'],
  EN: ['Language setting', 'Apply'],
  JP: ['言語設定', '適用する'],
};

const useLanguageModalResources = (): UseLanguageModalResources => {
  return {
    scripts,
  };
};

export default useLanguageModalResources;
