import { Vector3, Euler } from '@react-three/fiber';
import { Sky } from '@react-three/drei';
import { useMemo, useEffect } from 'react';
import * as THREE from 'three';
import { SkeletonUtils } from 'three-stdlib';
import { MeshPhysicalMaterial } from 'three';

import { useCompressedGLTF } from 'hooks/hooks';

const url3 = new URL('static/models/louvre55.min.glb', import.meta.url);

export default function WoodThema({}: {}) {
  const { scene: scene3, materials } = useCompressedGLTF(url3.href);
  const matNames: string[] = [];
  const disposeMesh: THREE.Mesh[] = [];
  const disposeTexture: THREE.Texture[] = [];

  const clonedScene3 = useMemo(() => SkeletonUtils.clone(scene3), [scene3]);
  clonedScene3.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      const mesh = object as THREE.Mesh;
      mesh.receiveShadow = true;
      const mat = mesh.material as THREE.Material;
      matNames.push(mat.name);

      disposeMesh.push(mesh);
      if (mat.map !== null) {
        disposeTexture.push(mat.map);
      }
    }
  });
  for (let i = 0; i < matNames.length; i++) {
    materials[matNames[i]].toneMapped = false;
  }
  useEffect(() => {
    return () => {
      for (let i = 0; i < disposeMesh.length; i++) {
        if (disposeMesh[i]) disposeMesh[i].geometry.dispose();
      }
      for (let i = 0; i < disposeTexture.length; i++) {
        if (disposeTexture[i]) disposeTexture[i].dispose();
      }
    };
  }, []);
  return (
    <group position={[0, -2.4, 0.9]} scale={1} rotation={[0, 0, 0]}>
      <primitive object={clonedScene3} />
    </group>
  );
}
