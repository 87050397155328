import * as THREE from 'three';
import React, {
  useState,
  useEffect,
  useRef,
  Suspense,
  Dispatch,
  useMemo,
  useCallback,
} from 'react';
import { Canvas, useFrame, ThreeEvent } from '@react-three/fiber';
import { useTexture, AdaptiveDpr } from '@react-three/drei';
import { Physics } from '@react-three/cannon';
import { ScrollControls, useScroll, Sky, Text } from '@react-three/drei';
import { useInfiniteQuery } from '@tanstack/react-query';
import { SkeletonUtils } from 'three-stdlib';
import { SetStateAction, useAtomValue, useAtom } from 'jotai';
import GifLoader from 'three-gif-loader';

import { useCompressedGLTF } from 'hooks/hooks';
import {
  Space,
  Item,
  getUserSpaces,
  User,
  followUser,
  unfollowUser,
  isFollow,
  APIError,
  APIIssues,
  getFollowers,
  getFollowings,
} from 'apis/index';
import { ThirdPlayer } from 'react3fiber/player/thirdperson';
import ProfileTheme from 'react3fiber/theme/profile-theme';
import ProfileThemeDynamic from 'react3fiber/theme/profile-theme-dynamic';
import ProfileThemeEnd from 'react3fiber/theme/profile-theme-end';

import ProfilePrimitiveform from './profile-primitive-form';
import PlaneMask from './plane-mask';
import FrameBox from './frame-box';
import CartoonBox from './cartoon-box';
import CyberBox from './cyber-box';
import FoodBox from './food-box';
import StoneBox from './stone-box';
import ForestBox from './forest-box';
import FlowerBox from './flower-box';
import DiagonalBox from './diagonal-box';
import SpaceBox from './space-box';
import CityBox from './city-box';
import ImageFrameBox from './imageframe-box';
import Button from './button';
import Button2 from './button2';
import FrameMirror from './frame-mirror';
import WoodBox from './wood-box';

import { tokenAtom, playModeAtom, userAtom, languageModeAtom, profileModeAtom } from 'src/atoms';

const scripts = {
  KR: ['팔로우', '팔로잉', '포스트'],
  EN: ['follow', 'following', 'Post'],
  JP: ['フォロー', 'フォローイング', 'Post'],
};

import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import useModal from '../../hooks/useModal';
import Follow from '../../pages/Follow';
import { TabType } from '../../pages/Follow/types';
let font = new URL('../../../static/fonts/NotoSansKR-Regular.otf', import.meta.url);
let font2 = new URL('../../../static/fonts/NotoSansKR-Medium.otf', import.meta.url);

const material = new THREE.MeshToonMaterial({ color: new THREE.Color(1, 1, 1), toneMapped: false });

let movex = 25;
let prev = 0;
let now = 0;

const getPositionByIndex = (index: number) => {
  let y = [30, 18, 6];
  return [36 + Math.floor(index / 3) * 12.1, y[index % 3], -41.5];
};

const getIndexByPlayerPosition = (position: number) => {
  return Math.floor(position / 10) * 3;
};

function calcAngleDegrees(x: number, y: number) {
  let theta_x = (Math.atan2(y, x) * 180) / Math.PI;
  if (theta_x < 0) {
    return theta_x + 360;
  }
  return theta_x;
}

function ClickBox({
  index,
  space,
  onPointerOut,
  onPointerOver,
}: {
  index: number;
  space: Space;
  onPointerOut: ThreeEvent<MouseEvent>;
  onPointerOver: ThreeEvent<MouseEvent>;
}) {
  let positions = getPositionByIndex(index);

  return (
    <mesh
      onClick={(e) => {
        window.location.href = '/s/' + space.id;
      }}
      onPointerOver={onPointerOver}
      onPointerOut={onPointerOut}
      position={[positions[0] + 0, positions[1] - 3, positions[2] - 10]}
      scale={[10, 10, 10]}>
      <boxGeometry attach='geometry' args={[1.05, 1]} />
      <meshStandardMaterial attach='material' color='red' visible={false} />
    </mesh>
  );
}

function MaskBox({
  index,
  space,
  onPointerOut,
  onPointerOver,
}: {
  index: number;
  space: Space;
  onPointerOut: ThreeEvent<MouseEvent>;
  onPointerOver: ThreeEvent<MouseEvent>;
}) {
  let positions = getPositionByIndex(index);

  let post = 0;
  if (space.id == '20755000-fc99-4a00-a162-39b3055c3f26') {
    post = 1;
  } else if (space.id == '644c32b1-af2f-4797-8c55-414cee9a306d') {
    post = 2;
  } else if (space.id == '118bc2c6-5b9d-4798-b885-7369c9158b9a') {
    post = 3;
  } else if (space.id == '91ba0773-c292-4d9c-be51-7c734a29ac40') {
    post = 4;
  }
  return (
    <>
      <ClickBox
        index={index}
        space={space}
        onPointerOut={onPointerOut}
        onPointerOver={onPointerOver}
      />
      <group position={[positions[0], positions[1], positions[2]]} scale={1.1}>
        <PlaneMask stencilRef={1} position={[0, 0, 1]} scale={1}>
          {space.theme == 2 && space.items[0].type == 1 && <CartoonBox />}
          {space.theme == 3 && <WoodBox />}
          {space.theme == 4 && <FlowerBox />}
          {space.theme == 5 && <DiagonalBox />}
          {space.theme == 6 && <SpaceBox />}
          {space.theme == 7 && <CityBox />}
          {post == 2 && <StoneBox />}
          {post == 3 && <CyberBox />}
          {post == 4 && <ForestBox />}
        </PlaneMask>
      </group>

      <group position={[positions[0], positions[1], positions[2]]} scale={5.9}>
        <PlaneMask stencilRef={1} position={[0, 0, 1]} scale={1}>
          {space.theme == 1 && post == 0 && <FrameBox stencilRef={1} color='white' />}

          {space.theme == 2 && space.items[0].type == 2 && <FrameMirror />}

          {post == 1 && <FoodBox />}
        </PlaneMask>
      </group>
    </>
  );
}

function ModelItem({ item, index, id }: { item: Item; index: number; id: string }) {
  let positions = getPositionByIndex(index);
  let { scene: scene1 } = useCompressedGLTF(item.file);
  let clonedScene1 = useMemo(() => SkeletonUtils.clone(scene1), [scene1]);

  clonedScene1.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      let mesh = object as THREE.Mesh;
      mesh.renderOrder = 2;
      mesh.material = mesh.material as THREE.Material;
      mesh.material.toneMapped = false;
      mesh.material.stencilWrite = true;
      mesh.material.stencilRef = 1;
      mesh.castShadow = true;
      mesh.material.stencilFunc = THREE.EqualStencilFunc;
      mesh.material.stencilFail = THREE.KeepStencilOp;
      mesh.material.stencilZFail = THREE.KeepStencilOp;
      mesh.material.stencilZPass = THREE.ReplaceStencilOp;
    }
    if ((object as THREE.Light).isLight) {
      let light = object as THREE.Light;
      light.intensity = 0;
    }
  });

  let sceneExtent = new THREE.BoxGeometry(9, 9, 9);
  let cube = new THREE.Mesh(sceneExtent, material);
  let sceneBounds = new THREE.Box3().setFromObject(cube);
  let meshBounds = new THREE.Box3().setFromObject(clonedScene1);

  // Calculate side lengths of scene (cube) bounding box
  let lengthSceneBounds = {
    x: Math.abs(sceneBounds.max.x - sceneBounds.min.x),
    y: Math.abs(sceneBounds.max.y - sceneBounds.min.y),
    z: Math.abs(sceneBounds.max.z - sceneBounds.min.z),
  };

  // Calculate side lengths of glb-model bounding box
  let lengthMeshBounds = {
    x: Math.abs(meshBounds.max.x - meshBounds.min.x),
    y: Math.abs(meshBounds.max.y - meshBounds.min.y),
    z: Math.abs(meshBounds.max.z - meshBounds.min.z),
  };

  // Calculate length ratios
  let lengthRatios = [
    lengthSceneBounds.x / lengthMeshBounds.x,
    lengthSceneBounds.y / lengthMeshBounds.y,
    lengthSceneBounds.z / lengthMeshBounds.z,
  ];

  // Select smallest ratio in order to contain the model within the scene
  let minRatio = Math.min(...lengthRatios);

  // If you need some padding on the sides
  let padding = 0;
  minRatio -= padding;

  // Use smallest ratio to scale the model
  clonedScene1.scale.multiplyScalar(minRatio);
  let post = 0;
  if (id == '20755000-fc99-4a00-a162-39b3055c3f26') {
    post = 1;
  } else if (id == '644c32b1-af2f-4797-8c55-414cee9a306d') {
    post = 2;
  } else if (id == '118bc2c6-5b9d-4798-b885-7369c9158b9a') {
    post = 3;
  } else if (id == '91ba0773-c292-4d9c-be51-7c734a29ac40') {
    post = 4;
  }
  return (
    <group position={[positions[0], positions[1] - 4.2, positions[2]]}>
      {post == 0 && <primitive object={clonedScene1} />}
    </group>
  );
}

function Item({ item, index }: { item: Item; index: number }) {
  let texture = useTexture(item.file + '?');
  let positions = getPositionByIndex(index);
  let ref = useRef();
  let loader = new GifLoader();
  let texture2;

  useEffect(() => {
    if (item.file.includes('gif')) {
      ref.current.map = loader.load(
        // resource URL
        item.file.includes('blob') ? item.file : item.file + '?',
        // onLoad callback
        function (reader) {
          // You probably don't need to set onLoad, as it is handled for you. However,
          // if you want to manipulate the reader, you can do so here:
        },

        // onProgress callback
        function (xhr) {
          console.log();
        },

        // onError callback
        function () {
          console.error('An error happened.');
        }
      );
    }
  }, []);

  return (
    <group>
      {item.frameType === 1 && (
        <ImageFrameBox position={[positions[0] - 15.2, positions[1] - 5.7, positions[2] + 11.4]} />
      )}
      <mesh
        castShadow
        position={[positions[0], positions[1], positions[2] - 5]}
        rotation={[item.rotationX, item.rotationY, item.rotationZ]}
        renderOrder={2}>
        <planeGeometry args={[8, 8]} />
        <meshStandardMaterial
          ref={ref}
          map={item.file.includes('gif') ? texture2 : texture}
          toneMapped={false}
          stencilWrite
          stencilRef={1}
          stencilFunc={THREE.EqualStencilFunc}
          stencilFail={THREE.KeepStencilOp}
          stencilZFail={THREE.KeepStencilOp}
          stencilZPass={THREE.ReplaceStencilOp}
        />
      </mesh>
    </group>
  );
}

function Objects({
  count,
  spaces,
  onPointerOut,
  onPointerOver,
}: {
  count: number;
  spaces: Space[];
  onPointerOut: ThreeEvent<MouseEvent>;
  onPointerOver: ThreeEvent<MouseEvent>;
}) {
  let imageArr = spaces.slice(0, spaces.length);
  let mapArr = [];
  let modelArr: JSX.Element[] = [];
  for (let i = 0; i < imageArr.length; i++) {
    if (i % 3 == 0) {
      if (mapArr.length == 1) {
        mapArr.push(imageArr[i]);
      } else if (i % 9 == 0) {
        mapArr.push(imageArr[i]);
      }
    }
  }

  for (let i = 1; i < mapArr.length; i++) {
    modelArr.push(
      <group key={i}>
        <mesh receiveShadow position={[25 + i * 35.2, 18, -36.1]} rotation={[0, 0, 0]}>
          <planeGeometry attach='geometry' args={[37, 36]} />
          <meshStandardMaterial
            toneMapped={false}
            attach='material'
            color='#dddddd'
            colorWrite={true}
            depthWrite={false}
            stencilWrite
            stencilFunc={THREE.AlwaysStencilFunc}
            stencilFail={THREE.ReplaceStencilOp}
            stencilZFail={THREE.ReplaceStencilOp}
            stencilZPass={THREE.ReplaceStencilOp}
          />
        </mesh>

        {i != 0 && (
          <>
            <ProfileThemeDynamic
              position={[-4.98 + i * 35.2, 16.2, -19]}
              scale={[10.2, 10.2, 10.2]}
              rotation={[0, -Math.PI / 2, 0]}
            />

            <ProfileThemeEnd
              position={[-4.98 + i * 35.2, 16.2, -19]}
              scale={[10.2, 10.2, 10.2]}
              rotation={[0, -Math.PI / 2, 0]}
            />
          </>
        )}
      </group>
    );
  }

  return (
    <>
      <ProfileTheme
        position={[31.2, 16.2, -19]}
        scale={[10.2, 10.2, 10.2]}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <ProfileThemeDynamic
        position={[-4.98, 16.2, -19]}
        scale={[10.2, 10.2, 10.2]}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <ProfileThemeEnd
        position={[-4.98, 16.2, -19]}
        scale={[10.2, 10.2, 10.2]}
        rotation={[0, -Math.PI / 2, 0]}
      />

      <mesh receiveShadow position={[25, 18, -36.1]} rotation={[0, 0, 0]}>
        <planeGeometry attach='geometry' args={[50, 36]} />
        <meshStandardMaterial
          toneMapped={false}
          attach='material'
          color='#dddddd'
          colorWrite={true}
          depthWrite={false}
          stencilWrite
          stencilFunc={THREE.AlwaysStencilFunc}
          stencilFail={THREE.ReplaceStencilOp}
          stencilZFail={THREE.ReplaceStencilOp}
          stencilZPass={THREE.ReplaceStencilOp}
        />
      </mesh>
      {imageArr.map((space, index) => (
        <group key={index}>
          {space.items[0]?.type == 1 ? (
            space.items[0]?.file == undefined ? null : (
              <Item item={space.items[0]} index={index} />
            )
          ) : space.items[0]?.file == undefined ? null : (
            <ModelItem item={space.items[0]} index={index} id={space.id} />
          )}
          <MaskBox
            index={index}
            space={space}
            onPointerOver={onPointerOver}
            onPointerOut={onPointerOut}
          />
        </group>
      ))}
      {modelArr}
    </>
  );
}

type SpaceMode = 'multiplay' | 'scroll';

function Players({
  currentUser,
  follower_num,
  following_num,
  userId,
  messages,
  username,
  space,
  mode,
  isInteractive,
  chat,
  chatOn,
  fetchNextPage,
  hasNextPage,
  dir,
  customOptions,
  count,
}: {
  dir: string;
  currentUser: User;
  follower_num: number | undefined;
  following_num: number | undefined;
  userId: string | undefined;
  messages: { username: string; message: string }[];
  username: string;
  space: Space[];
  mode: SpaceMode;
  isInteractive: boolean;
  chat: string;
  chatOn: number;
  hasNextPage: boolean | undefined;
  customOptions: {
    type: string;
    hair: number;
    face: number;
    top: number;
    bottom: number;
    shoes: number;
  };
  count: number;
}) {
  const ref = useRef<THREE.Group>();
  const scroll = useScroll();
  let [playerPosition, setPlayerPosition] = useState<number>(0);
  let [imageArrIndex, setImageArrIndex] = useState<number>(0);
  let [far, setFar] = useState<number>(0);
  let [load, setLoad] = useState<boolean>(false);
  let token = useAtomValue(tokenAtom);
  let user = useAtomValue(userAtom);
  let languageMode = useAtomValue(languageModeAtom);
  let [follow, setFollow] = useState<boolean>(false);
  const mediaMatch = useMediaQuery({ maxWidth: 1224 });
  const navigate = useNavigate();
  const { openModal, openAlertModal } = useModal();
  const [followerCount, setFollowerCount] = useState<number>();
  const [followingCount, setFollowingCount] = useState<number>();

  let [isNickNameModal, setIsNickNameModal] = useState(false);
  let bio;
  let url;
  if (currentUser?.bio.length > 124) {
    bio = currentUser?.bio.substring(0, 124).concat('...');
  } else {
    bio = currentUser?.bio;
  }
  if (currentUser?.url.length > 33) {
    url = currentUser?.url.substring(0, 33).concat('...');
  } else {
    url = currentUser?.url;
  }

  useFrame(({ camera }) => {
    if (mode == 'scroll') {
      movex = scroll.offset * 13 * (count / 3);
      camera.near = 1;
      camera.lookAt(movex, 18, 0);
      camera.position.set(
        movex,
        18,
        18 / Math.tan(THREE.MathUtils.degToRad((camera as THREE.PerspectiveCamera).fov) / 2)
      );
      camera.lookAt(movex, 18, 0);
      camera.fov = 45;
      if (load == false) {
        if (Math.floor(movex % 4) == 0) {
          if (hasNextPage == true) {
            now = Math.floor(movex);
            if (now > prev) {
              console.log('nextpage');
              fetchNextPage();
              prev = now;
            }
            prev = now;
          }
          setLoad(true);
        }
      } else {
        if (Math.floor(movex % 4) != 0) {
          setLoad(false);
        }
      }
    } else if (mode == 'multiplay') {
      if (load == false) {
        if (Math.floor(playerPosition % 10) == 0) {
          if (hasNextPage == true) {
            now = Math.floor(playerPosition);
            if (now > prev) {
              console.log('nextpage');
              fetchNextPage();
              prev = now;
            }
            prev = now;
          }
          setLoad(true);
        }
      } else {
        if (Math.floor(playerPosition % 10) != 0) {
          setLoad(false);
        }
      }
    }
  });

  useEffect(() => {
    setImageArrIndex(getIndexByPlayerPosition(playerPosition));
  }, [playerPosition]);

  useEffect(() => {
    if (token && currentUser && user) {
      (async () => {
        let isfollow = await isFollow(token, currentUser.username, user?.username);
        setFollow(isfollow);
      })();
    }
  }, [token, currentUser, user]);

  useEffect(() => {
    if (mode == 'multiplay') {
      setFar(100 + playerPosition);
    } else {
      setFar(1000 + playerPosition);
    }
  }, [mode, playerPosition]);

  let object = new URL('../../../static/imgs/default-profile.png', import.meta.url);

  let texture = useTexture(currentUser?.image ? currentUser.image + '?' : object.href);

  let [hover, setHover] = React.useState<boolean>(false);

  const refreshFollowCounts = useCallback(async () => {
    if (token) {
      try {
        const [followerDatas, followingDatas] = await Promise.all([
          getFollowers(`/users/${currentUser.username}/followers/`, token),
          getFollowings(`/users/${currentUser.username}/followings/`),
        ]);

        setFollowerCount(followerDatas.total_length);
        setFollowingCount(followingDatas.total_length);
      } catch (error) {
        let errorMessage: string;

        if (error instanceof APIError) {
          errorMessage = error.issues.detail;
        } else {
          errorMessage = error as string;
        }

        openAlertModal([{ type: 'NORMAL', message: errorMessage }]);
      }
    }
  }, [currentUser, token, setFollowerCount, setFollowingCount]);

  const onFollowClick = useCallback(
    (userName?: string, tabType?: TabType) => {
      if (userName) {
        /*
          INFO

          Mobile
        */
        if (mediaMatch) {
          return navigate(`/u/${userName}/follow/${tabType}`);
        }

        /*
          INFO

          PC
        */
        openModal({
          component: <Follow userName={userName} initialTabType={tabType} />,
          options: { isCloseOnESCKeyDown: true, isOverlayed: true, isCloseOnOverlayClick: true },
          onClosed: () => {
            if (location.pathname.split('/').pop() === userName) {
              refreshFollowCounts();
            }
          },
        });
      }
    },
    [mediaMatch]
  );

  const onPointerOver = React.useCallback((event: ThreeEvent<MouseEvent>) => {
    event.stopPropagation();

    setHover(true);
  }, []);

  const onPointerOut = React.useCallback((event: ThreeEvent<MouseEvent>) => {
    event.stopPropagation();

    setHover(false);
  }, []);

  React.useLayoutEffect(() => {
    document.body.style.cursor = hover ? 'pointer' : 'auto';
  }, [hover]);

  return (
    <>
      <mesh
        onClick={() => {
          setFollow(!follow);
          if (token && currentUser) {
            if (follow) {
              unfollowUser(token, username);
            } else {
              followUser(token, username);
            }
          }
        }}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
        position={[8, 25, -37]}
        scale={[7, 6, 1]}>
        <boxGeometry args={[1, 1]} />
        <meshStandardMaterial visible={false} />
      </mesh>
      <mesh
        onClick={() => onFollowClick(username, 'follower')}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
        position={[0.5, 7.5, -37]}
        scale={[4.5, 2, 1]}>
        <boxGeometry args={[1, 1]} />
        <meshStandardMaterial visible={false} />
      </mesh>
      <mesh
        onClick={() => onFollowClick(username, 'following')}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
        position={[9, 7.5, -37]}
        scale={[5, 2, 1]}>
        <boxGeometry args={[1, 1]} />
        <meshStandardMaterial visible={false} />
      </mesh>
      <mesh
        onClick={() => {
          window.open(currentUser?.url);
        }}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
        position={[0, 9, -37]}
        scale={[19, 1, 1]}>
        <boxGeometry args={[1, 1]} />
        <meshStandardMaterial visible={false} />
      </mesh>
      {mode == 'multiplay' && (
        <Physics>
          <ProfilePrimitiveform item={count} />
          <ThirdPlayer
            isNickNameModal={isNickNameModal}
            setIsNickNameModal={setIsNickNameModal}
            mode={mode}
            isInteractive={isInteractive}
            dir={dir}
          />
        </Physics>
      )}
      <group ref={ref} position={[-15, 0, 0]}>
        <mesh castShadow position={[8.5, 28, -35.9]} rotation={[0, 0, 0]}>
          <circleGeometry args={[3, 64]} />
          <meshStandardMaterial
            map={texture}
            toneMapped={false}
            emissiveIntensity={0.0}
            emissive='#ffffff'
          />
        </mesh>

        {token && currentUser && user?.username != username ? (
          follow ? (
            <>
              <Text
                color='#000000'
                anchorY='top'
                anchorX='center'
                position={[22.6, 28.3, -35.8]}
                fontSize={1.1}
                maxWidth={25}
                overflowWrap='break-word'
                font={font2.href}>
                {scripts[languageMode][1]}
              </Text>
              <Button2 scale={[4, 4, 1]} rotation={[0, 0, 0]} position={[22.5, 27.5, -35.9]} />
            </>
          ) : (
            <>
              <Text
                color='#ffffff'
                anchorY='top'
                anchorX='center'
                position={[22.6, 28.3, -35.8]}
                fontSize={1.1}
                maxWidth={25}
                overflowWrap='break-word'
                font={font2.href}>
                {scripts[languageMode][0]}
              </Text>
              <Button scale={[4, 4, 1]} rotation={[0, 0, 0]} position={[22.5, 27.5, -35.9]} />
            </>
          )
        ) : null}
        <Text
          color='#000000'
          anchorY='top'
          anchorX='left'
          position={[5.5, 24.2, -35.9]}
          fontSize={1.5}
          maxWidth={20}
          overflowWrap='break-word'
          font={font2.href}>
          {currentUser?.nickname}
        </Text>
        <Text
          anchorX='left'
          color='#777777'
          anchorY='top'
          position={[5.5, 22.2, -35.9]}
          fontSize={1}
          maxWidth={20}
          overflowWrap='break-word'
          font={font.href}>
          @{currentUser?.username}
        </Text>
        <Text
          color='#000000'
          anchorY='top'
          anchorX='left'
          position={[5.5, 12, -35.9]}
          fontSize={1}
          maxWidth={25}
          overflowWrap='break-word'
          font={font.href}>
          {scripts[languageMode][2]}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {scripts[languageMode][0]}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {scripts[languageMode][1]}
        </Text>
        <Text
          color='#000000'
          anchorY='top'
          anchorX='left'
          position={[9, 12, -35.9]}
          fontSize={1}
          maxWidth={25}
          overflowWrap='break-word'
          font={font2.href}>
          {count}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {followerCount ?? follower_num}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {followingCount ?? following_num}
        </Text>

        <Text
          color='#000000'
          textAlign='justify'
          anchorY='top'
          anchorX='left'
          position={[5.5, 20, -35.9]}
          fontSize={1}
          maxWidth={20}
          overflowWrap='break-word'
          font={font.href}>
          {bio}
        </Text>
        <Text
          anchorX='left'
          color='#2356a2'
          anchorY='top'
          position={[5.5, 14, -35.9]}
          fontSize={1}
          maxWidth={20}
          overflowWrap='break-word'
          font={font2.href}>
          {url}
        </Text>

        <Objects
          spaces={space}
          count={count}
          imageArrIndex={imageArrIndex}
          onPointerOver={onPointerOver}
          onPointerOut={onPointerOut}
        />
      </group>
    </>
  );
}

export default function ProfileSpace({
  currentUser,
  userId,
  username,
  userEmail,
  follower_num,
  following_num,
  customOptions,
  spaceMode,
  setSpaceMode,
}: {
  currentUser: User;
  userId: string | undefined;
  userEmail: string | undefined;
  username: string | undefined;
  follower_num: number;
  following_num: number;
  customOptions: {
    type: string;
    hair: number;
    face: number;
    top: number;
    bottom: number;
    shoes: number;
  };
  spaceMode: SpaceMode;
  setSpaceMode: Dispatch<SetStateAction<SpaceMode>>;
}) {
  let [dir, setDir] = useState('');
  let [xy, setXY] = useState({ x: 0, y: 0 });
  let [joyxy, setJoyxy] = useState({ x: 0, y: 0 });
  let [isjoy, setIsjoy] = useState('');
  let [isLike, setIsLike] = useState<boolean>(false);
  let joyout = new URL('../../../static/imgs/out.png', import.meta.url);
  let joyin = new URL('../../../static/imgs/in.png', import.meta.url);
  let handleMouseDown = (e) => {
    setXY({ x: e.changedTouches[0].pageX, y: e.changedTouches[0].pageY });
    setJoyxy({ x: e.changedTouches[0].pageX, y: e.changedTouches[0].pageY });
  };
  let clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);
  let handleMouseMove = (e) => {
    setJoyxy({
      x: clamp(e.changedTouches[0].pageX, xy.x - 20, xy.x + 20),
      y: clamp(e.changedTouches[0].pageY, xy.y - 20, xy.y + 20),
    });
    if (
      calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) > 140 &&
      calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) < 225
    ) {
      setDir('left');
    }
    if (
      calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) < 140 &&
      calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) > 45
    ) {
      setDir('backward');
    }
    if (
      calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) > 225 &&
      calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) < 325
    ) {
      setDir('forward');
    }
    if (calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) > 325) {
      setDir('right');
    }
    if (calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) < 45) {
      setDir('right');
    }
    setIsjoy('시작');
  };
  let handleMouseUp = () => {
    setIsjoy('끝');
    setDir('');
  };
  let [messages, setMessages] = useState<{ username: string; message: string }[]>([]);
  let canvasElRef = useRef<HTMLCanvasElement>(null);
  let [chatOn, setChatOn] = useState<number>(0);
  let [isInteractive, setIsInteractive] = useState(true);
  let [error, setError] = useState<APIIssues | null>(null);
  let [playMode, setPlayMode] = useAtom(playModeAtom);
  let user = useAtomValue(userAtom);
  let [profileMode, setProfileMode] = useAtom(profileModeAtom);

  let { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    ['user', username, 'spaces'],
    async ({ pageParam }) => {
      try {
        return await getUserSpaces(username, pageParam);
      } catch (error) {
        if (error instanceof APIError) {
          setError(error.issues);
        }
      }
    },
    {
      getNextPageParam: (lastPage) => (lastPage ? lastPage.cursor : undefined),
    }
  );

  let count = 0;
  if (!data) return null; // we use suspense so data is never undefined
  let spaces = data.pages.flatMap((page) => {
    if (typeof page.count === 'number') {
      count = page.count;
    }
    return page.spaces;
  });

  useEffect(() => {
    if (!canvasElRef.current) {
      return;
    }
    username == user?.username ? setProfileMode(true) : setProfileMode(false);
    let observer = new IntersectionObserver(([entry]) => setIsInteractive(entry.isIntersecting), {
      threshold: 0.8,
    });
    observer.observe(canvasElRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      setChatOn(0);
    }, 2500);
    return () => {
      clearTimeout(timer);
    };
  }, [messages]);
  const mediaMatch = useMediaQuery({ maxWidth: 1224 });
  return (
    <>
      {spaceMode === 'multiplay' && (
        <div
          className='absolute top-0 w-1/2 h-full bg-transparent z-10 lg:w-[940] lg:m-auto'
          onTouchStart={(e) => handleMouseDown(e)}
          onTouchMove={(e) => handleMouseMove(e)}
          onTouchEnd={() => handleMouseUp()}>
          {spaceMode == 'multiplay' ? (
            mediaMatch ? (
              isjoy == '시작' ? (
                <>
                  <div
                    // className='absolute w-20 h-20 bg-red-600 rounded-full'
                    style={{
                      position: 'absolute',
                      backgroundImage: `url(${'"' + joyout + '"'})`,
                      left: xy.x - 40,
                      top: xy.y - 75,
                      width: 100,
                      height: 100,
                      backgroundSize: 'contain',
                    }}></div>
                  <div
                    // className='absolute w-10 h-10 bg-blue-600 rounded-full'
                    style={{
                      backgroundImage: `url("${joyin.href}")`,
                      position: 'absolute',
                      // backgroundColor: 'red',
                      left: joyxy.x - 35,
                      top: joyxy.y - 75,
                      width: 100,
                      height: 100,
                      backgroundSize: 'contain',
                    }}></div>
                  <div style={{ position: 'absolute', left: xy.x, top: xy.y }}></div>
                </>
              ) : null
            ) : null
          ) : null}
        </div>
      )}
      <Canvas
        frameloop='demand'
        gl={{ antialias: false }}
        // shadows
        ref={canvasElRef}
        className='absolute top-0 left-0 w-full bg-white'
        camera={{ fov: 45 }}
        onCreated={({ camera }) => {
          camera.position.set(
            18,
            18,
            18 / Math.tan(THREE.MathUtils.degToRad((camera as THREE.PerspectiveCamera).fov) / 2)
          );
          camera.lookAt(18, 18, 0);
        }}
        onClick={(e) => {
          e.stopPropagation();
          // setSpaceMode('multiplay');
        }}>
        <AdaptiveDpr pixelated />
        <ambientLight color='#ffffff' intensity={0.8} />
        <directionalLight
          shadow-mapSize-width={4096}
          shadow-mapSize-height={4096}
          shadow-camera-far={500}
          shadow-camera-left={-100}
          shadow-camera-right={100}
          shadow-camera-top={100}
          shadow-camera-bottom={-100}
          castShadow
          intensity={0.5}
          position={[10, 10, 60]}
        />

        <ScrollControls pages={6} horizontal={true}>
          <Players
            dir={dir}
            currentUser={currentUser}
            userEmail={userEmail}
            follower_num={follower_num}
            following_num={following_num}
            userId={userId}
            messages={messages}
            username={username}
            space={spaces}
            count={count}
            mode={spaceMode}
            fetchNextPage={fetchNextPage}
            isInteractive={isInteractive}
            chat={messages.length > 0 ? messages[messages.length - 1].message : ''}
            chatOn={chatOn}
            hasNextPage={hasNextPage}
            customOptions={customOptions}
          />
        </ScrollControls>

        <Sky />
      </Canvas>
    </>
  );
}
