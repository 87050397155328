import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { useAtom, useAtomValue } from 'jotai';

import spaceLeaveIcon from 'static/icons/space_leave.svg';
import chatOpenIcon from 'static/icons/chat_open.svg';
import chatCloseIcon from 'static/icons/chat_close.svg';
import defaultUserImage from 'static/imgs/default-profile.png';
import eyeIcon from 'static/icons/eye.svg'

import { BreakPoint, Color } from 'src/constants';
import { currentRoomAtom, messageListAtom, playModeAtom } from 'src/atoms';
import useScroll from 'hooks/useScroll';
import useKeyLock from 'hooks/useKeyLock';

import { languageModeAtom, totalRoomsAtom } from 'src/atoms';
import * as Colyseus from 'colyseus.js';
import { ViewerModal } from './modals';
import { Space, Token, unlikeSpace } from 'apis/index';

const Leave = styled.img.attrs({ src: spaceLeaveIcon })`
  width: 8rem;
  height: 2.75rem;

  ${BreakPoint.mobile} {
    width: 5rem;
    height: 1.5rem;
  }
`;

const EyeIcon = styled.img.attrs({ src: eyeIcon })`
  width: 1.5 rem;
  height: 1.125rem;
  ${BreakPoint.mobile} {
    width: 1.375rem;
    height: 1.125rem;
  }
`;

const ChatOpen = styled.img.attrs({ src: chatOpenIcon })`
  width: 3.375rem;
  height: 3.375rem;

  ${BreakPoint.mobile} {
    width: 2.375rem;
    height: 2.375rem;
  }
`;

const ChatClose = styled.img.attrs({ src: chatCloseIcon })`
  width: 3.375rem;
  height: 3.375rem;

  ${BreakPoint.mobile} {
    width: 2.375rem;
    height: 2.375rem;
  }
`;

const ChatInputContainer = styled.div`
  position: relative;
  z-index: 999;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 2.5rem;

  .clickable:hover {
    cursor: pointer;
  }

  ${BreakPoint.mobile} {
    margin-bottom: 1rem;
  }
`;

const ChatInputForm = styled.form`
  display: flex;
  width: 54.625rem;

  ${BreakPoint.mobile} {
    width: 91.73%;
  }
`;

const ChatInputArea = styled.div`
  position: relative;
  width: 50.375rem;
  height: 3.375rem;
  margin-right: 1rem;

  ${BreakPoint.mobile} {
    width: 84.3%;
    height: 2.375rem;
  }
`;

const ChatInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: -0.02rem;
  color: ${Color.white};
  padding: 1rem 4.75rem 1rem 1.5rem;
  border: 2px solid ${Color.white};
  border-radius: 1.875rem;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: ${Color.white};
  }

  ${BreakPoint.mobile} {
    padding: 0.5rem 4.75rem 0.5rem 1rem;
  }
`;

const ChatButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1.5rem;

  ${BreakPoint.mobile} {
    top: 0.5rem;
    right: 1rem;
  }
`;

const GradientLi = styled.li<{ currentIndex: number }>`
  width: 100%;
  display: flex;

  margin-bottom: 0.5rem;
  color: ${({ currentIndex }) =>
    currentIndex >= 10 ? Color.white : Color.whiteAlpha(`0.${10 - currentIndex}`)};
`;

const Time = styled.div`
  width: 2.875rem;
  font-weight: 300;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  letter-spacing: -0.005rem;
`;

const UserName = styled.div`
  box-sizing: border-box;
  font-weight: 500;
  font-size: 0.8125rem;
  margin-right: 0.5rem;

  ${BreakPoint.mobile} {
    width: 2.75rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const Content = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  word-break: break-all;

  ${BreakPoint.mobile} {
    width: 63.26%;
  }
`;

const UserProfile = styled.img.attrs<{ imageURL?: string }>(({ imageURL }) => ({
  src: imageURL ? imageURL : defaultUserImage,
}))<{ imageURL?: string }>`
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
  margin: 0 0.25rem 0 0.5rem;
`;

const scripts = {
  KR: [
    '요약하기',
    '더 보기',
    '로그인이 필요합니다.',
    '차단되었습니다.',
    '명',
    '클립보드에 복사되었습니다.',
    '복사에 실패했습니다.',
  ],
  EN: ['See Less', 'More', 'Login required', 'Blocked.', ' People', 'copied to the clipboard', 'copy failed'],
  JP: [
    '要約する',
    'もっと見る',
    'ログインが必要です',
    'ブロックされました。',
    ' 人',
    'クリップボードにコピーされました。',
    'コピーに失敗しました',
  ],
};

export default function MultiPlayModeLayout({
  setIsInteractive,
  space
}: {
  setIsInteractive: React.Dispatch<React.SetStateAction<boolean>>;
  space: Space;
}) {
  let [currentMessage, setCurrentMessage] = useState('');
  let [playMode, setPlayMode] = useAtom(playModeAtom);
  let [chatOpen, setChatOpen] = useState<boolean>(false);
  let [currentChannel, setCurrentChannel] = useState<Colyseus.Room | null>(null);
  let [isViewerModalOpen, setIsViewerModalOpen] = useState<boolean>(false);
  let [spaceChannels, setSpaceChannels] = useState<any[] | null>(null);
  let [currentClients, setCurrentClients] = useState<number>(1);
  const mediaMatch = useMediaQuery({ maxWidth: 1224 });

  let chatLogsRef = useRef<HTMLDivElement>(null);
  let messageList = useAtomValue(messageListAtom);
  let currentRoom = useAtomValue(currentRoomAtom);
  let totalRooms = useAtomValue(totalRoomsAtom);
  let languageMode = useAtomValue(languageModeAtom);

  const { allAddHandler, allRemoveHandler } = useKeyLock();

 useEffect(() => {
    if (totalRooms && currentRoom) {
      setCurrentChannel(totalRooms.filter((room) => room.roomId === currentRoom?.id)[0]);
      setSpaceChannels(totalRooms.filter((room) => room.roomId.startsWith(space.id)));
      if (currentChannel) {
        setCurrentClients(currentChannel.clients);
      }
    }
  }, [currentRoom, totalRooms, space.id]);

  useEffect(() => {
    if (chatLogsRef.current && chatOpen) {
      chatLogsRef.current.scrollTop = chatLogsRef.current.scrollHeight;
    }
  }, [messageList, chatOpen]);

  useEffect(() => {
    (async () => {
      if (chatOpen) {
        allRemoveHandler();
      } else {
        allAddHandler();
      }
    })();

    console.log('Chat open');
  }, [chatOpen]);

  const onChatFormSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      currentMessage.trim() != '' &&
        currentRoom?.send('chat', {
          message: currentMessage,
        });
      setCurrentMessage('');
    },
    [currentMessage, currentRoom]
  );

  const timeFormatter = (date: Date) => {
    const hour = date.getHours();
    const minute = date.getMinutes();
    const formattedHour = hour >= 10 ? `${hour}` : `0${hour}`;
    const formattedMinute = minute >= 10 ? `${minute}` : `0${minute}`;

    return `${formattedHour}:${formattedMinute}`;
  };

  return (
    <>
      <div className='flex absolute items-center top-0 w-full z-10'>
        <div className='flex-initial flex justify-center items-center'>
          <button
            className='flex-initial lg:pt-11 lg:pl-20 pt-2 pl-4'
            onClick={(e) => {
              e.stopPropagation();

              setPlayMode('scroll');
            }}>
            <Leave />
          </button>
        </div>
        <div className='flex-auto justify-end' />
      </div>
      <div 
          className='flex justify-end top-0 right-0 z-5'
          onClick={(e) => {
            e.stopPropagation();
            setIsViewerModalOpen(true);
          }}>
          <div className='flex gap-1 items-center lg:m-10 m-2 p-2 rounded-lg py-0.8 px-0.12 z-30' style={{ backgroundColor: 'rgba(51,51,51,0.3)' }}>
            <div className='float-left inline-block items-center'>
              <EyeIcon />
            </div>
            <div className='text-xs text-white'>
              {currentClients} {scripts[languageMode][4]}
            </div>
          </div>
        </div>
        {isViewerModalOpen && (
          <ViewerModal
            setIsViewerModalOpen={setIsViewerModalOpen}
            currentChannel={currentChannel}
            spaceChannels={spaceChannels}
          />
        )}
      <div
        className='flex justify flex-col absolute bottom-0 w-full rounded-tl-xl rounded-tr-xl text-white grow-1 overflow-auto z-20'
        style={{
          maxHeight: '17.5rem',
          justifyContent: 'flex-end',
          alignItems: 'center',
          background:
            messageList.length === 0
              ? 'transparent'
              : 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(51, 51, 51, 0.7) 100%)',
        }}>
        {chatOpen && (
          <div
            className='overflow-y-scroll flex flex-col grow-1 pl-5 pr-7'
            ref={chatLogsRef}
            style={{ width: mediaMatch ? '95.73%' : '54.625rem' }}>
            <ul className='block'>
              {messageList.map((message, index) => {
                return message.u !== 'Unauthenticated' ? (
                  <Link key={index} to={'/u/' + message.u}>
                    <GradientLi currentIndex={messageList.length - index}>
                      <Time>{`[${timeFormatter(message.t)}]`}</Time>
                      <UserProfile imageURL={message.p} />
                      <UserName>{message.n}:</UserName>
                      <Content>{message.m}</Content>
                    </GradientLi>
                  </Link>
                ) : (
                  <GradientLi currentIndex={messageList.length - index}>
                    <Time>{`[${timeFormatter(message.t)}]`}</Time>
                    <UserProfile imageURL={message.p} />
                    <UserName>{message.n}:</UserName>
                    <Content>{message.m}</Content>
                  </GradientLi>
                );
              })}
            </ul>
          </div>
        )}
        {/* <div className='w-full pt-2 flex-grow-0 flex-shrink-0 basis-10'>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              currentMessage.trim() != '' &&
                currentRoom?.send('chat', {
                  message: currentMessage,
                });
              setCurrentMessage('');
            }}
            className='mx-auto w-full'>
            <label className='w-2/5 mx-auto border border-white rounded-full py-1 px-3 mb-3 flex justify-between'>
              <input
                type='text'
                value={currentMessage}
                onFocus={() => setIsInteractive(false)}
                onBlur={() => setIsInteractive(true)}
                onChange={(e) => setCurrentMessage(e.target.value)}
                placeholder='메시지를 입력하세요'
                className='text-base px-3 py-1 bg-transparent w-10/12 focus:outline-none placeholder:text-xs placeholder:text-white'
              />
              <button type='submit' className=' text-white p-1 text-xs'>
                보내기
              </button>
            </label>
          </form>
        </div> */}
        <ChatInputContainer onClick={(event) => event.stopPropagation()}>
          <ChatInputForm onSubmit={onChatFormSubmit}>
            <ChatInputArea>
              {chatOpen && (
                <>
                  <ChatInput
                    value={currentMessage}
                    placeholder='메세지를 입력하세요.'
                    onFocus={() => setIsInteractive(false)}
                    onBlur={() => setIsInteractive(true)}
                    onChange={(event) => setCurrentMessage(event.target.value)}
                  />
                  <ChatButton type='submit'>보내기</ChatButton>
                </>
              )}
            </ChatInputArea>
            {chatOpen ? (
              <ChatClose onClick={() => setChatOpen((prev) => !prev)} />
            ) : (
              <ChatOpen onClick={() => setChatOpen((prev) => !prev)} />
            )}
          </ChatInputForm>
        </ChatInputContainer>
      </div>
    </>
  );
}
