import styled from 'styled-components';

import CheckboxRectangleChecked from 'static/icons/checkbox_rectangle__checked.svg';
import CheckboxRectangleUnChecked from 'static/icons/checkbox_rectangle__unchecked.svg';

export const MarketingFlagContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const CheckboxArea = styled.label`
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
`;

export const Checkbox = styled.img.attrs<{ isChecked: boolean }>(({ isChecked }) => ({
  src: isChecked ? CheckboxRectangleChecked : CheckboxRectangleUnChecked,
}))<{ isChecked: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5rem;
  height: 1.5rem;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.015rem;
  color: #4d4d4d;
`;
