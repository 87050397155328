import { useCallback } from 'react';

import { Checkbox, CheckboxArea, Text, MarketingFlagContainer } from './styles';
import { MarketingFlagProps } from './types';

function MarketingFlag({ isChecked = false, setIsChecked, text }: MarketingFlagProps): JSX.Element {
  const onCheckboxClick = useCallback(() => {
    setIsChecked((prev) => !prev);
  }, []);

  return (
    <MarketingFlagContainer>
      <CheckboxArea onClick={onCheckboxClick}>
        <Checkbox isChecked={isChecked} />
      </CheckboxArea>
      <Text>{text}</Text>
    </MarketingFlagContainer>
  );
}

export default MarketingFlag;
