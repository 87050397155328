import { useCallback } from 'react';

import { CloseIconContainer, FollowHeaderContainer, Title } from './styles';

import { Close } from '../../../../components/Commons/icons';
import useModal from '../../../../hooks/useModal';

function FollowHeader(): JSX.Element {
  const { closeModal } = useModal();

  const onFollowCloseIconClick = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <FollowHeaderContainer>
      <Title>ID</Title>
      <CloseIconContainer>
        <button onClick={onFollowCloseIconClick}>
          <Close />
        </button>
      </CloseIconContainer>
    </FollowHeaderContainer>
  );
}

export default FollowHeader;
