import { Dispatch } from 'react';
import { SetStateAction, useAtom } from 'jotai';

import { languageModeAtom } from 'src/atoms';

const scripts = {
  KR: ['언어 설정'],
  EN: ['Language Setting'],
  JP: ['言語設定'],
};

export default function Language({ setMode }: { setMode: Dispatch<SetStateAction<string>> }) {
  let [languageMode, setLanguageMode] = useAtom(languageModeAtom);

  function changeLanguage(e) {
    setLanguageMode(e.target.value);
  }
  return (
    <>
      <div className='flex justify-center h-11 shrink-0 lg:hidden'>
        <div className='grow basis-0 justify-end'>
          <button className='flex-initial p-2.5' onClick={() => setMode('default')}>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M16 19.5L8 12L16 4.5' stroke='black' strokeLinecap='round' />
            </svg>
          </button>
        </div>
        <div className='flex items-center text-xl font-semibold'>{scripts[languageMode][0]}</div>
        <div className='flex grow basis-0 justify-end' />
      </div>
      <div className='flex flex-col px-10 gap-2 mt-4 lg:px-6'>
        <div className='hidden text-xl lg:flex lg:pb-4'>{scripts[languageMode][0]}</div>
        <div>
          <input
            type='radio'
            name='language'
            id='KR'
            value='KR'
            checked={languageMode === 'KR'}
            onChange={changeLanguage}
            className='mx-2'
          />
          <label>한국어</label>
        </div>
        <div>
          <input
            type='radio'
            name='language'
            id='EN'
            value='EN'
            checked={languageMode === 'EN'}
            onChange={changeLanguage}
            className='mx-2'
          />
          <label>English</label>
        </div>
        <div>
          <input
            type='radio'
            name='language'
            id='JP'
            value='JP'
            checked={languageMode === 'JP'}
            onChange={changeLanguage}
            className='mx-2'
          />
          <label>日本語</label>
        </div>
      </div>
    </>
  );
}
