import { Scripts } from '../../../../types';
import { CountryCodeSelectOption, UsePhoneInputResources } from './type';

const scripts: Scripts = {
  KR: [
    '휴대폰 번호를 입력해 주세요.',
    '유효하지 않은 전화번호입니다.',
    '휴대폰 번호',
    '인증번호 받기',
    '재전송',
    '인증완료',
  ],
  EN: [
    'Please enter your mobile phone number.',
    'Invalid phone number.',
    'Phone number',
    'Get a code',
    'Re-send',
    'Auth',
  ],
  JP: [
    '携帯電話番号を入力してください。',
    '無効な電話番号です。',
    '電話番号',
    '認証番号を取得',
    '再送信',
    '認証完了',
  ],
};

const countryCodeSelectOptions: CountryCodeSelectOption[] = [
  {
    name: 'Algeria',
    countryCode: 'DZ',
    numberCode: 213,
  },
  {
    name: 'Andorra',
    countryCode: 'AD',
    numberCode: 376,
  },
  {
    name: 'Angola',
    countryCode: 'AO',
    numberCode: 244,
  },
  {
    name: 'Anguilla',
    countryCode: 'AI',
    numberCode: 1264,
  },
  {
    name: 'Anguilla',
    countryCode: 'AI',
    numberCode: 1264,
  },
  {
    name: 'Antigua & Barbuda',
    countryCode: 'AG',
    numberCode: 1268,
  },
  {
    name: 'Argentina',
    countryCode: 'AR',
    numberCode: 54,
  },
  {
    name: 'Armenia',
    countryCode: 'AM',
    numberCode: 374,
  },
  {
    name: 'Aruba',
    countryCode: 'AW',
    numberCode: 297,
  },
  {
    name: 'Australia',
    countryCode: 'AU',
    numberCode: 61,
  },
  {
    name: 'Austria',
    countryCode: 'AT',
    numberCode: 43,
  },
  {
    name: 'Azerbaijan',
    countryCode: 'AZ',
    numberCode: 994,
  },
  {
    name: 'Bahamas',
    countryCode: 'BS',
    numberCode: 1242,
  },
  {
    name: 'Bahrain',
    countryCode: 'BH',
    numberCode: 973,
  },
  {
    name: 'Bangladesh',
    countryCode: 'BD',
    numberCode: 880,
  },
  {
    name: 'Barbados',
    countryCode: 'BB',
    numberCode: 1246,
  },
  {
    name: 'Belarus',
    countryCode: 'BY',
    numberCode: 375,
  },
  {
    name: 'Belgium',
    countryCode: 'BE',
    numberCode: 32,
  },
  {
    name: 'Belize',
    countryCode: 'BZ',
    numberCode: 501,
  },
  {
    name: 'Benin',
    countryCode: 'BJ',
    numberCode: 229,
  },
  {
    name: 'Bermuda',
    countryCode: 'BM',
    numberCode: 1441,
  },
  {
    name: 'Bhutan',
    countryCode: 'BT',
    numberCode: 975,
  },
  {
    name: 'Bolivia',
    countryCode: 'BO',
    numberCode: 591,
  },
  {
    name: 'Bosnia Herzegovina',
    countryCode: 'BA',
    numberCode: 387,
  },
  {
    name: 'Botswana',
    countryCode: 'BW',
    numberCode: 267,
  },
  {
    name: 'Brazil',
    countryCode: 'BR',
    numberCode: 55,
  },
  {
    name: 'Brunei',
    countryCode: 'BN',
    numberCode: 673,
  },
  {
    name: 'Bulgaria',
    countryCode: 'BG',
    numberCode: 359,
  },
  {
    name: 'Burkina Faso',
    countryCode: 'BF',
    numberCode: 226,
  },
  {
    name: 'Burundi',
    countryCode: 'BI',
    numberCode: 257,
  },
  {
    name: 'Cambodia',
    countryCode: 'KH',
    numberCode: 855,
  },
  {
    name: 'Cameroon',
    countryCode: 'CM',
    numberCode: 237,
  },
  {
    name: 'Canada',
    countryCode: 'CA',
    numberCode: 1,
  },
  {
    name: 'Cape Verde Islands',
    countryCode: 'CV',
    numberCode: 238,
  },
  {
    name: 'Cayman Islands',
    countryCode: 'KY',
    numberCode: 1345,
  },
  {
    name: 'Central African Republic',
    countryCode: 'CF',
    numberCode: 236,
  },
  {
    name: 'Chile',
    countryCode: 'CL',
    numberCode: 56,
  },
  {
    name: 'China',
    countryCode: 'CN',
    numberCode: 86,
  },
  {
    name: 'Colombia',
    countryCode: 'CO',
    numberCode: 57,
  },
  {
    name: 'Comoros',
    countryCode: 'KM',
    numberCode: 269,
  },
  {
    name: 'Congo',
    countryCode: 'CG',
    numberCode: 242,
  },
  {
    name: 'Cook Islands',
    countryCode: 'CK',
    numberCode: 682,
  },
  {
    name: 'Costa Rica',
    countryCode: 'CR',
    numberCode: 506,
  },
  {
    name: 'Croatia',
    countryCode: 'HR',
    numberCode: 385,
  },
  {
    name: 'Cuba',
    countryCode: 'CU',
    numberCode: 53,
  },
  {
    name: 'Cyprus North',
    countryCode: 'CY',
    numberCode: 90392,
  },
  {
    name: 'Cyprus South',
    countryCode: 'CY',
    numberCode: 357,
  },
  {
    name: 'Czech Republic',
    countryCode: 'CZ',
    numberCode: 42,
  },
  {
    name: 'Denmark',
    countryCode: 'DK',
    numberCode: 45,
  },
  {
    name: 'Djibouti',
    countryCode: 'DJ',
    numberCode: 253,
  },
  {
    name: 'Dominica',
    countryCode: 'DM',
    numberCode: 1809,
  },
  {
    name: 'Dominican Republic',
    countryCode: 'DO',
    numberCode: 1809,
  },
  {
    name: 'Ecuador',
    countryCode: 'EC',
    numberCode: 593,
  },
  {
    name: 'Egypt',
    countryCode: 'EG',
    numberCode: 20,
  },
  {
    name: 'El Salvador',
    countryCode: 'SV',
    numberCode: 503,
  },
  {
    name: 'Equatorial Guinea',
    countryCode: 'GQ',
    numberCode: 240,
  },
  {
    name: 'Eritrea',
    countryCode: 'ER',
    numberCode: 291,
  },
  {
    name: 'Estonia',
    countryCode: 'EE',
    numberCode: 372,
  },
  {
    name: 'Ethiopia',
    countryCode: 'ET',
    numberCode: 251,
  },
  {
    name: 'Falkland Islands',
    countryCode: 'FK',
    numberCode: 500,
  },
  {
    name: 'Faroe Islands',
    countryCode: 'FO',
    numberCode: 298,
  },
  {
    name: 'Fiji',
    countryCode: 'FJ',
    numberCode: 679,
  },
  {
    name: 'Finland',
    countryCode: 'FI',
    numberCode: 358,
  },
  {
    name: 'France',
    countryCode: 'FR',
    numberCode: 33,
  },
  {
    name: 'French Guiana',
    countryCode: 'GF',
    numberCode: 594,
  },
  {
    name: 'French Polynesia',
    countryCode: 'PF',
    numberCode: 689,
  },
  {
    name: 'Gabon',
    countryCode: 'GA',
    numberCode: 241,
  },
  {
    name: 'Gambia',
    countryCode: 'GM',
    numberCode: 220,
  },
  {
    name: 'Georgia',
    countryCode: 'GE',
    numberCode: 7880,
  },
  {
    name: 'Germany',
    countryCode: 'DE',
    numberCode: 49,
  },
  {
    name: 'Ghana',
    countryCode: 'GH',
    numberCode: 233,
  },
  {
    name: 'Gibraltar',
    countryCode: 'GI',
    numberCode: 350,
  },
  {
    name: 'Greece',
    countryCode: 'GR',
    numberCode: 30,
  },
  {
    name: 'Greenland',
    countryCode: 'GL',
    numberCode: 299,
  },
  {
    name: 'Grenada',
    countryCode: 'GD',
    numberCode: 1473,
  },
  {
    name: 'Guadeloupe',
    countryCode: 'GP',
    numberCode: 590,
  },
  {
    name: 'Guam',
    countryCode: 'GU',
    numberCode: 671,
  },
  {
    name: 'Guatemala',
    countryCode: 'GT',
    numberCode: 502,
  },
  {
    name: 'Guinea',
    countryCode: 'GN',
    numberCode: 224,
  },
  {
    name: 'Guinea - Bissau',
    countryCode: 'GN',
    numberCode: 245,
  },
  {
    name: 'Guyana',
    countryCode: 'GY',
    numberCode: 592,
  },
  {
    name: 'Haiti',
    countryCode: 'HT',
    numberCode: 509,
  },
  {
    name: 'Honduras',
    countryCode: 'HN',
    numberCode: 504,
  },
  {
    name: 'Hong Kong',
    countryCode: 'HK',
    numberCode: 852,
  },
  {
    name: 'Hungary',
    countryCode: 'HU',
    numberCode: 36,
  },
  {
    name: 'Iceland',
    countryCode: 'IS',
    numberCode: 354,
  },
  {
    name: 'India',
    countryCode: 'IN',
    numberCode: 91,
  },
  {
    name: 'Indonesia',
    countryCode: 'ID',
    numberCode: 62,
  },
  {
    name: 'Iran',
    countryCode: 'IR',
    numberCode: 98,
  },
  {
    name: 'Iraq',
    countryCode: 'IQ',
    numberCode: 964,
  },
  {
    name: 'Ireland',
    countryCode: 'IE',
    numberCode: 353,
  },
  {
    name: 'Israel',
    countryCode: 'IL',
    numberCode: 972,
  },
  {
    name: 'Italy',
    countryCode: 'IT',
    numberCode: 39,
  },
  {
    name: 'Jamaica',
    countryCode: 'JM',
    numberCode: 1876,
  },
  {
    name: 'Japan',
    countryCode: 'JP',
    numberCode: 81,
  },
  {
    name: 'Jordan',
    countryCode: 'JO',
    numberCode: 962,
  },
  {
    name: 'Kazakhstan',
    countryCode: 'KZ',
    numberCode: 7,
  },
  {
    name: 'Kenya',
    countryCode: 'KE',
    numberCode: 254,
  },
  {
    name: 'Kiribati',
    countryCode: 'KI',
    numberCode: 686,
  },
  {
    name: 'Korea North',
    countryCode: 'KP',
    numberCode: 850,
  },
  {
    name: 'Korea South',
    countryCode: 'KR',
    numberCode: 82,
  },
  {
    name: 'Kuwait',
    countryCode: 'KW',
    numberCode: 965,
  },
  {
    name: 'Kyrgyzstan',
    countryCode: 'KG',
    numberCode: 996,
  },
  {
    name: 'Laos',
    countryCode: 'LA',
    numberCode: 856,
  },
  {
    name: 'Latvia',
    countryCode: 'LV',
    numberCode: 371,
  },
  {
    name: 'Lebanon',
    countryCode: 'LB',
    numberCode: 961,
  },
  {
    name: 'Lesotho',
    countryCode: 'LS',
    numberCode: 266,
  },
  {
    name: 'Liberia',
    countryCode: 'LR',
    numberCode: 231,
  },
  {
    name: 'Libya',
    countryCode: 'LY',
    numberCode: 218,
  },
  {
    name: 'Liechtenstein',
    countryCode: 'LI',
    numberCode: 417,
  },
  {
    name: 'Lithuania',
    countryCode: 'LT',
    numberCode: 370,
  },
  {
    name: 'Luxembourg',
    countryCode: 'LU',
    numberCode: 352,
  },
  {
    name: 'Macao',
    countryCode: 'MO',
    numberCode: 853,
  },
  {
    name: 'Macedonia',
    countryCode: 'MK',
    numberCode: 389,
  },
  {
    name: 'Madagascar',
    countryCode: 'MG',
    numberCode: 261,
  },
  {
    name: 'Malawi',
    countryCode: 'MW',
    numberCode: 265,
  },
  {
    name: 'Malaysia',
    countryCode: 'MY',
    numberCode: 60,
  },
  {
    name: 'Maldives',
    countryCode: 'MV',
    numberCode: 960,
  },
  {
    name: 'Mali',
    countryCode: 'ML',
    numberCode: 223,
  },
  {
    name: 'Malta',
    countryCode: 'MT',
    numberCode: 356,
  },
  {
    name: 'Marshall Islands',
    countryCode: 'MH',
    numberCode: 692,
  },
  {
    name: 'Martinique',
    countryCode: 'MQ',
    numberCode: 596,
  },
  {
    name: 'Mauritania',
    countryCode: 'MR',
    numberCode: 222,
  },
  {
    name: 'Mayotte',
    countryCode: 'YT',
    numberCode: 269,
  },
  {
    name: 'Mexico',
    countryCode: 'MX',
    numberCode: 52,
  },
  {
    name: 'Micronesia',
    countryCode: 'FM',
    numberCode: 691,
  },
  {
    name: 'Moldova',
    countryCode: 'MD',
    numberCode: 373,
  },
  {
    name: 'Monaco',
    countryCode: 'MC',
    numberCode: 377,
  },
  {
    name: 'Mongolia',
    countryCode: 'MN',
    numberCode: 976,
  },
  {
    name: 'Montserrat',
    countryCode: 'MS',
    numberCode: 1664,
  },
  {
    name: 'Morocco',
    countryCode: 'MA',
    numberCode: 212,
  },
  {
    name: 'Mozambique',
    countryCode: 'MZ',
    numberCode: 258,
  },
  {
    name: 'Myanmar',
    countryCode: 'MN',
    numberCode: 95,
  },
  {
    name: 'Namibia',
    countryCode: 'NA',
    numberCode: 264,
  },
  {
    name: 'Nauru',
    countryCode: 'NR',
    numberCode: 674,
  },
  {
    name: 'Nepal',
    countryCode: 'NP',
    numberCode: 977,
  },
  {
    name: 'Netherlands',
    countryCode: 'NL',
    numberCode: 31,
  },
  {
    name: 'New Caledonia',
    countryCode: 'NC',
    numberCode: 687,
  },
  {
    name: 'New Zealand',
    countryCode: 'NZ',
    numberCode: 64,
  },
  {
    name: 'Nicaragua',
    countryCode: 'NI',
    numberCode: 505,
  },
  {
    name: 'Niger',
    countryCode: 'NE',
    numberCode: 227,
  },
  {
    name: 'Nigeria',
    countryCode: 'NG',
    numberCode: 234,
  },
  {
    name: 'Niue',
    countryCode: 'NU',
    numberCode: 683,
  },
  {
    name: 'Norfolk Islands',
    countryCode: 'NF',
    numberCode: 672,
  },
  {
    name: 'Northern Marianas',
    countryCode: 'NP',
    numberCode: 670,
  },
  {
    name: 'Norway',
    countryCode: 'NO',
    numberCode: 47,
  },
  {
    name: 'Oman',
    countryCode: 'OM',
    numberCode: 968,
  },
  {
    name: 'Palau',
    countryCode: 'PW',
    numberCode: 680,
  },
  {
    name: 'Panama',
    countryCode: 'PA',
    numberCode: 507,
  },
  {
    name: 'Papua New Guinea',
    countryCode: 'PG',
    numberCode: 675,
  },
  {
    name: 'Paraguay',
    countryCode: 'PY',
    numberCode: 595,
  },
  {
    name: 'Peru',
    countryCode: 'PE',
    numberCode: 51,
  },
  {
    name: 'Philippines',
    countryCode: 'PH',
    numberCode: 63,
  },
  {
    name: 'Poland',
    countryCode: 'PL',
    numberCode: 48,
  },
  {
    name: 'Portugal',
    countryCode: 'PT',
    numberCode: 351,
  },
  {
    name: 'Puerto Rico',
    countryCode: 'PR',
    numberCode: 1787,
  },
  {
    name: 'Qatar',
    countryCode: 'QA',
    numberCode: 974,
  },
  {
    name: 'Reunion',
    countryCode: 'RE',
    numberCode: 262,
  },
  {
    name: 'Romania',
    countryCode: 'RO',
    numberCode: 40,
  },
  {
    name: 'Russia',
    countryCode: 'RU',
    numberCode: 7,
  },
  {
    name: 'Rwanda',
    countryCode: 'RW',
    numberCode: 250,
  },
  {
    name: 'San Marino',
    countryCode: 'SM',
    numberCode: 378,
  },
  {
    name: 'Sao Tome & Principe',
    countryCode: 'ST',
    numberCode: 239,
  },
  {
    name: 'Saudi Arabia',
    countryCode: 'SA',
    numberCode: 966,
  },
  {
    name: 'Senegal',
    countryCode: 'SN',
    numberCode: 221,
  },
  {
    name: 'Serbia',
    countryCode: 'CS',
    numberCode: 381,
  },
  {
    name: 'Seychelles',
    countryCode: 'SC',
    numberCode: 248,
  },
  {
    name: 'Sierra Leone',
    countryCode: 'SL',
    numberCode: 232,
  },
  {
    name: 'Singapore',
    countryCode: 'SG',
    numberCode: 65,
  },
  {
    name: 'Slovak Republic',
    countryCode: 'SK',
    numberCode: 421,
  },
  {
    name: 'Slovenia',
    countryCode: 'SI',
    numberCode: 386,
  },
  {
    name: 'Solomon Islands',
    countryCode: 'SB',
    numberCode: 677,
  },
  {
    name: 'Somalia',
    countryCode: 'SO',
    numberCode: 252,
  },
  {
    name: 'South Africa',
    countryCode: 'ZA',
    numberCode: 27,
  },
  {
    name: 'South Africa',
    countryCode: 'ZA',
    numberCode: 27,
  },
  {
    name: 'Spain',
    countryCode: 'ES',
    numberCode: 34,
  },
  {
    name: 'Sri Lanka',
    countryCode: 'ES',
    numberCode: 94,
  },
  {
    name: 'St. Helena',
    countryCode: 'SH',
    numberCode: 290,
  },
  {
    name: 'St. Kitts',
    countryCode: 'KN',
    numberCode: 1869,
  },
  {
    name: 'St. Lucia',
    countryCode: 'SC',
    numberCode: 1758,
  },
  {
    name: 'Sudan',
    countryCode: 'SD',
    numberCode: 249,
  },
  {
    name: 'Sudan',
    countryCode: 'SD',
    numberCode: 249,
  },
  {
    name: 'Suriname',
    countryCode: 'SR',
    numberCode: 597,
  },
  {
    name: 'Swaziland',
    countryCode: 'SZ',
    numberCode: 268,
  },
  {
    name: 'Sweden',
    countryCode: 'SE',
    numberCode: 46,
  },
  {
    name: 'Switzerland',
    countryCode: 'CH',
    numberCode: 41,
  },
  {
    name: 'Syria',
    countryCode: 'SI',
    numberCode: 963,
  },
  {
    name: 'Taiwan',
    countryCode: 'TW',
    numberCode: 886,
  },
  {
    name: 'Tajikstan',
    countryCode: 'TJ',
    numberCode: 7,
  },
  {
    name: 'Thailand',
    countryCode: 'TH',
    numberCode: 66,
  },
  {
    name: 'Togo',
    countryCode: 'TG',
    numberCode: 228,
  },
  {
    name: 'Tonga',
    countryCode: 'TO',
    numberCode: 676,
  },
  {
    name: 'Trinidad & Tobago',
    countryCode: 'TT',
    numberCode: 1868,
  },
  {
    name: 'Tunisia',
    countryCode: 'TN',
    numberCode: 216,
  },
  {
    name: 'Turkey',
    countryCode: 'TR',
    numberCode: 90,
  },
  {
    name: 'Turkmenistan',
    countryCode: 'TM',
    numberCode: 7,
  },
  {
    name: 'Turkmenistan',
    countryCode: 'TM',
    numberCode: 993,
  },
  {
    name: 'Turks & Caicos Islands',
    countryCode: 'TC',
    numberCode: 1649,
  },
  {
    name: 'Tuvalu',
    countryCode: 'TV',
    numberCode: 688,
  },
  {
    name: 'Uganda',
    countryCode: 'UG',
    numberCode: 256,
  },
  {
    name: 'UK',
    countryCode: 'GB',
    numberCode: 44,
  },
  {
    name: 'Ukraine',
    countryCode: 'UA',
    numberCode: 380,
  },
  {
    name: 'United Arab Emirates',
    countryCode: 'AE',
    numberCode: 971,
  },
  {
    name: 'Uruguay',
    countryCode: 'UY',
    numberCode: 598,
  },
  {
    name: 'USA',
    countryCode: 'US',
    numberCode: 1,
  },
  {
    name: 'Uzbekistan',
    countryCode: 'UZ',
    numberCode: 7,
  },
  {
    name: 'Vanuatu',
    countryCode: 'VU',
    numberCode: 678,
  },
  {
    name: 'Vatican City',
    countryCode: 'VA',
    numberCode: 379,
  },
  {
    name: 'Venezuela',
    countryCode: 'VE',
    numberCode: 58,
  },
  {
    name: 'Vietnam',
    countryCode: 'VN',
    numberCode: 84,
  },
  {
    name: 'Virgin Islands - British',
    countryCode: 'VG',
    numberCode: 1284,
  },
  {
    name: 'Virgin Islands - US',
    countryCode: 'VI',
    numberCode: 1340,
  },
  {
    name: 'Wallis & Futuna',
    countryCode: 'WF',
    numberCode: 681,
  },
  {
    name: 'Yemen (North)',
    countryCode: 'YE',
    numberCode: 969,
  },
  {
    name: 'Yemen (South)',
    countryCode: 'YE',
    numberCode: 967,
  },
  {
    name: 'Zambia',
    countryCode: 'ZM',
    numberCode: 260,
  },
  {
    name: 'Zimbabwe',
    countryCode: 'ZW',
    numberCode: 263,
  },
];

const usePhoneInputResources = (): UsePhoneInputResources => {
  return {
    scripts,
    countryCodeSelectOptions,
  };
};

export default usePhoneInputResources;
