import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import useModal from 'hooks/useModal';
import { updateCharacter } from 'apis/index';
import { CharacterSchema } from 'apis/types';

import CustomAvatar from 'react3fiber/custom-avatar';

import { languageModeAtom, navbarTypeAtom, tokenAtom, userAtom } from 'src/atoms';

const scripts = {
  KR: [
    '캐릭터 설정',
    '저장',
    '헤어',
    '표정',
    '상의',
    '하의',
    '신발',
    '변경사항이 저장되었습니다.',
    '실패했습니다.',
  ],
  EN: [
    'Character Settings',
    'Save',
    'Hair',
    'Face',
    'Top',
    'Bottoms',
    'Shoes',
    'Your changes have been saved.',
    'You have been failed.',
  ],
  JP: [
    'キャラクター設定',
    '保存',
    'ヘア',
    '表情',
    '上着',
    '下衣',
    '靴',
    '変更が保存されました。',
    'あなたは失敗しました。',
  ],
};

type PieceType = 'hair' | 'face' | 'top' | 'bottom' | 'shoes';
type Gender = 'M' | 'F';
type PartUrls = {
  hair: string[];
  face: string[];
  top: string[];
  bottom: string[];
  shoes: string[];
};

export default function Avatar() {
  const user = useAtomValue(userAtom);
  const [customOptions, setCustomOptions] = useState<{
    type: string;
    hair: number;
    face: number;
    top: number;
    bottom: number;
    shoes: number;
  }>({
    type: user?.characters.type,
    hair: user?.characters.hair,
    face: user?.characters.face,
    top: user?.characters.top,
    bottom: user?.characters.bottom,
    shoes: user?.characters.shoes,
  });
  const [customOptionsFemail, setCustomOptionsFemail] = useState<{
    type: string;
    hair: number;
    face: number;
    top: number;
    bottom: number;
    shoes: number;
  }>({
    type: user?.characters.type,
    hair: user?.characters.hair,
    face: user?.characters.face,
    top: user?.characters.top,
    bottom: user?.characters.bottom,
    shoes: user?.characters.shoes,
  });
  const [genderOption, setGenderOption] = useState<string>('M');
  const [option, setOption] = useState<PieceType>('hair');

  let token = useAtomValue(tokenAtom);
  let [navbarType, setNavbarType] = useAtom(navbarTypeAtom);
  let languageMode = useAtomValue(languageModeAtom);
  let refreshUser = useSetAtom(userAtom);
  let navigate = useNavigate();
  const { openAlertModal } = useModal();
  useEffect(() => {
    setNavbarType('character');
  });

  const imageUrls: {
    [key in Gender]: PartUrls;
  } = {
    M: {
      hair: [],
      face: [],
      top: [],
      bottom: [],
      shoes: [],
    },
    F: {
      hair: [],
      face: [],
      top: [],
      bottom: [],
      shoes: [],
    },
  };
  const imageUrl1 = new URL('../../../static/imgs/avatar-thumbnail/manhair1.png', import.meta.url);
  const imageUrl2 = new URL('../../../static/imgs/avatar-thumbnail/manhair2.png', import.meta.url);
  const imageUrl3 = new URL('../../../static/imgs/avatar-thumbnail/manface.png', import.meta.url);
  const imageUrl4 = new URL('../../../static/imgs/avatar-thumbnail/mantop1.png', import.meta.url);
  const imageUrl5 = new URL('../../../static/imgs/avatar-thumbnail/mantop2.png', import.meta.url);
  const imageUrl6 = new URL(
    '../../../static/imgs/avatar-thumbnail/manbottom1.png',
    import.meta.url
  );
  const imageUrl7 = new URL(
    '../../../static/imgs/avatar-thumbnail/manbottom2.png',
    import.meta.url
  );
  const imageUrl8 = new URL('../../../static/imgs/avatar-thumbnail/manshoes1.png', import.meta.url);
  const imageUrl9 = new URL('../../../static/imgs/avatar-thumbnail/manshoes2.png', import.meta.url);
  const imageUrl10 = new URL(
    '../../../static/imgs/avatar-thumbnail/womanhair1.png',
    import.meta.url
  );
  const imageUrl11 = new URL(
    '../../../static/imgs/avatar-thumbnail/womanhair2.png',
    import.meta.url
  );
  const imageUrl12 = new URL(
    '../../../static/imgs/avatar-thumbnail/womanface.png',
    import.meta.url
  );
  const imageUrl13 = new URL(
    '../../../static/imgs/avatar-thumbnail/womantop1.png',
    import.meta.url
  );
  const imageUrl14 = new URL(
    '../../../static/imgs/avatar-thumbnail/womantop2.png',
    import.meta.url
  );
  const imageUrl15 = new URL(
    '../../../static/imgs/avatar-thumbnail/womanbottom1.png',
    import.meta.url
  );
  const imageUrl16 = new URL(
    '../../../static/imgs/avatar-thumbnail/womanbottom2.png',
    import.meta.url
  );
  const imageUrl17 = new URL(
    '../../../static/imgs/avatar-thumbnail/womanshoes1.png',
    import.meta.url
  );
  const imageUrl18 = new URL(
    '../../../static/imgs/avatar-thumbnail/womanshoes2.png',
    import.meta.url
  );
  const imageUrl19 = new URL(
    '../../../static/imgs/avatar-thumbnail/womantop3.png',
    import.meta.url
  );
  const imageUrl20 = new URL(
    '../../../static/imgs/avatar-thumbnail/womanbottom3.png',
    import.meta.url
  );
  const imageUrl21 = new URL(
    '../../../static/imgs/avatar-thumbnail/womanshoes3.png',
    import.meta.url
  );
  const imageUrl22 = new URL(
    '../../../static/imgs/avatar-thumbnail/womantop4.png',
    import.meta.url
  );
  imageUrls['M']['hair'].push(imageUrl1.href, imageUrl2.href);
  imageUrls['M']['face'].push(imageUrl3.href);
  imageUrls['M']['top'].push(imageUrl4.href, imageUrl5.href);
  imageUrls['M']['bottom'].push(imageUrl6.href, imageUrl7.href);
  imageUrls['M']['shoes'].push(imageUrl8.href, imageUrl9.href);
  imageUrls['F']['hair'].push(imageUrl10.href, imageUrl11.href);
  imageUrls['F']['face'].push(imageUrl12.href);
  imageUrls['F']['top'].push(imageUrl13.href, imageUrl14.href, imageUrl19.href, imageUrl22.href);
  imageUrls['F']['bottom'].push(imageUrl15.href, imageUrl16.href, imageUrl20.href);
  imageUrls['F']['shoes'].push(imageUrl17.href, imageUrl18.href, imageUrl21.href);

  const [red, setRed] = useState<number>(1);

  useEffect(() => {
    (async () => {
      if (token && user && user.characters.type == 'M') {
        setCustomOptions(user.characters);
        setGenderOption('M');
      } else {
        setCustomOptionsFemail(user?.characters);
        setGenderOption('F');
      }
    })();
  }, [token, user]);

  return (
    <>
      {!token && <Navigate to='/login' />}
      <form className='lg:w-[940] lg:m-auto' style={{ height: '100%' }}>
        <div className='flex justify-between items-center h-11 lg:hidden'>
          <div>
            <button className='flex-initial p-2.5' onClick={() => navigate('/')}>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path d='M16 19.5L8 12L16 4.5' stroke='black' strokeLinecap='round' />
              </svg>
            </button>
          </div>
          <div className='text-center'>{scripts[languageMode][0]}</div>
          <button
            type='button'
            className='text-pink-700 p-2.5'
            style={{ alignItems: 'left' }}
            onClick={(e) => {
              e.preventDefault();
              (async () => {
                if (token && user) {
                  let character;
                  if (genderOption == 'M') {
                    character = CharacterSchema.parse(customOptions);
                  } else {
                    character = CharacterSchema.parse(customOptionsFemail);
                  }
                  try {
                    const result = await updateCharacter(token, user.username, character);
                    refreshUser();
                    result &&
                      openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][7] }]);
                  } catch (error) {
                    openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][8] }]);
                  }
                }
              })();
            }}>
            {scripts[languageMode][1]}
          </button>
        </div>
        <div className='flex justify-between h-1/2 bg-pink-100/30 py-3'>
          <div className='flex flex-col w-2/4'>
            <div style={{ height: '90%' }}>
              <CustomAvatar
                customOptions={customOptions}
                customOptionsFemail={customOptionsFemail}
                rotation={[0, 0, 0]}
                gender={'M'}></CustomAvatar>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>Male</div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <input
                className='hover:cursor-pointer focus:cursor-pointer'
                type='checkbox'
                name='gender'
                value='M'
                onChange={() => (
                  setCustomOptions({ ...customOptions, type: 'M' }),
                  setCustomOptionsFemail({ ...customOptionsFemail, type: 'M' }),
                  setGenderOption('M')
                )}
                checked={customOptions.type == 'M'}></input>
            </div>
          </div>
          <div className='flex flex-col w-2/4 relative'>
            <div style={{ height: '90%' }}>
              <CustomAvatar
                customOptions={customOptions}
                customOptionsFemail={customOptionsFemail}
                rotation={[-Math.PI / 2, 0, 0]}
                gender={'F'}></CustomAvatar>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginLeft: '20px' }}>
              Female
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginLeft: '20px' }}>
              <input
                className='hover:cursor-pointer focus:cursor-pointer'
                type='checkbox'
                name='gender'
                value='F'
                onChange={() => (
                  setCustomOptions({ ...customOptions, type: 'F' }),
                  setCustomOptionsFemail({ ...customOptionsFemail, type: 'F' }),
                  setGenderOption('F')
                )}
                checked={customOptions.type == 'F'}></input>
            </div>
            <button
              type='button'
              onClick={(e) => {
                e.preventDefault();
                (async () => {
                  if (token && user) {
                    let character;
                    if (genderOption == 'M') {
                      character = CharacterSchema.parse(customOptions);
                    } else {
                      character = CharacterSchema.parse(customOptionsFemail);
                    }
                    const success = await updateCharacter(token, user.username, character);
                    refreshUser();
                    success &&
                      openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][7] }]);
                  }
                })();
              }}
              className='hidden text-white px-[41] py-1 text-base bg-pink-700 rounded-md lg:flex lg:absolute lg:right-0 lg:top-0'
              style={{ alignItems: 'left' }}>
              {scripts[languageMode][1]}
            </button>
          </div>
        </div>
        <div className='flex justify-around text-sm'>
          <div
            className='flex-auto p-2 text-center hover:cursor-pointer'
            onClick={() => {
              setOption('hair');
              setRed(1);
            }}>
            <span
              className={red === 1 ? ' text-red-500' : ' text-black'}
              onClick={() => {
                setRed(1);
              }}>
              {scripts[languageMode][2]}
            </span>
          </div>
          <div
            className='flex-auto p-2 text-center hover:cursor-pointer'
            onClick={() => {
              setOption('face');
              setRed(2);
            }}>
            <span className={red === 2 ? ' text-red-500' : ' text-black'}>
              {scripts[languageMode][3]}
            </span>
          </div>
          <div
            className='flex-auto p-2 text-center hover:cursor-pointer'
            onClick={() => {
              setOption('top');
              setRed(3);
            }}>
            <span className={red === 3 ? ' text-red-500' : ' text-black'}>
              {scripts[languageMode][4]}
            </span>
          </div>
          <div
            className='flex-auto p-2 text-center hover:cursor-pointer'
            onClick={() => {
              setOption('bottom');
              setRed(4);
            }}>
            <span className={red === 4 ? ' text-red-500' : ' text-black'}>
              {scripts[languageMode][5]}
            </span>
          </div>
          <div
            className='flex-auto p-2 text-center  hover:cursor-pointer'
            onClick={() => {
              setOption('shoes');
              setRed(5);
            }}>
            <span className={red === 5 ? ' text-red-500' : ' text-black'}>
              {scripts[languageMode][6]}
            </span>
          </div>
        </div>
        <div className='grid grid-cols-4 lg:grid-cols-8 xl:grid-cols-8 gap-2 mt-2 px-2'>
          <div
            className='w-full h-full bg-cover bg-no-repeat aspect-square rounded-md hover:cursor-pointer'
            style={{ backgroundImage: `url(${'"' + imageUrls[genderOption][option][0] + '"'})` }}
            onClick={() => {
              let currentOptions;
              if (genderOption == 'M') {
                currentOptions = customOptions;
              } else {
                currentOptions = customOptionsFemail;
              }
              currentOptions[option] = 1;
              if (genderOption == 'M') {
                setCustomOptions({ ...currentOptions });
              } else {
                setCustomOptionsFemail({ ...currentOptions });
              }
            }}></div>

          <div
            className='w-full h-full bg-cover bg-no-repeat aspect-square rounded-md hover:cursor-pointer'
            style={{ backgroundImage: `url(${'"' + imageUrls[genderOption][option][1] + '"'})` }}
            onClick={() => {
              let currentOptions;
              if (genderOption == 'M') {
                currentOptions = customOptions;
              } else {
                currentOptions = customOptionsFemail;
              }
              currentOptions[option] = 2;

              if (genderOption == 'M') {
                setCustomOptions({ ...currentOptions });
              } else {
                setCustomOptionsFemail({ ...currentOptions });
              }
            }}></div>
          <div
            className='w-full h-full bg-cover bg-no-repeat aspect-square rounded-md hover:cursor-pointer'
            style={{ backgroundImage: `url(${'"' + imageUrls[genderOption][option][2] + '"'})` }}
            onClick={() => {
              let currentOptions;
              if (genderOption == 'M') {
                currentOptions = customOptions;
              } else {
                currentOptions = customOptionsFemail;
              }
              currentOptions[option] = 3;

              if (genderOption == 'M') {
                setCustomOptions({ ...currentOptions });
              } else {
                setCustomOptionsFemail({ ...currentOptions });
              }
            }}></div>
          <div
            className='w-full h-full bg-cover bg-no-repeat aspect-square rounded-md hover:cursor-pointer'
            style={{ backgroundImage: `url(${'"' + imageUrls[genderOption][option][3] + '"'})` }}
            onClick={() => {
              let currentOptions;
              if (genderOption == 'M') {
                currentOptions = customOptions;
              } else {
                currentOptions = customOptionsFemail;
              }
              currentOptions[option] = 4;

              if (genderOption == 'M') {
                setCustomOptions({ ...currentOptions });
              } else {
                setCustomOptionsFemail({ ...currentOptions });
              }
            }}></div>
        </div>
      </form>
    </>
  );
}
