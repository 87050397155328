import { useMemo, useEffect } from 'react';
import { useAnimations, Environment, Sky } from '@react-three/drei';
import * as THREE from 'three';
import { useMediaQuery } from 'react-responsive';
import { SkeletonUtils } from 'three-stdlib';
import { MeshPhysicalMaterial } from 'three';

import { useCompressedGLTF } from 'hooks/hooks';

let url = new URL('../../../static/models/adler_grass_theme222222.min.glb', import.meta.url);
let url2 = new URL('../../../static/models/adler_grass_theme_rock.min.glb', import.meta.url);
let url3 = new URL('../../../static/models/adler_grass_theme_outliner.min.glb', import.meta.url);

const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]';
  })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
const mediaMatch = window.matchMedia('(min-width: 1024px)');
export default function FlowerThema({}: {}) {
  let { scene } = useCompressedGLTF(url.href);
  let clonedScene = useMemo(() => SkeletonUtils.clone(scene), [scene]);
  const disposeMesh: THREE.Mesh[] = [];
  const disposeTexture: THREE.Texture[] = [];

  clonedScene.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      let mesh = object as THREE.Mesh;
      mesh.material = mesh.material as THREE.Material;
      mesh.receiveShadow = true;
      mesh.material.toneMapped = false;
      const mat = mesh.material as THREE.Material;
      disposeMesh.push(mesh);
      if (mat.map !== null) {
        disposeTexture.push(mat.map);
      }
    }
  });
  let { scene: scene2, animations } = useCompressedGLTF(url2.href);
  let clonedScene2 = useMemo(() => SkeletonUtils.clone(scene2), [scene2]);
  let { ref: ref, actions: actions, names: names } = useAnimations(animations);

  clonedScene2.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      let mesh = object as THREE.Mesh;
      let glow = new MeshPhysicalMaterial({
        thickness: 5,
        roughness: 0,
        clearcoat: 1,
        clearcoatRoughness: 0,
        transmission: 1.025,
        ior: 1.25,
        envMapIntensity: 10,
        color: '#ffffff',
        toneMapped: 'false',
      });
      let mat = (mesh.material as THREE.Material).clone();
      {
        mediaMatch.matches === false || isSafari
          ? ((mesh.material.opacity = 0.2), (mesh.material.transparent = true))
          : (mesh.material = glow);
      }

      disposeMesh.push(mesh);
      if (mat.map !== null) {
        disposeTexture.push(mat.map);
      }
    }
  });
  useEffect(() => {
    for (let i = 0; i < names.length; i++) {
      actions[names[i]]?.reset().fadeIn(0.5).play();
    }
  }, [actions, names]);

  let { scene: scene3 } = useCompressedGLTF(url3.href);
  let clonedScene3 = useMemo(() => SkeletonUtils.clone(scene3), [scene3]);

  clonedScene3.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      let mesh = object as THREE.Mesh;
      mesh.material = mesh.material as THREE.Material;
      mesh.receiveShadow = true;
      mesh.material.toneMapped = false;
      const mat = mesh.material as THREE.Material;
      disposeMesh.push(mesh);
      if (mat.map !== null) {
        disposeTexture.push(mat.map);
      }
    }
  });
  useEffect(() => {
    return () => {
      for (let i = 0; i < disposeMesh.length; i++) {
        if (disposeMesh[i]) disposeMesh[i].geometry.dispose();
      }
      for (let i = 0; i < disposeTexture.length; i++) {
        if (disposeTexture[i]) disposeTexture[i].dispose();
      }
    };
  }, []);
  return (
    <>
      <Environment background resolution={32}>
        <Sky />
      </Environment>
      <group position={[0, -2, 0.9]} scale={1} rotation={[0, 0, 0]}>
        <primitive object={clonedScene} />
        {mediaMatch.matches === false && isSafari ? null : (
          <group ref={ref}>
            <primitive object={clonedScene2} />
          </group>
        )}
        <primitive object={clonedScene3} />
      </group>
    </>
  );
}
