import { Space } from 'apis/types';
import { useAtomValue } from 'jotai';
import { useCallback, useRef, useState } from 'react';
import { languageModeAtom } from 'src/atoms';
import {
  ModalNavbarContainer,
  ModalNavbarSide,
  URLInput,
  URLInputContainer,
  URLModalContainer,
  CopyButton,
} from './styles';

import closeIcon from 'static/icons/close.svg';
import styled from 'styled-components';
import Alert from 'components/Commons/alert';

const scripts = {
  KR: ['공유', '복사', '클립보드에 복사되었습니다.', '복사에 실패했습니다.'],
  EN: ['share', 'copy', 'copied to the clipboard', 'copy failed'],
  JP: ['シェア', 'コピー', 'クリップボードにコピーされました。', 'コピーに失敗しました'],
};

const CloseIcon = styled.img.attrs({ src: closeIcon })`
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.375rem;
  &:hover {
    cursor: pointer;
  }
`;

interface URLModalProps {
  space: Space;
  closeModal: (hasAction?: boolean | undefined) => void;
}

export default function URLModal({ space, closeModal }: URLModalProps) {
  const languageMode = useAtomValue(languageModeAtom);
  const [alertMessages, setAlertMessages] = useState<string[] | null>(null);
  const URLInputRef = useRef<HTMLInputElement>(null);

  const copyURL = useCallback(async () => {
    if (URLInputRef.current) {
      try {
        await navigator.clipboard.writeText(URLInputRef.current.value);
        setAlertMessages([scripts[languageMode][2]]);
      } catch (error) {
        setAlertMessages([scripts[languageMode][3]]);
      }
    }
  }, [URLInputRef.current]);

  return (
    <URLModalContainer>
      <ModalNavbarContainer>
        <ModalNavbarSide />
        {scripts[languageMode][0]}
        <ModalNavbarSide>
          <CloseIcon
            onClick={() => {
              closeModal();
            }}
          />
        </ModalNavbarSide>
      </ModalNavbarContainer>
      <URLInputContainer>
        <URLInput type='text' defaultValue={window.location.origin + '/s/' + space.id} ref={URLInputRef} readOnly />
        <CopyButton
          onClick={() => {
            setAlertMessages(null);
            copyURL();
          }}>
          {scripts[languageMode][1]}
        </CopyButton>
      </URLInputContainer>
      {alertMessages && <Alert text={alertMessages} />}
    </URLModalContainer>
  );
}
