import styled from 'styled-components';
import Heart from 'static/icons/heart_off.svg';
import HeartOn from 'static/icons/heart_on.svg';
import Union from 'static/icons/union.svg';
import shareIcon from 'static/icons/share.svg';
import { Color } from 'src/constants';

export const Modals = styled.div`
  width: 2.5rem;
  height: 10.25rem;
  padding-left: 0.0625rem;
  padding-top: 0.5rem;
  padding-right: 0.0625rem;
  padding-bottom: 0.5rem;

  text-align: center;
`;

export const TextModal = styled.div`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.3rem;
  text-align: center;

  align-items: center;
  letter-spacing: 0.0656px;

  /* Grayscale/w */
  color: #ffffff;
`;

export const SvgModalButton = styled.button`
  height: 1.5rem;
`;

export const HeartIcon = styled.img.attrs<{ isLike: boolean }>(({ isLike }) => ({
  src: isLike ? HeartOn : Heart,
}))<{
  isLike: boolean;
}>`
  width: 1.5rem;
  height: 1.5rem;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const ModalContainer = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  left: 20.4375rem;
  top: 32.0625rem;
  padding: 0.0625rem 0.5rem;
  gap: 0.0625rem;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const UnionTextModal = styled.div`
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.0625rem;
  text-align: center;

  align-items: center;
  letter-spacing: 0.0656px;

  /* Grayscale/w */
  color: ${Color.white};
`;

export const UnionIcon = styled.img.attrs({ src: Union })`
  width: 1.5rem;
  height: 1.5rem;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const ShareIcon = styled.img.attrs({ src: shareIcon })`
  width: 1.5rem;
  height: 1.5rem;

  &:hover {
    cursor: pointer;
  }
`;
