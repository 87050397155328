import React from 'react';
import { useCallback, useMemo } from 'react';

import { ConfirmModalContainer, Content, FirstButton, SecondButton, Title } from './styles';
import { ConfirmModalProps } from './types';

function ConfirmModal({ title, contents, firstButton, secondButton }: ConfirmModalProps): JSX.Element {
  const onFirstButtonClick = useCallback(() => {
    firstButton.onClick();
  }, [firstButton]);

  const onSecondButtonClick = useCallback(() => {
    secondButton.onClick();
  }, [secondButton]);

  const contentMessage = useMemo(
    () =>
      contents.map((content, index) => {
        if (content.type === 'NORMAL') return <React.Fragment key={index}>{content.message}</React.Fragment>;
        else if (content.type === 'IMPORTANT') return <span key={index}>{content.message}</span>;
        else return <br key={index} />;
      }),
    [contents]
  );

  return (
    <ConfirmModalContainer>
      {title && <Title>{title}</Title>}
      <Content>{contentMessage}</Content>
      <FirstButton onClick={onFirstButtonClick}>{firstButton.text}</FirstButton>
      <SecondButton onClick={onSecondButtonClick}>{secondButton.text}</SecondButton>
    </ConfirmModalContainer>
  );
}

export default ConfirmModal;
