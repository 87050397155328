import styled from 'styled-components';

import { BreakPoint } from 'src/constants';

export const TermsOfServiceContinaer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  li {
    padding-left: 0.5rem;
    display: list-item;
    list-style-position: inside;
  }
`;

export const TermsOfServiceArea = styled.div`
  width: 64rem;
  margin-bottom: 3.75rem;

  ${BreakPoint.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 86.66%;
  }
`;

export const Title = styled.div`
  width: 100%;
  margin-top: 3.75rem;
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;

  ${BreakPoint.mobile} {
    font-size: 1.25rem;
  }
`;

export const SubTitle = styled.div`
  width: 100%;
  margin-top: 1.25rem;
  font-weight: 400;
  font-size: 0.875rem;
  text-align: center;

  ${BreakPoint.mobile} {
    width: 66%;
    font-size: 0.875rem;
  }
`;

export const DivisionBorder = styled.div`
  width: 100%;
  margin: 1.5rem 0;
  border: 1px solid #db1f72;
`;

export const IndexText = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;
  font-weight: 500;

  ${BreakPoint.mobile} {
    font-size: 0.875rem;
  }
`;

export const ContentText = styled.div`
  width: 100%;
  margin-bottom: 1.25rem;
  font-size: 0.875rem;
  font-weight: 400;

  ${BreakPoint.mobile} {
    font-size: 0.75rem;
  }
`;

export const NewWindowLink = styled.div`
  display: inline-block;
  font-weight: 500;
  color: #db1f72;

  &:hover {
    cursor: pointer;
  }
`;
