import * as THREE from 'three';
import { useEffect, useRef } from 'react';
import { useTexture } from '@react-three/drei';
import GifLoader from 'three-gif-loader';

import Frame from 'react3fiber/frame';

import { Item } from 'apis/index';

export default function ImageItem({ item }: { item: Item }) {
  const texture = useTexture(item.file.includes('blob') ? item.file : item.file + '?');
  const ref = useRef<THREE.MeshBasicMaterial>(null);
  const ref2 = useRef<THREE.MeshBasicMaterial>(null);

  const loader = new GifLoader();

  useEffect(() => {
    if (item.file.includes('gif') && ref.current) {
      ref.current.map = loader.load(
        // resource URL
        item.file.includes('blob') ? item.file : item.file + '?',
        // onLoad callback
        function (reader) {
          // You probably don't need to set onLoad, as it is handled for you. However,
          // if you want to manipulate the reader, you can do so here:
        },

        // onProgress callback
        function (xhr) {
          console.log();
        },

        // onError callback
        function () {
          console.error('An error happened.');
        }
      );
      ref.current.map.dispose();
    }
  }, []);

  useEffect(() => {
    return () => {
      if (texture) texture.dispose();
    };
  }, []);
  return (
    <>
      {item.frameType === 1 && (
        <>
          <Frame
            position={[-1.66, -1.78, -0.4]}
            scale={[0.00022, 0.00022, 0.00022]}
            rotation={[0, Math.PI / 2, 0]}
          />
          {item.file.includes('gif') ? (
            <mesh
              position={[0.02, -0.09, -0.89]}
              rotation={[item.rotationX, item.rotationY, item.rotationZ]}>
              <planeGeometry args={[3.25, 3.25]} />
              <meshBasicMaterial ref={ref} toneMapped={false} />
            </mesh>
          ) : (
            <mesh
              position={[0.02, -0.09, -0.89]}
              rotation={[item.rotationX, item.rotationY, item.rotationZ]}>
              <planeGeometry args={[3.25, 3.25]} />
              <meshBasicMaterial ref={ref2} map={texture} toneMapped={false} />
            </mesh>
          )}
        </>
      )}
      {item.frameType === 0 && (
        <>
          {item.file.includes('gif') ? (
            <mesh
              position={[0, 0, -0.89]}
              rotation={[item.rotationX, item.rotationY, item.rotationZ]}>
              <planeGeometry args={[3.55, 3.55]} />
              <meshBasicMaterial ref={ref} toneMapped={false} />
            </mesh>
          ) : (
            <mesh
              position={[0, 0, -0.89]}
              rotation={[item.rotationX, item.rotationY, item.rotationZ]}>
              <planeGeometry args={[3.55, 3.55]} />
              <meshBasicMaterial ref={ref2} map={texture} toneMapped={false} />
            </mesh>
          )}
        </>
      )}
    </>
  );
}
