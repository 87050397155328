import * as React from "react";
import * as THREE from "three";
import { XRControllerModelFactory, OculusHandModel } from "three-stdlib";
import { useFrame, useThree, Canvas, extend, createPortal } from "@react-three/fiber";
import create from "zustand";
class XRController extends THREE.Group {
  constructor(index, gl) {
    super();
    this.index = index;
    this.controller = gl.xr.getController(index);
    this.grip = gl.xr.getControllerGrip(index);
    this.hand = gl.xr.getHand(index);
    this.grip.userData.name = "grip";
    this.controller.userData.name = "controller";
    this.hand.userData.name = "hand";
    this.visible = false;
    this.add(this.controller, this.grip, this.hand);
    this.onConnected = this.onConnected.bind(this);
    this.onDisconnected = this.onDisconnected.bind(this);
    this.controller.addEventListener("connected", this.onConnected);
    this.controller.addEventListener("disconnected", this.onDisconnected);
  }
  onConnected(event) {
    if (event.fake)
      return;
    this.visible = true;
    this.inputSource = event.data;
    this.dispatchEvent(event);
  }
  onDisconnected(event) {
    if (event.fake)
      return;
    this.visible = false;
    this.dispatchEvent(event);
  }
  dispose() {
    this.controller.removeEventListener("connected", this.onConnected);
    this.controller.removeEventListener("disconnected", this.onDisconnected);
  }
}
function useXREvent(event, handler, { handedness } = {}) {
  const handlerRef = React.useRef(handler);
  React.useEffect(() => void (handlerRef.current = handler), [handler]);
  const controllers = useXR((state) => state.controllers);
  React.useEffect(() => {
    const listeners = controllers.map((target) => {
      if (handedness && target.inputSource.handedness !== handedness)
        return;
      const listener = (nativeEvent) => handlerRef.current({ nativeEvent, target });
      target.controller.addEventListener(event, listener);
      return () => target.controller.removeEventListener(event, listener);
    });
    return () => listeners.forEach((cleanup) => cleanup == null ? void 0 : cleanup());
  }, [controllers, handedness, event]);
}
const tempMatrix = new THREE.Matrix4();
function InteractionManager({ children }) {
  const events = useThree((state) => state.events);
  const get = useThree((state) => state.get);
  const raycaster = useThree((state) => state.raycaster);
  const controllers = useXR((state) => state.controllers);
  const interactions = useXR((state) => state.interactions);
  const hoverState = useXR((state) => state.hoverState);
  const hasInteraction = useXR((state) => state.hasInteraction);
  const getInteraction = useXR((state) => state.getInteraction);
  const intersect = React.useCallback((controller) => {
    const objects = Array.from(interactions.keys());
    tempMatrix.identity().extractRotation(controller.matrixWorld);
    raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
    raycaster.ray.direction.set(0, 0, -1).applyMatrix4(tempMatrix);
    return raycaster.intersectObjects(objects, true);
  }, [interactions, raycaster]);
  useFrame(() => {
    if (interactions.size === 0)
      return;
    for (const target of controllers) {
      const hovering = hoverState[target.inputSource.handedness];
      const hits = /* @__PURE__ */ new Set();
      let intersections = intersect(target.controller);
      if (events.filter) {
        intersections = events.filter(intersections, get());
      } else {
        const hit = intersections.find((i) => i == null ? void 0 : i.object);
        if (hit)
          intersections = [hit];
      }
      for (const intersection of intersections) {
        let eventObject = intersection.object;
        while (eventObject) {
          if (hasInteraction(eventObject, "onHover") && !hovering.has(eventObject)) {
            const handlers = getInteraction(eventObject, "onHover");
            for (const handler of handlers) {
              handler({ target, intersection, intersections });
            }
          }
          const moveHandlers = getInteraction(eventObject, "onMove");
          if (moveHandlers) {
            moveHandlers == null ? void 0 : moveHandlers.forEach((handler) => handler({ target, intersection, intersections }));
          }
          hovering.set(eventObject, intersection);
          hits.add(eventObject.id);
          eventObject = eventObject.parent;
        }
      }
      for (const eventObject of hovering.keys()) {
        if (!hits.has(eventObject.id)) {
          hovering.delete(eventObject);
          const handlers = getInteraction(eventObject, "onBlur");
          if (!handlers)
            continue;
          for (const handler of handlers) {
            handler({ target, intersections });
          }
        }
      }
    }
  });
  const triggerEvent = React.useCallback((interaction) => (e) => {
    const hovering = hoverState[e.target.inputSource.handedness];
    const intersections = Array.from(new Set(hovering.values()));
    interactions.forEach((handlers, object) => {
      if (hovering.has(object)) {
        if (!handlers[interaction])
          return;
        for (const handler of handlers[interaction]) {
          handler({ target: e.target, intersection: hovering.get(object), intersections });
        }
      } else {
        if (interaction === "onSelect" && handlers["onSelectMissed"]) {
          for (const handler of handlers["onSelectMissed"]) {
            handler({ target: e.target, intersections });
          }
        } else if (interaction === "onSqueeze" && handlers["onSqueezeMissed"]) {
          for (const handler of handlers["onSqueezeMissed"]) {
            handler({ target: e.target, intersections });
          }
        }
      }
    });
  }, [hoverState, interactions]);
  useXREvent("select", triggerEvent("onSelect"));
  useXREvent("selectstart", triggerEvent("onSelectStart"));
  useXREvent("selectend", triggerEvent("onSelectEnd"));
  useXREvent("squeeze", triggerEvent("onSqueeze"));
  useXREvent("squeezeend", triggerEvent("onSqueezeEnd"));
  useXREvent("squeezestart", triggerEvent("onSqueezeStart"));
  return /* @__PURE__ */ React.createElement(React.Fragment, null, children);
}
function useInteraction(ref, type, handler) {
  const addInteraction = useXR((state) => state.addInteraction);
  const removeInteraction = useXR((state) => state.removeInteraction);
  const handlerRef = React.useRef(handler);
  React.useEffect(() => void (handlerRef.current = handler), [handler]);
  React.useEffect(() => {
    const target = ref.current;
    const handler2 = handlerRef.current;
    if (!target || !handler2)
      return;
    addInteraction(target, type, handler2);
    return () => removeInteraction(target, type, handler2);
  }, [ref, type, addInteraction, removeInteraction]);
}
const Interactive = React.forwardRef(function Interactive2({
  onHover,
  onBlur,
  onSelectStart,
  onSelectEnd,
  onSelectMissed,
  onSelect,
  onSqueezeStart,
  onSqueezeEnd,
  onSqueezeMissed,
  onSqueeze,
  onMove,
  children
}, passedRef) {
  const ref = React.useRef(null);
  React.useImperativeHandle(passedRef, () => ref.current);
  useInteraction(ref, "onHover", onHover);
  useInteraction(ref, "onBlur", onBlur);
  useInteraction(ref, "onSelectStart", onSelectStart);
  useInteraction(ref, "onSelectEnd", onSelectEnd);
  useInteraction(ref, "onSelectMissed", onSelectMissed);
  useInteraction(ref, "onSelect", onSelect);
  useInteraction(ref, "onSqueezeStart", onSqueezeStart);
  useInteraction(ref, "onSqueezeEnd", onSqueezeEnd);
  useInteraction(ref, "onSqueezeMissed", onSqueezeMissed);
  useInteraction(ref, "onSqueeze", onSqueeze);
  useInteraction(ref, "onMove", onMove);
  return /* @__PURE__ */ React.createElement("group", {
    ref
  }, children);
});
const RayGrab = React.forwardRef(function RayGrab2({ onSelectStart, onSelectEnd, children, ...rest }, forwardedRef) {
  const grabbingController = React.useRef();
  const groupRef = React.useRef(null);
  const previousTransform = React.useMemo(() => new THREE.Matrix4(), []);
  React.useImperativeHandle(forwardedRef, () => groupRef.current);
  useFrame(() => {
    const controller = grabbingController.current;
    const group = groupRef.current;
    if (!controller)
      return;
    group.applyMatrix4(previousTransform);
    group.applyMatrix4(controller.matrixWorld);
    group.updateMatrixWorld();
    previousTransform.copy(controller.matrixWorld).invert();
  });
  return /* @__PURE__ */ React.createElement(Interactive, {
    ref: groupRef,
    onSelectStart: (e) => {
      grabbingController.current = e.target.controller;
      previousTransform.copy(e.target.controller.matrixWorld).invert();
      onSelectStart == null ? void 0 : onSelectStart(e);
    },
    onSelectEnd: (e) => {
      if (e.target.controller === grabbingController.current) {
        grabbingController.current = void 0;
      }
      onSelectEnd == null ? void 0 : onSelectEnd(e);
    },
    ...rest
  }, children);
});
const XRStore = create((set, get) => ({
  set,
  get,
  controllers: [],
  isPresenting: false,
  isHandTracking: false,
  player: new THREE.Group(),
  session: null,
  foveation: 0,
  referenceSpace: "local-floor",
  hoverState: {
    left: /* @__PURE__ */ new Map(),
    right: /* @__PURE__ */ new Map(),
    none: /* @__PURE__ */ new Map()
  },
  interactions: /* @__PURE__ */ new Map(),
  hasInteraction(object, eventType) {
    var _a;
    return !!((_a = get().interactions.get(object)) == null ? void 0 : _a[eventType].length);
  },
  getInteraction(object, eventType) {
    var _a;
    return (_a = get().interactions.get(object)) == null ? void 0 : _a[eventType];
  },
  addInteraction(object, eventType, handler) {
    const interactions = get().interactions;
    if (!interactions.has(object)) {
      interactions.set(object, {
        onHover: [],
        onBlur: [],
        onSelect: [],
        onSelectEnd: [],
        onSelectStart: [],
        onSelectMissed: [],
        onSqueeze: [],
        onSqueezeEnd: [],
        onSqueezeStart: [],
        onSqueezeMissed: [],
        onMove: []
      });
    }
    const target = interactions.get(object);
    target[eventType].push(handler);
  },
  removeInteraction(object, eventType, handler) {
    const target = get().interactions.get(object);
    if (target) {
      const interactionIndex = target[eventType].indexOf(handler);
      if (interactionIndex !== -1)
        target[eventType].splice(interactionIndex, 1);
    }
  }
}));
const hitMatrix = new THREE.Matrix4();
function useHitTest(hitTestCallback) {
  const session = useXR((state) => state.session);
  const hitTestSource = React.useRef();
  React.useEffect(() => {
    if (!session)
      return void (hitTestSource.current = void 0);
    session.requestReferenceSpace("viewer").then(async (referenceSpace) => {
      var _a;
      hitTestSource.current = await ((_a = session == null ? void 0 : session.requestHitTestSource) == null ? void 0 : _a.call(session, { space: referenceSpace }));
    });
  }, [session]);
  useFrame((state, _, frame) => {
    if (!frame || !hitTestSource.current)
      return;
    const [hit] = frame.getHitTestResults(hitTestSource.current);
    if (hit) {
      const referenceSpace = state.gl.xr.getReferenceSpace();
      const pose = hit.getPose(referenceSpace);
      if (pose) {
        hitMatrix.fromArray(pose.transform.matrix);
        hitTestCallback(hitMatrix, hit);
      }
    }
  });
}
function XR({
  foveation = 0,
  referenceSpace = "local-floor",
  onSessionStart,
  onSessionEnd,
  onVisibilityChange,
  onInputSourcesChange,
  children
}) {
  const gl = useThree((state) => state.gl);
  const camera = useThree((state) => state.camera);
  const player = useXR((state) => state.player);
  const set = useXR((state) => state.set);
  const session = useXR((state) => state.session);
  const controllers = useXR((state) => state.controllers);
  React.useEffect(() => {
    const handlers = [0, 1].map((id) => {
      const target = new XRController(id, gl);
      const onConnected = () => set((state) => ({ controllers: [...state.controllers, target] }));
      const onDisconnected = () => set((state) => ({ controllers: state.controllers.filter((it) => it !== target) }));
      target.addEventListener("connected", onConnected);
      target.addEventListener("disconnected", onDisconnected);
      return () => {
        target.removeEventListener("connected", onConnected);
        target.removeEventListener("disconnected", onDisconnected);
      };
    });
    return () => handlers.forEach((cleanup) => cleanup());
  }, [gl, set]);
  React.useEffect(() => void gl.xr.setSession(session), [gl.xr, session]);
  React.useEffect(() => {
    gl.xr.setFoveation(foveation);
    set(() => ({ foveation }));
  }, [gl, foveation, set]);
  React.useEffect(() => {
    gl.xr.setReferenceSpaceType(referenceSpace);
    set(() => ({ referenceSpace }));
  }, [gl.xr, referenceSpace, set]);
  React.useEffect(() => {
    if (!session)
      return;
    const handleSessionStart = (nativeEvent) => onSessionStart == null ? void 0 : onSessionStart({ nativeEvent: { ...nativeEvent, target: session }, target: session });
    const handleSessionEnd = (nativeEvent) => onSessionEnd == null ? void 0 : onSessionEnd({ nativeEvent: { ...nativeEvent, target: session }, target: session });
    const handleVisibilityChange = (nativeEvent) => onVisibilityChange == null ? void 0 : onVisibilityChange({ nativeEvent, target: session });
    const handleInputSourcesChange = (nativeEvent) => onInputSourcesChange == null ? void 0 : onInputSourcesChange({ nativeEvent, target: session });
    gl.xr.addEventListener("sessionstart", handleSessionStart);
    gl.xr.addEventListener("sessionend", handleSessionEnd);
    session.addEventListener("visibilitychange", handleVisibilityChange);
    session.addEventListener("inputsourceschange", handleInputSourcesChange);
    return () => {
      gl.xr.removeEventListener("sessionstart", handleSessionStart);
      gl.xr.removeEventListener("sessionend", handleSessionEnd);
      session.removeEventListener("visibilitychange", handleVisibilityChange);
      session.removeEventListener("inputsourceschange", handleInputSourcesChange);
    };
  }, [session, gl.xr, onSessionStart, onSessionEnd, onVisibilityChange, onInputSourcesChange]);
  React.useEffect(() => {
    const handleSessionChange = () => set(() => ({ isPresenting: gl.xr.isPresenting }));
    gl.xr.addEventListener("sessionstart", handleSessionChange);
    gl.xr.addEventListener("sessionend", handleSessionChange);
    return () => {
      gl.xr.removeEventListener("sessionstart", handleSessionChange);
      gl.xr.removeEventListener("sessionend", handleSessionChange);
    };
  }, [set, gl.xr]);
  React.useEffect(() => {
    if (!session)
      return;
    const handleInputSourcesChange = (event) => set(() => ({
      isHandTracking: Object.values(event.session.inputSources).some((source) => source.hand)
    }));
    session.addEventListener("inputsourceschange", handleInputSourcesChange);
    handleInputSourcesChange({ session });
    return () => session.removeEventListener("inputsourceschange", handleInputSourcesChange);
  }, [set, session]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("primitive", {
    object: player
  }, /* @__PURE__ */ React.createElement("primitive", {
    object: camera
  }), controllers.map((controller, i) => /* @__PURE__ */ React.createElement("primitive", {
    key: i,
    object: controller
  }))), children);
}
const XRButton = React.forwardRef(function XRButton2({ mode, sessionInit, enterOnly = false, exitOnly = false, onClick, children, ...props }, ref) {
  const [status, setStatus] = React.useState("exited");
  const label = status === "unsupported" ? `${mode} unsupported` : `${status === "entered" ? "Exit" : "Enter"} ${mode}`;
  const sessionMode = mode === "inline" ? mode : `immersive-${mode.toLowerCase()}`;
  React.useEffect(() => {
    if (!(navigator == null ? void 0 : navigator.xr))
      return void setStatus("unsupported");
    navigator.xr.isSessionSupported(sessionMode).then((supported) => setStatus(supported ? "exited" : "unsupported"));
  }, [sessionMode]);
  const toggleSession = React.useCallback(async (event) => {
    onClick == null ? void 0 : onClick(event);
    const xrState = XRStore.getState();
    if (xrState.session && enterOnly)
      return;
    if (!xrState.session && exitOnly)
      return;
    let session = null;
    if (xrState.session) {
      await xrState.session.end();
      setStatus("exited");
    } else {
      session = await navigator.xr.requestSession(sessionMode, sessionInit);
      setStatus("entered");
    }
    xrState.set(() => ({ session }));
  }, [onClick, enterOnly, exitOnly, sessionMode, sessionInit]);
  return /* @__PURE__ */ React.createElement("button", {
    ...props,
    ref,
    onClick: status === "unsupported" ? onClick : toggleSession
  }, typeof children === "function" ? children(status) : children != null ? children : label);
});
const XRCanvas = React.forwardRef(function XRCanvas2({ foveation, referenceSpace, onSessionStart, onSessionEnd, onVisibilityChange, onInputSourcesChange, children, ...rest }, forwardedRef) {
  return /* @__PURE__ */ React.createElement(Canvas, {
    ...rest,
    ref: forwardedRef
  }, /* @__PURE__ */ React.createElement(XR, {
    foveation,
    referenceSpace,
    onSessionStart,
    onSessionEnd,
    onVisibilityChange,
    onInputSourcesChange
  }, /* @__PURE__ */ React.createElement(InteractionManager, null, children)));
});
const buttonStyles = {
  position: "absolute",
  bottom: "24px",
  left: "50%",
  transform: "translateX(-50%)",
  padding: "12px 24px",
  border: "1px solid white",
  borderRadius: "4px",
  background: "rgba(0, 0, 0, 0.1)",
  color: "white",
  font: "normal 0.8125rem sans-serif",
  outline: "none",
  zIndex: 99999,
  cursor: "pointer"
};
const VRCanvas = React.forwardRef(function VRCanvas2({
  sessionInit = {
    optionalFeatures: ["local-floor", "bounded-floor", "hand-tracking", "layers"]
  },
  children,
  ...rest
}, ref) {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(XRButton, {
    mode: "VR",
    style: buttonStyles,
    sessionInit
  }), /* @__PURE__ */ React.createElement(XRCanvas, {
    ref,
    ...rest
  }, children));
});
const ARCanvas = React.forwardRef(function ARCanvas2({
  sessionInit = {
    domOverlay: { root: document.body },
    optionalFeatures: ["hit-test", "dom-overlay", "dom-overlay-for-handheld-ar"]
  },
  children,
  ...rest
}, ref) {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(XRButton, {
    mode: "AR",
    style: buttonStyles,
    sessionInit
  }), /* @__PURE__ */ React.createElement(XRCanvas, {
    ref,
    ...rest
  }, children));
});
function useXR(selector = (state) => state, equalityFn) {
  return XRStore(selector, equalityFn);
}
function useController(handedness) {
  const controllers = useXR((state) => state.controllers);
  const controller = React.useMemo(() => controllers.find(({ inputSource }) => inputSource.handedness === handedness), [handedness, controllers]);
  return controller;
}
const Ray = React.forwardRef(function Ray2({ target, hideOnBlur = false, ...props }, forwardedRef) {
  const hoverState = useXR((state) => state.hoverState);
  const ray = React.useRef(null);
  const rayGeometry = React.useMemo(() => new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, -1)]), []);
  React.useImperativeHandle(forwardedRef, () => ray.current);
  useFrame(() => {
    let rayLength = 1;
    const intersection = hoverState[target.inputSource.handedness].values().next().value;
    if (intersection && target.inputSource.handedness !== "none") {
      rayLength = intersection.distance;
      if (hideOnBlur)
        ray.current.visible = false;
    } else if (hideOnBlur) {
      ray.current.visible = true;
    }
    const offset = -0.01;
    ray.current.scale.z = rayLength + offset;
  });
  return /* @__PURE__ */ React.createElement("line", {
    ref: ray,
    geometry: rayGeometry,
    "material-opacity": 0.8,
    "material-transparent": true,
    ...props
  });
});
const modelFactory = new XRControllerModelFactory();
class DefaultXRController extends THREE.Group {
  constructor(target) {
    super();
    this.add(modelFactory.createControllerModel(target.controller));
  }
}
function DefaultXRControllers({ rayMaterial = {}, hideRaysOnBlur = false }) {
  const controllers = useXR((state) => state.controllers);
  const isHandTracking = useXR((state) => state.isHandTracking);
  const rayMaterialProps = React.useMemo(() => Object.entries(rayMaterial).reduce((acc, [key, value]) => ({
    ...acc,
    [`material-${key}`]: value
  }), {}), [JSON.stringify(rayMaterial)]);
  React.useMemo(() => extend({ DefaultXRController }), []);
  React.useEffect(() => {
    for (const target of controllers) {
      target.controller.dispatchEvent({ type: "connected", data: target.inputSource, fake: true });
    }
  }, [controllers]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, controllers.map((target, i) => /* @__PURE__ */ React.createElement(React.Fragment, {
    key: i
  }, createPortal(/* @__PURE__ */ React.createElement("defaultXRController", {
    args: [target]
  }), target.grip), createPortal(/* @__PURE__ */ React.createElement(Ray, {
    visible: !isHandTracking,
    hideOnBlur: hideRaysOnBlur,
    target,
    ...rayMaterialProps
  }), target.controller))));
}
function Hands({ modelLeft, modelRight }) {
  const controllers = useXR((state) => state.controllers);
  React.useMemo(() => extend({ OculusHandModel }), []);
  React.useEffect(() => {
    for (const target of controllers) {
      target.hand.dispatchEvent({ type: "connected", data: target.inputSource, fake: true });
    }
  }, [controllers, modelLeft, modelRight]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, controllers.map(({ hand }) => createPortal(/* @__PURE__ */ React.createElement("oculusHandModel", {
    args: [hand, modelLeft, modelRight]
  }), hand)));
}
export { ARCanvas, DefaultXRControllers, Hands, InteractionManager, Interactive, Ray, RayGrab, VRCanvas, XRButton, XRCanvas, XRController, useController, useHitTest, useInteraction, useXR, useXREvent };
