import { Physics, Debug, RigidBody, MeshCollider } from '@react-three/rapier';
import { useThree } from '@react-three/fiber';
import * as Colyseus from 'colyseus.js';

import { ThirdPlayer } from 'react3fiber/player/thirdperson';

import Primitiveform from 'react3fiber/primitive/primitive';
import MultiPlayer from 'react3fiber/player/multi-player';
import OsstemPrimitive from 'react3fiber/primitive/osstem-primitive';
import FlowerThemaPrimitive from 'react3fiber/primitive/flower-theme-primitive';
import SpaceThemaPrimitive from 'react3fiber/primitive/space-theme-primitive';
import { Space } from 'apis/index';
import { Players } from 'components/Multiplay/types';
import UploadModelPrimitive from 'react3fiber/primitive/upload-model-primitive';
import { Sky } from '@react-three/drei';

export const REAL_WIDTH = 3600;
export const REAL_HEIGHT = 3600;
export const REAL_DEPTH = 7200;

export const REAL_TO_THREE_SCALE = 1 / 100;

export const THREE_WIDTH = REAL_WIDTH * REAL_TO_THREE_SCALE;
export const THREE_HEIGHT = REAL_HEIGHT * REAL_TO_THREE_SCALE;
export const THREE_DEPTH = REAL_DEPTH * REAL_TO_THREE_SCALE;

const mediaMatch = window.matchMedia('(min-width: 1024px)');
let isIOS =
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
type SpaceMode = 'multiplay' | 'scroll';

export function Players({
  nonMemberName,
  space,
  mode,
  room,
  playersRef,
  isInteractive,
}: {
  nonMemberName: string | null;
  space: Space;
  mode: SpaceMode;
  room: Colyseus.Room | null;
  playersRef: React.MutableRefObject<Players>;
  isInteractive: boolean;
}) {
  const { camera } = useThree();

  let post = 0;
  if (
    space.id == '20755000-fc99-4a00-a162-39b3055c3f26' ||
    space.id == '3c3804e5-0daa-4b83-a945-beb54c14fc92'
  ) {
    post = 1;
  } else if (
    space.id == '644c32b1-af2f-4797-8c55-414cee9a306d' ||
    space.id == 'f7b07e4c-9533-419f-9b58-c7411321c176'
  ) {
    post = 2;
  } else if (
    space.id == '118bc2c6-5b9d-4798-b885-7369c9158b9a' ||
    space.id == '019a3574-0d57-43ff-8814-580129b4bf70'
  ) {
    post = 3;
  } else if (
    space.id == '91ba0773-c292-4d9c-be51-7c734a29ac40' ||
    space.id == '41eea043-3049-4d6b-bdf3-a16094df0abc'
  ) {
    post = 4;
  }

  return mode == 'multiplay' ? (
    <Physics gravity={[0, -2, 0]}>
      {/* <Debug />
      {space.items.map((item, index) => (
        <group key={index}>
          {space.items[0]?.file == undefined ? null : <UploadModelPrimitive item={item} />}
        </group>
      ))} */}
      {space.theme == 7 ? (
        <Primitiveform height={1} left={-2} right={2} front={-0.6} back={30} />
      ) : null}
      {space.theme == 6 ? (
        <>
          <Primitiveform height={1} left={-3} right={3} front={-0.6} back={13} />
          <SpaceThemaPrimitive />
        </>
      ) : null}
      {space.theme == 5 ? (
        <Primitiveform height={1} left={-2} right={2} front={-0.6} back={4} />
      ) : null}
      {space.theme == 4 ? <FlowerThemaPrimitive /> : null}
      {space.theme == 3 ? (
        <Primitiveform height={1} left={-3} right={3} front={-0.6} back={8.5} />
      ) : null}
      {space.theme == 2 ? (
        <Primitiveform height={1.1} left={-1.5} right={1.5} front={-0.6} back={6} />
      ) : null}
      {space.theme === 1 && post == 0 ? (
        <Primitiveform height={1} left={-1.5} right={1.5} front={-0.6} back={6} />
      ) : null}
      {space.theme === 1 && post == 1 ? (
        <OsstemPrimitive
          THREE_WIDTH={THREE_WIDTH}
          THREE_HEIGHT={THREE_HEIGHT}
          THREE_DEPTH={THREE_DEPTH}
        />
      ) : null}
      {space.theme === 1 && post == 2 ? (
        <Primitiveform height={1} left={-3} right={3} front={-8} back={6} />
      ) : null}
      {space.theme === 1 && post == 3 ? (
        <Primitiveform height={1} left={-1.5} right={1.5} front={-0.6} back={6} />
      ) : null}
      <Sky />
      <ThirdPlayer nonMemberName={nonMemberName} room={room} isInteractive={isInteractive} />
      <MultiPlayer room={room} playersRef={playersRef} />
    </Physics>
  ) : null;
}
