import { useCallback, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useAtomValue, useAtom } from 'jotai';
import { useInfiniteQuery } from '@tanstack/react-query';

import { Alarm, APIError, APIIssues, getAlarm } from 'apis/index';
import { Reload } from 'components/Commons/icons';

import { tokenAtom, userAtom, navbarTypeAtom, languageModeAtom } from 'src/atoms';

const scripts = {
  KR: [
    '님께서 회원님의 3D post에 좋아요를 남겼습니다. 확인해 보세요!',
    '님께서 회원님을 팔로우 하였습니다. 확인해보세요!',
    '님께서 회원님의 3D post에 새로운 댓글을 남겼습니다. 확인해 보세요!',
    '님께서 회원님의 3D post에 흥미를 갖습니다. 확인해 보세요!',
    '개의 알림',
    '일시적인 오류가 발생했습니다. 다시 시도해주세요.',
  ],
  EN: [
    ' left a like on your 3D post. Check it out!',
    ' has followed you. Check it out!',
    ' left a new comment on your 3D post. Check it out!',
    ' is interested in your 3D post. Check it out!',
    'Notifications',
    'A temporary error has occurred. please try again.',
  ],
  JP: [
    'さんが会員様の3Dpostにいいねを残しました。 確認してみてください！',
    'サヤカ様が会員様をフォローいたしました。 確認してみてください！',
    'さやかさんが会員様の3Dpostに新しいコメントを残しました。 確認してみてください！',
    'サヤカ様が会員様の3Dpostに興味を持ちます。 確認してみてください!',
    'つのお知らせ',
    '一時的なエラーが発生しました。もう一度お試しください。',
  ],
};

export default function Alarm() {
  const token = useAtomValue(tokenAtom);
  const user = useAtomValue(userAtom);
  const [, setNavbarType] = useAtom(navbarTypeAtom);
  const languageMode = useAtomValue(languageModeAtom);
  const [error, setError] = useState<APIIssues | null>(null);
  const mediaMatch = useMediaQuery({ maxWidth: 1224 });

  useEffect(() => {
    setNavbarType('alarm');
  });

  const navigate = useNavigate();
  const {
    data: alarmPages,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [token!, 'alarm', user!.username],
    async ({ pageParam }) => {
      try {
        return await getAlarm(user!.username, token!, pageParam);
      } catch (error) {
        if (error instanceof APIError) {
          setError(error.issues);
        }
      }
    },
    {
      getNextPageParam: (lastPage) => (lastPage ? lastPage.cursor : undefined),
    }
  );
  const alarms = alarmPages!.pages.flatMap((page) => (page ? page.alarms : []));
  // const [alarms, setAlarms] = useState<Alarm[]>([]);
  function createMessage(alarm: Alarm) {
    switch (alarm.type) {
      case 'L':
        return scripts[languageMode][0];
      case 'F':
        return scripts[languageMode][1];
      case 'R':
        return scripts[languageMode][2];
      default:
        return scripts[languageMode][3];
    }
  }

  function handleScroll(e: React.UIEvent) {
    if (hasNextPage && !isFetchingNextPage) {
      if (
        e.currentTarget.scrollTop + e.currentTarget.clientHeight >=
        e.currentTarget.scrollHeight - 100
      )
        //100px은 임의의 값
        fetchNextPage();
    }
  }
  function refreshPage() {
    window.location.reload();
  }

  const onAlarmMessageClick = useCallback((moveURL: string | null) => {
    if (moveURL) {
      moveURL.replace('s/', 'i/');

      navigate(`/${moveURL}`);
    }
  }, []);

  return (
    <>
      {!token && <Navigate to='/login' />}
      {!error ? (
        mediaMatch ? (
          <div className='h-full overflow-y-scroll' onScroll={handleScroll}>
            <div className='flex h-14 px-4 items-center'>
              <div className='text-sm'>
                {alarms.length}
                {scripts[languageMode][4]}
              </div>
              <div className='flex-auto justify-end' />
            </div>
            <div className='px-4 text-sm'>
              <ul>
                {alarms.map((alarm, index) => (
                  <li
                    key={index}
                    className='flex gap-3 py-1 hover:cursor-pointer'
                    onClick={() => onAlarmMessageClick(alarm.moveURL)}>
                    <img className='w-8 h-8 rounded-full object-cover aspect-square mt-1.5' />
                    <div className='inline-block'>
                      <span className='font-semibold'>{`@${alarm.fromUser}`}</span>
                      {createMessage(alarm)}
                      <div className='inline text-zinc-400 font-light'>{' ' + alarm.created}</div>
                    </div>
                  </li>
                ))}
                {/* {errors && <li>{scripts[languageMode][5]}</li>} */}
              </ul>
            </div>
          </div>
        ) : (
          <div className='alarm-modal flex flex-col bg-white w-screen text-zinc-600 rounded-lg font-light'>
            <div className='h-full overflow-y-scroll' onScroll={handleScroll}>
              <div className='flex h-14 px-4 items-center'>
                <div className='text-sm'>
                  {alarms.length}
                  {scripts[languageMode][4]}
                </div>
                <div className='flex-auto justify-end' />
              </div>
              <div className='px-4 text-sm'>
                <ul>
                  {alarms.map((alarm, index) => (
                    <li
                      key={index}
                      className='flex gap-3 py-1 hover:cursor-pointer'
                      onClick={() => onAlarmMessageClick(alarm.moveURL)}>
                      <img className='w-8 h-8 rounded-full object-cover aspect-square mt-1.5' />
                      <div className='inline-block'>
                        <span className='font-semibold'>{`@${alarm.fromUser}`}</span>
                        {createMessage(alarm)}
                        <div className='inline text-zinc-400 font-light'>{' ' + alarm.created}</div>
                      </div>
                    </li>
                  ))}
                  {/* {errors && <li>{scripts[languageMode][5]}</li>} */}
                </ul>
              </div>
            </div>
          </div>
        )
      ) : (
        <div
          className='h-full'
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <div className='mt-3 text-xl'>Something went wrong...</div>
          <div
            className='flex flex-row mt-6 text-base gap-2 items-center justify-center'
            onClick={refreshPage}>
            <div>Please reload</div>
            <Reload />
          </div>
        </div>
      )}
    </>
  );
}
