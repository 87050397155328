import React, { useState, useEffect, useRef, Suspense } from 'react';
import { useAtomValue } from 'jotai';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useThree } from '@react-three/fiber';
import { VRCanvas, DefaultXRControllers } from '@react-three/xr';
import { Debug, Physics } from '@react-three/cannon';
import { PositionalAudio } from '@react-three/drei';

import { useCompressedGLTF } from 'hooks/hooks';
import { getLouvreSpaces } from 'apis/index';
import Objects from 'react3fiber/r3f-space/objects';
import deviceStore from 'react3fiber/device-store';
import MovementController from 'react3fiber/player/vrplayer';
import Theme3Primitiveform from 'react3fiber/theme/theme3primitive';
import Primitiveform from 'react3fiber/primitive/primitive';
import { THREE_WIDTH, THREE_HEIGHT, THREE_DEPTH } from 'react3fiber/r3f-space/players';

import { tokenAtom, playModeAtom, bgmModeAtom, spacechannel } from 'src/atoms';

import loadingIcon from 'static/icons/loading.svg';

let url3 = new URL('../../static/models/louvre55.min.glb', import.meta.url);

function PreloadPopularModels() {
  let gl = useThree((state) => state.gl);
  useCompressedGLTF.preload(url3.href, gl);
  return null;
}

export default function Spaces({ isFeed }: { isFeed: boolean }) {
  let token = useAtomValue(tokenAtom);

  let count = useAtomValue(spacechannel);
  let bgmMode = useAtomValue(bgmModeAtom);

  //여기서부터 colyseus

  //여기까지 colyseus

  let spacesElRef = useRef<HTMLElement>(null);
  let spaceElRef = useRef<HTMLDivElement>(null);
  let [height, setHeight] = useState(0);
  let [scrollTop, setScrollTop] = useState(0);
  let audioRef = useRef<HTMLAudioElement>(null);
  const [currentAudio, setCurrentAudio] = useState<number>(1);
  let audioUrl1 = new URL(
    '../../static/bgms/CD1-01_Bach-Violin_Concerto_No1-Oistrakh1962-Track01.mp3',
    import.meta.url
  );
  let audioUrl2 = new URL(
    '../../static/bgms/CD1-02_Bach-Violin_Concerto_No1-Oistrakh1962-Track02.mp3',
    import.meta.url
  );
  let audioUrl3 = new URL(
    '../../static/bgms/CD1-03_Bach-Violin_Concerto_No1-Oistrakh1962-Track03.mp3',
    import.meta.url
  );
  let audioUrl4 = new URL('../../static/bgms/CD1-06_Bach-Violin_Concerto_No2-Oistrakh1962-Track3.mp3', import.meta.url);
  let audioUrl5 = new URL(
    '../../static/bgms/CD1-09-Bach-Concerto_for_2_Violins_BWV1043-Oistrakh1961-Track3.mp3',
    import.meta.url
  );
  let audioUrl6 = new URL('../../static/bgms/CD1-11-Beethoven-Violin_Romance_No2-Oistrakh1962.mp3', import.meta.url);
  let audioUrl7 = new URL('../../static/bgms/LiquidPurgatory.m4a', import.meta.url);
  let audioUrls: string[] = [];
  audioUrls.push(
    audioUrl1.href,
    audioUrl2.href,
    audioUrl3.href,
    audioUrl4.href,
    audioUrl5.href,
    audioUrl6.href,
    audioUrl7.href
  );

  let { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    ['louvre', token, 'feeds'],
    async ({ pageParam }) => {
      return await getLouvreSpaces(token, pageParam);
    },
    {
      getNextPageParam: (lastPage) => lastPage.cursor,
    }
  );
  // if (!data) return null; // we use suspense so data is never undefined
  let spaces = data!.pages.flatMap((page) => page.spaces);
  let currentIndex = height === 0 ? 0 : Math.min(Math.max(Math.floor(scrollTop / height + 0.5), 0), spaces.length);

  let mode = useAtomValue(playModeAtom);
  const { device, setDevice } = deviceStore();

  const checkDevice = async () => {
    if (navigator.xr == undefined) return 'web';
    let isAR = await navigator.xr.isSessionSupported('immersive-ar');
    if (isAR) return 'webAR';
    let isVR = await navigator.xr.isSessionSupported('immersive-vr');
    if (isVR) return 'webVR';
    return 'web';
  };
  useEffect(() => {
    if (spaces.length > 0) setCurrentAudio(spaces[count].bgm);
  }, [spaces, count, bgmMode]);

  function SuspensefulUserProfile() {
    return bgmMode ? (
      <Suspense fallback={null}>
        <PositionalAudio autoplay loop={true} url={audioUrls[currentAudio - 1]} distance={bgmMode ? 100000 : 0} />
      </Suspense>
    ) : null;
  }

  useEffect(() => {
    const fetchData = async () => setDevice(await checkDevice());
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FIXME: this is all a giant hack
  useEffect(() => {
    if (!spaceElRef.current) return;
    let spaceEl = spaceElRef.current;
    setHeight(spaceEl.clientHeight);
    let resizeObserver = new ResizeObserver(() => setHeight(spaceEl.clientHeight));
    resizeObserver.observe(spaceEl);
    return () => resizeObserver.disconnect();
  }, [spaces.length]);

  useEffect(() => {
    if (spaces.length > 0) setCurrentAudio(spaces[count].bgm);
  }, [spaces, count, bgmMode]);

  useEffect(() => {
    if (currentIndex >= spaces.length - 3 && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [isFetchingNextPage, hasNextPage, spaces, currentIndex, fetchNextPage]);

  useEffect(() => {
    if (spaces.length > 0) {
      if (count >= spaces.length - 1 && hasNextPage) {
        fetchNextPage();
      }
    }
  }, [spaces, count, fetchNextPage]);
  return (
    // FIXME: Umm... unmounting a Canvas leaks WebGL contexts? Seems like a r3f bug...
    <Suspense
      fallback={
        <div
          className='grow'
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <img src={loadingIcon} style={{ height: '30px' }} />
          <div style={{ height: '30px' }}>loading...</div>
        </div>
      }>
      <VRCanvas
        style={{ position: 'fixed', inset: 0, zIndex: -1 }}
        onCreated={(state) => state.events.connect(spacesElRef.current)}>
        <PreloadPopularModels />
        {spaces.length > 0 ? (
          <Objects
            space={spaces[count < 0 ? (count = 0) : count > spaces.length - 1 ? (count = spaces.length - 1) : count]}
            mode={mode}
            device={device}
          />
        ) : null}
        <SuspensefulUserProfile />
        <Physics>
          {/* <Debug color={'black'} scale={1.1}> */}
          {spaces[count < 0 ? (count = 0) : count > spaces.length - 1 ? (count = spaces.length - 1) : count]?.theme ===
          3 ? (
            <Theme3Primitiveform
              THREE_WIDTH={THREE_WIDTH + 15}
              THREE_HEIGHT={THREE_HEIGHT}
              THREE_DEPTH={THREE_DEPTH * 2}
            />
          ) : spaces[count < 0 ? (count = 0) : count > spaces.length - 1 ? (count = spaces.length - 1) : count]
              ?.theme === 4 ? (
            <Theme3Primitiveform
              THREE_WIDTH={THREE_WIDTH * 2}
              THREE_HEIGHT={THREE_HEIGHT}
              THREE_DEPTH={THREE_DEPTH * 2}
            />
          ) : spaces[count < 0 ? (count = 0) : count > spaces.length - 1 ? (count = spaces.length - 1) : count]
              ?.theme === 5 ? (
            <Theme3Primitiveform
              THREE_WIDTH={THREE_WIDTH - 10}
              THREE_HEIGHT={THREE_HEIGHT}
              THREE_DEPTH={THREE_DEPTH - 10}
            />
          ) : (
            <Primitiveform THREE_WIDTH={THREE_WIDTH} THREE_HEIGHT={THREE_HEIGHT} THREE_DEPTH={THREE_DEPTH} />
          )}
          {/* <Debug color='black' scale={1.1}> */}
          <MovementController
            hand='right'
            rotationSensitivity={0.05}
            horizontalAxis={2}
            forwardAxis={3}
            rotationAxis={2}
            count={count}
            spacelength={spaces.length}
            deadzone={0.05}
            applyRotation={true}
          />
          <MovementController
            hand='left'
            count={count}
            rotationSensitivity={0.05}
            horizontalAxis={2}
            forwardAxis={3}
            rotationAxis={2}
            deadzone={0.05}
            spacelength={spaces.length}
            applyRotation={false}
          />
          {/* </Debug> */}
        </Physics>
        <DefaultXRControllers />
      </VRCanvas>
      )<main ref={spacesElRef} className='grow relative'></main>
    </Suspense>
  );
}
