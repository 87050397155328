/*
  TODO
  반응형 적용 시 앞으로 세분화할 예정
*/
export const BreakPoint = {
  mobile: '@media (max-width: 1224px)',
} as const;

export const Color = {
  white: '#FFFFFF',
  black: '#000000',
  grayScale1: '#333333',
  grayScale2: '#4D4D4D',
  grayScale3: '#808080',
  grayScale4: '#B3B3B3',
  grayScale8: '#EEEEEE',
  error: '#FB3939',
  whiteAlpha: (opacity: `0.${number}`) => `rgba(255, 255, 255, ${opacity})`,
} as const;
