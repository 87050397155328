import { Dispatch, useState } from 'react';
import { SetStateAction } from 'jotai';
import BgmPlayer from './bgm-player';

export default function BgmList({ bgm, setBgm }: { bgm: number; setBgm: Dispatch<SetStateAction<number>> }) {
  const bgmNames = [
    'Violin Concerto No. 1 in A minor BWV 1041: II. Andante',
    'Violin Concerto No. 1 in A minor BWV 1041: I. Allegro moderato',
    'Violin Concerto No. 1 in A minor BWV 1041: III. Allegro assai',
    'Violin Concerto No. 2 in E major BWV 1042: III. Allegro assai',
    'Concerto for Two Violins in D minor BWV 1043:  III. Allegro',
    'Romance for Violin and Orchestra No. 2 in F major op. 50',
  ];
  const producterNames = ['', '', '', '', '', ''];
  let audioUrl1 = new URL(
    '../../../static/bgms/CD1-01_Bach-Violin_Concerto_No1-Oistrakh1962-Track01.mp3',
    import.meta.url
  );
  let audioUrl2 = new URL(
    '../../../static/bgms/CD1-02_Bach-Violin_Concerto_No1-Oistrakh1962-Track02.mp3',
    import.meta.url
  );
  let audioUrl3 = new URL(
    '../../../static/bgms/CD1-03_Bach-Violin_Concerto_No1-Oistrakh1962-Track03.mp3',
    import.meta.url
  );
  let audioUrl4 = new URL(
    '../../../static/bgms/CD1-06_Bach-Violin_Concerto_No2-Oistrakh1962-Track3.mp3',
    import.meta.url
  );
  let audioUrl5 = new URL(
    '../../../static/bgms/CD1-09-Bach-Concerto_for_2_Violins_BWV1043-Oistrakh1961-Track3.mp3',
    import.meta.url
  );
  let audioUrl6 = new URL('../../../static/bgms/CD1-11-Beethoven-Violin_Romance_No2-Oistrakh1962.mp3', import.meta.url);
  let audioUrls: string[] = [];
  const [currentAudio, setCurrentAudio] = useState<number>(0);
  audioUrls.push(audioUrl1.href, audioUrl2.href, audioUrl3.href, audioUrl4.href, audioUrl5.href, audioUrl6.href);
  let [isPlaying, setIsPlaying] = useState<{ index: number; play: boolean }>({ index: 1, play: false });
  return (
    <div className='px-6'>
      <div className='bgm-container'>
        {audioUrls.map((audioUrl, index) => (
          <BgmPlayer
            audioUrl={audioUrl}
            bgmName={bgmNames[index]}
            prodName={producterNames[index]}
            key={index}
            index={index}
            isChecked={currentAudio === index}
            setCurrentAudio={setCurrentAudio}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            setBgm={setBgm}></BgmPlayer>
        ))}
      </div>
    </div>
  );
}
