export type ButtonColor = 'primary' | 'secondary';
export type ButtonVariant = 'filled' | 'outlined';
export type ButtonType = 'submit' | 'button';

export function Button({
  text,
  type = 'button',
  color = 'primary',
  style = 'filled',
  fontWeight,
  fontSize,
  ...props
}: {
  text: string;
  type?: ButtonType;
  color?: ButtonColor;
  style?: ButtonVariant;
  fontWeight?: string;
  fontSize?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  let className = 'bg-pink-600 rounded text-white p-1 h-12';

  if (color === 'primary' && style === 'outlined') {
    className = 'border border-pink-600 rounded text-pink-600 p-1 h-14';
  } else if (color === 'secondary' && style === 'filled') {
    className = 'bg-zinc-400 rounded text-white p-1 h-12';
  } else if (color === 'secondary' && style === 'outlined') {
    className = 'border border-zinc-500 rounded text-zinc-500 p-1 h-12';
  }

  if (fontWeight) {
    if (fontWeight === 'semibold') {
      className += ' font-semibold';
    }
  }
  if (fontSize) {
    if (fontSize === 'xs') {
      className += ' text-xs';
    }
  }

  return (
    <button type={type} className={className} {...props}>
      {text}
    </button>
  );
}
