import React, { useEffect, useMemo } from 'react';

import { InstanceMessageModalContainer } from './styles';
import { InstanceMessageModalProps } from './types';

import useModal from 'hooks/useModal';

function InstanceMessageModal({ texts, timeout = 2000 }: InstanceMessageModalProps): JSX.Element {
  const { closeModal } = useModal();

  useEffect(() => {
    setTimeout(() => closeModal(), timeout);
  }, [timeout]);

  const textMessage = useMemo(
    () =>
      texts.map((text, index) => {
        if (text.type === 'NORMAL') return <React.Fragment key={index}>{text.message}</React.Fragment>;
        else return <br key={index} />;
      }),
    [texts]
  );

  return <InstanceMessageModalContainer>{textMessage}</InstanceMessageModalContainer>;
}

export default InstanceMessageModal;
