import { Environment, useAnimations } from '@react-three/drei';
import { useMemo, useEffect } from 'react';
import * as THREE from 'three';
import { SkeletonUtils } from 'three-stdlib';
import { useCompressedGLTF } from 'hooks/hooks';

const url = new URL('static/models/Adler_0043.min.glb', import.meta.url);
const url3 = new URL('static/models/BADDACK.min.glb', import.meta.url);
const url2 = new URL('static/imgs/backlight5.hdr', import.meta.url);

export default function DiagonalThema({}: {}) {
  const { scene, animations } = useCompressedGLTF(url.href);
  const clonedScene = useMemo(() => SkeletonUtils.clone(scene), [scene]);
  const { ref: ref, actions: actions, names: names } = useAnimations(animations);
  const disposeMesh: THREE.Mesh[] = [];
  const disposeTexture: THREE.Texture[] = [];

  clonedScene.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      const mesh = object as THREE.Mesh;

      mesh.receiveShadow = true;

      mesh.material = (mesh.material as THREE.Material).clone();
      mesh.material.toneMapped = false;
      mesh.material.metalness = 1;
      mesh.material.roughness = 0;
      const mat = mesh.material as THREE.Material;
      disposeMesh.push(mesh);
      if (mat.map !== null) {
        disposeTexture.push(mat.map);
      }
    }
  });
  const { scene: scene2 } = useCompressedGLTF(url3.href);
  const clonedScene2 = useMemo(() => SkeletonUtils.clone(scene2), [scene2]);

  clonedScene2.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      const mesh = object as THREE.Mesh;

      mesh.receiveShadow = true;

      const mat = mesh.material as THREE.Material;
      mat.toneMapped = false;
      mat.metalness = 1;
      mat.roughness = 0.08;

      disposeMesh.push(mesh);
      if (mat.map !== null) {
        disposeTexture.push(mat.map);
      }
    }
  });
  useEffect(() => {
    for (let i = 0; i < names.length; i++) {
      actions[names[i]]?.reset().fadeIn(0.5).play();
    }
  }, [actions, names]);

  useEffect(() => {
    return () => {
      for (let i = 0; i < disposeMesh.length; i++) {
        if (disposeMesh[i]) disposeMesh[i].geometry.dispose();
      }
      for (let i = 0; i < disposeTexture.length; i++) {
        if (disposeTexture[i]) disposeTexture[i].dispose();
      }
    };
  }, []);
  return (
    <>
      <color attach='background' args={['#dae1e5']} />
      <Environment background={false} files={url2.href} />
      <group ref={ref} position={[0, -1.8, 0.9]} scale={1} rotation={[0, 0, 0]}>
        <primitive object={clonedScene} />
      </group>
      <group position={[0, -1.8, 0.9]} scale={1} rotation={[0, 0, 0]}>
        <primitive object={clonedScene2} />
      </group>
    </>
  );
}
