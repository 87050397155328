import styled from 'styled-components';

import { BreakPoint, Color } from 'src/constants';

export const URLModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30rem;
  padding: 1rem;
  background-color: ${Color.white};
  border-radius: 0.3125rem;
  font-weight: 300;

  ${BreakPoint.mobile} {
    width: 18rem;
  }
`;

export const ModalNavbarContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1.25rem;
  align-items: center;
`;

export const ModalNavbarSide = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  justify-content: flex-end;
`;

export const URLInputContainer = styled.div`
  background: #f7f7f7;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  width: 100%;
  height: 2.625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.375rem 1rem;
  gap: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

export const URLInput = styled.input`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CopyButton = styled.button`
  color: #0d6efd;
`;
