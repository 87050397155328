import { Clone } from '@react-three/drei';
import React, { useMemo } from 'react';
import { useGraph } from '@react-three/fiber';
import * as THREE from 'three';
import { SkeletonUtils } from 'three-stdlib';

import { useCompressedGLTF } from 'hooks/hooks';

let url = new URL('static/models/cart3.glb', import.meta.url);

export default function CartoonBox() {
  let { scene: scene1, nodes } = useCompressedGLTF(url.href);
  let nodeNames: any[] = [];
  let modelArr = [];

  scene1.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      let mesh = object as THREE.Mesh;
      if (['Plane007_2'].includes(mesh.name)) {
        mesh.position.y = 100;
      }
      mesh.renderOrder = 2;
      mesh.receiveShadow = true;
      mesh.material = mesh.material as THREE.Material;
      mesh.material.toneMapped = true;
      mesh.material.stencilWrite = true;
      mesh.material.stencilRef = 1;

      mesh.material.stencilFunc = THREE.EqualStencilFunc;
      mesh.material.stencilFail = THREE.KeepStencilOp;
      mesh.material.stencilZFail = THREE.KeepStencilOp;
      mesh.material.stencilZPass = THREE.ReplaceStencilOp;
    }
  });

  Object.values(nodes).map((value) => {
    nodeNames.push(value);
  });

  for (let i = 1; i < nodeNames.length; i++) {
    modelArr.push(<Clone key={i} renderOrder={2} object={nodeNames[i]} />);
  }
  return (
    <group position={[0, 0.0, 0]} scale={1} rotation={[0, Math.PI / 2, 0]}>
      {modelArr}
    </group>
  );
}
