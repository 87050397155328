import { useCallback, useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';

import { Button } from './styles';

import { APIError, followUser, unfollowUser } from 'apis/index';
import { languageModeAtom, tokenAtom } from 'src/atoms';
import Alert from 'components/Commons/alert';
import useModal from 'hooks/useModal';

interface Scripts {
  KR: string[];
  EN: string[];
  JP: string[];
}

const scripts: Scripts = {
  KR: ['팔로우', '팔로잉'],
  EN: ['follow', 'following'],
  JP: ['フォロー', 'フォローイング'],
};

export default function FollowButton({ username, isFollowing }: { username: string; isFollowing: boolean }) {
  const languageMode = useAtomValue(languageModeAtom);
  const token = useAtomValue(tokenAtom);
  const [currentFollowing, setCurrentFollowing] = useState<boolean>(isFollowing);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { openAlertModal } = useModal();

  useEffect(() => {
    if (errorMessage) {
      openAlertModal([{ type: 'NORMAL', message: errorMessage }]);
    }
  }, [errorMessage]);

  const followOrUnfollow = useCallback(
    async (currentFollowing: boolean) => {
      if (token) {
        try {
          let status = null;
          if (currentFollowing) {
            status = await unfollowUser(token, username);
          } else {
            status = await followUser(token, username);
          }
          if (status === 200) {
            setCurrentFollowing((currentFollowing) => !currentFollowing);
          }
        } catch (error) {
          if (error instanceof APIError) {
            setErrorMessage(error.issues.detail);
          }
        }
      }
    },
    [currentFollowing]
  );
  return (
    <>
      <Button
        type='button'
        currentFollowing={currentFollowing}
        onClick={() => {
          followOrUnfollow(currentFollowing);
        }}>
        {currentFollowing ? scripts[languageMode][1] : scripts[languageMode][0]}
      </Button>
    </>
  );
}
