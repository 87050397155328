import styled from 'styled-components';

export const FollowUserSearchContainer = styled.div`
  margin: 1rem 0;
  position: relative;
  width: calc(100% - 2rem);
`;

export const SearchInput = styled.input`
  width: 100%;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: -0.02rem;
  background-color: #eeeeee;
  border-radius: 1.25rem;
  padding: 0.25rem 0 0.25rem 1rem;

  &:focus {
    outline: none;
  }
`;

export const SearchIconContainer = styled.div`
  position: absolute;
  top: 0.375rem;
  right: 0.5rem;
  width: 1rem;
  height: 1rem;
  object-fit: scale-down;

  &:hover {
    cursor: pointer;
  }
`;
