import { Clone } from '@react-three/drei';
import * as THREE from 'three';

import { useCompressedGLTF } from 'hooks/hooks';

let url = new URL('static/models/frame.min.glb', import.meta.url);

export default function ImageFrameBox({ position }: { position: number[] }) {
  let { scene: scene1, nodes } = useCompressedGLTF(url.href);
  let nodeNames: any[] = [];
  let modelArr = [];

  scene1.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      let mesh = object as THREE.Mesh;

      mesh.renderOrder = 2;
      mesh.receiveShadow = true;
      mesh.material = mesh.material as THREE.Material;
      mesh.material.toneMapped = true;
      mesh.material.stencilWrite = true;
      mesh.material.stencilRef = 1;

      mesh.material.stencilFunc = THREE.EqualStencilFunc;
      mesh.material.stencilFail = THREE.KeepStencilOp;
      mesh.material.stencilZFail = THREE.KeepStencilOp;
      mesh.material.stencilZPass = THREE.ReplaceStencilOp;
    }
  });

  Object.values(nodes).map((value) => {
    if (value.name != 'mesh_0') {
      nodeNames.push(value);
    }
  });

  for (let i = 1; i < nodeNames.length; i++) {
    modelArr.push(<Clone key={i} renderOrder={2} object={nodeNames[i]} />);
  }

  return (
    <group>
      <group
        position={[position[0] + 10.92, position[1] + 1.4, position[2] - 15.38]}
        scale={0.000557}
        rotation={[0, Math.PI / 2, 0]}>
        {modelArr}
      </group>
    </group>
  );
}
