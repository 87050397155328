import { BreakPoint, Color } from 'src/constants';
import { exitCode } from 'process';
import styled from 'styled-components';

export const InfoModalContainer = styled.div`
  display: flex;
  bottom: 0rem;
  align-items: flex-end;
  position: absolute;
  width: 100%;
  padding: 1rem 0.5rem 1.5rem 1rem;
  gap: 0.5rem;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.14) 22.92%,
    rgba(32, 32, 32, 0.336364) 64.58%,
    rgba(66, 66, 66, 0.7) 100%
  );
  color: ${Color.white};
`;

export const StyleInfo = styled.div`
  height: 100%;
  grid-template-rows: repeat(2, minmax(0, 1fr));
  align-content: flex-end;
  flex: 1 1 0%;
`;
export const InfoModalStyle = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  flex-grow: 0;
`;
