import styled from 'styled-components';

import { CustomModalOverlayProps, CustomModalWrapperAlign } from './types';

export const CustomModalContainer = styled.div`
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`;

export const CustomModalOverlay = styled.div<CustomModalOverlayProps>`
  width: 100%;
  height: 100%;
  background-color: ${({ isOverlayed }) => (isOverlayed ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)')};
`;

export const CustomModalWrapper = styled.div<{ isAlign?: CustomModalWrapperAlign }>`
  position: relative;
  width: ${({ isAlign }) => (isAlign ? '100%' : 'auto')};
  height: ${({ isAlign }) => (isAlign ? '100vh' : 'auto')};
  display: flex;
  justify-content: ${({ isAlign }) => (isAlign ? isAlign.justifyContent : 'unset')};
  align-items: ${({ isAlign }) => (isAlign ? isAlign.alignItems : 'unset')};
`;
