import { useCallback } from 'react';
import {
  ContentText,
  DivisionBorder,
  IndexText,
  NewWindowLink,
  SubTitle,
  TermsOfServiceArea,
  TermsOfServiceContinaer,
  Title,
} from './styles';

function TermsOfService(): JSX.Element {
  const onNewWindowLinkClick = useCallback((url: string) => {
    if (url.indexOf('http') < 0) {
      return window.open(`https://${url}`);
    }

    window.open(url);
  }, []);

  return (
    <TermsOfServiceContinaer>
      <TermsOfServiceArea>
        <Title>[이용약관]</Title>
        <SubTitle>아들러&#40;Adler&#41; 및 제반 서비스 이용과 관련하여 필요한 사항을 규정합니다.</SubTitle>
        <DivisionBorder />
        <IndexText>제1조 (목적)</IndexText>
        <ContentText>
          본 약관은 아들러(Adler) 주식회사(이하 “Adler” 또는 “회사”)가 운영하는 웹 사이트 (
          <NewWindowLink onClick={() => onNewWindowLinkClick('http://adler.cx')}>http://adler.cx</NewWindowLink>),
          Adelr앱 및 이에 부수된 제반 서비스(이하 “서비스” 또는 “Adler”)의 이용과 관련하여 회사와 이용자와의 권리, 의무
          및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다. 본 이용약관에 동의하지 않는 경우 Adler를 이용할 수
          없으므로, 사용 전 신중하게 확인한 후 동의하여 주시기 바랍니다.
        </ContentText>
        <IndexText>제2조 (정의)</IndexText>
        <ContentText>
          이 약관에서 사용하는 용어는 아래와 같습니다.
          <ol>
            <li>
              "회원"이라 함은 회사의 "서비스"에 접속하여 이 약관에 따라 "회사"와 이용계약을 체결하고 "회사"가 제공하는
              "서비스"를 이용하는 고객을 말합니다.
            </li>
            <li>
              “인플루언서 회원”이라 함은 활동열정, 영향력 등 회사가 정한 내부 기준을 충족한 회원으로서 서비스 이용 시
              회사로부터 일정 혜택을 받는 회원을 의미합니다.
            </li>
            <li>
              "아이디(ID)"라 함은 "회원"의 식별과 서비스 이용을 위하여 "회원"이 정하고 "회사"가 승인하는 문자와 숫자의
              조합을 의미합니다.
            </li>
            <li>
              "비밀번호"라 함은 "회원"이 부여받은 "아이디와 일치되는 "회원"임을 확인하고 비밀보호를 위해 "회원" 자신이
              정한 문자 또는 숫자의 조합을 의미합니다.
            </li>
            <li>
              "유료서비스"라 함은 "서비스" 중 "회사"가 유료로 제공하는 각종 웹사이트 구축 서비스 및 제반 서비스를
              의미합니다.
            </li>
            <li>
              "게시물" 혹은 "콘텐츠"이라 함은 회원이 서비스를 이용함에 있어 서비스상에 게시한
              부호ᆞ문자ᆞ음성ᆞ음향ᆞ화상ᆞ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.
            </li>
          </ol>
        </ContentText>
        <IndexText>제3조 (약관의 게시와 개정)</IndexText>
        <ContentText>
          <ol>
            <li>
              "회사"는 이 약관의 내용을 "회원"이 쉽게 알 수 있도록 서비스 초기 화면 내 또는 별도의 연결화면에 게시하거나
              팝업화면 등으로 제공합니다.
            </li>
            <li>
              "회사"는 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 “정보통신망법”), 전자상거래
              등에서의소비자보호에 관한 법률(이하 “전자상거래법”), 약관의 규제에 관한 법률(이하 “약관법”) 등 관련 법을
              위반하지 않는 범위에서 이 약관을 개정할 수 있습니다.
            </li>
            <li>
              회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 서비스의 초기화면에 그
              적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는
              최소한 30일 이상의 사전 유예기간을 두고 개별 통지합니다.
            </li>
            <li>
              회사가 전항에 따라 개정약관을 공지하면서 회원에게 30일간의 기간 내에 의사 표시를 하지 않으면 의사표시가
              표명된 것으로 본다는 뜻을 명확하게 고지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우
              회원이 개정약관에 동의한 것으로 봅니다.
            </li>
          </ol>
        </ContentText>
        <IndexText>제4조 (약관의 해석)</IndexText>
        <ContentText>
          <ol>
            <li>
              회사는 개별 서비스에 대해서는 별도의 이용약관 및 정책(이하 “기타 약관 등”)을 둘 수 있으며, 해당 내용이 이
              약관과 상충할 경우에는 기타 약관 등이 우선하여 적용됩니다.
            </li>
            <li>이 약관에서 정하지 아니한 사항이나 해석에 대해서는 기타 약관 등 및 관계법령 또는 상관례에 따릅니다.</li>
          </ol>
        </ContentText>
        <IndexText>제5조 (이용계약 체결)</IndexText>
        <ContentText>
          <ol>
            <li>
              이용계약은 "회원"이 되고자 하는 자(이하 "가입신청자")가 약관의 내용에 대하여 동의를 한 다음 회원가입신청을
              하고 "회사"가 이러한 신청에 대하여 승낙함으로써 체결됩니다.
            </li>
            <li>
              "회사"는 "가입신청자"의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다. 다만, "회사"는 다음 각 호에
              해당하는 신청에 대하여는 그 승낙을 유보 또는 거부하거나, 사후에 이용계약을 해지할 수 있고, 필요한 경우
              일정기간 동안 서비스 이용을 제한할 수 있습니다.
              <ul>
                <li>가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우</li>
                <li>실명이 아니거나 타인의 명의를 이용한 경우</li>
                <li>허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우</li>
                <li>
                  14세 미만 아동이 회원가입을 신청한 경우 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한
                  제반 사항을 위반하며 신청하는 경우
                </li>
                <li>회원가입신청에 서비스 시스템에의 기계적 접근 또는 계정 도용</li>
                <li>“가입신청자”의 서비스 어뷰징 내역 확인 등</li>
              </ul>
            </li>
            <li>
              "회사"는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수
              있습니다.
            </li>
            <li>
              제2항과 제3항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, "회사"는 원칙적으로 이를
              가입신청자에게 알리도록 합니다.
            </li>
            <li>이용계약의 성립 시기는 "회사"가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.</li>
            <li>
              "회사"는 "회원"에 대해 회사정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여
              이용에 차등을 둘 수 있습니다.
            </li>
          </ol>
        </ContentText>
        <IndexText>제6조 (회원정보의 변경)</IndexText>
        <ContentText>
          <ol>
            <li>"회원"은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다.</li>
            <li>
              "회원"은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로
              "회사"에 대하여 그 변경사항을 알려야 합니다.
            </li>
            <li>제2항의 변경사항을 "회사"에 알리지 않아 발생한 불이익에 대하여 "회사"는 책임지지 않습니다.</li>
          </ol>
        </ContentText>
        <IndexText>제7조 (개인정보보호 의무)</IndexText>
        <ContentText>
          "회원"이 "회사"의 "서비스"에 제공하는 개인정보 일체는 개인정보처리방침에 따르며, "회사"가 귀하의 정보를 수집
          및 이용하는 행위는 동 정책에 따라 규율됩니다.
        </ContentText>
        <IndexText>제8조 ("회원"의 "아이디" 및 "비밀번호"의 관리에 대한 의무)</IndexText>
        <ContentText>
          "회원"은 본 서비스에서 사용하는 비밀번호와 관련된 모든 행동을 보호/관리해야 합니다. "회사"는 귀하의 계정에
          비밀번호를 강력한 암호(대문자, 소문자 및 숫자, 기호의 조합)로 설정할 것을 권장합니다. "회사"는 위 사항을
          만족하지 못했을 때 일어난 어떠한 손실이나 피해에 대해 책임지지 않습니다.
        </ContentText>
        <IndexText>제9조 ("회원"에 대한 통지)</IndexText>
        <ContentText>
          <ol>
            <li>
              "회사"가 "회원"에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한 "회원"이 지정한 전자우편주소, 서비스
              내 전자메모 및 쪽지 등으로 할 수 있습니다.
            </li>
            <li>
              "회사"는 "회원" 전체에 대한 통지의 경우 7일 이상 "회사"의 게시판에 게시함으로써 제1항의 통지에 갈음할 수
              있습니다.
            </li>
          </ol>
        </ContentText>
        <IndexText>제10조 ("회사"의 의무)</IndexText>
        <ContentText>
          <ol>
            <li>
              "회사"는 관련법과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로
              "서비스"를 제공하기 위하여 최선을 다하여 노력합니다.
            </li>
            <li>
              "회사"는 "회원"이 안전하게 "서비스"를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을
              갖추어야 하며 개인정보보호정책을 공시하고 준수합니다.
            </li>
            <li>
              "회사"는 서비스이용과 관련하여 "회원"으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를
              처리하여야 합니다. 회원이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여
              "회원"에게 처리과정 및 결과를 전달합니다.
            </li>
            <li>
              "회사"는 서비스이용에 미풍양속을 해치지 않는 건전한 서비스이용을 위해 서비스 내 발생하는 대중적으로 불쾌감
              및 혐오감을 주는 행위, 사용자를 학대하는 행위, 현행법에 위반하는 행위를 방지하기 위해 지속적으로 서비스
              전반을 검토합니다.
            </li>
          </ol>
        </ContentText>
        <IndexText>제11조 (서비스 이용의 중지 또는 계약의 해지)</IndexText>
        <ContentText>
          “회원”은 본 이용약관에서 명시한 제한 사항과 더불어 다음의 행위를 삼가는 데 동의합니다. 본 이용약관에서
          명시적으로 허용하는 경우를 제외하고, “회사”의 사전 서면 동의 없이 Adler IP 콘텐츠 및 서비스를 이용하는 행위는
          엄격히 금지되며, 이러한 행위가 발생할 경우, 본 이용약관에서 부여한 라이선스 사용 권한이 종료됩니다.
          <ol>
            <li>
              "회원"은 다음 행위를 하여서는 안 됩니다.
              <ul>
                <li>타인이나 기관 또는 그 관계를 사칭하는 행위</li>
                <li>서비스 보안을 훼손하는 행위</li>
                <li>원치 않는 광고, 스팸, 권유, 판촉 자료를 동의 없이 전송하는 행위</li>
                <li>
                  “회사”에서 제공하지 않은 로봇, 스파이더, 크롤러, 스크래퍼 및 기타 자동화된 수단이나 인터페이스를
                  사용하여 서비스에 접근하거나 데이터를 추출하는 행위
                </li>
                <li>
                  서비스 일부를 역설계하거나 서비스의 영역, 콘텐츠, 코드에 대한 접근을 방지하고 제한하기 위해 설계된
                  장치를 우회하거나 회피하고, 소스 코드를 찾아내려는 행위
                </li>
                <li>
                  “회사”에서 사용하는 콘텐츠 필터링 기술을 우회하거나 접근 권한이 없는 서비스의 영역 및 기능에 접근을
                  시도하는 행위
                </li>
                <li>
                  서비스에서 명시적으로 허용하는 경우를 제외하고, “회사” IP 콘텐츠 및 서비스, 그에 포함된 일체의 정보를
                  다운로드하는 행위(페이지 캐싱 제외)
                </li>
                <li>“회사”와의 관계를 드러내거나 회사의 명시적인 서면 동의 없이 제품 및 서비스를 홍보하는 행위</li>
                <li>괴롭힘, 협박, 위협 및 스토킹 등에 가담하는 행위</li>
                <li>동의 없이 사진을 촬영하는 등 타인의 초상권, 사생활, 정보보호 권리를 침해하는 행위</li>
                <li>타인의 특허권, 상표권, 영업 비밀, 저작권, 기타 지적 재산권, 소유권을 침해하는 행위</li>
                <li>
                  금전을 대가로 “회사”의 사전 서면 동의 없이 회사 계정, 메시지, 사용자명, 친구 링크에 대한 접근 권한을
                  매매, 임대, 대여, 기타 방식으로 판매하는 행위
                </li>
                <li>
                  “회사”의 사전 서면 동의 없이 사용자 콘텐츠 또는 서비스와 상호작용할 수 있는 제3자 앱을 개발하는 행위
                </li>
                <li>불법 또는 무단으로 서비스를 이용하거나 본 이용약관을 위반하는 행위를 권장 또는 촉진하는 행위</li>
                <li>"회사"에 게시된 정보를 권한없이 변경하는 행위</li>
                <li>"회사"와 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
                <li>"회사" 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
                <li>
                  다른 “회원”의 서비스 이용을 방해, 중단 및 제한하고, 이에 부정적인 영향을 주거나, 서비스 기능에 손상,
                  장애, 과부하, 파손을 초래하는 방식으로 서비스를 이용하는 행위
                </li>
                <li>
                  외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 "회사"에 공개 또는 게시하는 행위
                </li>
                <li>회사에서 별도 규정한 콘텐츠 원칙에 어긋나거나, 성격에 부합하지 않는 행위</li>
                <li>기타 불법적이거나 부당한 행위</li>
              </ul>
            </li>
            <li>
              "회원"은 관계법, 본 약관의 규정, 이용안내 및 "서비스"와 관련하여 공지한 주의사항, "회사"가 통지하는 사항
              등을 준수하여야 하며, 기타 "회사"의 업무에 방해되는 행위를 하여서는 안 됩니다. “회원”이 이를 위반하는
              행위로 “회사” 또는 “제3자”에게 손해가 발생한 경우 “회원”은 그 손해를 배상하여야 합니다.
            </li>
          </ol>
        </ContentText>
        <IndexText>제12조 (서비스의 제공 등)</IndexText>
        <ContentText>
          <ol>
            <li>서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.</li>
            <li>
              "회사"는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우
              서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 "회사"는 제9조("회원"에 대한 통지)에 정한 방법으로
              "회원"에게 통지합니다. 다만, "회사"가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수
              있습니다.
            </li>
            <li>
              "회사"는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스제공화면에 공시한
              바에 따릅니다.
            </li>
            <li>
              사업종목의 전환, 사업의 포기 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 "회사"는 제9조(회원에 대한
              통지)에서 정한 방법으로 "회원"에게 통지합니다.
            </li>
          </ol>
        </ContentText>
        <IndexText>제13조 (서비스의 변경)</IndexText>
        <ContentText>
          <ol>
            <li>
              "회사"는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 서비스를
              변경할 수 있습니다. 서비스의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될
              서비스의 내용 및 제공일자 등은 그 변경 전 7일 이상 해당 서비스 초기화면에 게시하여야 합니다.
            </li>
            <li>
              "회사"는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수
              있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.
            </li>
          </ol>
        </ContentText>
        <IndexText>제14조 (정보의 제공)</IndexText>
        <ContentText>
          <ol>
            <li>
              "회사"는 "회원"이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의 방법으로
              "회원"에게 제공할 수 있습니다. 다만, "회원"은 관련법에 따른 거래관련 정보, 고객센터 답변 등을 제외하고
              언제든지 전자우편 등을 통하여 수신 거절을 할 수 있습니다.
            </li>
            <li>
              “회사”가 광고성 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 "회원"의 사전 동의를 받아서
              전송합니다.
            </li>
          </ol>
        </ContentText>
        <IndexText>제15조 (“Adler”의 콘텐츠 및 “회원” 콘텐츠)</IndexText>
        <ContentText>
          <ol>
            <li>
              “Adler 콘텐츠"란, Adler 서비스를 구성하고 운영하기 위해 회사가 제작한 모든 저작물(맵, 아바타, 아이템,
              영상, 이미지, 텍스트, 그래픽, 음악, 문구 등)을 의미합니다(시각적으로 확인 가능한 자료들을 대상으로 하며,
              컴퓨터 등 정보처리장치 내에서 직, 간접적으로 사용되는 프로그램, 소프트웨어 등은 제외). “Adler IP 콘텐츠”의
              선택과 배치 등을 비롯하여 서비스에 포함된 모든 자료는 저작권, 상표권, 특허권 및/또는 기타 지식재산권은
              “Adler”에게 있으며, 관련 법률에 의해 보호받습니다.
            </li>
            <li>
              사용자는 “Adler IP 콘텐츠”를 이용하여 사진, 동영상, 텍스트, 그래픽, 아이템 및 기타 자료(이하 “사용자
              콘텐츠”)를 제작하고 게시하며, 전송 및 저장하는 등 적극적인 상호작용을 할 수 있습니다. “회사”는 사용자 간의
              적극적인 상호작용을 장려하기 위하여 Adler에서 하는 활동에 기초하여 사용자가 관심을 가질 만한 '콘텐츠',
              기능을 제안하거나 추천할 수 있으며, 사용자에게 Adler를 경험하는 방법을 제안하기도 합니다. 물론, 사용자는
              개인정보 설정에 따라 본인의 “사용자 콘텐츠”를 볼 수 있는 대상을 제한할 수 있습니다.
            </li>
            <li>
              사용자가 제작한 “사용자 콘텐츠”는 타인이 조회할 수 있으며, 사용자는 개인정보 설정 기능을 통해 콘텐츠에
              대한 접근을 관리할 수 있습니다. 회사의 직원은 Adler에 업로드 된 "사용자 콘텐츠"를 확인할 수 있습니다.
              “사용자 콘텐츠”의 저작권 기타 지식재산권은 사용자에게 있습니다.{' '}
            </li>
            <li>
              "회원"이 "서비스" 내에 게시하는 "사용자 콘텐츠"는 검색결과 내지 "서비스" 및 관련 프로모션 등에 노출될 수
              있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있으며, “회원”은 위
              목적에 한하여 회사가 “사용자 콘텐츠”를 보관, 복사, 임시저장, 인코딩, 저장, 기록, 배포, 전송, 방송, 싱크,
              편집, 수정, 공개적으로 전시, 공개 및 게시, 홍보, 제작하는 것에 대해 조건 없이 비독점적이고 취소 불가능하며
              로열티를 지급하지 않는 전세계적인 라이선스를 부여 및 승인합니다. "회원"은 언제든지 고객센터 또는 "서비스"
              내 관리기능을 통해 해당 “사용자 콘텐츠”에 대해 삭제, 검색결과 제외, 비공개 등의 조치를 취할 수 있습니다.
            </li>
            <li>
              "회사"는 제4항 이외의 방법으로 “사용자 콘텐츠”를 이용하고자 하는 경우에는 사전에 회원의 동의를 얻어야
              합니다.
            </li>
          </ol>
        </ContentText>
        <IndexText>제16조 (게시물의 관리)</IndexText>
        <ContentText>
          <ol>
            <li>
              "회원"의 게시물이 "정보통신망법" 및 "저작권법"등 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이
              정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, "회사"와 "회원"은 관련법에 따라
              조치를 취하여야 합니다.
            </li>
            <li>
              "회사"는 전항에 따른 권리자의 요청이 없는 경우 라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및
              관련법에 위반되는 경우에는 관련법에 따라 해당 게시물에 대해 임시조치 등을 취할 수 있습니다.
            </li>
            <li>
              “회원”은 제2항의 조치에 대하여 정당한 사유를 소명하여 이의를 제기할 수 있으며, “회사”는 “회원”의
              이의신청이 정당한 경우 임시조치를 철회할 수 있습니다.
            </li>
          </ol>
        </ContentText>
        <IndexText>제17조 (권리의 귀속)</IndexText>
        <ContentText>
          <ol>
            <li>
              "서비스"에 대한 저작권 등 지식재산권은 회사에 귀속됩니다. 단, 회원의 게시물 및 제휴계약에 따라 제공된
              저작물 등은 제외합니다.
            </li>
            <li>
              "회사"는 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라 계정, 아이디, 콘텐츠 등을 이용할 수 있는
              이용권만을 부여하며, "회원"은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.
            </li>
          </ol>
        </ContentText>
        <IndexText>제18조 ("회원"의 계약해제, 해지 등)</IndexText>
        <ContentText>
          <ol>
            <li>
              "회원"은 언제든지 계정설정 메뉴를 통하여 서비스 탈퇴 신청을 할 수 있으며, "회사"는 관련법령에서 정하는
              바에 따라 이를 즉시 처리하여야 합니다.
            </li>
            <li>
              "회원"이 계약을 해지할 경우, 관련법 및 개인정보처리방침에 따라 회사가 회원정보를 보유하는 경우를
              제외하고는 해지일로부터 1년 경과 후 회원의 개인정보 데이터는 소멸됩니다. 삭제되는 회원의 개인정보는 다음과
              같습니다.
              <ul>
                <li>회원 ID</li>
                <li>회원 닉네임</li>
                <li>회원 프로필 사진</li>
                <li>회원 이메일</li>
                <li>회원 전화번호</li>
                <li>회원 자기소개</li>
                <li>회원 팔로우 / 팔로잉 목록</li>
              </ul>
            </li>
            <li>
              "회원"이 계정을 탈퇴하는 경우, 회원이 삭제하지 않은 다음 데이터는 영구히 저장되며, 별도의 요청을 통해
              삭제가 가능합니다. 별도 요청이 없는 한 영구히 저장되는 데이터는 다음과 같습니다.
              <ul>
                <li>회원이 업로드한 포스트 및 포스트의 설정값</li>
                <li>회원이 작성한 댓글 내용</li>
                <li>회원이 제공한 좋아요 기록</li>
                <li>포스트, 댓글의 작성 시간</li>
              </ul>
            </li>
          </ol>
        </ContentText>
        <IndexText>제19조 (이용제한 등)</IndexText>
        <ContentText>
          <ol>
            <li>
              "회사"는 "회원"이 본 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우, 서비스 이용을 경고,
              일시정지, 계약해지로 단계적으로 제한할 수 있습니다.
            </li>
            <li>
              "회사"는 전항에도 불구하고, "주민등록법"을 위반한 명의도용 및 결제도용, "저작권법" 및
              "컴퓨터프로그램보호법"을 위반한 불법프로그램의 제공 및 운영방해, "정보통신망법"을 위반한 불법통신 및 해킹,
              악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 계약해지를 할 수 있습니다.
              본 항에 따른 계약해지 시 서비스 이용을 통해 획득한 혜택 등도 모두 소멸되며, 회사는 이에 대해 별도로
              보상하지 않습니다.
            </li>
            <li>
              "회사"는 무료회원이 계속해서 3개월 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해
              이용을 제한할 수 있습니다.
            </li>
            <li>
              회사는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 이용제한정책 등에서 정한 바에 의합니다.
            </li>
            <li>
              본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는 "회사"는 제9조["회원"에 대한 통지]에 따라
              통지합니다.
            </li>
            <li>
              "회원"은 본 조에 따른 이용제한 등에 대해 "회사"가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가
              정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.
            </li>
          </ol>
        </ContentText>
        <IndexText>제20조 (책임의 한계)</IndexText>
        <ContentText>
          <ol>
            <li>
              "회사"는 천재지변 또는 이에 준하는 불가항력으로 인하여 "서비스"를 제공할 수 없는 경우에는 "서비스" 제공에
              관한 책임이 면제됩니다.
            </li>
            <li>
              "회사"는 "회원"의 귀책사유로 인한 서비스 이용의 장애에 대하여는 회사의 고의 또는 과실이 없는 한 책임을
              지지 않습니다.
            </li>
            <li>
              "회사"는 "회원"이 "서비스"와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을
              지지 않습니다.
            </li>
            <li>
              "회사"는 "회원" 간 또는 "회원"과 제3자 상호간에 "서비스"를 매개로 하여 거래 등을 한 경우에는 책임이
              면제됩니다.
            </li>
            <li>
              "회사"는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다. 단
              회사의 고의 또는 과실로 인한 경우에는 고의 또는 과실의 범위에서 책임을 부담합니다.
            </li>
            <li>
              “회사"는 "회원"이 "서비스"를 이용하여 기대하는 수익을 보장하지 않습니다. 또한 이와 관련된 손해는 배상하지
              않습니다.
            </li>
          </ol>
        </ContentText>
        <IndexText>제21조 (준거법 및 재판관할)</IndexText>
        <ContentText>
          <ol>
            <li>"회사"와 "회원" 간 제기된 소송은 대한민국법을 준거법으로 합니다.</li>
            <li>"회사"와 "회원"간 발생한 분쟁에 관한 소송은 민사소송법 상의 관할법원에 제소합니다.</li>
          </ol>
        </ContentText>
        <IndexText>부칙</IndexText>
        <ContentText>
          <ol>
            <li>공지일자: 2022년 8월 4일</li>
            <li>적용일자: 2022년 8월 4일</li>
          </ol>
        </ContentText>
      </TermsOfServiceArea>
    </TermsOfServiceContinaer>
  );
}

export default TermsOfService;
