import styled from 'styled-components';

import { BreakPoint, Color } from 'src/constants';

export const CertificateCodeVerifyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InputArea = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const ValidationInputArea = styled.div`
  width: 25rem;

  ${BreakPoint.mobile} {
    width: calc(100% - 4.5rem);
  }
`;

export const Button = styled.div.attrs({ className: 'clickable' })<{
  isActive: boolean;
}>`
  width: 100%;
  font-weight: 700;
  line-height: 1.375rem;
  letter-spacing: -0.025rem;
  text-align: center;
  padding: 0.875rem 0;
  border-radius: 0.25rem;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};

  &:hover {
    opacity: ${({ isActive }) => (isActive ? 0.8 : 0.5)};
  }
`;

export const CertificateCodeVerifyButton = styled(Button)`
  width: calc(100% - 25rem);
  background-color: #f4bcd5;
  color: ${Color.white};

  ${BreakPoint.mobile} {
    width: 4.5rem;
  }
`;

export const InputError = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 0.875rem;
  color: #fb3939;
`;

export const TimerContainer = styled.div`
  width: 100%;
  margin: 0.25rem 0 3rem 0;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: -0.005rem;
  color: #808080;
`;
