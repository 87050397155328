import { Scripts } from 'src/types';
import { UseHeaderResources } from './types';

const scripts: Scripts = {
  KR: ['KR 한국어'],
  EN: ['EN English'],
  JP: ['JP 日本語'],
};

const useHeaderResources = (): UseHeaderResources => {
  return { scripts };
};

export default useHeaderResources;
