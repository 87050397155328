import { ChangeEvent } from 'react';
import { z, ZodError } from 'zod';

import {
  Item,
  Space,
  SpaceSchema,
  SpacePageSchema,
  User,
  UserSchema,
  Token,
  TokenSchema,
  Upload,
  UploadSchema,
  Account,
  AccountSchema,
  Alarm,
  Character,
  CharacterSchema,
  ReplySet,
  ReplySchema,
  AlarmPageSchema,
  SpaceSearchSetSchema,
  UserSearchSetSchema,
  PhoneToken,
  PhoneTokenSchema,
  isReadSchema,
  APIIssues,
  APIIssuesSchema,
  FollowUser,
  FollowUserSchema,
  PatchItem,
  PatchItemSchema,
} from './types';

export class APIError extends Error {
  issues: APIIssues;

  constructor(issues: APIIssues) {
    super();
    this.issues = issues;
  }
}

export type { Item, Space, User, Token, Upload, Account, Alarm, APIIssues };

export type SpaceMode = 'multiplay' | 'scroll';

export const API_BASE_URL = process.env.API_BASE_URL;
//export const COLYSEUS_BASE_URL = 'wss://sw1wsh.colyseus.in:443';
export const COLYSEUS_BASE_URL = 'wss://multiplay.adler3d.com:443';

export const REACT_APP_GOOGLE_TAG_MANAGER_ID = 'GTM-5MBZZZ2';

import { v4 as uuidv4 } from 'uuid';

export async function login(username: string, password: string): Promise<Token> {
  let response = await fetch(API_BASE_URL + '/tokens/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password,
    }),
  });
  let json = await response.json();
  if (response.ok) {
    let UserToken = TokenSchema.parse(json.token);
    return UserToken;
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function getTokenUser(token: Token): Promise<Account> {
  let response = await fetch(API_BASE_URL + `/account/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
  let json = await response.json();
  if (response.ok) {
    if (json.image === null) {
      let object = new URL('../../static/imgs/default-profile.png', import.meta.url); //TODO: 임시 - 용민
      json.image = object.href;
    }
    if (json.phone === null) {
      json.phone = '';
    }
    if (json.email === null) {
      json.email = '';
    }
    if (json.characters) {
      json.characters = json.characters[0];
    }
    let user = AccountSchema.parse(json);
    return user;
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function getUser(username: string): Promise<User> {
  let response = await fetch(API_BASE_URL + `/users/${username}/`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  let json = await response.json();
  if (response.ok) {
    if (json.image === null) {
      let object = new URL('../../static/imgs/default-profile.png', import.meta.url); //TODO: 임시 - 용민
      json.image = object.href;
    }
    let user = UserSchema.parse(json);
    return user;
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function getSpaces(
  token: Token | null,
  cursor: string | null = null
): Promise<{ spaces: Space[]; cursor: string | null }> {
  let headers: HeadersInit = {
    'Content-Type': 'application/json',
  };
  if (token) {
    headers['Authorization'] = `Token ${token}`;
  }
  let response = await fetch(API_BASE_URL + `/spaces/?cursor=${cursor ?? ''}`, {
    headers,
  });
  let json = await response.json();
  if (response.ok) {
    // throw new APIError({ code: 400,  detail: "Error", field: "Error" });
    let { next, results: spaces } = SpaceSearchSetSchema.parse(json);
    let nextCursor = next && next.searchParams.get('cursor');
    return { spaces, cursor: nextCursor };
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function getLastSpaces(
  token: Token | null,
  lastSpaceId: string,
  cursor: string | null = null
): Promise<{ spaces: Space[]; cursor: string | null }> {
  let headers: HeadersInit = {
    'Content-Type': 'application/json',
  };
  if (token) {
    headers['Authorization'] = `Token ${token}`;
  }
  let response = await fetch(
    API_BASE_URL + `/spaces/?after=${lastSpaceId}&cursor=${cursor ?? ''}`,
    {
      headers,
    }
  );
  let json = await response.json();
  if (response.ok) {
    let { next, results: spaces } = SpaceSearchSetSchema.parse(json);
    let nextCursor = next && next.searchParams.get('cursor');

    return { spaces, cursor: nextCursor };
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function getLouvreSpaces(
  token: Token | null,
  cursor: string | null = null
): Promise<{ spaces: Space[]; cursor: string | null }> {
  let headers: HeadersInit = {
    'Content-Type': 'application/json',
  };
  if (token) {
    headers['Authorization'] = `Token ${token}`;
  }
  let response = await fetch(API_BASE_URL + `/spaces/artfair/?cursor=${cursor ?? ''}`, {
    headers,
  });
  let json = await response.json();
  let { next, results: spaces } = SpaceSearchSetSchema.parse(json);
  let nextCursor = next && next.searchParams.get('cursor');
  return { spaces, cursor: nextCursor };
}

export async function getSpace(spaceId: string): Promise<Space> {
  let response = await fetch(API_BASE_URL + `/spaces/${spaceId}/`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  let json = await response.json();
  if (response.ok) {
    if (json.profileImgURL === null) {
      let object = new URL('../../static/imgs/default-profile.png', import.meta.url); //TODO: 임시 - 용민
      json.profileImgURL = object.href;
    }
    let space = SpaceSchema.parse(json);
    return space;
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function uploadFile(token: Token, file: File): Promise<Upload> {
  let formData = new FormData();
  formData.set('file', file);
  let response = await fetch(API_BASE_URL + '/uploads/', {
    method: 'POST',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: formData,
  });
  let json = await response.json();
  let upload = UploadSchema.parse(json);

  if (response.ok) {
    return upload;
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function createSpace(
  token: Token | null,
  items: (Omit<Item, 'id' | 'file'> & { uploadId: string })[],
  thumbId: string,
  description: string,
  bgm: number,
  theme: number,
  lightBright: number,
  theme_color: number,
  theme_saturation: number,
  theme_brightness: number
): Promise<Space> {
  let response = await fetch(API_BASE_URL + '/spaces/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify({
      items,
      thumbId,
      description,
      bgm,
      theme,
      lightBright,
      theme_color,
      theme_saturation,
      theme_brightness,
    }),
  });
  let json = await response.json();
  if (response.ok) {
    let space = SpaceSchema.parse(json);
    return space;
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function readSpace(token: Token | null, id: string) {
  let response = await fetch(API_BASE_URL + '/read/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify({
      spaceId: id,
    }),
  });
  let json = await response.json();
  if (response.ok) {
    let isread = isReadSchema.parse(json);
    return isread;
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function deleteSpace(id: string, token: Token | null): Promise<boolean> {
  let response = await fetch(API_BASE_URL + '/spaces/' + id, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
  if (response.ok) {
    return true;
  } else {
    let json = await response.json();
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function patchSpace(id: string, thumb_id: string | null): Promise<boolean> {
  let response = await fetch(API_BASE_URL + '/spaces/' + id + '/thumbnail/', {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      thumb_id: thumb_id,
    }),
  });

  let json = await response.json();
  if (response.ok) {
    console.log(response);
    return true;
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function verifyUser(token: Token | null): Promise<boolean> {
  let response = await fetch(API_BASE_URL + '/account/phone/verified/', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
  let json = await response.json();
  if (response.ok) {
    let { verified: verified } = z
      .object({
        verified: z.boolean(),
      })
      .parse(json);
    return verified;
  } else if (response.status === 500) {
    return true;
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function updateAccount(
  token: Token,
  username: string,
  nickname: string,
  bio: string,
  url: string,
  uploadId: string
): Promise<Account> {
  // TODO: throw하고 catch 배워서 수정 필요 - 용민
  let response = await fetch(API_BASE_URL + '/account/', {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify({
      username,
      nickname,
      bio,
      url,
      uploadId,
    }),
  });
  if (response.ok) {
    let json = await response.json();
    if (json.image === null) {
      let object = new URL('../../static/imgs/default-profile.png', import.meta.url); //TODO: 임시 - 용민
      json.image = object.href;
    }
    if (json.phone === null) {
      json.phone = '';
    }
    if (json.email === null) {
      json.email = '';
    }
    if (json.characters) {
      json.characters = json.characters[0];
    }
    let register = AccountSchema.parse(json);
    return register;
  } else {
    let json = await response.json();
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function followUser(token: Token, following_username: string): Promise<number> {
  let response = await fetch(API_BASE_URL + `/users/${following_username}/followers/`, {
    method: 'POST',
    headers: {
      Authorization: `Token ${token}`,
    },
  });
  if (response.ok) {
    return response.status;
  } else {
    let json = await response.json();
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function unfollowingUser(token: Token, following_username: string): Promise<number> {
  const response = await fetch(API_BASE_URL + `/users/${following_username}/followings/`, {
    method: 'DELETE',
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  return response.status;
}

export async function unfollowUser(token: Token, following_username: string): Promise<number> {
  let response = await fetch(API_BASE_URL + `/users/${following_username}/followers/`, {
    method: 'DELETE',
    headers: {
      Authorization: `Token ${token}`,
    },
  });
  if (response.ok) {
    return response.status;
  } else {
    let json = await response.json();
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function getFollowers(
  follower_url: string,
  token: string
): Promise<{ users: FollowUser[]; total_length: number; next_url: string }> {
  let response = token
    ? await fetch(API_BASE_URL + follower_url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`,
        },
      })
    : await fetch(API_BASE_URL + follower_url, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  let json = await response.json();
  if (response.ok) {
    let total_length = json['results']['totalLength'];
    let next_url = json.next ? json.next.slice(json.next.lastIndexOf('users') - 1) : '';
    let { users: users } = z
      .object({
        users: z.array(FollowUserSchema),
      })
      .parse(json['results']);
    return { users: users, total_length: total_length, next_url: next_url };
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function getFollowings(
  following_url: string
): Promise<{ users: FollowUser[]; total_length: number; next_url: string }> {
  let response = await fetch(API_BASE_URL + following_url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  let json = await response.json();
  if (response.ok) {
    let total_length = json['results']['totalLength'];
    let next_url = json.next ? json.next.slice(json.next.lastIndexOf('users') - 1) : null;
    let { users: users } = z
      .object({
        users: z.array(FollowUserSchema),
      })
      .parse(json['results']);
    return { users: users, total_length: total_length, next_url: next_url };
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function isFollow(
  token: Token,
  following_username: string,
  follower_username: string
): Promise<boolean> {
  let response = await fetch(
    API_BASE_URL + `/users/${following_username}/followers/${follower_username}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    }
  );
  let json = await response.json();
  if (response.ok) {
    return json.isFollow;
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function likeSpace(token: Token, spaceId: string) {
  let response = await fetch(API_BASE_URL + `/spaces/${spaceId}/likes/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
  if (response.ok) {
    return response.status == 200;
  } else {
    let json = await response.json();
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function unlikeSpace(token: Token, spaceId: string) {
  let response = await fetch(API_BASE_URL + `/spaces/${spaceId}/likes/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
  if (response.ok) {
    return response.status == 200;
  } else {
    let json = await response.json();
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function getIsLike(token: Token, spaceId: string, username: string) {
  let response = await fetch(API_BASE_URL + `/spaces/${spaceId}/likes/${username}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
  let json = await response.json();
  if (response.ok) {
    return json.isLike;
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function getUserSpaces(
  username: string,
  cursor: string | null = null
): Promise<{ spaces: Space[]; cursor: string | null; count: number }> {
  let response = await fetch(API_BASE_URL + `/users/${username}/spaces/?cursor=${cursor ?? ''}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  let json = await response.json();
  if (response.ok) {
    // throw new APIError({ code: 400,  detail: "Error", field: "Error" });
    let { next, results: spaces, count } = SpacePageSchema.parse(json);
    let nextCursor = next && next.searchParams.get('cursor'); // typing 필요
    return { spaces, cursor: nextCursor, count };
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function getFeeds(
  spaceId: string,
  cursor: string | null = null
): Promise<{ spaces: Space[]; cursor: string | null }> {
  let response = await fetch(API_BASE_URL + `/spaces/?cursor=${cursor ?? ''}&start=${spaceId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  let json = await response.json();
  if (response.ok) {
    // throw new APIError({ code: 400,  detail: "Error", field: "Error" });
    let { next, results: spaces } = SpaceSearchSetSchema.parse(json);
    let nextCursor = next && next.searchParams.get('cursor');
    return { spaces, cursor: nextCursor };
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function getAlarm(
  username: string,
  token: Token,
  cursor: string | null = null
): Promise<{ alarms: Alarm[]; cursor: string | null }> {
  let response = await fetch(API_BASE_URL + `/alarms/?cursor=${cursor ?? ''}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
  let json = await response.json();
  if (response.ok) {
    // throw new APIError({ code: 400100, detail: '', field: 'alarm' });
    let { results: alarms, next } = AlarmPageSchema.parse(json);
    let nextCursor = next && next.searchParams.get('cursor');
    return {
      alarms,
      cursor: nextCursor,
    };
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function getCharacter(token: Token, username: string): Promise<Character> {
  let response = await fetch(API_BASE_URL + `/users/${username}/characters/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
  let json = await response.json();
  if (response.ok) {
    let character = CharacterSchema.parse(json);
    return character;
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function updateCharacter(
  token: Token,
  username: string,
  character: Character
): Promise<boolean> {
  let response = await fetch(API_BASE_URL + `/users/${username}/characters/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify({ character: character }),
  });
  let json = await response.json();
  if (response.ok) {
    // throw new APIError({ code: 400,  detail: "Error", field: "Error" });
    return response.status == 200;
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function reportSpace(
  spaceId: string,
  token: Token,
  type: number,
  description: string
) {
  let response = await fetch(API_BASE_URL + `/spaces/${spaceId}/reports/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify({
      type,
      description,
    }),
  });
  let json = await response.json();
  if (response.ok) {
    // throw new APIError({ code: 400,  detail: "Error", field: "Error" });
    return response.status == 200;
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function reportReply(
  replyId: string,
  token: Token,
  type: number,
  description: string
) {
  let response = await fetch(API_BASE_URL + `/replys/${replyId}/reports/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify({
      type,
      description,
    }),
  });
  let json = await response.json();

  if (response.ok) {
    // throw new APIError({ code: 400,  detail: "Error", field: "Error" });
    return response.status == 200;
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function reportUser(
  username: string,
  token: Token,
  type: number,
  description: string
) {
  let response = await fetch(API_BASE_URL + `/users/${username}/reports/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify({
      type,
      description,
    }),
  });
  let json = await response.json();
  if (response.ok) {
    // throw new APIError({ code: 400,  detail: "Error", field: "Error" });
    return response.status == 200;
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function writeReply(token: Token, spaceId: string, reply: string): Promise<boolean> {
  let response = await fetch(API_BASE_URL + `/spaces/${spaceId}/replys/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify({
      reply,
    }),
  });
  let json = await response.json();
  if (response.ok) {
    // throw new APIError({ code: 400,  detail: "Error", field: "Error" });
    return response.status == 200;
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function getReplys(url: string): Promise<ReplySet> {
  let response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  let json = await response.json();
  if (json.next === null) {
    json.next = '';
  }
  if (json.previous === null) {
    json.previous = '';
  }
  if (response.ok) {
    // throw new APIError({ code: 400,  detail: "Error", field: "Error" });
    let replys = z
      .object({
        count: z.number(),
        results: z.array(ReplySchema),
        next: z.string(),
        previous: z.string(),
      })
      .parse(json);
    return replys;
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function searchByKeyword(
  token: Token,
  keyword: string,
  cursor: string | null = null
): Promise<{ spaces: Space[]; cursor: string | null }> {
  let response = await fetch(
    API_BASE_URL + `/search/content/?cursor=${cursor ?? ''}&&keyword=${keyword}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    }
  );
  let json = await response.json();
  if (response.ok) {
    let { next, results: spaces } = SpaceSearchSetSchema.parse(json);
    let nextCursor = next && next.searchParams.get('cursor');
    return { spaces, cursor: nextCursor };
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function searchByTag(
  token: Token,
  keyword: string,
  cursor: string | null = null
): Promise<{ spaces: Space[]; cursor: string | null }> {
  let response = await fetch(
    API_BASE_URL + `/search/hashtag/?cursor=${cursor ?? ''}&&keyword=${keyword}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    }
  );
  let json = await response.json();
  if (response.ok) {
    let { next, results: spaces } = SpaceSearchSetSchema.parse(json);
    let nextCursor = next && next.searchParams.get('cursor');
    return { spaces, cursor: nextCursor };
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function searchNickname(
  token: Token,
  keyword: string,
  cursor: string | null
): Promise<{ users: User[]; cursor: string | null }> {
  let response = await fetch(
    API_BASE_URL + `/search/nickname/?cursor=${cursor ?? ''}&&keyword=${keyword}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    }
  );
  let json = await response.json();
  for (let user of json.results) {
    if (user.image === null) {
      let object = new URL('../../static/imgs/default-profile.png', import.meta.url);
      user.image = object.href;
    }
    if (user.phone === null) {
      json.phone = '';
    }
    if (user.email === null) {
      json.email = '';
    }
  }
  if (response.ok) {
    let { next, results: users } = UserSearchSetSchema.parse(json);
    let nextCursor = next && next.searchParams.get('cursor');
    return { users, cursor: nextCursor };
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function searchUsername(
  token: Token,
  keyword: string,
  cursor: string | null
): Promise<{ users: User[]; cursor: string | null }> {
  let response = await fetch(
    API_BASE_URL + `/search/username/?cursor=${cursor ?? ''}&&keyword=${keyword}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    }
  );
  let json = await response.json();
  for (let user of json.results) {
    if (user.image === null) {
      let object = new URL('../../static/imgs/default-profile.png', import.meta.url);
      user.image = object.href;
    }
    if (user.phone === null) {
      json.phone = '';
    }
    if (user.email === null) {
      json.email = '';
    }
  }
  if (response.ok) {
    let { next, results: users } = UserSearchSetSchema.parse(json);
    let nextCursor = next && next.searchParams.get('cursor');
    return { users, cursor: nextCursor };
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function getTemporaryPW(phone: string, phoneToken: string): Promise<boolean> {
  let response = await fetch(API_BASE_URL + '/account/password/reset/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      phone,
      phoneToken,
    }),
  });
  if (response.ok) {
    if (response.status == 200) {
      return true;
    } else {
      return false;
    }
  } else {
    let json = await response.json();
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function ChangePassword(
  token: Token,
  oldPassword: string,
  newPassword: string
): Promise<boolean> {
  let response = await fetch(API_BASE_URL + '/account/password/', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify({
      oldPassword,
      newPassword,
    }),
  });
  if (response.ok) {
    return true;
  } else {
    let json = await response.json();
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function ChangeEmail(token: Token, email: string): Promise<boolean> {
  let response = await fetch(API_BASE_URL + '/account/email/', {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify({
      email,
    }),
  });
  if (response.ok) {
    if (response.status == 200) {
      return true;
    } else {
      return false;
    }
  } else {
    let json = await response.json();
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

function getBytesOfString(s: string, b: number, i: number, c: number) {
  for (b = i = 0; (c = s.charCodeAt(i++)); b += c >> 11 ? 3 : c >> 7 ? 2 : 1);
  return b;
}

export function handleOnInput(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, l: number) {
  if (e.target.value.length > l) {
    e.target.value = e.target.value.substring(0, l);
  }
}

export async function deleteReply(token: Token, replyID: string): Promise<boolean> {
  let response = await fetch(API_BASE_URL + '/replys/' + replyID, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
  if (response.ok) {
    return response.status == 200;
  } else {
    let json = await response.json();
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function getVerificationCode(
  phone: string,
  type: number
): Promise<{ result: boolean; key: string }> {
  let key = uuidv4();
  console.log(key);
  let response = await fetch(API_BASE_URL + '/certification/phone/begin/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      phone,
      type,
      key,
    }),
  });
  if (response.ok) {
    return { result: true, key };
  } else {
    let json = await response.json();
    console.log(json);
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export const generateRandomString = (num: number) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < num; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export async function verify(
  phone: string,
  code: string,
  key: string | null,
  type: number
): Promise<{ result: boolean; content?: PhoneToken }> {
  let response = await fetch(API_BASE_URL + '/certification/phone/end/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      phone,
      code,
      key,
      type,
    }),
  });
  let json = await response.json();
  if (response.ok) {
    let token = PhoneTokenSchema.parse(json);
    return { result: true, content: token };
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

/*
  TODO

  BACK-END 바꾼 후 다시 원복예정
*/
export async function registerCheck(
  username: string,
  email: string,
  password: string
): Promise<{ result: boolean }> {
  const response = await fetch(API_BASE_URL + '/account/register/validate/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      nickname: username,
      username,
      email,
      password,
    }),
  });

  if (response.ok) {
    return { result: response.status === 200 };
  } else {
    const json = await response.json();
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function register(
  username: string,
  phoneToken: string,
  password: string,
  email: string,
  marketingFlag: boolean
): Promise<{ result: boolean }> {
  let response = await fetch(API_BASE_URL + '/account/register/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      phone_token: phoneToken,
      password,
      email,
      marketing_flag: marketingFlag,
    }),
  });
  let json = await response.json();
  if (response.ok) {
    return { result: response.status === 200 };
  } else {
    let issues = APIIssuesSchema.parse(json);
    console.log(json);
    throw new APIError(issues);
  }
}

export async function verifyPhone(token: Token, phoneToken: string) {
  let response = await fetch(API_BASE_URL + '/account/phone/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify({
      phoneToken,
    }),
  });
  if (response.ok) {
    return { result: response.status === 200 };
  } else {
    let json = await response.json();
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function getRecommendedSpaces(
  cursor: string | null = null
): Promise<{ spaces: Space[]; cursor: string | null }> {
  let headers = {
    'Content-Type': 'application/json',
  };

  let response = await fetch(API_BASE_URL + `/spaces/recommend/?cursor=${cursor ?? ''}`, {
    headers,
  });
  let json = await response.json();
  if (response.ok) {
    let { next, results: spaces } = SpaceSearchSetSchema.parse(json);
    let nextCursor = next && next.searchParams.get('cursor');
    return { spaces, cursor: nextCursor };
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function blockUser(token: Token, username: string): Promise<boolean> {
  let response = await fetch(API_BASE_URL + `/users/${username}/block/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
  if (response.ok) {
    return true;
  } else {
    return false;
  }
}

export async function deleteUser(token: Token, username: string): Promise<boolean> {
  let response = await fetch(API_BASE_URL + `/users/${username}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  });
  if (response.ok) {
    return true;
  } else {
    return false;
  }
}

export async function usersFollowingsSearch(
  token: Token,
  userName: string,
  keyword: string
): Promise<{ users: FollowUser[]; total_length: number; next_url: string }> {
  const response = await fetch(
    `${API_BASE_URL}/users/${userName}/search/followings/?keyword=${keyword}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    }
  );

  const json = await response.json();

  if (response.ok) {
    let total_length = json['results']['totalLength'];
    let next_url = json.next ? json.next.slice(json.next.lastIndexOf('users') - 1) : null;
    let { users: users } = z
      .object({
        users: z.array(FollowUserSchema),
      })
      .parse(json['results']);
    return { users: users, total_length: total_length, next_url: next_url };
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}

export async function usersFollowersSearch(
  token: Token,
  userName: string,
  keyword: string
): Promise<{ users: FollowUser[]; total_length: number; next_url: string }> {
  const response = await fetch(
    `${API_BASE_URL}/users/${userName}/search/followers/?keyword=${keyword}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    }
  );

  const json = await response.json();

  if (response.ok) {
    let total_length = json['results']['totalLength'];
    let next_url = json.next ? json.next.slice(json.next.lastIndexOf('users') - 1) : null;
    let { users: users } = z
      .object({
        users: z.array(FollowUserSchema),
      })
      .parse(json['results']);
    return { users: users, total_length: total_length, next_url: next_url };
  } else {
    let issues = APIIssuesSchema.parse(json);
    throw new APIError(issues);
  }
}
