import styled from 'styled-components';
import { BreakPoint } from '../../constants';

export const FooterText = styled.div.attrs({ className: 'clickable' })`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: -0.005rem;
  color: #b3b3b3;
  margin-right: 1rem;
`;

export const FooterContainer = styled.div`
  display: flex;
  margin: 0 0 1.5rem;

  & > ${FooterText}:last-child() {
    margin-right: 0;
  }

  ${BreakPoint.mobile} {
    margin: 0 0 3.625rem;
  }
`;
