import { Dispatch, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { SetStateAction } from 'jotai';
import * as THREE from 'three';

import { Space } from 'apis/index';
import Objects from 'react3fiber/r3f-space/objects';

export default function ModelingScaler({
  preSpace,
  scale,
  position,
  setScale,
  setPosition,
  setCapturedImg,
}: {
  preSpace: Space;
  scale: number;
  position: THREE.Vector3;
  setScale: Dispatch<SetStateAction<number>>;
  setPosition: Dispatch<SetStateAction<THREE.Vector3>>;
  setCapturedImg: Dispatch<SetStateAction<File | null>>;
}) {
  let [checkItemLoading, setCheckItemLoading] = useState<boolean | null>(false);
  return (
    <>
      <div className='h-1/2'>
        <Canvas
          camera={{ fov: 60 }}
          onCreated={({ camera }) => {
            camera.position.set(0, 0, 5);
            camera.lookAt(0, 0, 0);
          }}>
          {preSpace.items[0].file !== '' && <Objects space={preSpace} device={null} displayStatus={null} />}
        </Canvas>
      </div>
      <div className='flex flex-col justify-center items-center grow pb-16'>
        <div className='text-xs mb-6'>{scale}%</div>
        <input
          className='w-80'
          type='range'
          id='size'
          name='volume'
          min='1'
          max='100'
          step='0.1'
          onChange={(e) => setScale(Number(e.target.value))}
        />
      </div>
      <div className='flex flex-col justify-center items-center grow pb-16'>
        <div className='text-xs mb-6'>{position.x}%</div>
        <input
          className='w-80'
          type='range'
          id='size'
          name='volume'
          min='0'
          max='100'
          step='1'
          onChange={(e) => setPosition(new THREE.Vector3(Number(e.target.value), position.y, position.z))}
        />
      </div>
      <div className='flex flex-col justify-center items-center grow pb-16'>
        <div className='text-xs mb-6'>{position.y}%</div>
        <input
          className='w-80'
          type='range'
          id='size'
          name='volume'
          min='0'
          max='100'
          step='1'
          onChange={(e) => setPosition(new THREE.Vector3(position.x, Number(e.target.value), position.z))}
        />
      </div>
      <div className='flex flex-col justify-center items-center grow pb-16'>
        <div className='text-xs mb-6'>{position.z}%</div>
        <input
          className='w-80'
          type='range'
          id='size'
          name='volume'
          min='0'
          max='100'
          step='1'
          onChange={(e) => setPosition(new THREE.Vector3(position.x, position.y, Number(e.target.value)))}
        />
      </div>
    </>
  );
}
