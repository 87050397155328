import { ErrorCode, Scripts } from 'src/types';

export const apiErrorCodeToMessage = (errorCode: ErrorCode, fieldName?: string): Scripts => {
  switch (errorCode) {
    case 400100:
      return {
        KR: [
          fieldName
            ? `${fieldName}의 입력값이 옳지 않습니다. 다시 확인해 주세요.`
            : '유효한 입력값이 아닙니다. 다시 확인해 주세요.',
        ],
        EN: [
          fieldName
            ? `${fieldName} input is incorrect. Please check again`
            : 'This is not a valid input. Please check again.',
        ],
        JP: [
          fieldName
            ? `${fieldName}の入力値が正しくありません。もう一度確認してください。`
            : '有効な入力値ではありません。もう一度確認してください。',
        ],
      };
    case 400101:
      return {
        KR: [
          fieldName
            ? `${fieldName}의 입력값이 존재하지 않습니다. 다시 확인해 주세요.`
            : '입력값이 존재하지 않습니다. 다시 입력해 주세요.',
        ],
        EN: [
          fieldName
            ? `The input for ${fieldName} does not exist. Please check again.`
            : 'The input does not exist. Please re-enter.',
        ],
        JP: [
          fieldName
            ? `${fieldName}の入力値が存在しません。もう一度確認してください。`
            : '入力値が存在しません。もう一度入力してください。',
        ],
      };
    case 400102:
      return {
        KR: [
          fieldName
            ? `${fieldName}의 입력값이 이미 존재합니다. 다시 확인해 주세요.`
            : '입력값이 이미 존재합니다. 다시 확인해 주세요.',
        ],
        EN: [
          fieldName
            ? `The input for ${fieldName} already exists. Please check again.`
            : 'The input already exists. Please check again.',
        ],
        JP: [
          fieldName
            ? `${fieldName}の入力値はすでに存在します。もう一度確認してください。`
            : '入力値はすでに存在します。もう一度確認してください。',
        ],
      };
    case 400103:
      return {
        KR: [
          fieldName
            ? `${fieldName}에서 P:E가 발생했습니다. 최대한 빨리 조치하겠습니다.`
            : 'P:E가 발생했습니다. 최대한 빨리 조치하겠습니다.',
        ],
        EN: [
          fieldName
            ? `P:E occurred in ${fieldName}. We will take action as soon as possible.`
            : 'P:E has occurred. We will take action as soon as possible.',
        ],
        JP: [
          fieldName
            ? `${fieldName}でP：Eが発生しました。できるだけ早く対応させていただきます。`
            : 'P:Eが発生しました。できるだけ早く対応させていただきます。',
        ],
      };
    case 400104:
      return {
        KR: ['파일 타입이 옳지 않습니다. 다시 확인해 주세요.'],
        EN: ['The file type is incorrect. Please check again.'],
        JP: ['ファイルタイプが正しくありません。もう一度確認してください。'],
      };
    case 400105:
      return {
        KR: ['파일 크기가 너무 큽니다. 다시 확인해 주세요.'],
        EN: ['The file size is too large. Please check again.'],
        JP: ['ファイルサイズが大きすぎます。もう一度確認してください。'],
      };
    case 400106:
      return {
        KR: ['잘못된 인증방식으로 접근하셨습니다. 처음부터 다시 시도해주세요.'],
        EN: ['You have accessed the wrong authentication method. Please try again from the beginning.'],
        JP: ['間違った認証方法でアクセスしました。最初からやり直してください。'],
      };
    case 400107:
      return {
        KR: ['자기 자신을 블락 할 수 없습니다. 다시 한 번 확인해 주세요.'],
        EN: ['You cannot block yourself. Please check again.'],
        JP: ['自分自身をブロックすることはできません。もう一度確認してください。'],
      };
    case 400108:
      return {
        KR: ['스스로 팔로우 할 수 없습니다. 다시 한 번 확인해 주세요.'],
        EN: ['You cannot follow yourself. Please check again.'],
        JP: ['自分でフォローできません。もう一度確認してください。'],
      };
    case 400201:
      return {
        KR: ['인증코드가 옳지 않거나 만료되었습니다. 다시 확인해 주세요.'],
        EN: ['The verification code is incorrect or has expired. Please check again.'],
        JP: ['認証コードが正しくないか期限切れです。もう一度確認してください。'],
      };
    case 400202:
      return {
        KR: ['인증 횟수가 남아있지 않습니다. 다시 확인해 주세요.'],
        EN: ['There are no authentication counts left. Please check again.'],
        JP: ['認証回数が残っていません。もう一度確認してください。'],
      };
    case 400203:
      return {
        KR: ['해당 핸드폰 번호로 가입된 유저가 존재하지 않습니다. 다시 확인해 주세요.'],
        EN: ['There is no user registered with the mobile phone number. Please check again.'],
        JP: ['その携帯電話番号で登録されたユーザーは存在しません。もう一度確認してください。'],
      };
    default:
      return {
        KR: ['예상치 못한 에러가 발생했습니다. 최대한 빨리 조치하겠습니다.'],
        EN: ['An unexpected error has occurred. We will take action as soon as possible.'],
        JP: ['予期しないエラーが発生しました。できるだけ早く対応させていただきます。'],
      };
  }
};
