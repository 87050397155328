import styled from 'styled-components';

import { BreakPoint, Color } from 'src/constants';
import { APIIssues } from 'apis/types';

export const PasswordResetContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.5rem;
  padding: 0 2rem;

  ${BreakPoint.mobile} {
    height: 37.5rem;
  }
`;

export const LockIconContainer = styled.div`
  display: hidden;

  ${BreakPoint.mobile} {
    display: block;
    margin: 2.5rem 0;
  }
`;
export const TextContainer = styled.div`
  text-align: center;
`;

export const MainTextContainer = styled.div`
  line-height: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

export const SubTextContainer = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #71717a;
`;

export const CertificationArea = styled.div`
  margin: 2.5rem 0 0.75rem;
`;

export const CertificationFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`;

export const PWDispenserButton = styled.button<{ validateError: APIIssues | null; isVerified: boolean }>`
  display: block;
  width: 100%;
  height: 3rem;
  color: ${Color.white};
  background-color: ${({ validateError, isVerified }) => (!validateError && isVerified ? '#db1f72' : '#f9a8d4')};
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
`;

export const LoginButton = styled.button`
  display: block;
  width: 100%;
  height: 3rem;
  color: ${Color.black};
  background-color: #e4e4e7;
  border-radius: 0.25rem;
`;
