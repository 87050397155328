import { useNavigate } from 'react-router-dom';

export default function Goback() {
  const navigate = useNavigate();

  return (
    <button className='flex-initial p-2.5' onClick={() => navigate(-1)}>
      <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_553_9851)'>
          <path d='M18 23.3281L6 12.3281L18 1.32812' stroke='black' strokeWidth='2' strokeLinecap='round' />
        </g>
        <defs>
          <clipPath id='clip0_553_9851'>
            <rect width='24' height='24' fill='white' transform='translate(0 0.328125)' />
          </clipPath>
        </defs>
      </svg>
    </button>
  );
}
