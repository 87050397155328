import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';

import { CustomModalContainer, CustomModalOverlay, CustomModalWrapper } from './styles';
import { CustomModalWrapperAlign } from './types';

import useModal from 'hooks/useModal';

import { customModalAtom } from 'src/atoms';

function CustomModal(): JSX.Element {
  const [modalRootElement, setModalRootElement] = useState<HTMLElement>();
  const [isEscKeyDownEvent, setIsEscKeyDownEvent] = useState(false);
  const [isOverlayed, setIsOverlayed] = useState(false);
  const [isAlign, setIsAlign] = useState<CustomModalWrapperAlign>();
  const [currentPath, setCurrentPath] = useState('');
  const [modals] = useAtom(customModalAtom);
  const location = useLocation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { closeModal, closeAllModals } = useModal();

  /* 
    INFO

    컴포넌트 마운트 시 모달을 담아줄 root element를 감지한다. 
    존재할 경우, 해당 element를 ref에 담아 추후에 포탈로 연결.
    존재하지 않읗 경우 react의 기본 element인 root의 뒤에 새로 생성한다.
  */
  useEffect(() => {
    const targetModalRootElement = document.getElementById('custom-modal-root');

    if (targetModalRootElement) {
      setModalRootElement(targetModalRootElement);
    } else {
      createModalRoot();
    }
  }, []);

  /* 
    INFO

    1. 최상단에 활성화 된 모달의 esc key down이벤트, overlay가 설정되었는지를 감지해 적용한다.

    2. 옵션이 overlay인지 아닌지를 판단해서 넣어준다.
  */
  useEffect(() => {
    function onEscKeyDown(event: KeyboardEvent) {
      event.key === 'Escape' && closeModal();
    }

    if (modals.length !== 0) {
      if (modals.length === 1) {
        setCurrentPath(location.pathname);
      }

      if (modals[modals.length - 1].options?.isCloseOnESCKeyDown && !isEscKeyDownEvent) {
        setIsEscKeyDownEvent(true);
        document.addEventListener('keydown', onEscKeyDown);
      } else if (!modals[modals.length - 1].options?.isCloseOnESCKeyDown && isEscKeyDownEvent) {
        setIsEscKeyDownEvent(false);
        document.removeEventListener('keydown', onEscKeyDown);
      }

      if (modals[modals.length - 1].options?.isOverlayed && !isOverlayed) {
        setIsOverlayed(true);
      } else if (!modals[modals.length - 1].options?.isOverlayed && isOverlayed) {
        setIsOverlayed(false);
      }

      if (modals[modals.length - 1].options?.align) {
        setIsAlign(modals[modals.length - 1].options?.align);
      }
    } else {
      setIsEscKeyDownEvent(false);
      document.removeEventListener('keydown', onEscKeyDown);
    }
  }, [modals]);

  /*
    INFO

    route path 변경 시, 현재 활성화되어있는 모달을 전부 제거한다.
  */
  useEffect(() => {
    if (modals.length !== 0 && currentPath !== location.pathname) {
      closeAllModals();
    }
  }, [location.pathname]);

  const onOverlayClick = useCallback(() => {
    if (modals[modals.length - 1].options?.isCloseOnOverlayClick) {
      closeModal();
    }
  }, [modals]);

  const onWrapperClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (event.target !== wrapperRef.current) {
        event.stopPropagation();
      }
    },
    [wrapperRef.current]
  );

  const customModals = useMemo(() => {
    if (modals.length === 0) return <></>;

    return (
      <CustomModalContainer>
        <CustomModalOverlay isOverlayed={isOverlayed} onClick={onOverlayClick}>
          {React.Children.toArray(
            modals.map((modal) => (
              <CustomModalWrapper ref={wrapperRef} onClick={onWrapperClick} isAlign={isAlign}>
                {modal.component}
              </CustomModalWrapper>
            ))
          )}
        </CustomModalOverlay>
      </CustomModalContainer>
    );
  }, [modals, isOverlayed, isAlign]);

  const createModalRoot = useCallback(() => {
    const rootElement = document.getElementById('root');
    const newModalRootElement = document.createElement('div');

    newModalRootElement.setAttribute('id', 'custom-modal-root');

    if (rootElement) {
      rootElement.after(newModalRootElement);

      setModalRootElement(newModalRootElement);
    }
  }, []);

  if (!modalRootElement) return <></>;

  return ReactDOM.createPortal(customModals, modalRootElement);
}

export default CustomModal;
