import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useErrorHandler } from 'react-error-boundary';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Canvas, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { AdaptiveDpr, PointerLockControls } from '@react-three/drei';
import * as Colyseus from 'colyseus.js';
import Modal, { Styles as ModalStyles } from 'react-modal';

import SpaceComponent, { SpaceDisplayStatus, SpaceLayoutComponent } from './space';

import { useCompressedGLTF } from 'hooks/hooks';
import {
  getSpaces,
  getFeeds,
  getLastSpaces,
  COLYSEUS_BASE_URL,
  APIError,
  APIIssues,
  Space,
} from 'apis/index';

import {
  tokenAtom,
  navbarTypeAtom,
  userAtom,
  playModeAtom,
  bgmModeAtom,
  totalRoomsAtom,
  currentRoomAtom,
  wheelDelta,
} from 'src/atoms';
import LoginModal from 'components/CustomModal/components/LoginModal';
import useModal from 'hooks/useModal';

const client = new Colyseus.Client(COLYSEUS_BASE_URL);
const url2 = new URL('static/models/base.min.glb', import.meta.url);

export function Feeds() {
  const token = useAtomValue(tokenAtom);
  const [error, setError] = useState<APIIssues | null>(null);
  const [initialLastSpaceId, setInitialLastSpaceId] = useState(localStorage.lastSpaceId);
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    ['spaces', token, 'feeds', initialLastSpaceId],
    async ({ pageParam }) => {
      try {
        return initialLastSpaceId // FIXME: this implicitly runs on load
          ? await getLastSpaces(token, initialLastSpaceId, pageParam)
          : await getSpaces(token, pageParam);
      } catch (error) {
        if (error instanceof APIError) {
          setError(error.issues);
        }
      }
    },
    {
      placeholderData: { pages: [], pageParams: [] },
      getNextPageParam: (lastPage) => (lastPage ? lastPage.cursor : undefined),
      refetchOnWindowFocus: false,
    }
  );
  // if (!data) return null; // we use suspense so data is never undefined
  const spaces = data!.pages.flatMap((page) => (page ? page.spaces : []));

  return (
    <Spaces
      spaces={spaces}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      error={error}
    />
  );
}

export function Feed() {
  const { spaceId } = useParams() as { spaceId: string };
  const token = useAtomValue(tokenAtom);
  const [error, setError] = useState<APIIssues | null>(null);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isError } = useInfiniteQuery(
    ['space', token, 'feed'],
    async ({ pageParam }) => {
      try {
        return await getFeeds(spaceId, pageParam);
      } catch (error) {
        if (error instanceof APIError) {
          setError(error.issues);
        }
      }
    },
    {
      placeholderData: { pages: [], pageParams: [] },
      getNextPageParam: (lastPage) => (lastPage ? lastPage.cursor : undefined),
    }
  );
  const spaces = data!.pages.flatMap((page) => (page ? page.spaces : []));

  return (
    <Spaces
      spaces={spaces}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      error={error}
    />
  );
}

export function Spaces({
  spaces,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  error,
}: {
  spaces: Space[];
  fetchNextPage: () => {};
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean | undefined;
  error: APIIssues | null;
}) {
  const token = useAtomValue(tokenAtom);
  const user = useAtomValue(userAtom);
  const bgmMode = useAtomValue(bgmModeAtom);
  const handleError = useErrorHandler();
  const setNavbarType = useSetAtom(navbarTypeAtom);
  const [playMode, setPlayMode] = useAtom(playModeAtom);

  //여기서부터 colyseus
  const lobby = useRef<Colyseus.Room | null>();
  const [lobbyLoaded, setLobbyLoaded] = useState<boolean>(false);
  const setTotalRooms = useSetAtom(totalRoomsAtom);
  const currentRoom = useAtomValue(currentRoomAtom);

  useEffect(() => {
    if (!lobbyLoaded) joinLobby();
  }, [lobbyLoaded]);

  function joinLobby() {
    client
      .joinOrCreate('lobby')
      .then((room) => {
        lobby.current = room;
        console.log(lobby);
        onjoin();
        console.log('Joined lobby room!');
      })
      .catch((e) => {
        console.error('Error', e);
      });
  }

  function onjoin() {
    if (lobby.current) {
      lobby.current.onMessage('rooms', (rooms) => {
        setLobbyLoaded(true);

        setTotalRooms(rooms);
      });
      lobby.current.onMessage('+', ([roomId, room]) => {
        setTotalRooms((totalRooms) => {
          const roomIndex = totalRooms.findIndex((room) => room.roomId === roomId);
          if (roomIndex !== -1) {
            return totalRooms?.map((existingRoom, index) => {
              if (index === roomIndex) {
                return room;
              } else {
                return existingRoom;
              }
            });
          } else {
            return [...totalRooms, room];
          }
        });
      });
      lobby.current.onMessage('-', (roomId) => {
        setTotalRooms((totalRooms) => {
          return totalRooms.filter((room) => room.roomId !== roomId);
        });
      });
      lobby.current.onLeave(() => {
        setTotalRooms([]);
      });
    }
  }
  function leaveLobby() {
    if (lobby.current) {
      lobby.current.leave();
    } else {
      console.warn('Not connected.');
    }
  }
  //여기까지 colyseus

  const [customOptions, setCustomOptions] = useState<{
    type: string;
    hair: number;
    face: number;
    top: number;
    bottom: number;
    shoes: number;
  }>({
    type: 'M',
    hair: 1,
    face: 1,
    top: 1,
    bottom: 1,
    shoes: 1,
  });

  let spacesElRef = useRef<HTMLElement>(null);
  let spaceElRef = useRef<HTMLDivElement>(null);
  let [isIphoneVer, setIsIphoneVer] = useState(false);
  let [height, setHeight] = useState(0);
  let [scrollTop, setScrollTop] = useState(0);
  let audioRef = useRef<HTMLAudioElement>(null);
  let wheelnum = useSetAtom(wheelDelta);
  const [currentAudio, setCurrentAudio] = useState<number>(1);
  const audioUrl1 = new URL(
    '../../../static/bgms/CD1-01_Bach-Violin_Concerto_No1-Oistrakh1962-Track01.mp3',
    import.meta.url
  );
  const audioUrl2 = new URL(
    '../../../static/bgms/CD1-02_Bach-Violin_Concerto_No1-Oistrakh1962-Track02.mp3',
    import.meta.url
  );
  const audioUrl3 = new URL(
    '../../../static/bgms/CD1-03_Bach-Violin_Concerto_No1-Oistrakh1962-Track03.mp3',
    import.meta.url
  );
  const audioUrl4 = new URL(
    '../../../static/bgms/CD1-06_Bach-Violin_Concerto_No2-Oistrakh1962-Track3.mp3',
    import.meta.url
  );
  const audioUrl5 = new URL(
    '../../../static/bgms/CD1-09-Bach-Concerto_for_2_Violins_BWV1043-Oistrakh1961-Track3.mp3',
    import.meta.url
  );
  const audioUrl6 = new URL(
    '../../../static/bgms/CD1-11-Beethoven-Violin_Romance_No2-Oistrakh1962.mp3',
    import.meta.url
  );
  const audioUrl7 = new URL('../../../static/bgms/LiquidPurgatory.m4a', import.meta.url);
  const audioUrls: string[] = [];
  audioUrls.push(
    audioUrl1.href,
    audioUrl2.href,
    audioUrl3.href,
    audioUrl4.href,
    audioUrl5.href,
    audioUrl6.href,
    audioUrl7.href
  );

  const currentIndex =
    height === 0 ? 0 : Math.min(Math.max(Math.floor(scrollTop / height + 0.5), 0), spaces.length);

  const isLoginModalOpen = !token && currentIndex >= 10;
  const handleScroll = useCallback((e: React.UIEvent) => {
    const scrollTop = e.currentTarget.scrollTop;
    setScrollTop(scrollTop);
  }, []);
  const mediaMatch = window.matchMedia('(min-width: 1024px)');
  const customStyles: ModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: mediaMatch.matches ? '448px' : '85%',
      height: mediaMatch.matches ? '651px' : '70%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0',
    },
  };

  useEffect(() => {
    setNavbarType('feed');

    setPlayMode('scroll');
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (token && user) {
          setCustomOptions(user.characters);
        }
      } catch (error) {
        handleError(error);
      }
    })();
  }, [token, user]);

  useEffect(() => {
    if (!spaceElRef.current) return;
    const spaceEl = spaceElRef.current;
    setHeight(spaceEl.clientHeight);
    const resizeObserver = new ResizeObserver(() => setHeight(spaceEl.clientHeight));
    resizeObserver.observe(spaceEl);
    return () => resizeObserver.disconnect();
  }, [lobbyLoaded, spaces.length, spaceElRef.current]);

  useEffect(() => {
    if (spaces.length > 0) setCurrentAudio(spaces[currentIndex].bgm);
  }, [spaces, currentIndex, bgmMode]);

  useEffect(() => {
    if (currentIndex >= spaces.length - 3 && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [isFetchingNextPage, hasNextPage, spaces, currentIndex, fetchNextPage]);

  useEffect(() => {
    if (spaces.length > 0) setCurrentAudio(spaces[currentIndex].bgm);
  }, [spaces, currentIndex, bgmMode]);

  function displayStatusFromIndex(index: number): SpaceDisplayStatus {
    if (currentIndex - 1 <= index && index < currentIndex) {
      return 'previous';
    } else if (index == currentIndex) {
      return 'current';
    } else if (currentIndex < index && index <= currentIndex + 1) {
      return 'next';
    } else {
      return 'hidden';
    }
  }

  useEffect(() => {
    if (playMode == 'multiplay') {
      window.addEventListener('wheel', (event) => {
        wheelnum(event.wheelDelta);
      });
    }
  }, [playMode]);

  function PreloadPopularModels() {
    const gl = useThree((state) => state.gl);

    useCompressedGLTF.preload(url2.href, gl);

    return null;
  }
  const { openModal } = useModal();

  function loginModal() {
    openModal({
      component: <LoginModal />,
      options: {
        isCloseOnOverlayClick: true,
        isOverlayed: true,
        align: { alignItems: 'center', justifyContent: 'center' },
      },
    });
  }

  let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  let checkVersion = function () {
    let agent = window.navigator.userAgent,
      start = agent.indexOf('OS ');
    if ((agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) && start > -1) {
      return window.Number(agent.substr(start + 3, 3).replace('_', '.'));
    }
    return 0;
  };
  return (
    <>
      <Canvas
        shadows
        gl={{
          preserveDrawingBuffer: playMode === 'scroll' ? true : false,
          shadowMapType: THREE.BasicShadowMap,
          outputEncoding: THREE.sRGBEncoding,
        }}
        dpr={[0, 1.5]}
        onWheel={(e) => this.onWheel(e)}
        style={{
          position: 'fixed',
          inset: 0,
          zIndex: -1,
          touchAction: playMode === 'multiplay' ? 'none' : 'none',
        }}
        onCreated={(state) => state.events.connect(spacesElRef.current)}>
        <PreloadPopularModels />
        {spaces.map((space, index) => {
          return (
            <SpaceComponent
              key={index}
              space={space}
              displayStatus={displayStatusFromIndex(index)}
              customOptions={customOptions}
              audioRef={audioRef}
              client={client}
            />
          );
        })}
        {playMode == 'multiplay' && mediaMatch.matches ? <PointerLockControls /> : null}
        <AdaptiveDpr pixelated />
      </Canvas>
      <main ref={spacesElRef} className='grow relative'>
        <div
          id='scrollElement'
          style={{ touchAction: playMode === 'multiplay' ? 'none' : 'pan-y' }}
          className={
            'relative h-full snap-y snap-mandatory lg:m-auto ' +
            (playMode === 'scroll'
              ? 'overflow-y-auto lg:w-[940]'
              : 'overflow-y-hidden lg:fixed lg:inset-0 lg:w-full lg:h-full')
          }
          onScroll={handleScroll}>
          {spaces.map((space, index) => (
            <div
              key={index}
              style={{ touchAction: playMode === 'multiplay' ? 'none' : 'pan-y' }}
              ref={currentIndex == index ? spaceElRef : null}
              className='relative w-full h-full snap-start'
              id={`trackeddiv-${space.id}`}
              onClick={(e) => {
                if (currentRoom) {
                  currentRoom.send('playtype', { playtype: 1 });
                  if (mediaMatch.matches || !isIOS) {
                    setPlayMode('multiplay');
                  } else if (checkVersion() > 15) {
                    setPlayMode('multiplay');
                  }
                }
              }}>
              <SpaceLayoutComponent
                key={index}
                space={space}
                displayStatus={displayStatusFromIndex(index)}
              />
            </div>
          ))}
        </div>
        {isLoginModalOpen && loginModal()}
        <audio
          id='divAudio'
          ref={audioRef}
          muted={!bgmMode}
          loop
          src={audioUrls[currentAudio - 1]}
          autoPlay
        />
      </main>
    </>
  );
}
