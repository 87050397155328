import { useCallback } from 'react';
import { useAtom } from 'jotai';

import { keyEventStatusAtom } from 'src/atoms';
import { useAtomCallback } from 'jotai/utils';

export type KeyEvent = 'TAB' | 'SPACE' | 'ARROWUP' | 'ARROWDOWN';

export type KeyEventStatus = Record<KeyEvent, boolean>;

interface KeyLock {
  addHandler: (keyEvent: KeyEvent) => void;
  removeHandler: (keyEvent: KeyEvent) => void;
  allAddHandler: (arg?: unknown) => void | Promise<void>;
  allRemoveHandler: (arg?: unknown) => void | Promise<void>;
}

const tabKeyHandler = (event: KeyboardEvent) => {
  const key = event.key || event.keyCode;

  if (key === 'Tab' || key === 9) {
    event.preventDefault();
  }
};

const spaceKeyHandler = (event: KeyboardEvent) => {
  const key = event.key || event.keyCode;

  if (key === 'Space' || key === 32 || event.keyCode === 32) {
    event.preventDefault();
  }
};

const arrowUpKeyHandler = (event: KeyboardEvent) => {
  const key = event.key || event.keyCode;

  if (key === 'ArrowUp' || key === 38) {
    event.preventDefault();
  }
};

const arrowDownKeyHandler = (event: KeyboardEvent) => {
  const key = event.key || event.keyCode;

  if (key === 'ArrowDown' || key === 40) {
    event.preventDefault();
  }
};

function useKeyLock(): KeyLock {
  const [keyEventStatus, setKeyEventStatus] = useAtom(keyEventStatusAtom);

  const allAddHandler = useAtomCallback(
    useCallback((get, set) => {
      set(keyEventStatusAtom, (prev) => {
        if (!prev.TAB && !prev.SPACE && !prev.ARROWUP && !prev.ARROWDOWN) {
          window.addEventListener('keydown', tabKeyHandler, true);
          window.addEventListener('keydown', spaceKeyHandler, true);
          window.addEventListener('keydown', arrowUpKeyHandler, true);
          window.addEventListener('keydown', arrowDownKeyHandler, true);

          return {
            TAB: true,
            SPACE: true,
            ARROWUP: true,
            ARROWDOWN: true,
          };
        }

        return prev;
      });
    }, [])
  );

  const allRemoveHandler = useAtomCallback(
    useCallback((get, set) => {
      set(keyEventStatusAtom, (prev) => {
        if (prev.TAB && prev.SPACE && prev.ARROWUP && prev.ARROWDOWN) {
          window.removeEventListener('keydown', tabKeyHandler, true);
          window.removeEventListener('keydown', spaceKeyHandler, true);
          window.removeEventListener('keydown', arrowUpKeyHandler, true);
          window.removeEventListener('keydown', arrowDownKeyHandler, true);

          return {
            TAB: false,
            SPACE: false,
            ARROWUP: false,
            ARROWDOWN: false,
          };
        }

        return prev;
      });
    }, [])
  );

  const addHandler = (keyEvent: KeyEvent) => {
    setKeyEventStatus((prev) => {
      switch (keyEvent) {
        case 'TAB':
          if (!prev.TAB) {
            window.addEventListener('keydown', tabKeyHandler);
          }

          return {
            TAB: true,
            SPACE: prev.SPACE,
            ARROWUP: prev.ARROWUP,
            ARROWDOWN: prev.ARROWDOWN,
          };
        case 'SPACE':
          if (!prev.SPACE) {
            window.addEventListener('keydown', tabKeyHandler);
            keyEventStatus.SPACE = true;
          }

          return {
            TAB: prev.TAB,
            SPACE: true,
            ARROWUP: prev.ARROWUP,
            ARROWDOWN: prev.ARROWDOWN,
          };
        case 'ARROWUP':
          if (!prev.ARROWUP) {
            window.addEventListener('keydown', tabKeyHandler);
            keyEventStatus.ARROWUP = true;
          }

          return {
            TAB: prev.TAB,
            SPACE: prev.SPACE,
            ARROWUP: true,
            ARROWDOWN: prev.ARROWDOWN,
          };
        case 'ARROWDOWN':
          if (!prev.ARROWDOWN) {
            window.addEventListener('keydown', tabKeyHandler);
            keyEventStatus.ARROWDOWN = true;
          }

          return {
            TAB: prev.TAB,
            SPACE: prev.SPACE,
            ARROWUP: prev.ARROWUP,
            ARROWDOWN: true,
          };
        default:
          return prev;
      }
    });
  };

  const removeHandler = (keyEvent: KeyEvent) => {
    setKeyEventStatus((prev) => {
      switch (keyEvent) {
        case 'TAB':
          if (prev.TAB) {
            window.removeEventListener('keydown', tabKeyHandler);
          }

          return {
            TAB: false,
            SPACE: prev.SPACE,
            ARROWUP: prev.ARROWUP,
            ARROWDOWN: prev.ARROWDOWN,
          };
        case 'SPACE':
          if (prev.SPACE) {
            window.removeEventListener('keydown', tabKeyHandler);
            keyEventStatus.SPACE = true;
          }

          return {
            TAB: prev.TAB,
            SPACE: false,
            ARROWUP: prev.ARROWUP,
            ARROWDOWN: prev.ARROWDOWN,
          };
        case 'ARROWUP':
          if (prev.ARROWUP) {
            window.removeEventListener('keydown', tabKeyHandler);
            keyEventStatus.ARROWUP = true;
          }

          return {
            TAB: prev.TAB,
            SPACE: prev.SPACE,
            ARROWUP: false,
            ARROWDOWN: prev.ARROWDOWN,
          };
        case 'ARROWDOWN':
          if (prev.ARROWDOWN) {
            window.removeEventListener('keydown', tabKeyHandler);
            keyEventStatus.ARROWDOWN = true;
          }

          return {
            TAB: prev.TAB,
            SPACE: prev.SPACE,
            ARROWUP: prev.ARROWUP,
            ARROWDOWN: false,
          };
        default:
          return prev;
      }
    });
  };

  // useEffect(() => {
  //   (async () => {
  //     await allAddHandler();
  //   })();

  //   return () => {
  //     (async () => {
  //       await allRemoveHandler();
  //     })();
  //   };
  // }, []);

  return {
    addHandler,
    removeHandler,
    allAddHandler,
    allRemoveHandler,
  };
}

export default useKeyLock;
