import { Eye } from 'components/Commons/icons';
import { ViewerModal } from 'pages/Home/modals';
import { useState, useEffect } from 'react';
import { useAtomValue } from 'jotai';

import { currentRoomAtom, languageModeAtom, totalRoomsAtom } from 'src/atoms';
import * as Colyseus from 'colyseus.js';
import { Space } from 'apis/types';

const scripts = {
  KR: ['요약하기', '더 보기', '로그인이 필요합니다.', '차단되었습니다.', '명'],
  EN: ['See Less', 'More', 'Login required', 'Blocked.', ' People'],
  JP: ['要約する', 'もっと見る', 'ログインが必要です', 'ブロックされました。', ' 人'],
};

export function ViwerModal({ space }: { space: Space }) {
  let [isViewerModalOpen, setIsViewerModalOpen] = useState<boolean>(false);
  let languageMode = useAtomValue(languageModeAtom);
  let currentRoom = useAtomValue(currentRoomAtom);
  let totalRooms = useAtomValue(totalRoomsAtom);
  let [currentChannel, setCurrentChannel] = useState<Colyseus.Room | null>(null);
  let [spaceChannels, setSpaceChannels] = useState<any[] | null>(null);
  let [currentClients, setCurrentClients] = useState<number>(0);

  useEffect(() => {
    if (totalRooms && currentRoom) {
      setCurrentChannel(totalRooms.filter((room) => room.roomId === currentRoom?.id)[0]);
      setSpaceChannels(totalRooms.filter((room) => room.roomId.startsWith(space.id)));
      if (currentChannel) {
        setCurrentClients(currentChannel.clients);
      }
    }
  }, [currentRoom, totalRooms, space.id, currentChannel]);

  return (
    <>
      <div
        className='flex justify-end absolute top-0 right-0'
        onClick={(e) => {
          e.stopPropagation();
          setIsViewerModalOpen(true);
        }}>
        <div className='m-4 p-2 rounded-lg' style={{ backgroundColor: 'rgba(51,51,51,0.3)' }}>
          <span className='float-left inline-block items-center'>
            <Eye />
          </span>
          <span className='float-right text-xs inline-block items-center py-1 pl-1 text-white'>
            {currentClients} {scripts[languageMode][4]}
          </span>
        </div>
      </div>
      {isViewerModalOpen && (
        <ViewerModal
          setIsViewerModalOpen={setIsViewerModalOpen}
          currentChannel={currentChannel}
          spaceChannels={spaceChannels}
        />
      )}
    </>
  );
}
