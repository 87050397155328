import { Vector3, Euler } from '@react-three/fiber';
import { useMemo, useEffect } from 'react';
import { useCompressedGLTF } from 'hooks/hooks';

const url = new URL('static/models/frame.min.glb', import.meta.url);

export default function Frame({
  position,
  scale,
  rotation,
}: {
  position: Vector3;
  scale: Vector3;
  rotation: Euler;
}) {
  const { scene, nodes, materials } = useCompressedGLTF(url.href);
  const disposeMesh: THREE.Mesh[] = [];
  const disposeTexture: THREE.Texture[] = [];

  scene.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      const mesh = object as THREE.Mesh;

      const mat = mesh.material as THREE.Material;
      disposeMesh.push(mesh);
      if (mat.map !== null) {
        disposeTexture.push(mat.map);
      }
    }
  });
  useEffect(() => {
    return () => {
      for (let i = 0; i < disposeMesh.length; i++) {
        if (disposeMesh[i]) disposeMesh[i].geometry.dispose();
      }
      for (let i = 0; i < disposeTexture.length; i++) {
        if (disposeTexture[i]) disposeTexture[i].dispose();
      }
    };
  }, []);
  return (
    <group position={position} scale={scale} rotation={rotation}>
      <group position={[12.54, 1.08, 8.82]} scale={1}>
        <mesh geometry={nodes.mesh_0_1.geometry} material={materials.MATAL} />
        <mesh geometry={nodes.mesh_0_2.geometry} material={materials.bloom} />
      </group>
    </group>
  );
}
