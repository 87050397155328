import React, { useState, useEffect, useRef, useCallback, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useInfiniteQuery } from '@tanstack/react-query';

import { getSpaces, getFeeds, getLouvreSpaces, APIIssues, APIError } from 'apis/index';
import { Spaces } from 'pages/Home/spaces';

import { tokenAtom, navbarTypeAtom, userAtom, playModeAtom, bgmModeAtom, spacechannel } from 'src/atoms';

export default function LouvreSpaces({ myself, isFeed }: { myself: string; isFeed: boolean }) {
  let { spaceId } = useParams() as { spaceId: string };
  let token = useAtomValue(tokenAtom);
  let [error, setError] = useState<APIIssues | null>(null);

  let { data, fetchNextPage, hasNextPage, isFetchingNextPage, isError } = useInfiniteQuery(
    ['space', token, 'feed'],
    async ({ pageParam }) => {
      try {
        return isFeed ? await getLouvreSpaces(token, pageParam) : await getFeeds(spaceId, pageParam);
      } catch (error) {
        if (error instanceof APIError) {
          setError(error.issues);
        }
      }
    },
    {
      placeholderData: { pages: [], pageParams: [] },
      getNextPageParam: (lastPage) => (lastPage ? lastPage.cursor : undefined),
    }
  );
  let spaces = data!.pages.flatMap((page) => (page ? page.spaces : []));

  return (
    <Spaces
      spaces={spaces}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      error={error}></Spaces>
  );
}
