import * as THREE from 'three';
import * as Colyseus from 'colyseus.js';
import _ from 'lodash';
import React, { forwardRef, Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useAtomValue } from 'jotai';
import { PositionalAudio, Billboard, Text } from '@react-three/drei';
import Character from './character';
import CharacterFemale from './character-female';
import { bgmModeAtom } from 'src/atoms';
import { Players, PlayerMeshs, PlayerAdditionalInfos } from 'components/Multiplay/types';
import { useFrame } from '@react-three/fiber';

const PhraseGen = require('korean-random-words');
const phraseGen = new PhraseGen({
  delimiter: ' ',
});
let tmpName = phraseGen.generatePhrase().split(' ');
const font2 = new URL('static/fonts/NotoSansKR-Medium.otf', import.meta.url);

const spawnAudioURL = new URL('static/sounds/spawn.wav', import.meta.url);

const Player = forwardRef<
  THREE.Group,
  {
    custom: {
      type: string;
      hair: number;
      face: number;
      top: number;
      bottom: number;
      shoes: number;
    };
    isMoved: number;
    nickname: string | null;
    username: string | null;
  } & Pick<JSX.IntrinsicElements['mesh'], 'children'>
>(function Player({ children, custom, isMoved, nickname, username }, ref) {
  let bgmMode = useAtomValue(bgmModeAtom);
  const [nonUserNickName, setNonUserNickName] = useState<string>('');

  useEffect(() => {
    setNonUserNickName(tmpName[1].concat(' ' + tmpName[2]));
  }, [tmpName]);
  return (
    <group ref={ref}>
      <mesh scale={0.3}>
        <Billboard
          follow={true}
          lockX={false}
          lockY={false}
          lockZ={false}
          scale={1}
          position={[0, 2.6, 0]}>
          <Text
            onClick={(e) => console.log('click')}
            fontSize={0.2}
            color='black'
            anchorX='center'
            anchorY='middle'
            font={font2.href}>
            {username === 'Unauthenticated' ? nonUserNickName : nickname}
          </Text>
        </Billboard>
        <PositionalAudio
          autoplay
          loop={false}
          url={spawnAudioURL.href}
          distance={bgmMode ? 10 : 0}
        />
        <Suspense fallback={null}>
          {custom.type == 'M' ? (
            <Character customOptions={custom} gender={custom.type} isMoved={isMoved} />
          ) : (
            <CharacterFemale customOptions={custom} gender={custom.type} isMoved={isMoved} />
          )}
        </Suspense>
        {children}
      </mesh>
    </group>
  );
});

export default function MultiPlayer({
  room,
  playersRef,
}: {
  room: Colyseus.Room | null;
  playersRef: React.MutableRefObject<Players>;
}) {
  const mediaMatch = useMediaQuery({ maxWidth: 1224 });
  const playersMeshRef = useRef<PlayerMeshs>({});
  const [playerAdditionalInfos, setPlayerAdditionalInfos] = useState<PlayerAdditionalInfos>({});

  const PlayerMeshComponent = useMemo(() => {
    if (!room) return;
    return Object.entries(playersRef.current).map(([key, value], index) => {
      if (key in playerAdditionalInfos) {
        playersMeshRef.current[key] = React.createRef<THREE.Group>();
        const characterInfo = {
          type: value.type,
          hair: value.hair,
          face: value.face,
          top: value.top,
          bottom: value.bottom,
          shoes: value.shoes,
        };
        return (
          <Player
            custom={characterInfo}
            isMoved={playerAdditionalInfos[key]}
            nickname={value.nickname}
            username={value.username}
            key={key}
            ref={playersMeshRef.current[key]}
          />
        );
      }
    });
  }, [playerAdditionalInfos, room]);
  useFrame(() => {
    if (!room) return;

    const newPlayerAdditionalInfos: PlayerAdditionalInfos = {};

    for (const [key, value] of Object.entries(playersRef.current)) {
      if (key == room.sessionId) continue;
      if (value.playtype == 1) {
        newPlayerAdditionalInfos[key] = value.ani;
      }
    }

    for (const [key, value] of Object.entries(playersMeshRef.current)) {
      if (!(key in newPlayerAdditionalInfos)) {
        delete playersMeshRef.current[key];
      }
    }
    for (const [key, value] of Object.entries(newPlayerAdditionalInfos)) {
      if (key in playersMeshRef.current) {
        var m = playersMeshRef.current[key];
        if (m.current !== null) {
          m.current.position.x = playersRef.current[key].posx;
          m.current.position.y = playersRef.current[key].posy - (mediaMatch ? 1.6 : 1.6);
          m.current.position.z = playersRef.current[key].posz;
          m.current.rotation.x = playersRef.current[key].rotx;
          m.current.rotation.y = playersRef.current[key].roty;
          m.current.rotation.z = playersRef.current[key].rotz;
        }
      }
    }
    if (!_.isEqual(newPlayerAdditionalInfos, playerAdditionalInfos)) {
      setPlayerAdditionalInfos(newPlayerAdditionalInfos);
    }
  });
  return <>{PlayerMeshComponent}</>;
}
