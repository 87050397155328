import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SetStateAction, useAtomValue } from 'jotai';

import { Close } from 'components/Commons/icons';
import PhoneCerification from 'components/Commons/phone-certification';
import { APIError, APIIssues, getTokenUser, getVerificationCode, verify, verifyPhone } from 'apis/index';

import { languageModeAtom, tokenAtom, userAtom } from 'src/atoms';
import useModal from 'hooks/useModal';

const scripts = {
  KR: [
    '인증시간이 만료되었습니다.',
    '핸드폰 인증',
    '알파 버젼 회원님',
    '환영합니다!',
    '회원님이 업로드한 작품들은 잘 옮겨져 있습니다.',
    '휴대폰 인증을 통해 바로 만나보세요.',
    '국가선택',
    '유효하지 않은 전화번호입니다.',
    '입력하신 전화번호로 등록된 계정이 있는지 확인해주세요',
    '확인',
    '재전송',
    '인증이 완료되지 않았습니다.',
    '인증완료',
    '서비스 정책',
    '서비스 이용약관 동의 (필수)',
    '내용보기',
    '이용약관에 동의해 주세요.',
    '휴대폰 인증에 실패했습니다. 다시 시도해주세요.',
    '확인',
  ],
  EN: [
    'Certification time has expired',
    'Mobile phone authentication',
    'Alpha version member',
    'Welcome!',
    'The works you uploaded are well transferred.',
    'Check it out right away through mobile phone authentication.',
    'Select country',
    'Invalid phone number',
    'Please check if there is an account registered with the phone number you entered',
    'Confirm',
    'Resend',
    'Authentication is not completed',
    'Authentication completed',
    'Service Policy',
    'Consent to Terms of Service (Required)',
    'View Contents',
    'Please onsent to Terms of Use',
    'Phone Authentication Failed. Please try again.',
    'Check',
  ],
  JP: [
    '認証時間が満了しました。',
    '携帯電話認証',
    'アルファバージョン会員様',
    '歓迎します！',
    '会員様がアップロードした作品はちゃんと移されています。',
    '携帯電話認証を通じてすぐにご覧ください。',
    '国の選択',
    '有効でない電話番号です。',
    '入力した電話番号に登録されたアカウントがあるか確認してください',
    '確認',
    '再送信',
    '認証完了',
    'サービスの利用約款の同意(必須)',
    '内容を見る',
    '利用約款に同意してください',
    '携帯電話番号に失敗しました。',
    '認証を入力した。 もう一度試してください。',
    '確認',
  ],
};
interface TimeState {
  minutes: number;
  seconds: number;
}

function Timer({ minutes, seconds }: TimeState) {
  let languageMode = useAtomValue(languageModeAtom);
  const [time, setTime] = useState<TimeState>({ minutes, seconds });
  const [isOver, setIsOver] = useState<boolean>(false);
  const tick = () => {
    if (time.seconds === 0) {
      if (time.minutes === 0) {
        setIsOver(true);
      } else {
        setTime({ minutes: time.minutes - 1, seconds: 59 });
      }
    } else {
      setTime({ minutes: time.minutes, seconds: time.seconds - 1 });
    }
  };
  const secondStringfy = (second: number) => {
    if (second >= 10) {
      return String(second);
    } else {
      return '0' + String(second);
    }
  };
  useEffect(() => {
    const timeId = setInterval(() => tick(), 1000);
    return () => clearInterval(timeId);
  });
  return (
    <div className='text-xs text-error'>
      {isOver ? scripts[languageMode][0] : String(time.minutes) + ':' + secondStringfy(time.seconds)}
    </div>
  );
}

export default function AlphaValidate(): JSX.Element | null {
  let token = useAtomValue(tokenAtom);
  let user = useAtomValue(userAtom);
  let languageMode = useAtomValue(languageModeAtom);
  let navigate = useNavigate();
  let [codeNumber, setCodeNumber] = useState<string>('82');
  let [isVerified, setIsVerified] = useState<boolean>(false);
  let [agreement, setAgreement] = useState<boolean>(false);
  let [agreementError, setAgreementError] = useState<boolean>(false);

  let [phone, setPhone] = useState<string>('');
  let [phoneToken, setPhoneToken] = useState<string | null>(null);
  let [validateError, setValidateError] = useState<APIIssues | null>(null);
  let [isOpen, setIsOpen] = useState<boolean>(false);
  let content = null;
  const { openAlertModal } = useModal();

  useEffect(() => {
    if (user) {
      if (!user.phoneVerified) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    } else {
      setIsOpen(false);
    }
  }, [user]);

  return isOpen ? (
    <>
      <div className='fixed inset-0' style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} />
      <div className='absolute bg-white px-4 py-3 border border-zinc-300 rounded bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2 w-5/6 h-[90%] lg:w-fit'>
        <div className='w-full flex items-center'>
          <div className='grow basis-0 justify-end' />
          <div className='flex grow basis-0 justify-end'>
            <button className='flex justify-end items-center' onClick={() => setIsOpen(false)}>
              <Close />
            </button>
          </div>
        </div>
        <div className='text-xl text-center mb-6'>{scripts[languageMode][1]}</div>
        <div>
          <p className='text-base'>{scripts[languageMode][2]}</p>
          <p className='text-base'>{scripts[languageMode][3]}</p>
        </div>
        <div className='py-2 mb-6'>
          <p className='text-sm text-zinc-500'>{scripts[languageMode][4]}</p>
          <p className='text-sm text-zinc-500'>{scripts[languageMode][5]}</p>
        </div>
        <form className='max-w-sm flex flex-col gap-2 m-auto h-full'>
          <PhoneCerification
            type={3}
            phone={phone}
            setPhone={setPhone}
            phoneToken={phoneToken}
            setPhoneToken={setPhoneToken}
            codeNumber={codeNumber}
            setCodeNumber={setCodeNumber}
            isVerified={isVerified}
            setIsVerified={setIsVerified}
            validateError={validateError}
            setValidateError={setValidateError}></PhoneCerification>
          <div className='flex flex-col gap-1 py-7'>
            <div className='text-xs text-zinc-400'>{scripts[languageMode][13]}</div>
            <div className='flex items-center'>
              <div className='flex gap-1.5 text-sm text-zinc-500'>
                <input
                  type='checkbox'
                  checked={agreement}
                  onChange={(e) => {
                    setAgreement(e.currentTarget.checked);
                    if (e.currentTarget.checked === true) {
                      setAgreementError(false);
                    }
                  }}
                />
                <div>{scripts[languageMode][14]}</div>
              </div>
              <div className='flex-auto justify-end' />
              <div
                className='text-xs text-zinc-400 underline pt-px'
                onClick={() => {
                  window.open('https://adler-inc.notion.site/adler-inc/a131945a6a1347bf87c15eba69227083');
                }}>
                {scripts[languageMode][15]}
              </div>
            </div>
            {agreementError && <div className='text-xs text-error'>{scripts[languageMode][16]}</div>}
          </div>

          <button
            type='submit'
            className='bg-pink-600 rounded-md text-white p-1 h-12 font-semibold text-lg'
            onClick={async (e) => {
              e.preventDefault();
              if (!agreement) {
                setAgreementError(true);
                return;
              }
              if (!validateError && isVerified) {
                if (phoneToken && token) {
                  setAgreementError(false);
                  let result = await verifyPhone(token, phoneToken);
                  if (result.result) {
                    navigate('/');
                    setIsOpen(false);
                  } else {
                    openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][17] }]);
                  }
                }
              }
            }}>
            {scripts[languageMode][18]}
          </button>
        </form>
      </div>
    </>
  ) : null;
}
