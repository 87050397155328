import { Vector3, Euler } from '@react-three/fiber';
import { useAnimations, Environment } from '@react-three/drei';
import { useMemo, useEffect } from 'react';
import * as THREE from 'three';
import { SkeletonUtils } from 'three-stdlib';
import { Cloud } from '@react-three/drei';

import { useCompressedGLTF } from 'hooks/hooks';

let url = new URL('static/imgs/forest3.hdr', import.meta.url);
let url2 = new URL('static/models/chen_final.min.glb', import.meta.url);

export default function ForestThema({ position, rotation }: { position: Vector3; rotation: Euler }) {
  let { scene: scene1, animations } = useCompressedGLTF(url2.href);
  let clonedScene1 = useMemo(() => SkeletonUtils.clone(scene1), [scene1]);
  let { ref, actions, names } = useAnimations(animations);

  clonedScene1.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      let mesh = object as THREE.Mesh;

      mesh.material = mesh.material as THREE.Material;
      mesh.receiveShadow = true;
    }
  });
  useEffect(() => {
    for (let i = 0; i < names.length; i++) {
      actions[names[i]]?.reset().play();
    }
  }, [actions, names]);

  return (
    <>
      <group position={[position[0], position[1] - 0.5, position[2]]} scale={1} rotation={rotation}>
        <group ref={ref}>
          <primitive object={clonedScene1} />
        </group>
      </group>
      <Environment files={url.href} background />
    </>
  );
}
