import { useCallback, useEffect, useState } from 'react';

interface ScrollState {
  posX: number;
  posY: number;
}

type RunScroll = (posX?: number, posY?: number) => void;

interface UseScrollParams {
  ref?: React.MutableRefObject<HTMLElement | undefined>;
}

interface UseScroll {
  scrollState: ScrollState;
  runScroll: RunScroll;
  goToTop: () => void;
}

/*
  INFO

  ref이 존재할 경우 ref의 element의 스크롤 위치를 최상단으로, 그렇지 않을 경우 scrollable한 scrollElement란 id를 가진 element를, 
  최악엔 document기반 스크롤 위치를 최상단으로 보내주는 훅
*/
function useScroll({ ref }: UseScrollParams): UseScroll {
  const scrollElement = document.getElementById('scrollElement2');
  const [scrollState, setScrollState] = useState<ScrollState>({ posX: 0, posY: 0 });

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const onScroll = useCallback(() => {
    setScrollState({
      posX: ref?.current ? ref.current.scrollWidth : window.scrollX,
      posY: ref?.current ? ref.current.scrollHeight : window.scrollY,
    });
  }, []);

  const runScroll: RunScroll = useCallback(
    (posX = 0, posY = 0) => {
      if (ref?.current) {
        try {
          ref.current.scroll({
            top: posY,
            left: posX,
            behavior: 'smooth',
          });
        } catch (error) {
          ref.current.scrollTo(posX, posY);
        }
      } else if (scrollElement) {
        try {
          scrollElement.scroll({
            top: posY,
            left: posX,
            behavior: 'smooth',
          });
        } catch (error) {
          scrollElement.scrollTo(posX, posY);
        }
      } else {
        try {
          window.scroll({
            top: posY,
            left: posX,
            behavior: 'smooth',
          });
        } catch (error) {
          window.scrollTo(posX, posY);
        }
      }
    },
    [ref?.current, scrollElement]
  );

  const goToTop = useCallback(() => {
    if (ref?.current) {
      try {
        ref.current.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } catch (error) {
        ref.current.scrollTo(0, 0);
      }
    } else if (scrollElement) {
      try {
        scrollElement.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } catch (error) {
        scrollElement.scrollTo(0, 0);
      }
    } else {
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } catch (error) {
        window.scrollTo(0, 0);
      }
    }
  }, [ref?.current, scrollElement]);

  return {
    scrollState,
    runScroll,
    goToTop,
  };
}

export default useScroll;
