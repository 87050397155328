import { useLayoutEffect } from 'react';
import create from 'zustand';

function tunnel() {
  const useStore = create(set => ({
    current: null,
    set
  }));
  return {
    In: ({
      children
    }) => {
      const set = useStore(state => state.set);
      useLayoutEffect(() => {
        set({
          current: children
        });
        return () => void set({
          current: null
        });
      }, [children]);
      return null;
    },
    Out: () => {
      const current = useStore(state => state.current);
      return current;
    }
  };
}

export { tunnel as default };
