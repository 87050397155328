import { useCallback, useMemo, useState } from 'react';
import { useAtomValue } from 'jotai';

import { FollowUserSearchContainer, SearchIconContainer, SearchInput } from './styles';
import { FollowUserSearchProps } from './types';

import { SearchIcon } from 'components/Commons/icons';
import useModal from 'hooks/useModal';
import { APIError, getFollowers, getFollowings, usersFollowersSearch, usersFollowingsSearch } from 'apis/index';
import { tokenAtom } from 'src/atoms';

function FollowUserSearch({
  currentTab,
  currentUserName,
  setFollowerDatas,
  setFollowingDatas,
}: FollowUserSearchProps): JSX.Element {
  const token = useAtomValue(tokenAtom);
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const { openAlertModal } = useModal();

  const isFollowerTab = useMemo(() => currentTab === 'follower', [currentTab]);

  const onSearchBarOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  }, []);

  const getSearchedFollowData = useCallback(async () => {
    if (token && currentUserName) {
      if (searchKeyword && searchKeyword !== '') {
        try {
          const followDatas = await (isFollowerTab ? usersFollowersSearch : usersFollowingsSearch)(
            token,
            currentUserName,
            searchKeyword
          );

          isFollowerTab ? setFollowerDatas(followDatas) : setFollowingDatas(followDatas);
        } catch (error) {
          let errorMessage;

          if (error instanceof APIError) {
            errorMessage = error.issues.detail;
          } else {
            errorMessage = error as string;
          }

          openAlertModal([{ type: 'NORMAL', message: errorMessage }]);
        }
      } else {
        if (isFollowerTab) {
          const followerDatas = await getFollowers(`/users/${currentUserName}/followers/`, token);

          setFollowerDatas(followerDatas);
        } else {
          const followingDatas = await getFollowings(`/users/${currentUserName}/followings/`);

          setFollowingDatas(followingDatas);
        }
      }
    }
  }, [isFollowerTab, searchKeyword, token, currentUserName, setFollowerDatas, setFollowingDatas]);

  const onSearchBarEnterKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        getSearchedFollowData();
      }
    },
    [getSearchedFollowData]
  );

  return (
    <FollowUserSearchContainer>
      <SearchInput placeholder='검색' onChange={onSearchBarOnChange} onKeyDown={onSearchBarEnterKeyDown} />
      <SearchIconContainer onClick={getSearchedFollowData}>
        <SearchIcon />
      </SearchIconContainer>
    </FollowUserSearchContainer>
  );
}

export default FollowUserSearch;
