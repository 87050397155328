import styled from 'styled-components';

import { BreakPoint, Color } from 'src/constants';

import close from 'static/icons/close.svg';
import close_m from 'static/icons/close_m.svg';
import searchGray from 'static/icons/search_gray.svg';
import searchGray_m from 'static/icons/search_gray_m.svg';
import checkPink from 'static/icons/check_pink.svg';
import checkPink_m from 'static/icons/check_pink_m.svg';

export const DropboxModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
  padding: 2rem;
  border-radius: 0.625rem 0.625rem 0 0;
  box-sizing: border-box;
  background-color: ${Color.white};

  .clickable:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  ${BreakPoint.mobile} {
    width: 100%;
    height: 100%;
  }
`;

export const DropboxHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 1.5rem;
  margin-bottom: 1rem;
`;

export const DropboxTitle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.25rem;
  letter-spacing: -0.02rem;
  color: ${Color.grayScale1};
`;

export const CloseIcon = styled.img.attrs<{ isMobile: boolean }>(({ isMobile }) => ({
  src: isMobile ? close_m : close,
  className: 'clickable',
}))<{ isMobile: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  width: 1.25rem;
  height: 1.25rem;
`;

export const SearchArea = styled.div`
  position: relative;
  width: 100%;
  height: 2.875rem;
`;

export const SearchInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.75rem 0 0.75rem 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: -0.02rem;
  border-radius: 0.125rem;
  color: ${Color.grayScale3};
  background-color: ${Color.grayScale8};

  &:focus {
    outline: none;
  }
`;

export const SearchIcon = styled.img.attrs<{ isMobile: boolean }>(({ isMobile }) => ({
  src: isMobile ? searchGray_m : searchGray,
}))<{ isMobile: boolean }>`
  position: absolute;
  top: 0.625rem;
  right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
`;

export const DropboxContentArea = styled.div`
  width: 100%;
  max-height: 42.5rem;
  margin-top: 1rem;
  overflow: auto;

  ${BreakPoint.mobile} {
    max-height: unset;
    height: calc(100% - 7.5rem);
  }
`;

export const DropboxContentContainer = styled.div.attrs({ className: 'clickable' })`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
  padding-right: 1rem;
  box-sizing: border-box;
`;

export const DropboxContentTextHidden = styled.div`
  position: absolute;
  display: none;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: -0.02rem;
  color: ${Color.grayScale3};
`;

const DropboxContentText = styled.div`
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: -0.02rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${Color.grayScale3};
`;

export const DropboxContentTitle = styled(DropboxContentText)`
  position: relative;
  width: 5.5rem;

  &:hover + ${DropboxContentTextHidden} {
    display: block;
  }
`;

export const DropboxContentIcon = styled.img.attrs<{ icon?: string }>(({ icon }) => ({
  src: icon,
}))<{ icon?: string }>`
  opacity: ${({ icon }) => (icon ? 1 : 0)};
  width: 1.5rem;
  height: 1.5rem;
`;

export const DropboxContentSubtitle = styled(DropboxContentText)`
  position: relative;
  width: 14.875rem;

  &:hover + ${DropboxContentTextHidden} {
    display: block;
  }
`;

export const DropboxContentChecked = styled.img.attrs<{ isChecked?: boolean; isMobile: boolean }>(
  ({ isChecked, isMobile }) => ({
    src: isChecked ? (isMobile ? checkPink_m : checkPink) : undefined,
  })
)<{ isChecked?: boolean; isMobile: boolean }>`
  opacity: ${({ isChecked }) => (isChecked ? 1 : 0)};
  width: 1.5rem;
  height: 1.5rem;
`;
