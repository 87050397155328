import styled from 'styled-components';

import { BreakPoint } from 'src/constants';

export const IntroductionContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 6.25rem;
`;

export const IntroductionArea = styled.div`
  width: 64rem;

  ${BreakPoint.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 86.66%;
  }
`;

export const DivisionBorder = styled.div`
  width: 100%;
  margin: 1.5rem 0;
  border: 1px solid #db1f72;
`;

export const TextArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0;

  ${BreakPoint.mobile} {
    padding: 0.25rem 0;
    margin-bottom: 0.25rem;
  }
`;

export const NameText = styled.div`
  width: 24%;
  font-weight: 500;
  border-right: 2px solid #db1f72;

  ${BreakPoint.mobile} {
    font-size: 0.75rem;
  }
`;

export const ContentText = styled.div`
  width: 74%;
  font-weight: 500;

  ${BreakPoint.mobile} {
    font-size: 0.75rem;
  }

  span {
    font-weight: 700;
    color: #db1f72;
  }
`;
