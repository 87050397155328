import { useMemo, useEffect } from 'react';
import { Vector3, Euler } from '@react-three/fiber';
import { SkeletonUtils } from 'three-stdlib';

import { useCompressedGLTF } from 'hooks/hooks';

const url = new URL('static/models/2.min.glb', import.meta.url);

export default function ProfileThemeDynamic({
  position,
  scale,
  rotation,
}: {
  position: Vector3;
  scale: Vector3;
  rotation: Euler;
}) {
  const { scene: scene1 } = useCompressedGLTF(url.href);
  const clonedScene1 = useMemo(() => SkeletonUtils.clone(scene1), [scene1]);
  const disposeMesh: THREE.Mesh[] = [];
  const disposeTexture: THREE.Texture[] = [];

  clonedScene1.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      const mesh = object as THREE.Mesh;
      mesh.material = mesh.material as THREE.Material;
      mesh.material.toneMapped = false;
      const mat = mesh.material as THREE.Material;
      disposeMesh.push(mesh);
      if (mat.map !== null) {
        disposeTexture.push(mat.map);
      }
    }
  });
  useEffect(() => {
    return () => {
      for (let i = 0; i < disposeMesh.length; i++) {
        if (disposeMesh[i]) disposeMesh[i].geometry.dispose();
      }
      for (let i = 0; i < disposeTexture.length; i++) {
        if (disposeTexture[i]) disposeTexture[i].dispose();
      }
    };
  }, []);
  return (
    <group position={position} scale={scale} rotation={rotation}>
      <primitive object={clonedScene1} />
    </group>
  );
}
