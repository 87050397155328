import { Vector3, Euler } from '@react-three/fiber';
import { useMemo } from 'react';
import * as THREE from 'three';
import { SkeletonUtils } from 'three-stdlib';

import { useCompressedGLTF } from 'hooks/hooks';

let url = new URL('static/models/adler_box_main.min.glb', import.meta.url);

export default function Button({ position, scale, rotation }: { position: Vector3; scale: Vector3; rotation: Euler }) {
  let { scene: scene1 } = useCompressedGLTF(url.href);
  let clonedScene1 = useMemo(() => SkeletonUtils.clone(scene1), [scene1]);
  clonedScene1.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      let mesh = object as THREE.Mesh;
      mesh.material = mesh.material as THREE.Material;
      mesh.material.toneMapped = false;
    }
  });

  return (
    <group position={position} scale={scale} rotation={rotation}>
      <primitive object={clonedScene1} />
    </group>
  );
}
