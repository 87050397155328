import { z } from 'zod';

export let APIIssuesSchema = z.object({ code: z.number(), detail: z.string(), field: z.string() });
export type APIIssues = z.infer<typeof APIIssuesSchema>;

// ItemSerializer
export let ItemSchema = z.object({
  id: z.string().uuid(),
  type: z.number(),
  file: z.string().url(),
  frameType: z.number(),
  positionX: z.number(),
  positionY: z.number(),
  positionZ: z.number(),
  rotationX: z.number(),
  rotationY: z.number(),
  rotationZ: z.number(),
  scale: z.number(),
});

export let PatchItemSchema = z.object({
  type: z.number(),
  upload_id: z.string().url(),
  frame_type: z.number(),
  positionX: z.number(),
  positionY: z.number(),
  positionZ: z.number(),
  rotationX: z.number(),
  rotationY: z.number(),
  rotationZ: z.number(),
  scale: z.number(),
});

export type PatchItem = z.infer<typeof PatchItemSchema>;
export type Item = z.infer<typeof ItemSchema>;
export type UploadItem = Omit<Item, 'id' | 'file'> & {
  uploadId: string;
};

// CharacterSerializer
export let CharacterSchema = z.object({
  type: z.string(),
  hair: z.number(),
  face: z.number(),
  top: z.number(),
  bottom: z.number(),
  shoes: z.number(),
});
export type Character = z.infer<typeof CharacterSchema>;

// SpaceSerializer
export let SpaceSchema = z.object({
  id: z.string().uuid(),
  username: z.string(),
  userNickname: z.string(),
  profileImgURL: z.nullable(z.string().url()),
  thumbImage: z.nullable(z.string().url()),
  items: z.array(ItemSchema),
  description: z.string(),
  hashTags: z.array(z.string()),
  noHashTagDescription: z.string(),
  likeNum: z.number(),
  isLike: z.boolean(),
  replyNum: z.number(),
  bgm: z.number(),
  theme: z.number(),
  lightBright: z.number(),
  created: z.string(),
  themeColor: z.number(),
  themeSaturation: z.number(),
  themeBrightness: z.number(),
});

export type Space = z.infer<typeof SpaceSchema>;

export let SpacePageSchema = z.object({
  next: z.nullable(
    z
      .string()
      .url()
      .transform((url) => new URL(url))
  ),
  results: z.array(SpaceSchema),
  count: z.number(),
});

export type isRead = z.infer<typeof isReadSchema>;

export let isReadSchema = z.object({
  isRead: z.boolean(),
});

export type SpacePage = z.infer<typeof SpacePageSchema>;

export let UserSchema = z.object({
  id: z.string(),
  username: z.string(),
  nickname: z.string(),
  bio: z.string(),
  image: z.string().nullable(),
  url: z.string(),
  grade: z.number(),
});

export type User = z.infer<typeof UserSchema>;

export let FollowUserSchema = z.object({
  id: z.string(),
  username: z.string(),
  nickname: z.string(),
  bio: z.string(),
  image: z.string().nullable(),
  url: z.string(),
  grade: z.number(),
  isFollowing: z.boolean(),
});

export type FollowUser = z.infer<typeof FollowUserSchema>;

export let AccountSchema = z.object({
  username: z.string(),
  nickname: z.string(),
  bio: z.string(),
  image: z.string().url(),
  phone: z.string(),
  phoneVerified: z.boolean(),
  url: z.string(),
  grade: z.number(),
  email: z.optional(z.string()),
  characters: z.object({
    type: z.string(),
    hair: z.number(),
    face: z.number(),
    top: z.number(),
    bottom: z.number(),
    shoes: z.number(),
  }),
});

export type Account = z.infer<typeof AccountSchema>;

export let TokenSchema = z.string();
export type Token = z.infer<typeof TokenSchema>;

export let UploadSchema = z.object({
  id: z.string(),
  created: z.string(),
});
export type Upload = z.infer<typeof UploadSchema>;

export let AlarmSchema = z.object({
  fromUser: z.string(),
  user: z.string(),
  created: z.string(),
  type: z.string(),
  fromImgURL: z.string().nullable(),
  fromUserURL: z.string().nullable(),
  moveURL: z.string().nullable(),
});
export type Alarm = z.infer<typeof AlarmSchema>;

export let AlarmPageSchema = z.object({
  next: z.nullable(
    z
      .string()
      .url()
      .transform((url) => new URL(url))
  ),
  results: z.array(AlarmSchema),
});
export type AlarmPage = z.infer<typeof AlarmPageSchema>;

export type NavbarType =
  | 'posting'
  | 'feed'
  | 'search'
  | 'character'
  | 'settings'
  | 'login'
  | 'signup'
  | 'make'
  | 'alarm'
  | 'password-reset'
  | 'profile'
  | 'follow'
  | '2dFeed'
  | 'focus-art-fair'
  | 'certificate';

// ReplySerializer
export let ReplySchema = z.object({
  id: z.string().uuid(),
  user: z.string(),
  profileImgURL: z.nullable(z.string().url()),
  space: z.string().uuid(),
  reply: z.string(),
  hashTags: z.array(z.string()),
  noHashTagReply: z.string(),
  created: z.string(),
});

export type Reply = z.infer<typeof ReplySchema>;

export let ReplySetSchema = z.object({
  count: z.number(),
  results: z.array(ReplySchema),
  next: z.string().url(),
  previous: z.string().url(),
});

export type ReplySet = z.infer<typeof ReplySetSchema>;

export let SpaceSearchSetSchema = z.object({
  next: z.nullable(
    z
      .string()
      .url()
      .transform((url) => new URL(url))
  ),
  results: z.array(SpaceSchema),
});

export type SpaceSearchSet = z.infer<typeof SpaceSearchSetSchema>;

export let UserSearchSetSchema = z.object({
  next: z.nullable(
    z
      .string()
      .url()
      .transform((url) => new URL(url))
  ),
  results: z.array(UserSchema),
});

export type UserSearchSet = z.infer<typeof UserSearchSetSchema>;

export let PhoneTokenSchema = z.object({
  phoneToken: z.string(),
});

export type PhoneToken = z.infer<typeof PhoneTokenSchema>;
