import styled from 'styled-components';
import DefaultProfile from 'static/imgs/default-profile.png';
import { Button } from 'components/Commons/buttons';
import Rectangle from 'static/imgs/rectangle.svg';
import { Color } from 'src/constants';

export const ReplyModalContainer = styled.div`
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 0rem;
  left: 0rem;
  z-index: 20;
  background-color: ${Color.white};
  width: 100vw;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  max-height: 90vh;
  display: flex;
`;

export const UserReply = styled.div`
  padding-bottom: 0.5rem;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const SingleReplyContainer = styled.div`
  display: flex;
  padding-top: 0.5rem; /* 8px */
  padding-bottom: 0.5rem; /* 8px */
`;

export const ProfileImageContainer = styled.div`
  flex-shrink: 0;
  padding-left: 1rem; /* 16px */
  padding-right: 1rem; /* 16px */
  padding-top: 0.25rem;
`;

export const ProfileImage = styled.img.attrs<{ profileImgURL: string | null }>(
  ({ profileImgURL }) => ({
    src: profileImgURL ? profileImgURL : DefaultProfile,
  })
)<{ profileImgURL: string | null }>`
  height: 2rem;
  width: 2rem;
  border-radius: 624.9375rem;
  object-fit: cover;
  aspect-ratio: 1 / 1;
`;

export const ReplyArea = styled.div`
  padding-right: 1rem; /* 16px */
`;

export const TextArea = styled.div`
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  padding-bottom: 0.25rem; /* 4px */
`;

export const NickName = styled.div`
  display: inline;
  font-weight: 600;
  //FIXME :className을 clickavble로 설정하는 방식으로 작성

  &:hover {
    cursor: pointer;
  }
`;

export const TextComponent = styled.div`
  display: inline;
  word-break: break-all;
`;

export const HashTagArea = styled.div`
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  color: rgb(0, 55, 107, 1);
`;

export const HashTagText = styled.div`
  cursor: pointer;
`;

export const ButtonArea = styled.div`
  display: flex;
  gap: 1rem;
  font-size: 0.75rem; /* 12px */
  line-height: 1rem; /* 16px */
  align-items: center;
`;

export const ReplyDate = styled.div`
  color: rgb(107 114 128);
  font-size: 0.75rem;
  line-height: 1rem;
`;

export const DeleteReplyButton = styled(Button)`
  color: rgb(107 114 128);
  font-size: 0.75rem;
  line-height: 1rem;
`;

export const ReplyCount = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const ReplyCloseButton = styled.img.attrs({ src: Rectangle })`
  padding-top: 1rem;
`;
