import { useAnimations } from '@react-three/drei';
import { useEffect, useMemo } from 'react';
import { useGraph } from '@react-three/fiber';
import { SkeletonUtils } from 'three-stdlib';

import { useCompressedGLTF } from 'hooks/hooks';

const url = new URL('static/models/1028_girl.min.glb', import.meta.url);

export default function CharacterFemale({
  customOptions,
  gender,
  isMoved,
}: {
  customOptions: {
    type: string;
    hair: number;
    face: number;
    top: number;
    bottom: number;
    shoes: number;
  };
  gender: string;
  isMoved: number;
}) {
  const { scene, animations } = useCompressedGLTF(url.href);
  const clonedScene = useMemo(() => SkeletonUtils.clone(scene), [scene]);
  const { nodes, materials } = useGraph(clonedScene);
  const { ref, actions, names } = useAnimations(animations);
  const disposeMesh: THREE.Mesh[] = [];
  const disposeTexture: THREE.Texture[] = [];
  const matNames: any[] = [];

  Object.values(materials).map((key) => {
    matNames.push(key.name);
  });

  for (let i = 0; i < matNames.length; i++) {
    materials[matNames[i]].toneMapped = false;
  }

  clonedScene.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      const mesh = object as THREE.Mesh;
      disposeMesh.push(mesh);
      const mat = mesh.material as THREE.Material;
      if (mat.map !== null) {
        disposeTexture.push(mat.map);
      }
    }
  });

  useEffect(() => {
    return () => {
      for (let i = 0; i < disposeMesh.length; i++) {
        if (disposeMesh[i]) disposeMesh[i].geometry.dispose();
      }
      for (let i = 0; i < disposeTexture.length; i++) {
        if (disposeTexture[i]) disposeTexture[i].dispose();
      }
    };
  }, []);

  useEffect(() => {
    if (isMoved === 1) {
      actions[names[1]]?.reset().fadeIn(0.5).play();

      return () => {
        actions[names[1]]?.fadeOut(0.5);
      };
    } else {
      actions[names[0]]?.reset().fadeIn(0.5).play();

      return () => {
        actions[names[0]]?.fadeOut(0.5);
      };
    }
  }, [actions, names, isMoved]);
  return (
    <group
      ref={ref}
      castShadow
      rotation={[Math.PI / 2, 0, Math.PI]}
      scale={0.026}
      position={[0, -0.5, 0]}>
      <group name='Scene'>
        <group name='Armature' rotation={[0, 0, 0]} scale={1}>
          <primitive object={nodes.mixamorigHips} />
          {customOptions.type !== 'D' && (
            <group name='girl_body'>
              <skinnedMesh
                castShadow={true}
                frustumCulled={false}
                name='mesh_0'
                geometry={nodes.mesh_0.geometry}
                material={materials['lambert1.001']}
                skeleton={nodes.mesh_0.skeleton}
                position={[-0.38263446, -0.77616137, -0.12234595]}
                scale={0.00007213}
              />
            </group>
          )}
          {customOptions.hair == 1 && (
            <group name='girl_hair001'>
              <skinnedMesh
                castShadow={true}
                frustumCulled={false}
                name='mesh_1'
                geometry={nodes.mesh_1.geometry}
                material={materials['Material.002']}
                skeleton={nodes.mesh_1.skeleton}
                position={[-0.38263446, -0.77616137, -0.12234595]}
                scale={0.00007213}
              />
            </group>
          )}
          {customOptions.hair == 2 && (
            <group name='girl_hair002'>
              <skinnedMesh
                castShadow={true}
                frustumCulled={false}
                name='mesh_2'
                geometry={nodes.mesh_2.geometry}
                material={materials['Material.016']}
                skeleton={nodes.mesh_2.skeleton}
                position={[-0.38263446, -0.77616137, -0.12234595]}
                scale={0.00007213}
              />
            </group>
          )}
          {customOptions.hair > 2 && (
            <group name='girl_hair002'>
              <skinnedMesh
                castShadow={true}
                frustumCulled={false}
                name='mesh_2'
                geometry={nodes.mesh_2.geometry}
                material={materials['Material.016']}
                skeleton={nodes.mesh_2.skeleton}
                position={[-0.38263446, -0.77616137, -0.12234595]}
                scale={0.00007213}
              />
            </group>
          )}
          {customOptions.type !== 'D' && (
            <group name='girl_head'>
              <skinnedMesh
                castShadow={true}
                frustumCulled={false}
                name='mesh_3'
                geometry={nodes.mesh_3.geometry}
                material={materials['lambert1.002']}
                skeleton={nodes.mesh_3.skeleton}
                position={[-0.38263446, -0.77616137, -0.12234595]}
                scale={0.00007213}
              />
            </group>
          )}

          {customOptions.bottom == 1 && customOptions.top != 4 && (
            <group name='girl_pants001'>
              <skinnedMesh
                castShadow={true}
                frustumCulled={false}
                name='mesh_5'
                geometry={nodes.mesh_5.geometry}
                material={materials['Material.029']}
                skeleton={nodes.mesh_5.skeleton}
                position={[-0.38263446, -0.77616137, -0.12234595]}
                scale={0.00007213}
              />
            </group>
          )}
          {customOptions.bottom == 2 && customOptions.top != 4 && (
            <group name='girl_pants002'>
              <skinnedMesh
                castShadow={true}
                frustumCulled={false}
                name='mesh_6'
                geometry={nodes.mesh_6.geometry}
                material={materials['Material.018']}
                skeleton={nodes.mesh_6.skeleton}
                position={[-0.38263446, -0.77616137, -0.12234595]}
                scale={0.00007213}
              />
            </group>
          )}
          {customOptions.bottom == 3 && customOptions.top != 4 && (
            <group name='girl_pants003'>
              <skinnedMesh
                castShadow={true}
                frustumCulled={false}
                name='mesh_7'
                geometry={nodes.mesh_7.geometry}
                material={materials['Material.034']}
                skeleton={nodes.mesh_7.skeleton}
                position={[-0.38263446, -0.77616137, -0.12234595]}
                scale={0.00007213}
              />
            </group>
          )}
          {customOptions.bottom > 3 && customOptions.top != 4 && (
            <group name='girl_pants003'>
              <skinnedMesh
                castShadow={true}
                frustumCulled={false}
                name='mesh_7'
                geometry={nodes.mesh_7.geometry}
                material={materials['Material.034']}
                skeleton={nodes.mesh_7.skeleton}
                position={[-0.38263446, -0.77616137, -0.12234595]}
                scale={0.00007213}
              />
            </group>
          )}

          {customOptions.top == 1 && (
            <group name='girl_shirts001'>
              <skinnedMesh
                castShadow={true}
                frustumCulled={false}
                name='mesh_8'
                geometry={nodes.mesh_8.geometry}
                material={materials['Material.037']}
                skeleton={nodes.mesh_8.skeleton}
                position={[-0.38263446, -0.77616137, -0.12234595]}
                scale={0.00007213}
              />
            </group>
          )}
          {customOptions.top == 2 && (
            <group name='girl_shirts002'>
              <skinnedMesh
                castShadow={true}
                frustumCulled={false}
                name='mesh_9'
                geometry={nodes.mesh_9.geometry}
                material={materials['Material.001']}
                skeleton={nodes.mesh_9.skeleton}
                position={[-0.38263446, -0.77616137, -0.12234595]}
                scale={0.00007213}
              />
            </group>
          )}
          {customOptions.top == 3 && (
            <group name='girl_shirts003'>
              <skinnedMesh
                castShadow={true}
                frustumCulled={false}
                name='mesh_10'
                geometry={nodes.mesh_10.geometry}
                material={materials['Material.017']}
                skeleton={nodes.mesh_10.skeleton}
                position={[-0.38263446, -0.77616137, -0.12234595]}
                scale={0.00007213}
              />
            </group>
          )}
          {customOptions.top == 4 && (
            <group name='girl_onepiece001'>
              <skinnedMesh
                castShadow={true}
                frustumCulled={false}
                name='mesh_4'
                geometry={nodes.mesh_4.geometry}
                material={materials['Material.009']}
                skeleton={nodes.mesh_4.skeleton}
                position={[-0.38263446, -0.77616137, -0.12234595]}
                scale={0.00007213}
              />
            </group>
          )}
          {customOptions.shoes == 1 && (
            <group name='girl_shoes001'>
              <group position={[-0.38263446, -0.77616137, -0.12234595]} scale={0.00007213}>
                <skinnedMesh
                  castShadow={true}
                  frustumCulled={false}
                  name='mesh_11'
                  geometry={nodes.mesh_11.geometry}
                  material={materials['Material.019']}
                  skeleton={nodes.mesh_11.skeleton}
                />
                <skinnedMesh
                  castShadow={true}
                  frustumCulled={false}
                  name='mesh_11_1'
                  geometry={nodes.mesh_11_1.geometry}
                  material={materials['Material.020']}
                  skeleton={nodes.mesh_11_1.skeleton}
                />
                <skinnedMesh
                  castShadow={true}
                  frustumCulled={false}
                  name='mesh_11_2'
                  geometry={nodes.mesh_11_2.geometry}
                  material={materials['Material.021']}
                  skeleton={nodes.mesh_11_2.skeleton}
                />
              </group>
            </group>
          )}
          {customOptions.shoes == 2 && (
            <group name='girl_shoes002'>
              <group position={[-0.38263446, -0.77616137, -0.12234595]} scale={0.00007213}>
                <skinnedMesh
                  castShadow={true}
                  frustumCulled={false}
                  name='mesh_12'
                  geometry={nodes.mesh_12.geometry}
                  material={materials['Material.006']}
                  skeleton={nodes.mesh_12.skeleton}
                />
                <skinnedMesh
                  castShadow={true}
                  frustumCulled={false}
                  name='mesh_12_1'
                  geometry={nodes.mesh_12_1.geometry}
                  material={materials['Material.008']}
                  skeleton={nodes.mesh_12_1.skeleton}
                />
              </group>
            </group>
          )}
          {customOptions.shoes == 3 && (
            <group name='girl_shoes003'>
              <group position={[-0.38263446, -0.77616137, -0.12234595]} scale={0.00007213}>
                <skinnedMesh
                  name='mesh_13'
                  castShadow={true}
                  frustumCulled={false}
                  geometry={nodes.mesh_13.geometry}
                  material={materials['Material.038']}
                  skeleton={nodes.mesh_13.skeleton}
                />
                <skinnedMesh
                  name='mesh_13_1'
                  castShadow={true}
                  frustumCulled={false}
                  geometry={nodes.mesh_13_1.geometry}
                  material={materials['Material.039']}
                  skeleton={nodes.mesh_13_1.skeleton}
                />
                <skinnedMesh
                  name='mesh_13_2'
                  castShadow={true}
                  frustumCulled={false}
                  geometry={nodes.mesh_13_2.geometry}
                  material={materials['Material.040']}
                  skeleton={nodes.mesh_13_2.skeleton}
                />
                <skinnedMesh
                  name='mesh_13_3'
                  castShadow={true}
                  frustumCulled={false}
                  geometry={nodes.mesh_13_3.geometry}
                  material={materials['Material.041']}
                  skeleton={nodes.mesh_13_3.skeleton}
                />
                <skinnedMesh
                  name='mesh_13_4'
                  castShadow={true}
                  frustumCulled={false}
                  geometry={nodes.mesh_13_4.geometry}
                  material={materials['Material.042']}
                  skeleton={nodes.mesh_13_4.skeleton}
                />
              </group>
            </group>
          )}
          {customOptions.shoes > 3 && (
            <group name='girl_shoes003'>
              <group position={[-0.38263446, -0.77616137, -0.12234595]} scale={0.00007213}>
                <skinnedMesh
                  name='mesh_13'
                  castShadow={true}
                  frustumCulled={false}
                  geometry={nodes.mesh_13.geometry}
                  material={materials['Material.038']}
                  skeleton={nodes.mesh_13.skeleton}
                />
                <skinnedMesh
                  name='mesh_13_1'
                  castShadow={true}
                  frustumCulled={false}
                  geometry={nodes.mesh_13_1.geometry}
                  material={materials['Material.039']}
                  skeleton={nodes.mesh_13_1.skeleton}
                />
                <skinnedMesh
                  name='mesh_13_2'
                  castShadow={true}
                  frustumCulled={false}
                  geometry={nodes.mesh_13_2.geometry}
                  material={materials['Material.040']}
                  skeleton={nodes.mesh_13_2.skeleton}
                />
                <skinnedMesh
                  name='mesh_13_3'
                  castShadow={true}
                  frustumCulled={false}
                  geometry={nodes.mesh_13_3.geometry}
                  material={materials['Material.041']}
                  skeleton={nodes.mesh_13_3.skeleton}
                />
                <skinnedMesh
                  name='mesh_13_4'
                  castShadow={true}
                  frustumCulled={false}
                  geometry={nodes.mesh_13_4.geometry}
                  material={materials['Material.042']}
                  skeleton={nodes.mesh_13_4.skeleton}
                />
              </group>
            </group>
          )}
        </group>
      </group>
    </group>
  );
}
