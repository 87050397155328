import { memo, useState, useEffect, useRef } from 'react';
import { useAtomValue, useSetAtom, atom, useAtom } from 'jotai';
import Modal from 'react-modal';
import tunnel from 'tunnel-rat';
import { View, Html, useGLTF } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import ScrollModeLayout from 'pages/ScrollModeLayout/ScrollModeLayout';
import MultiPlayModeLayout from './multiplay-mode-layout';
import Joy from './joy';
import * as Colyseus from 'colyseus.js';

import { Space } from 'apis/index';

import Objects from 'react3fiber/r3f-space/objects';
import { Players as PlayersComponent } from 'react3fiber/r3f-space/players';

import { tokenAtom, userAtom, playModeAtom, messageListAtom, currentRoomAtom } from 'src/atoms';

import { Player, Players } from 'components/Multiplay/types';
import { useFrame } from '@react-three/fiber';

const PhraseGen = require('korean-random-words');
const phraseGen = new PhraseGen({
  delimiter: ' ',
});

const scripts = {
  KR: ['실시간채팅', '메시지를 입력하세요', '보내기'],
  EN: ['Real-time chat', 'Enter a message', 'Send'],
  JP: ['リアルタイムチャット', 'メッセージを入力してください', '送信'],
};

let t = tunnel();
Modal.setAppElement('#root');

export type SpaceDisplayStatus = 'hidden' | 'previous' | 'next' | 'current';
export type SpaceProps = {
  space: Space;

  customOptions?: {
    type: string;
    hair: number;
    face: number;
    top: number;
    bottom: number;
    shoes: number;
  };
  displayStatus: SpaceDisplayStatus;
  audioRef: React.RefObject<HTMLAudioElement>;
  client: Colyseus.Client;
};

// TODO: Fix everything... LOL
let dirAtom = atom<string[]>([]);
let isInteractiveAtom = atom(true);
console.log(isInteractiveAtom);
let SpaceComponent = memo(function SpaceComponent({
  space,
  customOptions,
  displayStatus,
  client,
}: SpaceProps) {
  let mode = useAtomValue(playModeAtom);
  let isInteractive = useAtomValue(isInteractiveAtom);
  let dir = useAtomValue(dirAtom);
  let token = useAtomValue(tokenAtom);
  let user = useAtomValue(userAtom);
  let [isNickNameModal, setIsNickNameModal] = useState(false);

  let divElRef = useRef<HTMLElement>(document.getElementById(`trackeddiv-${space.id}`)!);

  useEffect(() => {
    if (token == null) {
      let tmpName = phraseGen.generatePhrase().split(' ');
      tmpName[0] = tmpName[1].concat(' ' + tmpName[2]);

      setNonMemberNickName(tmpName[0]);
    }
  }, []);
  let [nonMemberNickName, setNonMemberNickName] = useState<string | null>(null);

  let userData = {
    nickname: user ? user.nickname : nonMemberNickName,
    username: user ? user.username : 'Unauthenticated',
    profile: user ? user.image : '',
    playtype: 0,
    ...customOptions,
  };

  // 여기서부터 colyseus
  const playersRef = useRef<Players>({});
  const [playerIds, setPlayerIds] = useState<string[]>([]); // 사람 입장/퇴장시 Rerendering용도

  let [room, setRoom] = useState<Colyseus.Room | null>(null);
  let sessionIdRef = useRef<string | null>(null);
  let updatedTimeRef = useRef<number>(Date.now());

  let setMessageList = useSetAtom(messageListAtom);
  let [currentRoom, setCurrentRoom] = useAtom(currentRoomAtom);
  //TODO : make room as cancelable custom hook.
  useEffect(() => {
    let isCancelled = false;
    if (displayStatus === 'current') {
      let room: Colyseus.Room | null = null;
      (async () => {
        room = await client
          .joinOrCreate('game', { spaceId: space.id, ...userData })
          .then((room) => {
            sessionIdRef.current = room.sessionId;
            //console.log('joined succesfully', room);
            return room;
          })
          .catch(() => {
            console.log('Room enter error!');
            return null;
          });
        if (!isCancelled) {
          setRoom(room);
        } else {
          if (room) {
            room.leave();
          }
        }
      })();
      return () => {
        isCancelled = true;
        if (room) {
          room.leave();
          setRoom(null);
        }
      };
    }
  }, [displayStatus]);

  useEffect(() => {
    if (displayStatus === 'current') {
      setCurrentRoom(room);
    }
  }, [room, displayStatus]);

  useEffect(() => {
    if (room === null) {
      return;
    }
    room.state.players.onAdd = function (player: Player, sessionId: string) {
      player.onChange = function () {
        playersRef.current[sessionId] = player;
        updatedTimeRef.current = Date.now();
      };
      playersRef.current[sessionId] = player;
      //INFO : 강제 형변환 이슈
      setPlayerIds(Object.keys((room as Colyseus.Room<any>).state.players));
    };
    room.state.players.onRemove = function (_player: Player, sessionId: string) {
      delete playersRef.current[sessionId];
      // INFO : 강제 형변환 이슈
      setPlayerIds(Object.keys((room as Colyseus.Room<any>).state.players));
    };

    room.onMessage('chat', (message) => {
      setMessageList((messageList) => [...messageList, message]);
    });
  }, [room]);

  useEffect(() => {
    if (mode == 'scroll') {
      setMessageList([]);
    }
  }, [mode, setMessageList]);

  //여기까지 colyseus
  const { camera } = useThree();
  useEffect(() => {
    if (mode == 'scroll') {
      camera.position.set(0, 0, 4);
      camera.lookAt(0, 0, 0);
      camera.scale.z = 1;
    }
  }, [mode]);
  return displayStatus !== 'hidden' ? (
    <View track={divElRef}>
      <Objects space={space} device={null} displayStatus={displayStatus} />

      {displayStatus == 'current' && (
        <PlayersComponent
          nonMemberName={userData.nickname}
          space={space}
          mode={mode}
          isInteractive={isInteractive}
          room={room}
          playersRef={playersRef}
        />
      )}
    </View>
  ) : null;
});

export default SpaceComponent;

export function SpaceLayoutComponent({
  space,
  displayStatus,
}: {
  space: Space;
  displayStatus: SpaceDisplayStatus;
}) {
  const [isLike, setIsLike] = useState(space.isLike);
  const mode = useAtomValue(playModeAtom);
  const user = useAtomValue(userAtom);
  const token = useAtomValue(tokenAtom);
  const setDir = useSetAtom(dirAtom);
  const setIsInteractive = useSetAtom(isInteractiveAtom);
  const mediaMatch = window.matchMedia('(min-width: 1024px)');

  return (
    <>
      {mode === 'multiplay' && mediaMatch.matches == false && displayStatus === 'current' ? (
        <Joy setDir={setDir} />
      ) : null}
      <t.Out />
      {displayStatus !== 'hidden' &&
        (mode === 'scroll' ? (
          <ScrollModeLayout space={space} />
        ) : (
          <MultiPlayModeLayout setIsInteractive={setIsInteractive} space={space} />
        ))}
    </>
  );
}
