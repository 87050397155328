import styled from 'styled-components';

import { BreakPoint, Color } from 'src/constants';

import checkIcon from 'static/icons/check.svg';
import arrowBottom from 'static/icons/arrow_bottom.svg';

export const PhoneInputContainer = styled.div`
  margin: 3rem 0 2.5rem 0;
  display: flex;
  width: 100%;
  flex-direction: column;

  ${BreakPoint.mobile} {
    margin: 3.25rem 0 2.5rem 0;
  }
`;

export const InputLabel = styled.label`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.015rem;
  margin-bottom: 0.25rem;
`;

export const InputArea = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

export const CountryCodeDropbox = styled.div.attrs({ className: 'clickable' })`
  display: flex;
  align-items: flex-end;
  width: 8rem;
  height: 3rem;
  margin-right: 0.25rem;
  padding: 1.125rem 0 1.125rem 0.5rem;
  border: 1px solid #eeeeee;
  border-radius: 0.0625rem;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${BreakPoint.mobile} {
    width: 22.66%;
    margin-right: 0.125rem;
    padding-left: 0.5rem;
  }
`;

export const CountryCodeDropboxHiddenText = styled.div`
  position: absolute;
  display: none;
  background-color: ${Color.whiteAlpha('0.8')};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 0.625rem;
  letter-spacing: -0.015rem;
`;

export const CountryCodeDropboxText = styled.div`
  position: relative;
  width: 5rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 0.625rem;
  letter-spacing: -0.015rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  span {
    font-size: 0.625rem;
    line-height: 0.625rem;
    letter-spacing: -0.015rem;
    margin-right: 0.25rem;
  }

  &:hover + ${CountryCodeDropboxHiddenText} {
    display: block;
  }
`;

export const ArrowBottomIcon = styled.img.attrs({ src: arrowBottom })`
  width: 1rem;
  height: 1rem;
  padding-top: 0.25rem;
`;

export const ValidationInputArea = styled.div`
  width: 14.125rem;

  ${BreakPoint.mobile} {
    width: calc(77.34% - 4.5rem);
  }
`;

const Button = styled.div.attrs({ className: 'clickable' })<{
  isActive: boolean;
}>`
  width: 100%;
  font-weight: 700;
  line-height: 1.375rem;
  letter-spacing: -0.025rem;
  text-align: center;
  padding: 0.875rem 0;
  border-radius: 0.25rem;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};

  &:hover {
    opacity: ${({ isActive }) => (isActive ? 0.8 : 0.5)};
  }
`;

export const CheckIcon = styled.img.attrs({ src: checkIcon })`
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.125rem;
`;

export const CertificateCodeButton = styled(Button)<{ isVerified: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.875rem;
  background-color: ${({ isVerified }) => (isVerified ? '#00B031' : '#f7f7f7')};
  border: 1px solid ${({ isVerified }) => (isVerified ? '#00B031' : '#eeeeee')};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.015rem;
  color: ${({ isVerified }) => (isVerified ? '#FFFFFF' : '#333333')};

  ${BreakPoint.mobile} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.5rem;
    min-height: 3.125rem;
    padding: 0.375rem 0.875rem;
    font-size: 0.75rem;
    line-height: 1.125rem;
    letter-spacing: -0.005rem;
  }
`;
