import { useState, useCallback, useRef, useEffect, useMemo, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import {
  User,
  UserActivated,
  Character,
  CharacterActivated,
  Home,
  HomeActivated,
  SearchIcon,
  SearchActivated,
  Posting,
} from 'components/Commons/icons';
import Navbar from 'components/navbar';

import { navbarTypeAtom, playModeAtom, tokenAtom, userAtom } from 'src/atoms';

import bottomNavbarHomeOnIcon from 'static/icons/bottom_navbar_home_on.svg';
import bottomNavbarHomeOffIcon from 'static/icons/bottom_navbar_home_off.svg';
import bottomNavbarSearchOnIcon from 'static/icons/bottom_navbar_search_on.svg';
import bottomNavbarSearchOffIcon from 'static/icons/bottom_navbar_search_off.svg';
import bottomNavbarMakeOnIcon from 'static/icons/bottom_navbar_make_on.svg';
import bottomNavbarMakeOffIcon from 'static/icons/bottom_navbar_make_off.svg';
import bottomNavbarCharacterOnIcon from 'static/icons/bottom_navbar_character_on.svg';
import bottomNavbarCharacterOffIcon from 'static/icons/bottom_navbar_character_off.svg';
import bottomNavbarProfileOnIcon from 'static/icons/bottom_navbar_profile_on.svg';
import bottomNavbarProfileOffIcon from 'static/icons/bottom_navbar_profile_off.svg';

const BottomNavbarHomeIcon = styled.img.attrs<{ isActive: boolean }>(({ isActive }) => ({
  src: isActive ? bottomNavbarHomeOnIcon : bottomNavbarHomeOffIcon,
}))<{ isActive: boolean }>`
  width: 1.375rem;
  height: 1.375rem;
  margin: 0.5rem;

  &:hover {
    cursor: pointer;
  }
`;

const BottomNavbarSearchIcon = styled.img.attrs<{ isActive: boolean }>(({ isActive }) => ({
  src: isActive ? bottomNavbarSearchOnIcon : bottomNavbarSearchOffIcon,
}))<{ isActive: boolean }>`
  width: 1.375rem;
  height: 1.375rem;
  margin: 0.5rem;

  &:hover {
    cursor: pointer;
  }
`;

const BottomNavbarMakeIcon = styled.img.attrs<{ isActive: boolean }>(({ isActive }) => ({
  src: isActive ? bottomNavbarMakeOnIcon : bottomNavbarMakeOffIcon,
}))<{ isActive: boolean }>`
  width: 1.375rem;
  height: 1.375rem;
  margin: 0.5rem;

  &:hover {
    cursor: pointer;
  }
`;

const BottomNavbarCharacterIcon = styled.img.attrs<{ isActive: boolean }>(({ isActive }) => ({
  src: isActive ? bottomNavbarCharacterOnIcon : bottomNavbarCharacterOffIcon,
}))<{ isActive: boolean }>`
  width: 1.375rem;
  height: 1.375rem;
  margin: 0.5rem;

  &:hover {
    cursor: pointer;
  }
`;

const BottomNavbarProfileIcon = styled.img.attrs<{ isActive: boolean }>(({ isActive }) => ({
  src: isActive ? bottomNavbarProfileOnIcon : bottomNavbarProfileOffIcon,
}))<{ isActive: boolean }>`
  width: 1.375rem;
  height: 1.375rem;
  margin: 0.5rem;

  &:hover {
    cursor: pointer;
  }
`;

//TODO: 레아아웃 + 네비게이션바 구조 개판이라 죄송합니다... 베타 끝나면 깔끔하게 리팩토링 할게요... -용민

export default function Layout({ children }: { children: React.ReactElement | React.ReactElement[] }) {
  const mediaMatch = useMediaQuery({ maxWidth: 1224 });
  let token = useAtomValue(tokenAtom);
  let user = useAtomValue(userAtom);
  let navbarType = useAtomValue(navbarTypeAtom);
  let navigate = useNavigate();
  let playMode = useAtomValue(playModeAtom);
  const location = useLocation();

  const onHomeIconClick = useCallback(() => {
    const currentPath = location.pathname;
    const scrollTargetElement = document.getElementById('scrollElement');

    if (scrollTargetElement && currentPath === '/') {
      try {
        scrollTargetElement.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } catch (error) {
        scrollTargetElement.scrollTo(0, 0);
      }
    } else {
      navigate('/');
    }
  }, [location]);

  // useEffect(() => {
  //   if (!window.location.href.includes('localhost')) {
  //     ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
  //   }
  //   setInitialized(true);
  // }, []);

  // useEffect(() => {
  //   if (initialized) {
  //     ReactGA.pageview(location.pathname + location.search);
  //   }
  // }, [initialized, location]);
  let logoURL = new URL('../../static/imgs/logo.png', import.meta.url);
  const bottomBar = useMemo(() => {
    if (navbarType === 'login') {
      // bottombar = (
      //   <nav className='flex bg-white items-center justify-center h-12 shrink-0 lg:hidden'>
      //     <div className='flex gap-3 items-center'>
      //       <div
      //         style={{
      //           backgroundImage: `url(${'"' + logoURL + '"'})`,
      //           backgroundSize: 'cover',
      //           width: '50px',
      //           height: '15px',
      //         }}></div>
      //       <div className='text-xs font-light pt-1'>
      //         Copyright <strong>Adler</strong> All Rights Reserved
      //       </div>
      //     </div>
      //   </nav>
      // );
      return <></>;
    } else if (navbarType === 'signup' || playMode === 'multiplay' || navbarType === 'password-reset') {
      return <div className='h-0'></div>;
    } else {
      return (
        <nav className='flex bg-white items-center px-7 justify-between h-12 shrink-0 lg:hidden'>
          <BottomNavbarHomeIcon isActive={navbarType === 'feed'} onClick={onHomeIconClick} />
          <BottomNavbarSearchIcon isActive={navbarType === 'search'} onClick={() => navigate('/search')} />
          <BottomNavbarMakeIcon isActive={navbarType === 'posting'} onClick={() => navigate('/new')} />
          <BottomNavbarCharacterIcon isActive={navbarType === 'character'} onClick={() => navigate('/avatar/')} />
          <BottomNavbarProfileIcon
            isActive={navbarType === 'settings'}
            onClick={() => {
              if (token && user) {
                navigate('/settings');
              } else {
                navigate('/login');
              }
            }}
          />
        </nav>
      );
    }
  }, [navbarType]);

  return (
    <div className='fixed inset-0 flex flex-col'>
      <header>
        <Navbar />
      </header>
      {children}
      {bottomBar}
    </div>
  );
}
