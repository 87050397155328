import { Vector3, Euler } from '@react-three/fiber';
import { Sky, useAnimations, Environment } from '@react-three/drei';
import { useMemo, useEffect } from 'react';
import * as THREE from 'three';
import { SkeletonUtils } from 'three-stdlib';

import { useCompressedGLTF } from 'hooks/hooks';

const url = new URL('static/models/uzoo3.min.glb', import.meta.url);
const url3 = new URL('static/models/z9jjin.min.glb', import.meta.url);
const url2 = new URL('static/imgs/crab_nebula.hdr', import.meta.url);

export default function SpaceTheme() {
  const { scene } = useCompressedGLTF(url.href);
  const clonedScene = useMemo(() => SkeletonUtils.clone(scene), [scene]);
  const disposeMesh: THREE.Mesh[] = [];
  const disposeTexture: THREE.Texture[] = [];

  clonedScene.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      const mesh = object as THREE.Mesh;
      const mat = mesh.material as THREE.Material;
      mesh.receiveShadow = true;

      mat.toneMapped = true;
      mat.roughness = 0;
      mat.metalness = 0.92;

      disposeMesh.push(mesh);
      if (mat.map !== null) {
        disposeTexture.push(mat.map);
      }
    }
  });
  const { scene: scene2, animations } = useCompressedGLTF(url3.href);
  const clonedScene2 = useMemo(() => SkeletonUtils.clone(scene2), [scene2]);
  const { ref: ref, actions: actions, names: names } = useAnimations(animations);
  clonedScene2.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      const mesh = object as THREE.Mesh;
      const mat = mesh.material as THREE.Material;

      mat.toneMapped = true;

      disposeMesh.push(mesh);
      if (mat.map !== null) {
        disposeTexture.push(mat.map);
      }
    }
  });
  useEffect(() => {
    for (let i = 0; i < names.length; i++) {
      actions[names[i]]?.reset().fadeIn(0.5).play();
    }
  }, [actions, names]);
  useEffect(() => {
    return () => {
      for (let i = 0; i < disposeMesh.length; i++) {
        if (disposeMesh[i]) disposeMesh[i].geometry.dispose();
      }
      for (let i = 0; i < disposeTexture.length; i++) {
        if (disposeTexture[i]) disposeTexture[i].dispose();
      }
    };
  }, []);
  return (
    <group position={[0, -1.8, 0.9]} scale={1} rotation={[0, 0, 0]}>
      <Environment background={true} files={url2.href} />

      <primitive object={clonedScene} />
      <group position={[0, 10, 0]}>
        <primitive ref={ref} object={clonedScene2} />
      </group>
    </group>
  );
}
