import { useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from './icons';
export function TextInput({
  title,
  type,
  name,
  required = false,
  placeholder = '',
  error = false,
  value = '',
  onText,
  onChangeCustomHandler,
}: {
  title: string;
  type: string;
  name: string;
  required?: boolean;
  value?: string;
  onText?: (x: any) => any;
  placeholder?: string;
  error?: boolean;
  onChangeCustomHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  let [isTypePassword, setIsTypePassword] = useState<boolean>(false);
  let [isPasswordOpen, setIsPasswordOpen] = useState<boolean>(false);
  if (type === 'password') {
    type = isPasswordOpen ? 'text' : 'password';
  }
  useEffect(() => {
    if (type === 'password') {
      setIsTypePassword(true);
    }
  }, [isTypePassword, setIsTypePassword, type]);
  return (
    <label
      className={
        'flex flex-col gap-1.5 rounded-md border-solid border px-4 py-2 ' +
        (isTypePassword && ' relative') +
        (error ? ' border-error' : ' border-zinc-300')
      }>
      <span className='text-xs'>
        <div className='flex'>
          {/* <div className='text-zinc-400'>{title}</div>
          {required && <div className='text-error px-1'>*</div>} */}
        </div>
      </span>
      <div className='flex gap-2'>
        <input
          className='form-input placeholder:text-zinc-300 py-1 text-base w-full'
          type={type}
          name={name}
          value={onText ? value : undefined}
          onChange={
            onText
              ? (e) => {
                  onChangeCustomHandler && onChangeCustomHandler(e);
                  onText(e.target.value);
                }
              : undefined
          }
          placeholder={placeholder}
        />
        {isTypePassword ? (
          <button
            type='button'
            className='visibility-off'
            onClick={() => setIsPasswordOpen((isPasswordOpen) => !isPasswordOpen)}>
            {isPasswordOpen ? <Visibility /> : <VisibilityOff />}
          </button>
        ) : null}
      </div>
    </label>
  );
}
