import useModal from 'hooks/useModal';
import { useCallback, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import {
  CloseIcon,
  DropboxContentArea,
  DropboxContentChecked,
  DropboxContentContainer,
  DropboxContentIcon,
  DropboxContentSubtitle,
  DropboxContentTextHidden,
  DropboxContentTitle,
  DropboxHeader,
  DropboxModalContainer,
  DropboxTitle,
  SearchArea,
  SearchIcon,
  SearchInput,
} from './styles';
import { DropboxContentData, DropboxModalProps } from './types';

function DropboxModal({
  dropboxTitle,
  searchInputPlaceholder,
  dropboxContentDatas,
  dropboxValue,
  searchKeyTypes,
  setDropboxValue,
}: DropboxModalProps): JSX.Element {
  const { closeModal } = useModal();
  const isMobile = useMediaQuery({ maxWidth: 1224 });
  const [searchedDropboxContentDatas, setSearchedDropboxContentDatas] =
    useState<DropboxContentData[]>(dropboxContentDatas);

  const onCloseClick = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const onDropboxContentClick = useCallback(
    (dataKey: any) => {
      if (dataKey !== dropboxValue) {
        setDropboxValue(dataKey);
        closeModal();
      }
    },
    [dropboxValue, setDropboxValue, closeModal]
  );

  const onSearchInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputSearchKey = event.target.value;

      setSearchedDropboxContentDatas(
        dropboxContentDatas.filter((dropboxContentData) => {
          const lowerDropboxContentTitle = dropboxContentData.title?.toLowerCase();
          const lowerDropboxContentSubtitle = dropboxContentData.subTitle?.toLowerCase();
          const lowerInputSearchKey = inputSearchKey.toLowerCase();

          if (searchKeyTypes.length === 2) {
            return (
              !!lowerDropboxContentTitle?.includes(lowerInputSearchKey) ||
              !!lowerDropboxContentSubtitle?.toLowerCase().includes(lowerInputSearchKey)
            );
          } else if (searchKeyTypes.length === 1) {
            if (searchKeyTypes[0] === 'title') {
              return !!lowerDropboxContentTitle?.includes(lowerInputSearchKey);
            } else {
              return !!lowerDropboxContentSubtitle?.includes(lowerInputSearchKey);
            }
          } else {
            return false;
          }
        })
      );
    },
    [dropboxContentDatas, searchKeyTypes]
  );

  return (
    <DropboxModalContainer>
      <DropboxHeader>
        {dropboxTitle && <DropboxTitle>{dropboxTitle}</DropboxTitle>}
        <CloseIcon isMobile={isMobile} onClick={onCloseClick} />
      </DropboxHeader>
      <SearchArea>
        <SearchInput placeholder={searchInputPlaceholder} onChange={onSearchInputChange} />
        <SearchIcon isMobile={isMobile} />
      </SearchArea>
      <DropboxContentArea>
        {searchedDropboxContentDatas.map(
          (searchedDropboxContentData, searchedDropboxContentDataIndex) => (
            <DropboxContentContainer
              key={searchedDropboxContentDataIndex}
              onClick={() => onDropboxContentClick(searchedDropboxContentData.dataKey)}>
              <DropboxContentTitle>
                {searchedDropboxContentData.title}
                <DropboxContentTextHidden>
                  {searchedDropboxContentData.title}
                </DropboxContentTextHidden>
              </DropboxContentTitle>
              <DropboxContentSubtitle>
                {searchedDropboxContentData.subTitle}
                <DropboxContentTextHidden>
                  {searchedDropboxContentData.subTitle}
                </DropboxContentTextHidden>
              </DropboxContentSubtitle>
              <DropboxContentIcon icon={searchedDropboxContentData.icon} />
              <DropboxContentChecked
                isChecked={dropboxValue === searchedDropboxContentData.dataKey}
                isMobile={isMobile}
              />
            </DropboxContentContainer>
          )
        )}
      </DropboxContentArea>
    </DropboxModalContainer>
  );
}

export default DropboxModal;
