import { Dispatch, useEffect, useState } from 'react';
import { SetStateAction, useAtomValue, useSetAtom } from 'jotai';
import { apiErrorCodeToMessage } from 'utils/errorUtils';
import { Camera, CheckMainColor } from 'components/Commons/icons';
import AutosizeTextarea from 'components/AutosizeTextarea';
import { handleOnInput, updateAccount, uploadFile, APIError, APIIssues } from 'apis/index';
import { ErrorCode } from 'src/types';
import { languageModeAtom, tokenAtom, userAtom } from 'src/atoms';
import { InputData, OnError } from 'components/ValidationInput/types';
import useModal from 'hooks/useModal';
const scripts = {
  KR: [
    '프로필 설정',
    '아이디와 닉네임은 필수입력정보입니다.',
    '저장되었습니다',
    '닉네임',
    '아이디',
    '이미 존재하는 아이디 이거나 조건에 맞지 않습니다.(6-20자, 영문 소문자, 숫자, 중복되지 않은 언더바(_))',
    '홈페이지/블로그/페이스북/트위터등의 URL을 입력하세요',
    '유효한 URL을 입력해 주세요.',
    '소개',
    '간략한 자기소개를 입력하세요',
    '필수입력정보입니다.',
  ],
  EN: [
    'Profile Setting',
    'You must fill in ID and nickname.',
    'saved',
    'nickname',
    'ID',
    'Your ID already exists or does not meet conditions (6-20 characters, lowercase letters, numbers, non-overlapping underbars (_))',
    'Enter the URL of the homepage/blog/Facebook/Twitter, etc',
    'Please enter a valid URL',
    'Introduction',
    'Please type a brief self-introduction.',
    'Required history information',
  ],
  JP: [
    'プロフィールの設定',
    'IDとニックネームは必須入力情報です。',
    '保存されました',
    'ニックネーム',
    'ID',
    'すでに存在するIDや条件に合いません。（6-20文字、英文小文字、数字、重複していないアンダーバー（_）',
    'ホームページ/ブログ/フェイスブック/ツイッターなどのURLを入力してください',
    '有効なURLを入力してください',
    '紹介',
    '簡単な自己紹介を入力してください',
    '必須入力情報です。',
  ],
};

// const SaveButton = styled.div`
//   width: 100%;
//   padding: 0.25rem 0;
//   background-color: #db1f72;
//   color: ${Color.white};
//   font-weight: 400;
//   line-height: 1.25rem;
//   letter-spacing: -0.02rem;
//   text-align: center;

//   &:hover {
//     cursor: pointer;
//   }
// `;

export default function MyPage({ setMode }: { setMode: Dispatch<SetStateAction<string>> }) {
  let user = useAtomValue(userAtom);
  let refreshUser = useSetAtom(userAtom);
  let token = useAtomValue(tokenAtom);
  let languageMode = useAtomValue(languageModeAtom);
  let [nickname, setNickname] = useState<string | undefined>(user?.nickname);
  let [username, setUsername] = useState<string | undefined>(user?.username);
  let [userURL, setUserURL] = useState<string>(user ? user.url : '');
  let [bio, setBio] = useState<string>(user ? user.bio : '');
  let [image, setImage] = useState<File | null>(null);
  let [errors, setErrors] = useState<APIIssues>({});
  const mediaMatch = window.matchMedia('(min-width: 1024px)');
  const [matches, setMatches] = useState<boolean>(mediaMatch.matches);
  const [usernameInputData, setUsernameInputData] = useState<InputData>();
  const { openAlertModal } = useModal();

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  async function getProfileImageID() {
    if (!token || !image) {
      return '';
    } else {
      let id = await uploadFile(token, image);
      return id.id;
    }
  }
  return (
    <>
      <div className='flex justify-center h-11 shrink-0 lg:hidden'>
        <div className='grow basis-0 justify-end'>
          <button className='flex-initial p-2.5' onClick={() => setMode('default')}>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M16 19.5L8 12L16 4.5' stroke='black' strokeLinecap='round' />
            </svg>
          </button>
        </div>
        <div className='flex items-center text-xl font-normal'>{scripts[languageMode][0]}</div>
        <div className='flex grow basis-0 justify-end'>
          <button
            className='p-2.5'
            onClick={async (e) => {
              if (username === '' || nickname === '') {
                e.preventDefault();
                openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][1] }]);
              }
              if (user) {
                e.preventDefault();
                let img = await getProfileImageID();
                let id = username;
                let nick = nickname;
                let b = bio;
                let u = userURL;
                let account = null;
                if (token && id && nick) {
                  try {
                    account = await updateAccount(token, id, nick, b, u, img);
                    refreshUser();
                    setErrors({});
                    openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][2] }]);
                  } catch (error) {
                    if (error instanceof APIError) {
                      setErrors(error.issues);
                    } else {
                      // handleError(error);
                    }
                  }
                  // if (account.result === true) {
                  // } else {
                  //   setErrors(Object.keys(account.content));
                  // }
                } else {
                  account = 'no token or username'; //이럴 경우 어떻게 처리해야되지?(용민)
                }
              }
            }}>
            <CheckMainColor />
          </button>
        </div>
      </div>
      <div className='px-12 lg:grow lg:flex w-full'>
        <form className='max-w-sm flex flex-col gap-2 m-auto h-full pt-4 lg:grow lg:m-0 lg:max-w-none'>
          <div className='flex flex-col items-center w-full mb-8 lg:flex-row lg:gap-8'>
            <div className={'relative w-30 ' + (matches ? 'setting-label' : '')}>
              <div className='w-24 h-24 border-gray-300 border rounded-full'>
                <img
                  className='w-24 h-24 rounded-full object-cover aspect-square'
                  src={image === null ? user?.image : URL.createObjectURL(image)}
                />
              </div>
              <label className='w-6 h-6 rounded-full border border-gray-400 flex items-center justify-center bg-white absolute bottom-0 right-1'>
                <Camera width='20' height='20' />
                <input
                  type='file'
                  accept='image/*'
                  className='hidden'
                  onChange={(e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      setImage(e.target.files[0]);
                    }
                  }}
                />
              </label>
            </div>
            <div className={'hidden relative w-36 lg:flex lg:flex-col lg:px-3 ' + (matches ? 'setting-input' : '')}>
              ID
              <label className='w-36 h-6 flex flex-row bg-white'>
                <div className='text-xs text-rose-600'>프로필사진 수정</div>
                <input
                  type='file'
                  accept='image/*'
                  className='hidden'
                  onChange={(e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      setImage(e.target.files[0]);
                    }
                  }}
                />
              </label>
            </div>
          </div>
          <label className='flex flex-col gap-1 lg:flex-row lg:gap-8'>
            <div className={'flex justify-between ' + (matches ? 'setting-label' : '')}>
              <div className='flex p-0 -my-1'>
                <span className='text-sm'>{scripts[languageMode][3]}</span>
                <div className='text-error pl-0.5'>*</div>
              </div>
              {/* <span className='text-xs text-gray-400'>0/30 bytes</span> */}
            </div>
            <input
              type='text'
              name='nickname'
              className={
                'rounded border-solid border px-3 py-1' +
                (errors.nickname ? ' border-error' : ' border-zinc-300') +
                (matches ? ' setting-input' : '')
              }
              defaultValue={nickname}
              onChange={(e) => {
                handleOnInput(e, 30);
                setNickname(e.target.value);
              }}
            />
            {errors.nickname && <div className='text-xs text-error'>{errors.nickname}</div>}
          </label>
          <div className='lg:flex lg:gap-8 lg:mb-3'>
            <div className='setting-label'></div>
            <div className='flex justify-end setting-input'>
              <span className='text-xs text-gray-400'>{nickname ? nickname.length : 0} / 30 bytes</span>
            </div>
          </div>
          <label className='flex flex-col gap-1 lg:flex-row lg:gap-8 lg:mb-0'>
            <div className={'flex justify-between ' + (matches ? 'setting-label' : '')}>
              <div className='flex -my-1'>
                <span className='text-sm'>{scripts[languageMode][4]}</span>
                <div className='text-error pl-0.5'>*</div>
              </div>
              {/* <span className='text-xs text-gray-400'>0/20 자</span> */}
            </div>
            <input
              type='text'
              name='username'
              className={
                'rounded border-solid border px-3 py-1' +
                (errors.username ? ' border-error' : ' border-zinc-300') +
                (matches ? ' setting-input' : '')
              }
              value={username}
              onChange={(e) => {
                setUsernameInputData(null);
                handleOnInput(e, 20);
                setUsername(e.target.value);
              }}
            />
            {errors.username && <div className='text-xs text-error'>{errors.username}</div>}
          </label>
          <div className='lg:flex lg:gap-8 lg:mb-3'>
            <div className='setting-label'></div>
            <div className='flex justify-end setting-input'>
              <span className='text-xs text-red-400'>{usernameInputData?.errorMessage}</span>
              <span className='text-xs text-gray-400'>{username ? username.length : 0} / 20 자</span>
            </div>
          </div>
          <label className='flex flex-col gap-1 mb-6 lg:flex-row lg:gap-8'>
            <span className={'text-sm ' + (matches ? 'setting-label' : '')}>URL</span>
            <input
              type='text'
              name='url'
              placeholder={scripts[languageMode][6]}
              className={
                'rounded border-solid border px-3 py-1 placeholder:text-sm' +
                (errors.url ? ' border-error' : ' border-zinc-300') +
                (matches ? ' setting-input' : '')
              }
              value={userURL}
              onChange={(e) => {
                setUserURL(e.target.value);
              }}
            />
            {errors.url && <div className='text-xs text-error'>{errors.url}</div>}
          </label>
          <label className='flex flex-col gap-1 lg:flex-row lg:gap-8'>
            <div className={'flex justify-between ' + (matches ? 'setting-label' : '')}>
              <span className='text-sm'>{scripts[languageMode][8]}</span>
              {/* <span className='text-xs text-gray-400'>0/150 bytes</span> */}
            </div>
            <AutosizeTextarea
              value={bio}
              setValue={setBio}
              placeholder={scripts[languageMode][9]}
              className={
                'rounded border-solid border px-3 py-1 placeholder:text-sm' +
                (errors.bio ? ' border-error' : ' border-zinc-300') +
                (matches ? ' setting-input' : '')
              }
              onChange={(event) => handleOnInput(event, 150)}
            />
            {errors.bio && <div className='text-xs text-error'>{errors.bio}</div>}
          </label>
          <div className='lg:flex lg:gap-8 lg:mb-3'>
            <div className='setting-label'></div>
            <div className='flex justify-between setting-input'>
              <span className='text-xs text-gray-400'>
                <span className='text-error pr-0.5'>*</span>
                {scripts[languageMode][10]}
              </span>
              <span className='text-xs text-gray-400'>{bio.length} / 150 bytes</span>
            </div>
          </div>
          <div className='lg:flex lg:gap-8'>
            <div className={matches ? ' setting-label' : ''}></div>
            <div className={matches ? ' setting-input mt-7 lg:mt-0' : 'mt-7 lg:mt-0'}>
              <button
                className='rounded bg-pink-600 text-white text-xs py-2 px-2 lg:w-28 w-full '
                onClick={async (e) => {
                  if (username === '' || nickname === '') {
                    e.preventDefault();
                    openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][1] }]);
                  }
                  if (user) {
                    e.preventDefault();
                    let img = await getProfileImageID();
                    let id = username;
                    let nick = nickname;
                    let b = bio;
                    let u = userURL;

                    if (token && id && nick) {
                      try {
                        await updateAccount(token, id, nick, b, u, img);
                        refreshUser();
                        setErrors({});
                        openAlertModal([{ type: 'NORMAL', message: scripts[languageMode][2] }]);
                      } catch (error) {
                        if (error instanceof APIError) {
                          console.log(usernameInputData);
                          switch (error.issues.field) {
                            case 'username':
                              return setUsernameInputData((prev) => ({
                                ...prev,
                                value: prev?.value ? prev.value : '',
                                isError: true,
                                errorMessage: apiErrorCodeToMessage((error as APIError).issues.code as ErrorCode, id)[
                                  languageMode
                                ][0],
                              }));

                            default:
                              return;
                          }
                        }
                      }
                    } else {
                      account = 'no token or username'; //이럴 경우 어떻게 처리해야되지?(용민)
                    }
                  }
                }}>
                저장
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
