import { UseLanguageModalResources } from './types';

import { Scripts } from 'src/types';

const scripts: Scripts = {
  KR: [
    '더 많은 포스트를 보시려면',
    '아들러에 로그인 하세요.',
    '로그인 하기',
    '계정이 없으신가요?',
    '가입하기',
  ],
  EN: ['For more posts,', 'Log in to Adler.', 'Log in', 'Do not you have an account?', 'Sign up'],
  JP: [
    'もっと多くのポストを見るには',
    'アドラーにログインしてください。',
    'ログインする',
    'アカウントをお持ちではありませんか？',
    '加入する',
  ],
};

const useLanguageModalResources = (): UseLanguageModalResources => {
  return {
    scripts,
  };
};

export default useLanguageModalResources;
