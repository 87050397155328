import React from 'react';

import { LoadingContainer } from './styles';

import loadingIcon from 'static/icons/loading.svg';

function Loading(): JSX.Element {
  return (
    <LoadingContainer>
      <img src={loadingIcon} />
    </LoadingContainer>
  );
}

export default Loading;
