import * as React from 'react';
import * as THREE from 'three';

type Props = {
  children?: React.ReactNode;
  stencilRef: number;
  position?: [number, number, number];
  rotation?: [number, number, number];
  scale: number;
};

export default function PlaneMask({ children, stencilRef, position = [0, 0, 0], rotation = [0, 0, 0], scale }: Props) {
  return (
    <>
      {children}
      <mesh position={position} rotation={rotation} scale={scale} renderOrder={1}>
        <planeGeometry args={[2, 2]} />
        <meshBasicMaterial
          toneMapped={true}
          colorWrite={false}
          depthWrite={false}
          stencilWrite
          stencilRef={stencilRef}
          stencilFunc={THREE.AlwaysStencilFunc}
          stencilFail={THREE.ReplaceStencilOp}
          stencilZFail={THREE.ReplaceStencilOp}
          stencilZPass={THREE.ReplaceStencilOp}
        />
      </mesh>
    </>
  );
}
