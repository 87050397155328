import React, { useCallback, useMemo } from 'react';
import { useAtomValue } from 'jotai';

import {
  ArrowBottomIcon,
  CertificateCodeButton,
  CheckIcon,
  CountryCodeDropbox,
  CountryCodeDropboxHiddenText,
  CountryCodeDropboxText,
  InputArea,
  InputLabel,
  PhoneInputContainer,
  ValidationInputArea,
} from './styles';
import { PhoneInputProps } from './type';
import usePhoneInputResources from './resources';

import { OnError } from 'components/ValidationInput/types';
import ValidationInput from 'components/ValidationInput';
import DropboxModal from 'components/CustomModal/components/DropboxModal';
import { DropboxContentData } from 'components/CustomModal/components/DropboxModal/types';

import { apiErrorCodeToMessage } from 'utils/errorUtils';
import { APIError, getVerificationCode } from 'apis/index';
import useModal from 'hooks/useModal';

import { languageModeAtom } from 'src/atoms';
import { ErrorCode } from 'src/types';

function PhoneInput({
  label,
  placeholder,
  certificateCodeButtonClickCount,
  phoneNumberInputData,
  phoneNumberCountryCode,
  phoneToken,
  setPhoneNumberInputData,
  setPhoneNumberCountryCode,
  setCertificateKey,
  setCertificateCodeButtonClickCount,
  timerRun,
  timerReload,
}: PhoneInputProps): JSX.Element {
  const languageMode = useAtomValue(languageModeAtom);
  const { countryCodeSelectOptions } = usePhoneInputResources();
  const { scripts } = usePhoneInputResources();
  const { openModal } = useModal();

  const countryCode = useMemo(
    () =>
      countryCodeSelectOptions.filter((item) => item.numberCode === phoneNumberCountryCode)[0]
        .countryCode,
    [phoneNumberCountryCode]
  );

  const isCertificateCodeButtonActive = useMemo(
    () => phoneNumberInputData?.isError === false && phoneNumberInputData?.value !== '',
    [phoneNumberInputData]
  );

  const certivicateCodeButtonText = useMemo(
    () =>
      certificateCodeButtonClickCount === 0
        ? scripts[languageMode][3]
        : phoneToken
        ? scripts[languageMode][5]
        : scripts[languageMode][4],
    [certificateCodeButtonClickCount, phoneToken, languageMode]
  );

  const onCountryCodeDropboxClick = useCallback(() => {
    openModal({
      component: (
        <DropboxModal
          dropboxTitle='국가코드 입력'
          searchInputPlaceholder='국가명, 국가코드 입력'
          searchKeyTypes={['title', 'subTitle']}
          dropboxValue={phoneNumberCountryCode}
          setDropboxValue={setPhoneNumberCountryCode}
          dropboxContentDatas={countryCodeSelectOptions.map<DropboxContentData>(
            (countryCodeSelectOption) => ({
              dataKey: countryCodeSelectOption.numberCode,
              title: `${countryCodeSelectOption.countryCode}+${countryCodeSelectOption.numberCode}`,
              subTitle: countryCodeSelectOption.name,
            })
          )}
        />
      ),
      options: {
        isOverlayed: true,
        isCloseOnESCKeyDown: true,
        isCloseOnOverlayClick: true,
        align: { alignItems: 'center', justifyContent: 'center' },
      },
    });
  }, [openModal, phoneNumberCountryCode, setPhoneNumberCountryCode]);

  const onPhoneNumberError: OnError = useCallback((data) => {
    const phoneRegex = /^[0-9]+$/;

    if (data === '') return scripts[languageMode][0];

    if (!phoneRegex.test(data)) return scripts[languageMode][1];

    return;
  }, []);

  const onCertificateCodeButtonClick = useCallback(async () => {
    if (!isCertificateCodeButtonActive) return;

    if (phoneNumberCountryCode && phoneNumberInputData?.value) {
      try {
        const result = await getVerificationCode(
          '+' + phoneNumberCountryCode + phoneNumberInputData.value,
          1
        );

        if (result.result) {
          setCertificateKey(result.key);
          certificateCodeButtonClickCount === 0 ? timerRun() : timerReload();
          setCertificateCodeButtonClickCount((prev) => prev + 1);
        }
      } catch (error) {
        if (error instanceof APIError) {
          setPhoneNumberInputData((prev) => ({
            ...prev,
            value: prev?.value ? prev.value : undefined,
            isError: true,
            errorMessage: apiErrorCodeToMessage(
              (error as APIError).issues.code as ErrorCode,
              scripts[languageMode][2]
            )[languageMode][0],
          }));
        }
      }
    }
  }, [
    phoneNumberCountryCode,
    certificateCodeButtonClickCount,
    phoneNumberInputData,
    languageMode,
    isCertificateCodeButtonActive,
  ]);

  return (
    <PhoneInputContainer>
      <InputLabel>{label}</InputLabel>
      <InputArea>
        <CountryCodeDropbox onClick={onCountryCodeDropboxClick}>
          <CountryCodeDropboxText>
            <span>{countryCode}</span>
            {`+${phoneNumberCountryCode}`}
            <CountryCodeDropboxHiddenText>{`${countryCode} +${phoneNumberCountryCode}`}</CountryCodeDropboxHiddenText>
          </CountryCodeDropboxText>
          <ArrowBottomIcon />
        </CountryCodeDropbox>
        <ValidationInputArea>
          <ValidationInput
            type='text'
            placeholder={placeholder}
            value={phoneNumberInputData}
            setValue={setPhoneNumberInputData}
            onError={onPhoneNumberError}
          />
        </ValidationInputArea>
        <CertificateCodeButton
          isActive={isCertificateCodeButtonActive}
          isVerified={!!phoneToken}
          onClick={onCertificateCodeButtonClick}>
          {!!phoneToken && <CheckIcon />}
          {certivicateCodeButtonText}
        </CertificateCodeButton>
      </InputArea>
    </PhoneInputContainer>
  );
}

export default PhoneInput;
