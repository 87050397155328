import { Physics, RigidBody } from '@react-three/rapier';
import { useGLTF } from '@react-three/drei';
import { useCompressedGLTF } from 'hooks/hooks';

const url = new URL('../../../static/colliders/grass.gltf', import.meta.url);
const url2 = new URL('../../../static/colliders/grass_cylin.gltf', import.meta.url);

export default function FlowerThemaPrimitive({}: {}) {
  const { nodes } = useCompressedGLTF(url.href);
  const { nodes: nodes2 } = useCompressedGLTF(url2.href);

  return (
    <RigidBody position={[0, -1.2, 1]} scale={0.8} type='fixed' colliders='trimesh'>
      <mesh geometry={nodes.Torch_1.geometry}>
        <meshStandardMaterial visible={false} />
      </mesh>
      <mesh geometry={nodes.Torch_2.geometry}>
        <meshStandardMaterial visible={false} />
      </mesh>
      <mesh geometry={nodes2.Cylinder.geometry} position={[0, 4.29, 0]}>
        <meshStandardMaterial visible={false} />
      </mesh>
    </RigidBody>
  );
}
