import styled from 'styled-components';

import { Margin } from 'src/types';

import { BreakPoint, Color } from 'src/constants';

import warning from 'static/icons/warning.svg';
import inputValueVisible from 'static/icons/input_value_visible.svg';
import inputValueInvisible from 'static/icons/input_value_invisible.svg';

export const ValidationInputContainer = styled.div<{ margin?: Margin }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: ${({ margin }) => margin};
`;

export const Label = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.015rem;
  margin-bottom: 0.375rem;

  span {
    color: ${Color.error};
  }
`;

export const InputArea = styled.div`
  position: relative;
  width: 100%;
  height: 3.125rem;
`;

export const Input = styled.input<{ isError?: boolean }>`
  position: absolute;
  width: 100%;
  padding: 0.875rem 0 0.875rem 1rem;
  border: 1px solid ${({ isError }) => (isError ? '#fb3939' : '#eeeeee')};
  border-radius: 0.25rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: -0.02rem;

  &:focus {
    outline: none;
  }
`;

export const InputEyeIcon = styled.img.attrs<{ isVisible: boolean }>(({ isVisible }) => ({
  src: isVisible ? inputValueVisible : inputValueInvisible,
}))<{ isVisible: boolean }>`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  width: 1.5rem;
  height: 1.5rem;
`;

export const WarningIcon = styled.img.attrs({ src: warning })`
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.25rem;

  ${BreakPoint.mobile} {
    margin-right: 0.125rem;
  }
`;

export const Detail = styled.div`
  display: flex;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 400;
  font-size: 0.625rem;
  line-height: 0.875rem;
  color: #4d4d4d;
`;

export const Error = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 0.875rem;
  color: #fb3939;
`;
