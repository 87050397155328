import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

interface Time {
  hours: number;
  minutes: number;
  seconds: number;
}

export interface FormatTime {
  hours: string;
  minutes: string;
  seconds: string;
}

type FormatType = 'h:m:s' | 'h:m:ss' | 'h:mm:s' | 'h:mm:ss';

type UseTimerParams = Time & { formatType?: FormatType };

interface UseTimer {
  time: FormatTime;
  isFinished: boolean;
  run: () => void;
  stop: () => void;
  reload: () => void;
}

/*  
  INFO

  타이머로서의 기본 기능만 하는 함수, promotion관련 타이머는 usePromotionTimer를 참고하자
  run : 실행
  stop : 일시중지
  reload : 초기화 후 실행
*/
function useTimer({ hours, minutes, seconds, formatType }: UseTimerParams): UseTimer {
  const [time, setTime] = useState<Time>({ hours, minutes, seconds });
  const timerRef = useRef<NodeJS.Timer>();

  const isFinished = useMemo(
    () => !!timerRef.current && time.hours === 0 && time.minutes === 0 && time.seconds === 0,
    [time, timerRef.current]
  );

  /*
    INFO

    SetInterval 내부의 함수를 외부 함수로 빼내지 않은 이유는, setInterval내의 useCallback으로 감싸진 함수는
    초기 함수의 status를 기억하기 때문에 time이 초기값에서 update가 이뤄지지 않기에 내부에 남겨두었다.
  */
  const run = useCallback(() => {
    const MAXIMUM_MINUTES = 59;
    const MAXIMUM_SECONDS = 59;

    timerRef.current = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime.seconds === 0) {
          if (prevTime.minutes === 0) {
            if (prevTime.hours !== 0) {
              return {
                ...prevTime,
                hours: hours - 1,
                minutes: MAXIMUM_MINUTES,
                seconds: MAXIMUM_SECONDS,
              };
            }
          } else {
            return {
              ...prevTime,
              minutes: prevTime.minutes - 1,
              seconds: MAXIMUM_SECONDS,
            };
          }
        } else {
          return { ...prevTime, seconds: prevTime.seconds - 1 };
        }

        return prevTime;
      });
    }, 1000);
  }, []);

  const stop = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = undefined;
    }
  }, [timerRef.current]);

  const reload = useCallback(() => {
    if (timerRef.current) {
      stop();

      setTime({ hours, minutes, seconds });

      run();
    }
  }, [timerRef.current, hours, minutes, seconds, run, stop]);

  const timeFormatter = useCallback(() => {
    let formattedTime: FormatTime = {
      hours: '0',
      minutes: '0',
      seconds: '0',
    };

    switch (formatType) {
      case 'h:m:s':
        formattedTime = {
          hours: `${time.hours}`,
          minutes: `${time.minutes}`,
          seconds: `${time.seconds}`,
        };

        break;
      case 'h:m:ss':
        formattedTime = {
          hours: `${time.hours}`,
          minutes: `${time.minutes}`,
          seconds: time.seconds >= 10 ? `${time.seconds}` : `0${time.seconds}`,
        };

        break;
      case 'h:mm:s':
        formattedTime = {
          hours: `${time.hours}`,
          minutes: time.minutes >= 10 ? `${time.minutes}` : `0${time.minutes}`,
          seconds: `${time.seconds}`,
        };

        break;
      case 'h:mm:ss':
        formattedTime = {
          hours: `${time.hours}`,
          minutes: time.minutes >= 10 ? `${time.minutes}` : `0${time.minutes}`,
          seconds: time.seconds >= 10 ? `${time.seconds}` : `0${time.seconds}`,
        };

        break;
      default:
        formattedTime = {
          hours: `${time.hours}`,
          minutes: `${time.minutes}`,
          seconds: `${time.seconds}`,
        };
    }

    return formattedTime;
  }, [time]);

  useEffect(() => {
    return () => stop();
  }, []);

  return {
    time: timeFormatter(),
    isFinished,
    run,
    stop,
    reload,
  };
}

export default useTimer;
