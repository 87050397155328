import { usePlane, useBox } from '@react-three/cannon';

function Plane(props) {
  let [ref] = usePlane(() => ({ rotation: props.rotation, position: props.position }));
  return <mesh ref={ref}></mesh>;
}

function Cube(props) {
  let [ref] = useBox(() => ({ type: 'Static', position: props.position, args: props.args, rotation: props.rotation }));
  return <mesh ref={ref}></mesh>;
}

export default function ProfilePrimitiveform({ item }: { item: number }) {
  return (
    <>
      <Plane
        position={[180, -1.5, -18]}
        args={[360, 36]}
        color='green'
        visible={false}
        rotation={[-Math.PI / 2, 0, 0]}
      />
      <Cube
        position={[-15, 18, -18]}
        args={[36, 36, 0.1]}
        color='green'
        visible={false}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <Cube
        position={[63 + Math.ceil(item / 3) * 36.2, 18, -18]}
        args={[36, 36, 0.1]}
        color='green'
        rotation={[0, Math.PI / 2, 0]}
      />
      <Cube position={[18, 18, -33]} args={[360, 36, 0.1]} color='green' visible={false} rotation={[0, 0, 0]} />
      <Cube position={[18, 18, 5]} args={[360, 36, 0.1]} color='green' visible={false} rotation={[0, 0, 0]} />
    </>
  );
}
