import { Vector3, Euler } from '@react-three/fiber';
import { useAnimations } from '@react-three/drei';
import { useMemo, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { SkeletonUtils } from 'three-stdlib';
import GifLoader from 'three-gif-loader';

import { useCompressedGLTF } from 'hooks/hooks';

const gif = new URL('static/imgs/VI1.gif', import.meta.url);
const gif2 = new URL('static/imgs/VI2.gif', import.meta.url);
const gif3 = new URL('static/imgs/VI3.gif', import.meta.url);
const gif4 = new URL('static/imgs/vi0.gif', import.meta.url);
const url3 = new URL('static/models/MinJunHongB2.min.glb', import.meta.url);

export default function CyberTheme({ position }: { position: Vector3 }) {
  const { scene: scene3, animations } = useCompressedGLTF(url3.href);
  const clonedScene3 = useMemo(() => SkeletonUtils.clone(scene3), [scene3]);
  const { ref, actions, names } = useAnimations(animations);
  const disposeMesh: THREE.Mesh[] = [];
  const disposeTexture: THREE.Texture[] = [];

  clonedScene3.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      const mesh = object as THREE.Mesh;

      mesh.material = mesh.material as THREE.Material;
      const mat = mesh.material as THREE.Material;
      disposeMesh.push(mesh);
      if (mat.map !== null) {
        disposeTexture.push(mat.map);
      }
    }
  });

  useEffect(() => {
    for (let i = 0; i < names.length; i++) {
      actions[names[i]]?.reset().fadeIn(0.5).play();
    }

    return () => {
      actions[names[0]]?.fadeOut(0.5);
    };
  }, [actions, names]);
  const loader = new GifLoader();

  const ref2 = useRef<THREE.MeshBasicMaterial>(null);
  useEffect(() => {
    ref2.current.map = loader.load(
      gif.href,

      function (reader) {},

      function (xhr) {
        console.log();
      },

      function () {
        console.error('An error happened.');
      }
    );
    ref2.current.map.dispose();
  }, []);

  const ref3 = useRef<THREE.MeshBasicMaterial>(null);
  useEffect(() => {
    ref3.current.map = loader.load(
      gif2.href,

      function (reader) {},

      function (xhr) {
        console.log();
      },

      function () {
        console.error('An error happened.');
      }
    );
    ref3.current.map.dispose();
  }, []);
  const ref4 = useRef<THREE.MeshBasicMaterial>(null);
  useEffect(() => {
    ref4.current.map = loader.load(
      gif3.href,

      function (reader) {},

      function (xhr) {
        console.log();
      },

      function () {
        console.error('An error happened.');
      }
    );
    ref4.current.map.dispose();
  }, []);
  const ref5 = useRef<THREE.MeshBasicMaterial>(null);
  useEffect(() => {
    ref5.current.map = loader.load(
      gif4.href,

      function (reader) {},

      function (xhr) {
        console.log();
      },

      function () {
        console.error('An error happened.');
      }
    );
  }, []);
  useEffect(() => {
    return () => {
      for (let i = 0; i < disposeMesh.length; i++) {
        if (disposeMesh[i]) disposeMesh[i].geometry.dispose();
      }
      for (let i = 0; i < disposeTexture.length; i++) {
        if (disposeTexture[i]) disposeTexture[i].dispose();
      }
    };
  }, []);
  return (
    <group position={[20, -2, 2]} rotation={[0, Math.PI, 0]} scale={0.7}>
      <group
        ref={ref}
        position={[position[0] - 30, position[1] - 60, position[2]]}
        scale={1}
        rotation={[0, 0, 0]}>
        <primitive object={clonedScene3} />
      </group>
      <mesh position={[4.2, 12, -37]} rotation={[0, 0, 0]}>
        <planeGeometry args={[7.5, 10.8]} />
        <meshBasicMaterial ref={ref2} toneMapped={false} />
      </mesh>
      <mesh position={[52.3, 7.3, -28.3]} rotation={[0, -Math.PI / 7, 0]}>
        <planeGeometry args={[7.4, 10.1]} />
        <meshBasicMaterial ref={ref3} toneMapped={false} />
      </mesh>
      <mesh position={[45.8, 21.8, 16.1]} rotation={[0, -Math.PI / 1.445, 0]}>
        <planeGeometry args={[11.5, 17.5]} />
        <meshBasicMaterial ref={ref4} toneMapped={false} />
      </mesh>
      <mesh position={[23.3, 29, 101]} rotation={[0, Math.PI, 0]}>
        <planeGeometry args={[65.4, 86]} />
        <meshBasicMaterial ref={ref5} toneMapped={false} />
      </mesh>
    </group>
  );
}
