import styled from 'styled-components';

import { Color } from 'src/constants';

export const ConfirmModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 25rem;
  background-color: ${Color.white};
  border-radius: 0.25rem;

  .clickable:hover {
    cursor: pointer;
  }
`;

export const Title = styled.div`
  width: 100%;
  margin-top: 1.5rem;
  padding: 0 1.5rem;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: -0.02rem;
  text-align: center;
  color: ${Color.grayScale1};
`;

export const Content = styled.div`
  width: 100%;
  margin: 0.5rem 0 1.25rem;
  padding: 0 1.5rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: -0.015rem;
  text-align: center;
  color: ${Color.grayScale3};
`;

const Button = styled.div.attrs({ className: 'clickable' })`
  width: 100%;
  padding: 0.875rem 1.5rem;
  text-align: center;
  box-sizing: border-box;
  border-top: 1px solid ${Color.grayScale8};
`;

export const FirstButton = styled(Button)`
  color: ${Color.error};
`;

export const SecondButton = styled(Button)`
  color: ${Color.grayScale1};
`;
