import styled from 'styled-components';

import { BreakPoint } from 'src/constants';

const defaultImageObject = new URL('static/imgs/default-profile.png', import.meta.url);

export const FollowUserBoxContinaer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const UserIcon = styled.div<{ imageURL?: string }>`
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  margin: 0 0.5rem 0 1rem;
  background-image: url(${({ imageURL }) => (imageURL ? imageURL : defaultImageObject.href)});
  background-repeat: none;
  background-size: cover;

  ${BreakPoint.mobile} {
    margin-top: 0.125rem;
  }
`;

export const UserInformation = styled.div`
  display: flex;
  flex-direction: column;
  width: 62%;
  margin: 0.875rem 0;
`;

export const UserNickname = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.015rem;
`;

export const UserId = styled.div`
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 0.8125rem;
  letter-spacing: 0.005rem;
`;

export const UserManageButton = styled.div`
  padding: 0.2188rem 1rem;
  background-color: #eeeeee;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  border-radius: 0.3125rem;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  ${BreakPoint.mobile} {
    width: 14.5%;
    padding: 0.2188rem 0;
  }
`;
