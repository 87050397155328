import { useCallback, useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { CertificateCodeVerifyButton, CertificateCodeVerifyContainer, InputArea, TimerContainer } from './styles';
import { CertificateCodeVerifyInput } from './types';
import useCertificateCodeVerifyInputResources from './resources';

import ValidationInput from 'components/ValidationInput';
import useModal from 'hooks/useModal';
import { APIError, verify } from 'apis/index';
import { apiErrorCodeToMessage } from 'utils/errorUtils';

import { languageModeAtom } from 'src/atoms';
import { ErrorCode } from 'src/types';

function CertificateCodeVerifyInput({
  placeholder,
  time,
  verifyCodeNumberInputData,
  isTimerFinished,
  phoneNumberCountryCode,
  phoneNumberInputData,
  certificateKey,
  setVerifyCodeNumberInputData,
  setPhoneToken,
}: CertificateCodeVerifyInput): JSX.Element {
  const languageMode = useAtomValue(languageModeAtom);
  const { scripts } = useCertificateCodeVerifyInputResources();
  const { openAlertModal } = useModal();

  const isCertificateCodeVerifyButtonActive = useMemo(
    () => !!verifyCodeNumberInputData?.value && verifyCodeNumberInputData.value.length === 6 && !isTimerFinished,
    [verifyCodeNumberInputData, isTimerFinished]
  );

  const timerText = useMemo(
    () => (isTimerFinished ? scripts[languageMode][4] : `${time.minutes}:${time.seconds} ${scripts[languageMode][3]}`),
    [isTimerFinished, time, languageMode]
  );

  const onVerifyCodeNumberError = useCallback((data: string) => {
    if (data === '') return scripts[languageMode][0];

    if (data.length !== 6) return scripts[languageMode][1];

    return;
  }, []);

  const onCertificateCodeVerifyButtonClick = useCallback(async () => {
    if (verifyCodeNumberInputData?.isError === true || verifyCodeNumberInputData?.value?.length !== 6) return;

    if (phoneNumberInputData?.value && verifyCodeNumberInputData?.value && certificateKey) {
      try {
        const response = await verify(
          '+' + phoneNumberCountryCode + phoneNumberInputData.value,
          verifyCodeNumberInputData.value,
          certificateKey,
          1
        );

        if (response.content) {
          // TODO 인증 성공
          setPhoneToken(response.content.phoneToken);
        } else {
          openAlertModal([{ type: 'NORMAL', message: '서버 에러 발생' }]);
        }
      } catch (error) {
        if (error instanceof APIError) {
          setVerifyCodeNumberInputData((prev) => ({
            ...prev,
            value: prev?.value ? prev.value : '',
            isError: true,
            errorMessage: apiErrorCodeToMessage((error as APIError).issues.code as ErrorCode, scripts[languageMode][5])[
              languageMode
            ][0],
          }));
        }
      }
    }
  }, [phoneNumberInputData, verifyCodeNumberInputData, certificateKey, languageMode]);

  return (
    <CertificateCodeVerifyContainer>
      <InputArea>
        <ValidationInput
          placeholder={placeholder}
          value={verifyCodeNumberInputData}
          setValue={setVerifyCodeNumberInputData}
          onError={onVerifyCodeNumberError}
        />
        <CertificateCodeVerifyButton
          isActive={isCertificateCodeVerifyButtonActive}
          onClick={onCertificateCodeVerifyButtonClick}>
          {scripts[languageMode][2]}
        </CertificateCodeVerifyButton>
      </InputArea>
      <TimerContainer>{timerText}</TimerContainer>
    </CertificateCodeVerifyContainer>
  );
}

export default CertificateCodeVerifyInput;
