import {
  ContentText,
  DivisionBorder,
  IntroductionArea,
  IntroductionContainer,
  NameText,
  TextArea,
} from './styles';

function Introduction(): JSX.Element {
  return (
    <IntroductionContainer>
      <IntroductionArea>
        <DivisionBorder />
        <TextArea>
          <NameText>회사명</NameText>
          <ContentText>
            <span>Adler Inc.</span> 아들러 주식회사
          </ContentText>
        </TextArea>
        <TextArea>
          <NameText>주소</NameText>
          <ContentText>
            서울특별시 관악구 남부순환로224길 25 2층 2F, 25, Nambusunhwan-ro 224-gil, Gwanak-gu,
            Seoul, Republic of Korea
          </ContentText>
        </TextArea>
        <TextArea>
          <NameText>대표이사</NameText>
          <ContentText>한세진 | SEJIN HAN</ContentText>
        </TextArea>
        <TextArea>
          <NameText>사업자등록번호</NameText>
          <ContentText>772-88-02469</ContentText>
        </TextArea>
        <TextArea>
          <NameText>통신판매번호</NameText>
          <ContentText>2021-서울관악-2418</ContentText>
        </TextArea>
        <TextArea>
          <NameText>회사 전화번호</NameText>
          <ContentText>02-874-6182</ContentText>
        </TextArea>
        <TextArea>
          <NameText>Contact</NameText>
          <ContentText>cs@adler3d.com</ContentText>
        </TextArea>
      </IntroductionArea>
    </IntroductionContainer>
  );
}

export default Introduction;
