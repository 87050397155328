import { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';

import Character from './player/character';
import CharacterFemale from './player/character-female';

export default function CustomAvatar({
  customOptionsFemail,
  customOptions,
  gender,
}: {
  customOptionsFemail: {
    type: string;
    hair: number;
    face: number;
    top: number;
    bottom: number;
    shoes: number;
  };
  customOptions: {
    type: string;
    hair: number;
    face: number;
    top: number;
    bottom: number;
    shoes: number;
  };
  gender: string;
}) {
  return (
    <Canvas camera={{ rotation: [0, 0, 0], position: [0, 1, 4], fov: 50 }}>
      <ambientLight intensity={0.7} />
      <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, 10]} />
      <Suspense fallback={null}>
        <group position={[0, -1, 0]} rotation={[0, Math.PI, 0]}>
          {gender == 'M' && <Character customOptions={customOptions} isMoved={false} />}
          {gender == 'F' && <CharacterFemale customOptions={customOptionsFemail} isMoved={false} />}
        </group>
      </Suspense>
      <OrbitControls enablePan={true} enableZoom={false} enableDamping={true} />
    </Canvas>
  );
}
