import { UseCertificateCodeVerifyInputResources } from './types';

import { Scripts } from 'src/types';

const scripts: Scripts = {
  KR: [
    '인증번호를 입력해 주세요.',
    '인증번호는 6자리의 숫자입니다.',
    '확인',
    '뒤에 만료됩니다.',
    '만료되었습니다. 재전송 후 진행해 주세요.',
    '인증번호',
  ],
  EN: [
    'Please enter the verification code.',
    'The verification code is a 6-digit number.',
    'Confirm',
    'will expire later.',
    'Expired. Please retransmit before proceeding.',
    'Verification code',
  ],
  JP: [
    '認証番号を入力してください。',
    '認証番号は6桁の数字です。',
    '確認',
    '後ろに期限切れになります。',
    '期限切れです。再送信後に進めてください。',
    '認証番号',
  ],
};

function useCertificateCodeVerifyInputResources(): UseCertificateCodeVerifyInputResources {
  return { scripts };
}

export default useCertificateCodeVerifyInputResources;
