import * as THREE from 'three';

type Props = {
  stencilRef: number;
  color: string;
};

export default function FrameBox({ stencilRef, color }: Props) {
  return (
    <>
      <mesh receiveShadow position={[0, 0, -0.99]} renderOrder={2}>
        <boxGeometry args={[2, 2, 0.1]} />
        <meshStandardMaterial
          toneMapped={true}
          color={color}
          stencilWrite
          stencilRef={stencilRef}
          stencilFunc={THREE.EqualStencilFunc}
          stencilFail={THREE.KeepStencilOp}
          stencilZFail={THREE.KeepStencilOp}
          stencilZPass={THREE.ReplaceStencilOp}
        />
      </mesh>
      <mesh receiveShadow position={[-1, 0, 0]} rotation={[0, Math.PI / 2, 0]} renderOrder={2}>
        <boxGeometry args={[2, 2, 0.1]} />
        <meshStandardMaterial
          toneMapped={false}
          color={color}
          stencilWrite
          stencilRef={stencilRef}
          stencilFunc={THREE.EqualStencilFunc}
          stencilFail={THREE.KeepStencilOp}
          stencilZFail={THREE.KeepStencilOp}
          stencilZPass={THREE.ReplaceStencilOp}
        />
      </mesh>
      <mesh receiveShadow position={[1, 0, 0]} rotation={[0, Math.PI / 2, 0]} renderOrder={2}>
        <boxGeometry args={[2, 2, 0.1]} />
        <meshStandardMaterial
          toneMapped={true}
          color={color}
          stencilWrite
          stencilRef={stencilRef}
          stencilFunc={THREE.EqualStencilFunc}
          stencilFail={THREE.KeepStencilOp}
          stencilZFail={THREE.KeepStencilOp}
          stencilZPass={THREE.ReplaceStencilOp}
        />
      </mesh>
      <mesh receiveShadow position={[0, 1, 0]} rotation={[Math.PI / 2, 0, 0]} renderOrder={2}>
        <boxGeometry args={[2, 2, 0.1]} />
        <meshStandardMaterial
          toneMapped={true}
          color={color}
          stencilWrite
          stencilRef={stencilRef}
          stencilFunc={THREE.EqualStencilFunc}
          stencilFail={THREE.KeepStencilOp}
          stencilZFail={THREE.KeepStencilOp}
          stencilZPass={THREE.ReplaceStencilOp}
        />
      </mesh>
      <mesh receiveShadow position={[0, -1, 0]} rotation={[Math.PI / 2, 0, 0]} renderOrder={2}>
        <boxGeometry args={[2, 2, 0.1]} />
        <meshStandardMaterial
          toneMapped={true}
          color={color}
          stencilWrite
          stencilRef={stencilRef}
          stencilFunc={THREE.EqualStencilFunc}
          stencilFail={THREE.KeepStencilOp}
          stencilZFail={THREE.KeepStencilOp}
          stencilZPass={THREE.ReplaceStencilOp}
        />
      </mesh>
    </>
  );
}
