import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useAtomValue, useAtom } from 'jotai';

import ProfileSpace2D from './2D/profile-space2d';
import ProfileLayout from './profile-layout';

import { Bell, BgmOnOff, Gear } from 'components/Commons/icons';
import ProfileSpace from 'react3fiber/profile-space/profile-space';
import useModal from 'hooks/useModal';
import {
  User,
  getUser,
  followUser,
  unfollowUser,
  isFollow,
  getFollowers,
  getFollowings,
  Space,
  getUserSpaces,
  getCharacter,
} from 'apis/index';

import { bgmModeAtom, navbarTypeAtom, playModeAtom, tokenAtom, userAtom } from 'src/atoms';

export default function User() {
  let token = useAtomValue(tokenAtom);
  let [navbarType, setNavbarType] = useAtom(navbarTypeAtom);
  useEffect(() => {
    setNavbarType('profile');
  });
  let [playMode, setPlayMode] = useAtom(playModeAtom);
  let [bgmMode, setBgmMode] = useAtom(bgmModeAtom);
  const [currentUser] = useAtom(userAtom);
  const [isAlarmOpen, setIsAlarmOpen] = useState<boolean>(false);
  let { username } = useParams() as { username: string };
  let [user, setUser] = useState<User | null>(null);
  let [is_follow, setIs_follow] = useState<boolean>(false);
  let [follower_num, setFollower_num] = useState<number>(0);
  let [following_num, setFollowing_num] = useState<number>(0);
  let [spaces, setSpaces] = useState<Space[]>([]);
  let [is3D, setIs3D] = useState<boolean>(true);
  let navigate = useNavigate();
  const { openAlertModal } = useModal();
  const [customOptions, setCustomOptions] = useState<{
    type: string;
    hair: number;
    face: number;
    top: number;
    bottom: number;
    shoes: number;
  }>({
    type: 'M',
    hair: 1,
    face: 1,
    top: 1,
    bottom: 1,
    shoes: 1,
  });
  function onClick(e: React.MouseEvent<HTMLButtonElement>) {
    if (token) {
      (async () => {
        let status = 200;
        if (is_follow) {
          status = await unfollowUser(token, username);
        } else {
          status = await followUser(token, username);
        }
        if (status == 200) {
          setIs_follow(!is_follow);
        }
      })();
    } else {
      navigate('/login');
    }
  }

  useEffect(() => {
    (async () => {
      if (username) {
        try {
          let user = await getUser(username);
          setUser(user);
          if (token) {
            let init_follow = await isFollow(token, username, user.username);
            setIs_follow(init_follow);
          }
        } catch {
          console.log('으익');
          navigate('/');
        }
      }
    })();
  }, [token, username, navigate]);

  useEffect(() => {
    (async () => {
      try {
        if (token && user) {
          let character_init = await getCharacter(token, user.username);
          setCustomOptions(character_init);
        }
      } catch (error) {
        openAlertModal([
          { type: 'NORMAL', message: 'This website is temporarily unavailable please try again later.' },
        ]);
      }
    })();
  }, [token, user]);

  useEffect(() => {
    let isCancelled = false;
    if (username && token) {
      (async () => {
        try {
          let user = await getUser(username);
          if (isCancelled) {
            return;
          }
          setUser(user);
        } catch (error) {
          openAlertModal([{ type: 'NORMAL', message: '계정이 존재하지 않습니다.' }]);
          return;
        }
        let follower_result = await getFollowers(`/users/${username}/followers/`, token);
        setFollower_num(follower_result.total_length);
        let following_result = await getFollowings(`/users/${username}/followings/`);
        setFollowing_num(following_result.total_length);
      })();
    }

    return () => {
      isCancelled = true;
    };
  }, [username, is_follow, navigate]);

  useEffect(() => {
    if (username) {
      (async () => {
        try {
          let spaces_ = await getUserSpaces(username);
          setSpaces(spaces_.spaces);
        } catch (error) {
          openAlertModal([
            { type: 'NORMAL', message: 'This website is temporarily unavailable please try again later.' },
          ]);
        }
      })();
    }
  }, [username]);

  return (
    user &&
    (is3D ? (
      <div className='h-full flex flex-col'>
        {playMode === 'scroll' && (
          <>
            <div className='flex justify-center h-11 lg:hidden'>
              <Link to='/' className='flex-initial flex justify-center items-center'>
                <button className='p-2.5 font-light'>{user.username}</button>
              </Link>
              <div className='flex-auto justify-end' />
              <Link to='/' className='p-2 flex justify-center items-center'>
                <button
                  onClick={() => {
                    setBgmMode(!bgmMode);
                  }}>
                  <BgmOnOff sound={bgmMode} />
                </button>
              </Link>
              <div className='p-0.3 flex justify-center items-center mr-1'>
                {token ? (
                  currentUser?.username === user.username ? (
                    <Link to='/settings' className='p-2.5 flex justify-center items-center mr-1.5'>
                      <button>
                        <Gear />
                      </button>
                    </Link>
                  ) : (
                    <Link to='/alarm'>
                      <button className='p-2 flex justify-center items-center'>
                        <Bell colored={isAlarmOpen} />
                      </button>
                    </Link>
                  )
                ) : null}
              </div>
            </div>
            <div className='relative grow lg:w-full lg:m-auto'>
              <ProfileSpace
                customOptions={customOptions}
                currentUser={user!} // user를 user Effect에서 불러오고 있어서 있는지 없는지 체킹을 언제 해야될지 모르겟넹 - 용민
                userId={user?.id}
                userEmail={user?.email}
                username={user?.username}
                follower_num={follower_num}
                following_num={following_num}
                spaceMode={playMode}
                setSpaceMode={setPlayMode}></ProfileSpace>
              <ProfileLayout userId={user.username} token={token} setIs3D={setIs3D} />
            </div>
          </>
        )}
        {playMode === 'multiplay' && (
          <>
            <div className='flex absolute items-center top-0 w-full z-20 lg:hidden'>
              <div className='flex-initial flex justify-center items-center'>
                <button className='flex-initial p-2.5' onClick={() => setPlayMode('scroll')}>
                  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M16 19.5L8 12L16 4.5' stroke='black' strokeLinecap='round' />
                  </svg>
                </button>
              </div>
              <div className='flex-auto justify-end' />
              {/* <div className='my-2 mx-4 bg-zinc-400 px-1.5 py-0.5 rounded-lg'>
                <span className='float-left inline-block items-center'>
                  <Eye />
                </span>
                <span className='float-right text-xs inline-block items-center py-1 pl-1 text-white'>990K명</span>
              </div> */}
            </div>
            <div className='relative grow lg:w-[940] lg:m-auto'>
              <div className='absolute z-50 justify-center items-center hidden lg:flex'>
                <button className='flex-initial p-2.5' onClick={() => setPlayMode('scroll')}>
                  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M16 19.5L8 12L16 4.5' stroke='black' strokeLinecap='round' />
                  </svg>
                </button>
              </div>
              <ProfileSpace
                customOptions={customOptions}
                currentUser={user!} // user를 user Effect에서 불러오고 있어서 있는지 없는지 체킹을 언제 해야될지 모르겟넹 - 용민
                userId={user?.id}
                userEmail={user?.email}
                username={user?.username}
                follower_num={follower_num}
                following_num={following_num}
                spaceMode={playMode}
                setSpaceMode={setPlayMode}></ProfileSpace>
            </div>
          </>
        )}
      </div>
    ) : (
      <>
        <div className='flex justify-center h-11'>
          <button className='flex-initial p-2.5' onClick={() => setIs3D(true)}>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M16 19.5L8 12L16 4.5' stroke='black' strokeLinecap='round' />
            </svg>
          </button>
          <div className='flex-auto justify-end' />
          <Link to='/' className='p-2 flex justify-center items-center'>
            <button
              onClick={() => {
                setBgmMode(!bgmMode);
              }}>
              <BgmOnOff sound={bgmMode} />
            </button>
          </Link>
          <Link to='/settings' className='p-2.5 flex justify-center items-center mr-1.5'>
            <button>
              <Gear />
            </button>
          </Link>
        </div>
        <ProfileSpace2D username={user?.username} />
      </>
    ))
  );
}
