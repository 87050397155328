import {
  SvgModalButton,
  TextModal,
  HeartIcon,
  Modals,
  UnionIcon,
  ModalContainer,
  UnionTextModal,
  ShareIcon,
} from './ModalsStyle';
import { useState, useMemo, useCallback } from 'react';
import { ReplyModals } from './ReplyModal';

import { Space, likeSpace, unlikeSpace } from 'apis/index';
import { useAtomValue } from 'jotai';
import { tokenAtom, userAtom } from 'src/atoms';
import { useNavigate } from 'react-router-dom';
import { ReplySet } from 'apis/types';
import useModal from 'src/hooks/useModal';
import Alert from 'components/Commons/alert';
import { InfoModalFrame } from 'pages/Home/modals';
import URLModal from 'components/CustomModal/components/URLModal';

export default function ModalComponent({ space }: { space: Space }) {
  let token = useAtomValue(tokenAtom);
  let user = useAtomValue(userAtom);
  let [isLike, setIsLike] = useState(space.isLike);
  const [replys, setReplys] = useState<ReplySet | null>(null);
  const { openModal, closeModal } = useModal();
  const [alertMessages, setAlertMessages] = useState<string[] | null>(null);
  const navigate = useNavigate();

  const replyCount = useMemo(() => {
    if (replys && replys.count !== 0) return replys.count;

    return space.replyNum;
  }, [replys, space]);

  const onSvgModalClick = useCallback(async () => {
    if (token) {
      try {
        if (token && user) {
          setIsLike((prev) => !prev);
          if (isLike == true) {
            space.likeNum = space.likeNum - 1;
          } else {
            space.likeNum = space.likeNum + 1;
          }
          isLike === true ? await unlikeSpace(token, space.id) : await likeSpace(token, space.id);
        }
      } catch (error) {
        alert(error);
      }
    } else {
      alert('보내기');
      navigate('/login');
    }
  }, [isLike, navigate, space, token, user]);

  const onReplyClick = useCallback(() => {
    openModal({
      component: <ReplyModals space={space} />,
      options: { isCloseOnESCKeyDown: false, isOverlayed: true, isCloseOnOverlayClick: true },
    });
  }, [openModal, space]);

  const onShareIconClick = useCallback(() => {
    openModal({
      component: <URLModal space={space} closeModal={closeModal} />,
      options: { isCloseOnESCKeyDown: true, isCloseOnOverlayClick: true, isOverlayed: true },
    });
  }, [space]);

  return (
    <Modals>
      <ModalContainer>
        <SvgModalButton onClick={onSvgModalClick}>{<HeartIcon isLike={isLike} />}</SvgModalButton>
        <TextModal>{space.likeNum}</TextModal>
      </ModalContainer>
      <ModalContainer>
        <UnionIcon
          onClick={() => {
            onReplyClick();
          }}
        />
        <UnionTextModal>{replyCount}</UnionTextModal>
      </ModalContainer>
      <ModalContainer>
        <ShareIcon onClick={onShareIconClick} />
        {alertMessages && <Alert text={alertMessages} />}
      </ModalContainer>
      <ModalContainer>
        <InfoModalFrame space={space} token={token} isWhite={true} />
      </ModalContainer>
    </Modals>
  );
}
