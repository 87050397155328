import React, { Suspense, useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Route, Routes, Outlet, useLocation } from 'react-router-dom';
import { useAtom, useSetAtom, useAtomValue } from 'jotai';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { getSpaces, getLastSpaces, APIError, APIIssues, Space } from './apis';
import { tokenAtom, userAtom, playModeAtom, languageModeAtom } from './atoms';
import channelService from './channel-service';
import Layout from './components/layout';
import { Feed, Feeds } from './pages/Home/spaces';
import Login from './pages/Login';
import SpaceForm from './pages/New/space-form';
import User from './pages/ProfileSpace/user';
import Signup from './pages/Signup';
import Certificate from './pages/Certificate';
import Settings from './pages/Settings/settings';
import Follow from './pages/Follow';
import Search from './pages/Search/search';
import Avatar from './pages/Avatar/avatar';
import Alarm from './pages/Alarm/alarm';
import ItemPage from './pages/Home/2D/item-page';
import PasswordReset from './pages/PasswordReset';

import AlphaValidate from './components/alpha-validate';

import { REACT_APP_GOOGLE_TAG_MANAGER_ID } from './apis';
import LouvreSpaces from './pages/FAF/louvre-spaces';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Introduction from './pages/Introduction';
import ScrollToTop from './components/ScrollToTop';
import VrSpaces from './react3fiber/VrSpaces';
import CustomModal from './components/CustomModal';
import { useInfiniteQuery } from '@tanstack/react-query';
import TagManager from 'react-gtm-module';
import getEnvPath from './utils/environmentUtils';

import loadingIcon from 'static/icons/loading.svg';

import('dotenv').then((dotenv) => dotenv.config({ path: getEnvPath() }));

const scripts = {
  KR: [
    '일시적인 오류가 발생했습니다.',
    '오류코드',
    '서비스 이용에 불편을 드려 죄송합니다.',
    '아래 방법중 하나로 문의 주시면',
    '빠른 해결을 도와드리겠습니다.',
    '우측 하단에 있는 채널톡',
    "카카오톡 'Adler 인플루언서'",
    'cs@adler.cx',
    '문의가 끝나면 임시 방편으로 로그아웃 후',
    '다시 이용 부탁드립니다.',
    '로그아웃',
  ],
  EN: [
    'Temporary error has occurred',
    'Error Code',
    'Sorry for the inconvenience of using the service',
    'Please contact us in one of the following ways',
    'and we will help you resolve it quickly.',
    'ChannelTalk at the bottom right',
    "KakaoTalk 'Adler 인플루언서'",
    'cs@adler.cx',
    'After the inquiry, please log out as a',
    'temporary measure and use it again.',
    'Logout',
  ],
  JP: [
    '一時的なエラーが発生しました。',
    'エラーコード',
    'サービスのご利用にご不便をおかけして申し訳ございません。',
    '下記の方法のうち一つにお問い合わせいただければ',
    '迅速な解決をお手伝いいたします。',
    '右下にあるチャンネルトーク',
    "KakaoTalk 'Adler 인플루언서'",
    'cs@adler.cx',
    'お問い合わせが終わりましたら、',
    'その場しのぎでログアウト後、再度ご利用ください。',
    'ログアウト',
  ],
};

const tagManagerArgs = {
  gtmId: REACT_APP_GOOGLE_TAG_MANAGER_ID,
};
TagManager.initialize(tagManagerArgs);

let queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

function GenericErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  let languageMode = useAtomValue(languageModeAtom);
  channelService.boot({
    pluginKey: '2d7dd802-484a-413a-adb6-9f2b6e9c8dc7',
  });
  return (
    <div className='error-body'>
      <div className='error-form'>
        <div style={{ textAlign: 'center', fontSize: '40px', marginBottom: '10px' }}>😿</div>
        <h1 style={{ fontSize: '20px', paddingBottom: '10px' }}>{scripts[languageMode][0]}</h1>
        <details style={{ paddingLeft: '10px', color: 'rgb(136, 134, 134)' }}>
          <summary>
            {scripts[languageMode][1]} {error.name}
          </summary>
          <code className='codename'>
            <pre>
              {error.message}
              {error.stack}
            </pre>
          </code>
        </details>
        <div className='error-message'>
          {scripts[languageMode][2]}
          <br />
          <br />
          {scripts[languageMode][3]}
          <br /> {scripts[languageMode][4]}
          <br />
          <br />
          <ul className='list-disc' style={{ marginLeft: '2rem' }}>
            <li>{scripts[languageMode][5]}</li>
            <li>{scripts[languageMode][6]}</li>
            <li>{scripts[languageMode][7]}</li>
          </ul>
          <br />
          {scripts[languageMode][8]}
          <br />
          {scripts[languageMode][9]}
        </div>
        <div className='w-full text-center'>
          <button
            onClick={() => {
              resetErrorBoundary();
            }}
            className='error-btn'>
            {scripts[languageMode][10]}
          </button>
        </div>
      </div>
      {/* <h1>Sorry, an unexpected error occurred: {error.name}</h1>
    <code style={{fontSize:"14px"}}>{error.stack}</code>
    <div></div>
    <button onClick={resetErrorBoundary}>Try again</button> */}
    </div>
  );
}

function ScrollableMain() {
  // let loading = new URL('../static/imgs/loading.gif', import.meta.url);
  let playMode = useAtomValue(playModeAtom);
  return (
    <Suspense
      fallback={
        <div
          className='grow'
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <img src={loadingIcon} style={{ height: '30px' }} />
          <div style={{ height: '30px' }}>loading...</div>
        </div>
      }>
      <main
        id='scrollElement2'
        className={
          playMode == 'scroll'
            ? 'grow overflow-y-auto snap-y snap-mandatory'
            : 'grow overflow-y-hidden snap-y snap-mandatory'
        }>
        <Outlet />
      </main>
    </Suspense>
  );
}

function PreloadAtoms() {
  useAtom(userAtom);

  return null;
}

function App() {
  let setToken = useSetAtom(tokenAtom);

  return (
    <ErrorBoundary FallbackComponent={GenericErrorFallback} onReset={() => setToken(null)}>
      <Suspense fallback={null}>
        <PreloadAtoms />
      </Suspense>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ScrollToTop />
          <Layout>
            <Routes>
              <Route path='/' element={<Feeds />} />
              <Route path='/focusartfair' element={<LouvreSpaces myself='' isFeed={true} />} />
              <Route path='/vr' element={<VrSpaces isFeed={true} />} />
              <Route path='/s/:spaceId' element={<Feed />} />
              {/* FIXME: Ew. Don't judge me. */}
              <Route path='*' element={<ScrollableMain />}>
                <Route path='i/:spaceId' element={<ItemPage />} />
                <Route path='login' element={<Login />} />
                <Route path='settings' element={<Settings />} />
                <Route path='new' element={<SpaceForm />} />
                <Route path='search' element={<Search />} />
                <Route path='search/tag/:tag' element={<Search />} />
                <Route path='search/:keyword' element={<Search />} />
                <Route path='avatar' element={<Avatar />} />
                <Route path='u/:username' element={<User />} />
                <Route path='u/:username/follow/:mobileTabType' element={<Follow />} />
                <Route path='signup' element={<Signup />} />
                <Route path='certificate' element={<Certificate />} />
                <Route path='alarm' element={<Alarm />} />
                <Route path='password' element={<PasswordReset />} />
                <Route path='introduction' element={<Introduction />} />
                <Route path='introduction/termsofservice' element={<TermsOfService />} />
                <Route path='introduction/privacy' element={<PrivacyPolicy />} />
              </Route>
            </Routes>
            <AlphaValidate />
          </Layout>
          <CustomModal />
        </BrowserRouter>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

createRoot(document.getElementById('root')!).render(
  <Suspense fallback={null}>
    <App />
  </Suspense>
);
