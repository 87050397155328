import styled from 'styled-components';

import { Color } from 'src/constants';

import logoIcon from 'static/imgs/logo.png';

export const LoginModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 20.625rem;
  height: 18.125rem;
  padding: 2.1875rem;
  box-sizing: border-box;
  background-color: ${Color.white};
  border-radius: 0.25rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 2.5rem;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.25rem;
  letter-spacing: -0.02rem;
`;

export const LogoIcon = styled.img.attrs({ src: logoIcon })`
  width: 6.25rem;
  height: 1.875rem;
`;

export const LoginTextArea = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: -0.0049rem;
  text-align: center;
  color: ${Color.grayScale1};
  margin-bottom: 1.5rem;
`;

export const ApplyButton = styled.div`
  width: 100%;
  padding: 0.625rem 0;
  background-color: #db1f72;
  font-weight: 500;
  line-height: 1.375rem;
  letter-spacing: -0.025rem;
  text-align: center;
  color: ${Color.white};
  border-radius: 0.25rem;
  margin-bottom: 1.25rem;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: Noto Sans KR;
  font-size: 0.9375rem;
  font-weight: 350;
  line-height: 1.25rem;
  letter-spacing: -0.015rem;
  text-align: left;
  color: #4d4d4d;
`;

export const PinkText = styled.button`
  font-weight: 500;
  line-height: 1.25rem;
  color: #db1f72;
  margin-left: 0.5rem;
`;
