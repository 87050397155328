import { useCallback } from 'react';
import {
  ContentText,
  DivisionBorder,
  IndexText,
  NewWindowLink,
  PrivacyPolicyArea,
  PrivacyPolicyContainer,
  SubTitle,
  Title,
} from './styles';

function PrivacyPolicy(): JSX.Element {
  const onNewWindowLinkClick = useCallback((url: string) => {
    if (url.indexOf('http') < 0) {
      return window.open(`https://${url}`);
    }

    window.open(url);
  }, []);

  return (
    <PrivacyPolicyContainer>
      <PrivacyPolicyArea>
        <Title>[개인정보처리방침]</Title>
        <SubTitle>서문</SubTitle>
        <DivisionBorder />
        <IndexText>Adler(아들러) 개인정보 처리방침</IndexText>
        <ContentText>
          Adler(아들러)는 ｢개인정보 보호법｣ 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고
          원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
        </ContentText>
        <SubTitle>목차</SubTitle>
        <DivisionBorder />
        <ContentText>
          <ol>
            <li>개인정보의 처리 목적 </li>
            <li>개인정보의 처리 및 보유 기간</li>
            <li>처리하는 개인정보의 항목</li>
            <li>개인정보의 제 3자 제공</li>
            <li>개인정보 처리의 위탁</li>
            <li>개인정보의 파기 절차 및 방법</li>
            <li>정보주체와 법정대리인의 권리, 의무 및 행사방법</li>
            <li>개인정보의 안전성 확보조치</li>
            <li>개인정보 자동 수집 장치의 설치, 운영 및 거부에 관한 사항</li>
            <li>행태정보의 수집, 이용 및 거부등에 관한 사항</li>
            <li>개인정보 보호 담당부서</li>
            <li>권익침해 구제방법</li>
            <li>개인정보 처리방침의 변경</li>
          </ol>
        </ContentText>
        <SubTitle>본문</SubTitle>
        <DivisionBorder />
        <IndexText>개인정보의 처리 목적</IndexText>
        <ContentText>
          Adler(아들러)는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의
          용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는
          등 필요한 조치를 이행할 예정입니다.
          <ol>
            <li>회원 가입 및 관리 </li>
            회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 각종
            고지·통지, 고충처리 목적으로 개인정보를 처리합니다.
            <li>재화 또는 서비스 제공 </li>
            서비스 제공, 콘텐츠 제공 목적으로 개인정보를 처리합니다.
            <li>마케팅 및 광고 </li>
            마케팅 및 광고 활용 목적으로 개인정보를 처리합니다.
          </ol>
        </ContentText>
        <SubTitle>개인정보의 처리 및 보유 기간</SubTitle>
        <DivisionBorder />
        <IndexText>
          <ol>
            <li>
              Adler(아들러)는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은
              개인정보 보유·이용기간 내에서 개인정보를 처리· 보유합니다.
            </li>
            <li>각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</li>
          </ol>
        </IndexText>
        <ContentText>
          <ol>
            <li>
              홈페이지 회원 가입 및 관리 : 홈페이지 탈퇴 등 개인정보처리 목적 달성 후 1년까지 (다만, 다음의 사유에
              해당하는 경우에는 해당 사유 종료 시까지)
              <ol>
                <li>관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 해당 수사·조사 종료 시까지</li>
                <li>홈페이지 이용에 따른 채권·채무관계 잔존 시에는 해당 채권·채무관계 정산 시까지</li>
              </ol>
            </li>
            <li>
              재화 또는 서비스 제공 : 재화·서비스 공급완료 시까지 (다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료
              시까지)
              <ol>
                <li>
                  「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시·광고, 계약내용 및 이행 등 거래에 관한 기록
                </li>
                <ul>
                  <li>표시·광고에 관한 기록 : 6개월</li>
                  <li>계약 또는 청약철회, 대금결제, 재화 등의 공급기록 : 5년</li>
                  <li>소비자 불만 또는 분쟁처리에 관한 기록 : 3년</li>
                </ul>
                <li>「통신비밀보호법」에 따른 통신사실확인자료 보관</li>
                <ul>
                  <li>
                    가입자 전기통신일시, 개시·종료시간, 상대방 가입자번호, 사용도수, 발신기지국 위치추적자료 : 1년
                  </li>
                  <li>컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월</li>
                </ul>
              </ol>
            </li>
          </ol>
        </ContentText>
        <SubTitle>처리하는 개인정보의 항목</SubTitle>
        <DivisionBorder />
        <IndexText>Adler(아들러)는 다음의 개인정보 항목을 처리하고 있습니다.</IndexText>
        <ContentText>
          <ol>
            <li>
              회원 가입 및 관리를 위하여 아래 정보를 수집 및 이용합니다.
              <ul>
                <li>필수항목 : 성명, 생년월일, 주소, 전화번호, 이메일주소</li>
                <li>선택항목 : 결혼여부, 관심분야, 구매내역 등</li>
              </ul>
            </li>
            <li>인플루언서 회원의 경우 채널명, 대표채널URL 등 정보, 국적 등이 수집될 수 있습니다.</li>
            <li>
              인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.
              <ul>
                <li>IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록</li>
              </ul>
            </li>
          </ol>
        </ContentText>
        <SubTitle>개인정보의 제3자 제공에 관한 사항</SubTitle>
        <DivisionBorder />
        <IndexText>
          <ol>
            <li>
              Adler(아들러)는 정보주체의 개인정보를 개인정보의 처리 목적에서 명시한 범위 내에서만 처리하며, 정보주체의
              동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게
              제공하고 그 이외에는 정보주체의 개인정보를 제3자에게 제공하지 않습니다.
            </li>
            <li>
              Adler(아들러)는 원활한 서비스 제공을 위해 개인정보를 제3자에게 제공하는 경우 정보주체의 동의를 얻어 필요
              최소한의 범위로만 제공합니다.
            </li>
          </ol>
        </IndexText>
        <SubTitle>개인정보 처리의 위탁</SubTitle>
        <DivisionBorder />
        <IndexText>
          <ol>
            <li>
              Adler(아들러)는 원활한 개인정보 업무처리를 위하여 필요 시 제3자에게 개인정보 처리업무를 위탁하고 본
              처리방침을 통해 정보주체에게 공지합니다.
            </li>
            <li>
              Adler(아들러)는 위탁계약 체결 시 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지,
              기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등
              문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
            </li>
            <li>
              위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다
            </li>
          </ol>
        </IndexText>
        <SubTitle>개인정보의 파기 절차 및 방법</SubTitle>
        <DivisionBorder />
        <IndexText>
          <ol>
            <li>
              Adler(아들러)는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이
              해당 개인정보를 파기합니다.
            </li>
            <li>
              정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음 에도 불구하고 다른 법령에
              따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나
              보관장소를 달리하여 보존합니다.
            </li>
            <li>개인정보 파기의 절차 및 방법은 다음과 같습니다.</li>
          </ol>
        </IndexText>
        <ContentText>
          <ol>
            <li>파기절차</li>
            Adler(아들러)는 파기 사유가 발생한 개인정보를 선정하고, &#60;개인정보 처리자명&#62;의 개인정보 보호책임자의
            승인을 받아 개인정보를 파기합니다.
            <li>파기방법</li>
            Adler(아들러)는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에
            기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
          </ol>
        </ContentText>
        <SubTitle>정보주체와 법정대리인의 권리·의무 및 행사방법</SubTitle>
        <DivisionBorder />
        <IndexText>
          <ol>
            <li>
              정보주체는 Adler(아들러)에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수
              있습니다.
            </li>
            <li>
              권리 행사는 Adler(아들러)에 대해 「개인정보 보호법」 시행령 제41조 제1항에 따라 서면, 전자우편,
              모사전송(FAX) 등을 통하여 하실 수 있으며, Adler(아들러)는 이에 대해 지체없이 조치하겠습니다.
            </li>
            <li>
              권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수도 있습니다. 이 경우
              “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
            </li>
            <li>
              개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가
              제한될 수 있습니다.
            </li>
            <li>
              개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를
              요구할 수 없습니다.
            </li>
            <li>
              Adler(아들러)는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리 정지의 요구 시 열람 등 요구를 한
              자가 본인이거나 정당한 대리인인지를 확인합니다.
            </li>
          </ol>
        </IndexText>
        <SubTitle>개인정보의 안전성 확보조치</SubTitle>
        <DivisionBorder />
        <IndexText>Adler(아들러)는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</IndexText>
        <ContentText>
          <ol>
            <li>관리적 조치 : 내부관리계획 수립·시행, 전담조직 운영, 정기적 직원 교육</li>
            <li>
              기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 개인정보의 암호화, 보안프로그램
              설치 및 갱신
            </li>
            <li>물리적 조치 : 전산실, 자료보관실 등의 접근통제</li>
          </ol>
        </ContentText>
        <SubTitle>개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항</SubTitle>
        <DivisionBorder />
        <IndexText>
          <ol>
            <li>
              Adler(아들러)는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용 정보를 저장하고 수시로 불러오는
              ‘쿠키(cookie)’를 사용합니다.
            </li>
            <li>
              쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며
              이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
            </li>
          </ol>
        </IndexText>
        <ContentText>
          <ol>
            <li>
              쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속
              여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
            </li>
            <li>
              쿠키의 설치·운영 및 거부 : 웹브라우저 상단의 도구 &#62; 인터넷 옵션 &#62; 개인정보 메뉴의 옵션 설정을 통해
              쿠키 저장을 거부 할 수 있습니다.
            </li>
            <li>쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</li>
          </ol>
        </ContentText>
        <SubTitle>행태정보의 수집·이용 및 거부 등에 관한 사항</SubTitle>
        <DivisionBorder />
        <IndexText>
          <ol>
            <li>
              Adler(아들러)는 서비스 이용과정에서 정보주체에게 최적화된 맞춤형 서비스 및 혜택, 온라인 맞춤형 광고 등을
              제공하기 위하여 행태정보를 수집·이용하고 있습니다.
            </li>
            <li>
              Adler(아들러)는 다음과 같이 행태정보를 수집합니다.
              <ContentText>
                <table>
                  <tr>
                    <th>수집하는 행태정보의 항목</th>
                    <th>행태정보 수집 방법</th>
                    <th>행태정보 수집 목적</th>
                    <th>보유, 이용기간 및 이후 정보처리 방법</th>
                  </tr>
                  <tr>
                    <td>이용자의 웹사이트 / 앱 서비스 방문이력, 검색이력, 구매이력 등</td>
                    <td>이용자의 웹 사이트 및 앱 방문 / 실행시 자동 수집</td>
                    <td>이용자의 관심, 성향에 기반한 개인 맞춤형 상품추천 서비스(광고포함)를 제공</td>
                    <td>수집일로부터 3년 후 파기</td>
                  </tr>
                </table>
              </ContentText>
            </li>

            <li>
              Adler(아들러)는 다음과 같이 온라인 맞춤형 광고 사업자가 행태정보를 수집·처리하도록 허용하고 있습니다.
              <ContentText>
                <ul>
                  <li>행태정보를 수집 및 처리하려는 광고 사업자 : 구글 애널리틱스</li>
                  <li>행태정보 수집 방법 : 이용자가 당사 웹사이트를 방문하거나 앱을 실행할 때 자동 수집 및 전송</li>
                  <li>수집·처리되는 행태정보 항목 : 이용자의 웹/앱 방문이력, 검색이력, 서비스 이용 이력 등</li>
                  <li>보유·이용기간 : 3년</li>
                </ul>
              </ContentText>
            </li>

            <li>
              Adler(아들러)는 온라인 맞춤형 광고 등에 필요한 최소한의 행태정보만을 수집하며, 사상, 신념, 가족 및
              친인척관계, 학력·병력, 기타 사회활동 경력 등 개인의 권리·이익이나 사생활을 뚜렷하게 침해할 우려가 있는
              민감한 행태정보를 수집하지 않습니다.
            </li>
            <li>
              Adler(아들러)는 만 14세 미만임을 알고 있는 아동이나 만14세 미만의 아동을 주 이용자로 하는 온라인
              서비스로부터 맞춤형 광고 목적의 행태정보를 수집하지 않고, 만 14세 미만임을 알고 있는 아동에게는 맞춤형
              광고를 제공하지 않습니다.
            </li>
            <li>
              Adler(아들러)는 모바일 앱에서 온라인 맞춤형 광고를 위하여 광고식별자를 수집· 이용합니다. 정보주체는 모바일
              단말기의 설정 변경을 통해 앱의 맞춤형 광고를 차단·허용할 수 있습니다.
              <ContentText>
                <ul>
                  <li>스마트폰의 광고식별자 차단/허용</li>
                </ul>
                <ol>
                  <li>(안드로이드) ① 설정 → ② 개인정보보호 → ③ 광고 → ③ 광고 ID 재설정 또는 광고ID 삭제</li>
                  <li>(아이폰) ① 설정 → ② 개인정보보호 → ③ 추적 → ④ 앱이 추적을 요청하도록 허용 끔</li>
                  <br />※ 모바일 OS 버전에 따라 메뉴 및 방법이 다소 상이할 수 있습니다.
                </ol>
              </ContentText>
            </li>

            <li>
              정보주체는 웹브라우저의 쿠키 설정 변경 등을 통해 온라인 맞춤형 광고를 일괄적으로 차단·허용할 수 있습니다.
              다만, 쿠키 설정 변경은 웹사이트 자동로그인 등 일부 서비스의 이용에 영향을 미칠 수 있습니다.
              <ContentText>
                웹브라우저를 통한 맞춤형 광고 차단/허용
                <ol>
                  <li>
                    인터넷 익스플로러(Windows 10용 Internet Explorer 11)
                    <ul>
                      <li>Internet Explorer에서 도구 버튼을 선택한 다음 인터넷 옵션을 선택</li>
                      <li>개인 정보 탭을 선택하고 설정에서 고급을 선택한 다음 쿠키의 차단 또는 허용을 선택</li>
                    </ul>
                  </li>
                  <li>
                    Microsoft Edge
                    <ul>
                      <li>Edge에서 오른쪽 상단 ‘…’ 표시를 클릭한 후, 설정을 클릭합니다.</li>
                      <li>
                        설정 페이지 좌측의 ‘개인정보, 검색 및 서비스’를 클릭 후 「추적방지」 섹션에서 ‘추적방지’ 여부 및
                        수준을 선택합니다.
                      </li>
                      <li>‘InPrivate를 검색할 때 항상 “엄격” 추적 방지 사용’ 여부를 선택합니다.</li>
                      <li>아래 「개인정보」 섹션에서 ‘추적 안함 요청보내기’ 여부를 선택합니다.</li>
                    </ul>
                  </li>
                  <li>
                    크롬 브라우저
                    <ul>
                      <li>
                        Chrome에서 오른쪽 상단 ‘⋮’ 표시(chrome 맞춤설정 및 제어)를 클릭한 후, 설정 표시를 클릭합니다.
                      </li>
                      <li>
                        설정 페이지 하단에 ‘고급 설정 표시’를 클릭하고 「개인정보」 섹션에서 콘텐츠 설정을 클릭합니다.
                      </li>
                      <li>쿠키 섹션에서 ‘타사 쿠키 및 사이트 데이터 차단’의 체크박스를 선택합니다.</li>
                    </ul>
                  </li>
                </ol>
              </ContentText>
            </li>

            <li>
              정보주체는 아래의 개인정보 보호 담당부서로 행태정보와 관련하여 궁금한 사항과 거부권 행사, 피해 신고 접수
              등을 문의할 수 있습니다.
            </li>
          </ol>
        </IndexText>
        <SubTitle>개인정보 보호 담당부서</SubTitle>
        <DivisionBorder />
        <IndexText>
          <ol>
            <li>
              Adler(아들러)는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리
              및 피해구제 등을 위하여 개인정보 보호책임자를 지정하고 개인정보 보호 담당부서를 운영하고 있습니다.
              <ContentText>
                <ul>
                  <li>개인정보 보호 담당부서</li>
                  부서명 : 마케팅 팀<br />
                  연락처 : 02-874-6182, cs@adler.cx
                </ul>
              </ContentText>
            </li>
            <li>
              정보주체는 Adler(아들러)의 서비스를 이용하시면서 발생한 모든 개인정보보호 관련 문의, 불만처리, 피해구제
              등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의할 수 있습니다. 정보주체는 「개인정보 보호법」
              제35조에 따른 개인정보의 열람 청구를 제1항의 부서에 할 수 있습니다. Adler(아들러)는 정보주체의 개인정보
              열람청구가 신속하게 처리되도록 노력하겠습니다.
            </li>
          </ol>
        </IndexText>
        <SubTitle>권익침해 구제방법</SubTitle>
        <DivisionBorder />
        <IndexText>
          <ol>
            <li>
              정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원
              개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고,
              상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
              <ContentText>
                <ol>
                  <li>
                    개인정보분쟁조정위원회 : (국번없이) 1833-6972 (
                    <NewWindowLink onClick={() => onNewWindowLinkClick('www.kopico.go.kr')}>
                      www.kopico.go.kr
                    </NewWindowLink>
                    )
                  </li>
                  <li>
                    개인정보침해신고센터 : (국번없이) 118 (
                    <NewWindowLink onClick={() => onNewWindowLinkClick('privacy.kisa.or.kr')}>
                      privacy.kisa.or.kr
                    </NewWindowLink>
                    )
                  </li>
                  <li>
                    대검찰청 사이버수사과: (국번없이) 1301 (
                    <NewWindowLink onClick={() => onNewWindowLinkClick('www.spo.go.kr')}>www.spo.go.kr</NewWindowLink>)
                  </li>
                  <li>
                    경찰청 사이버안전국: (국번없이) 182 (
                    <NewWindowLink onClick={() => onNewWindowLinkClick('ecrm.cyber.go.kr')}>
                      ecrm.cyber.go.kr
                    </NewWindowLink>
                    )
                  </li>
                </ol>
              </ContentText>
            </li>
            <li>
              Adler(아들러)는 정보주체의 개인정보자기결정권을 보장하고, 개인정보 침해로 인한 상담 및 피해 구제를 위해
              노력하고 있으며, 신고나 상담이 필요한 경우 위 개인정보 보호 관련 담당부서로 연락해 주시기 바랍니다.
            </li>
            <li>
              「개인정보 보호법」 제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지
              등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를
              받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다. ▸ 중앙행정심판위원회 : (국번없이)
              110 (
              <NewWindowLink onClick={() => onNewWindowLinkClick('www.simpan.go.kr')}>www.simpan.go.kr</NewWindowLink>)
            </li>
          </ol>
        </IndexText>
        <SubTitle>개인정보 처리방침의 변경</SubTitle>
        <DivisionBorder />
        <IndexText>이 개인정보 처리방침은 2022. 8. 4부터 적용됩니다.</IndexText>
      </PrivacyPolicyArea>
    </PrivacyPolicyContainer>
  );
}

export default PrivacyPolicy;
