import { CuboidCollider, RigidBody } from '@react-three/rapier';

function Plane(props) {
  return (
    <RigidBody {...props} type='fixed' colliders={false}>
      <mesh position={[0, 0, 0]} rotation-x={-Math.PI / 2}>
        <planeGeometry args={[1000, 1000]} />
        <meshBasicMaterial visible={false} />
      </mesh>
      <CuboidCollider args={[1000, 0, 1000]} position={[0, -2, 0]} />
    </RigidBody>
  );
}

function Cube(props) {
  return (
    <RigidBody {...props} type='fixed' colliders='cuboid'>
      <mesh>
        <boxGeometry args={[100, 2, 0.1]} />
        <meshBasicMaterial visible={false} />
      </mesh>
    </RigidBody>
  );
}

export default function Primitiveform({
  left,
  right,
  front,
  back,
  height,
}: {
  left: number;
  right: number;
  front: number;
  back: number;
  height: number;
}) {
  return (
    <group>
      <Plane position={[0, height, 0]} />
      <Cube position={[left, 0, 0]} rotation={[0, -Math.PI / 2, 0]} />
      <Cube position={[right, 0, 0]} rotation={[0, -Math.PI / 2, 0]} />
      <Cube position={[0, 0, front]} rotation={[0, 0, 0]} />
      <Cube position={[0, 0, back]} rotation={[0, 0, 0]} />
    </group>
  );
}
