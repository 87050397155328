import * as THREE from 'three';
import React, { useState, useRef, useMemo, forwardRef } from 'react';
import { useEffect } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { Billboard, PositionalAudio, Text, Html } from '@react-three/drei';
import * as Colyseus from 'colyseus.js';
import { useAtomValue } from 'jotai';

import { CapsuleCollider, RigidBody, useRapier, RigidBodyApi } from '@react-three/rapier';
import Character from './character';
import CharacterFemale from './character-female';
import { tokenAtom, userAtom, wheelDelta, bgmModeAtom } from 'src/atoms';
import { Euler } from 'three';

const font2 = new URL('../../../static/fonts/NotoSansKR-Medium.otf', import.meta.url);
const spawnAudioURL = new URL('../../../static/sounds/spawn.wav', import.meta.url);

const scripts = {
  KR: ['프로필가기', '팔로우', '팔로잉'],
  EN: ['go to profile', 'follow', 'following'],
  JP: ['プロフィールに移動', 'フォロー', 'フォローイング'],
};

const SPEED = 1.4;
let rotation = new THREE.Vector3();
let rotation2 = new THREE.Vector3();
let position = new THREE.Vector3();

let euler = new Euler(0, 0, 0, 'YXZ');
const mobileDirection = new THREE.Vector3(0, 0, -1);
const vec = new THREE.Vector3();
const quate = new THREE.Quaternion();
const keys = {
  KeyW: 'forward',
  KeyS: 'backward',
  KeyA: 'left',
  KeyD: 'right',
  Space: 'jump',
  ArrowUp: 'forward',
  ArrowDown: 'backward',
  ArrowRight: 'right',
  ArrowLeft: 'left',
};

const moveFieldByKey = (key) => keys[key];

const usePlayerControls = ({ isInteractive }: { isInteractive: boolean }) => {
  const [movement, setMovement] = useState({ forward: 0, backward: 0, left: 0, right: 0, jump: 0 });
  const [movementX, setMovementX] = useState<number>(0);
  const [startMovementX, setStartMovementX] = useState<number>(0);
  const [startMovementY, setStartMovementY] = useState<number>(0);

  const [movementY, setMovementY] = useState<number>(0);
  const [touchCheck, setTouchCheck] = useState<boolean>(false);
  const [dir, setDir] = useState<string[]>([]);

  let [xy, setXY] = useState({ x: innerWidth / 2, y: innerHeight });
  let [joyxy, setJoyxy] = useState({ x: innerWidth / 2, y: innerHeight });

  let clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);
  const gl = useThree((state) => state.gl);

  function calcAngleDegrees(x: number, y: number) {
    let theta_x = (Math.atan2(y, x) * 180) / Math.PI;

    if (theta_x < 0) {
      return theta_x + 360;
    }

    return theta_x;
  }

  let [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  useEffect(() => {
    setXY({ x: innerWidth / 10, y: innerHeight / 1.1 });
    setJoyxy({ x: innerWidth / 10 + 5, y: innerHeight / 1.1 });
  }, [windowSize]);
  useEffect(() => {
    if (isInteractive) {
      const handleKeyDown = (e) => setMovement((m) => ({ ...m, [moveFieldByKey(e.code)]: 1 }));
      const handleKeyUp = (e) => setMovement((m) => ({ ...m, [moveFieldByKey(e.code)]: 0 }));
      const handleTouchMove = (e) => {
        setMovementX(e.changedTouches[0].clientX / gl.domElement.clientWidth - 0.5),
          setMovementY(e.changedTouches[0].clientY / gl.domElement.clientHeight - 0.5);
      };
      const handleTouchStart = (e) => {
        setStartMovementX(e.changedTouches[0].clientX / gl.domElement.clientWidth - 0.5);
        setStartMovementY(e.changedTouches[0].clientY / gl.domElement.clientHeight - 0.5);

        setTouchCheck(true);
      };
      const handleTouchEnd = () => {
        setTouchCheck(false);
      };
      const handleJoystickMove = (e) => {
        setJoyxy({
          x: clamp(e.changedTouches[0].pageX - 45, xy.x - 20, xy.x + 20),
          y: clamp(e.changedTouches[0].pageY + 100, xy.y - 20, xy.y + 20),
        });

        if (
          calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) > 110 &&
          calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) < 195
        ) {
          setDir(['left', '']);
        }
        if (
          calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) > 195 &&
          calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) < 255
        ) {
          setDir(['left', 'forward']);
        }

        if (
          calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) < 170 &&
          calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) > 110
        ) {
          setDir(['left', 'backward']);
        }

        if (
          calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) < 100 &&
          calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) > 105
        ) {
          console.log(calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y));
          setDir(['', 'backward']);
        }
        if (
          calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) > 255 &&
          calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) < 295
        ) {
          setDir(['', 'forward']);
        }

        if (
          calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) > 295 &&
          calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) < 355
        ) {
          setDir(['right', 'forward']);
        }
        if (
          calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) > 15 &&
          calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) < 75
        ) {
          setDir(['right', 'backward']);
        }
        if (calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) > 355) {
          setDir(['right', '']);
        }
        if (calcAngleDegrees(joyxy.x - xy.x, joyxy.y - xy.y) < 15) {
          setDir(['right', '']);
        }
      };

      let handleJoystickStart = (e) => {
        setJoyxy({
          x: clamp(e.changedTouches[0].pageX - 50, xy.x - 20, xy.x + 20),
          y: clamp(e.changedTouches[0].pageY + 150, xy.y - 20, xy.y + 20),
        });
      };
      let handleJoystickEnd = (e) => {
        setJoyxy({ x: xy.x + 5, y: xy.y });
        setDir(['', '']);
      };
      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('keyup', handleKeyUp);
      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchstart', handleTouchStart);
      document.addEventListener('touchend', handleTouchEnd);
      document.querySelector('#joystick')?.addEventListener('touchmove', handleJoystickMove);
      document.querySelector('#joystick')?.addEventListener('touchstart', handleJoystickStart);
      document.querySelector('#joystick')?.addEventListener('touchend', handleJoystickEnd);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('keyup', handleKeyUp);
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchstart', handleTouchStart);
        document.removeEventListener('touchend', handleTouchEnd);
        document.querySelector('#joystick')?.removeEventListener('touchmove', handleJoystickMove);
        document.querySelector('#joystick')?.removeEventListener('touchstart', handleJoystickStart);
        document.querySelector('#joystick')?.removeEventListener('touchend', handleJoystickEnd);
      };
    }
  }, [joyxy, xy.x, xy.y, isInteractive]);
  return { movement, movementX, movementY, touchCheck, dir, startMovementX, startMovementY };
};

const Player = forwardRef<
  THREE.Mesh,
  {
    custom: {
      type: string;
      hair: number;
      face: number;
      top: number;
      bottom: number;
      shoes: number;
    };
    isMoved: number;
    isCharacterDirection: number;
  } & Pick<JSX.IntrinsicElements['mesh'], 'position' | 'rotation' | 'children'>
>(function Player({ position, rotation, children, custom, isMoved, isCharacterDirection }, ref) {
  const bgmMode = useAtomValue(bgmModeAtom);

  return (
    <mesh ref={ref} position={position} rotation={[0, isCharacterDirection, 0]} scale={0.3}>
      <PositionalAudio autoplay loop={false} url={spawnAudioURL.href} distance={bgmMode ? 10 : 0} />

      {custom.type == 'M' ? (
        <Character customOptions={custom} gender={custom.type} isMoved={isMoved} />
      ) : (
        <CharacterFemale customOptions={custom} gender={custom.type} isMoved={isMoved} />
      )}

      {children}
    </mesh>
  );
});

type SpaceMode = 'multiplay' | 'scroll';

function ThirdPlayer({
  nonMemberName,
  room,
  isInteractive,
}: {
  nonMemberName: string | null;
  room: Colyseus.Room | null;
  isInteractive: boolean;
}) {
  const playerMeshRef = useRef<THREE.Group>(null);
  const ref = useRef<RigidBodyApi>(null);

  const billRef2 = useRef<THREE.Group | null>();
  const rapier = useRapier();
  let {
    movement: { forward, backward, left, right },
    movementX,
    movementY,
    startMovementX,
    startMovementY,
    touchCheck,
    dir,
  } = usePlayerControls({ isInteractive });
  let { camera } = useThree();

  const color = new THREE.Color('black');
  color.convertSRGBToLinear();
  const [isMoved, setIsMoved] = useState<number>(1);
  const [isTouchHandler, setIsTouchHandler] = useState<number>(0);
  const [isCharacterDirection, setIsCharacterDirection] = useState<number>(0);
  const direction = useMemo(() => new THREE.Vector3(), []);
  const velocity = useRef([0, 0, 0]);
  const token = useAtomValue(tokenAtom);
  const mediaMatch = window.matchMedia('(min-width: 1024px)');

  const [customOptions, setCustomOptions] = useState<{
    type: string;
    hair: number;
    face: number;
    top: number;
    bottom: number;
    shoes: number;
  }>({
    type: 'D',
    hair: 0,
    face: 0,
    top: 0,
    bottom: 0,
    shoes: 0,
  });
  const gl = useThree((state) => state.gl);
  gl.setDrawingBufferSize(gl.domElement.clientWidth, gl.domElement.clientHeight, 1.4);

  useEffect(() => {
    if (token && user) {
      setCustomOptions(user.characters);
    } else {
      setCustomOptions({ type: 'M', hair: 1, face: 1, top: 1, bottom: 1, shoes: 1 });
    }
  }, []);

  useEffect(() => {
    if (dir[1] == 'forward') {
      forward = 1;
    } else if (dir[1] == 'backward') {
      backward = 1;
    }
    if (dir[0] == 'right') {
      right = 1;
    } else if (dir[0] == 'left') {
      left = 1;
    }
    if (backward === 1) {
      setIsCharacterDirection(Math.PI);
      if (right === 1) {
        setIsCharacterDirection(-Math.PI / 1.5);
      } else if (left === 1) {
        setIsCharacterDirection(Math.PI / 1.5);
      }
    } else if (forward === 1) {
      setIsCharacterDirection(0);
      if (right === 1) {
        setIsCharacterDirection(-Math.PI / 4);
      } else if (left === 1) {
        setIsCharacterDirection(Math.PI / 4);
      }
    } else if (right === 1) {
      setIsCharacterDirection(-Math.PI / 2);
      if (backward === 1) {
        setIsCharacterDirection(-Math.PI / 1.5);
      } else if (forward === 1) {
        setIsCharacterDirection(-Math.PI / 4);
      }
    } else if (left === 1) {
      setIsCharacterDirection(Math.PI / 2);
      if (backward === 1) {
        setIsCharacterDirection(Math.PI / 1.5);
      } else if (forward === 1) {
        setIsCharacterDirection(Math.PI / 4);
      }
    }
  }, [right, left, backward, forward, dir]);

  useEffect(() => {
    if (dir[1] == 'forward') {
      forward = 1;
    } else if (dir[1] == 'backward') {
      backward = 1;
    }
    if (dir[0] == 'right') {
      right = 1;
    } else if (dir[0] == 'left') {
      left = 1;
    }
    if (right - left != 0 || backward - forward != 0) {
      setIsMoved(1);
    } else {
      setIsMoved(0);
    }
  }, [right, left, backward, forward, dir]);

  useEffect(() => {
    if (!isMoved) {
      euler.setFromQuaternion(camera.quaternion);
      euler.y -= movementX * 0.15 - startMovementX / 10;
      euler.x -= movementY * 0.15 - startMovementY / 10;
      euler.x = Math.max(Math.PI / 2 - Math.PI / 1.5, Math.min(Math.PI / 2 - Math.PI / 4, euler.x));
      camera.quaternion.setFromEuler(euler);
    }
  }, [movementX, movementY]);
  useFrame(() => {
    if (!playerMeshRef.current) {
      return;
    }
    if (dir[1] == 'forward') {
      forward = 1;
    } else if (dir[1] == 'backward') {
      backward = 1;
    }
    if (dir[0] == 'right') {
      right = 1;
    } else if (dir[0] == 'left') {
      left = 1;
    }
    if (isMoved) {
      playerMeshRef.current.quaternion.slerp(camera.quaternion, 0.05);
      playerMeshRef.current.quaternion.x = 0;
      playerMeshRef.current.quaternion.z = 0;
    }
    const velocity = ref.current.linvel();
    direction
      .set(right - left, 0, backward - forward)
      .multiplyScalar(SPEED)
      .applyEuler(playerMeshRef.current.rotation);

    if (mediaMatch.matches) {
      camera.position.setX(ref.current.translation().x);
      camera.position.setY(ref.current.translation().y - 0.1);
      camera.position.setZ(ref.current.translation().z);
      camera.getWorldDirection(rotation);
      rotation2.x = rotation.x / 2;
      rotation2.y = 0;
      rotation2.z = rotation.z / 2;
      ref.current.setLinvel({ x: direction.x, y: velocity.y, z: direction.z });
      position.set(...ref.current.translation());
      playerMeshRef.current.position.copy(position).add(rotation2);
      billRef2.current.position.copy(position).add(rotation2);
    } else if (mediaMatch.matches === false) {
      camera.position.setX(ref.current.translation().x);
      camera.position.setY(ref.current.translation().y - 0.1);
      camera.position.setZ(ref.current.translation().z);
      camera.getWorldDirection(rotation);
      rotation2.x = rotation.x / 2;
      rotation2.y = 0;
      rotation2.z = rotation.z / 2;
      ref.current.setLinvel({ x: direction.x, y: velocity.y, z: direction.z });
      position.set(...ref.current.translation());
      playerMeshRef.current.position.copy(position).add(rotation2);

      billRef2.current.position.copy(playerMeshRef.current.position).add(rotation2);
    }

    if (room) {
      room.send('move', {
        pos: {
          x: playerMeshRef.current.position.x - rotation2.x,
          y: playerMeshRef.current.position.y + (mediaMatch.matches === false ? 0.575 : 0.565),
          z: playerMeshRef.current.position.z - rotation2.z,
        },
        rot: {
          x: playerMeshRef.current.rotation.x,
          y: playerMeshRef.current.rotation.y,
          z: playerMeshRef.current.rotation.z,
        },
        ani: isMoved,
      });
    }
  });

  const user = useAtomValue(userAtom);
  const [hover, setHover] = React.useState<boolean>(false);
  const wheelnum = useAtomValue(wheelDelta);

  React.useLayoutEffect(() => {
    document.body.style.cursor = hover ? 'pointer' : 'auto';
  }, [hover]);

  useEffect(() => {
    if (wheelnum > 0) {
      if (camera.scale.z < 1.5) camera.scale.z += 0.01;
    } else if (wheelnum < 0) {
      if (camera.scale.z > 0.6) camera.scale.z -= 0.01;
    }
  }, [wheelnum, camera]);

  return (
    <>
      <RigidBody
        ref={ref}
        colliders={false}
        mass={1}
        type='dynamic'
        position={[0, 0, mediaMatch.matches === false ? 3.5 : 3.5]}
        enabledRotations={[false, false, false]}>
        <CapsuleCollider args={[0.25, 0.125]} />
      </RigidBody>
      <group ref={playerMeshRef}>
        <Player
          position={[0, mediaMatch.matches === false ? -1.03 : -1.03, 0]}
          custom={customOptions}
          isCharacterDirection={isCharacterDirection}
          isMoved={isMoved}></Player>
      </group>
      <group ref={billRef2}>
        <Billboard
          follow={true}
          lockX={false}
          lockY={false}
          lockZ={false}
          scale={1}
          position={[0, mediaMatch.matches === false ? -0.38 : -0.21, 0]}>
          <Text
            onClick={(e) => console.log('click')}
            fontSize={0.05}
            color={color}
            anchorX='center'
            anchorY='middle'
            font={font2.href}>
            {user ? user.nickname : nonMemberName}
          </Text>
        </Billboard>
      </group>
    </>
  );
}

export { ThirdPlayer };
