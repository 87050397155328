import { SetStateAction, useAtomValue } from 'jotai';
import { Dispatch, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { APIError, APIIssues, getTokenUser, getVerificationCode, verify, verifyPhone } from '../../apis';
import { languageModeAtom, tokenAtom } from '../../atoms';
import CountryCode from './country-code';
import { Button } from './buttons';
import { TextInput } from './text-input';

const scripts = {
  KR: [
    '인증시간이 만료되었습니다.',
    '핸드폰 인증',
    '국가선택',
    '유효하지 않은 전화번호입니다.',
    '입력하신 전화번호로 등록된 계정이 있는지 확인해주세요',
    '확인',
    '재전송',
    '인증이 완료되지 않았습니다.',
    '인증완료',
    '서비스 정책',
    '서비스 이용약관 동의 (필수)',
    '내용보기',
    '이용약관에 동의해 주세요.',
    '휴대폰 인증에 실패했습니다. 다시 시도해주세요.',
    '확인',
    '임시 비밀번호 발급',
    '회원가입',
  ],
  EN: [
    'Certification time has expired',
    'Mobile phone authentication',
    'Select country',
    'Invalid phone number',
    'Please check if there is an account registered with the phone number you entered',
    'Confirm',
    'Resend',
    'Authentication is not completed',
    'Authentication completed',
    'Service Policy',
    'Consent to Terms of Service (Required)',
    'View Contents',
    'Please onsent to Terms of Use',
    'Phone Authentication Failed. Please try again.',
    'Check',
    'Temporary password',
    'Registration',
  ],
  JP: [
    '認証時間が満了しました。',
    '携帯電話認証',
    '国の選択',
    '有効でない電話番号です。',
    '入力した電話番号に登録されたアカウントがあるか確認してください',
    '確認',
    '再送信',
    '認証完了',
    'サービスの利用約款の同意(必須)',
    '内容を見る',
    '利用約款に同意してください',
    '携帯電話番号に失敗しました。',
    '認証を入力した。 もう一度試してください。',
    '確認',
    '臨時パスワード発行',
    '会員登録',
  ],
};

interface TimeState {
  minutes: number;
  seconds: number;
}

function Timer({ minutes, seconds }: TimeState) {
  let languageMode = useAtomValue(languageModeAtom);
  const [time, setTime] = useState<TimeState>({ minutes, seconds });
  const [isOver, setIsOver] = useState<boolean>(false);
  const tick = () => {
    if (time.seconds === 0) {
      if (time.minutes === 0) {
        setIsOver(true);
      } else {
        setTime({ minutes: time.minutes - 1, seconds: 59 });
      }
    } else {
      setTime({ minutes: time.minutes, seconds: time.seconds - 1 });
    }
  };
  const secondStringfy = (second: number) => {
    if (second >= 10) {
      return String(second);
    } else {
      return '0' + String(second);
    }
  };
  useEffect(() => {
    const timeId = setInterval(() => tick(), 1000);
    return () => clearInterval(timeId);
  });
  return (
    <div className='text-xs text-error'>
      {isOver ? scripts[languageMode][0] : String(time.minutes) + ':' + secondStringfy(time.seconds)}
    </div>
  );
}

export default function PhoneCerification({
  type,
  phone,
  setPhone,
  phoneToken,
  setPhoneToken,
  codeNumber,
  setCodeNumber,
  isVerified,
  setIsVerified,
  validateError,
  setValidateError,
}: {
  type: number;
  phone: string;
  setPhone: Dispatch<SetStateAction<string>>;
  phoneToken: string | null;
  setPhoneToken: Dispatch<SetStateAction<string | null>>;
  codeNumber: string;
  setCodeNumber: Dispatch<SetStateAction<string>>;
  isVerified: boolean;
  setIsVerified: Dispatch<SetStateAction<boolean>>;
  validateError: APIIssues | null;
  setValidateError: Dispatch<SetStateAction<APIIssues | null>>;
}) {
  let token = useAtomValue(tokenAtom);
  let languageMode = useAtomValue(languageModeAtom);
  let navigate = useNavigate();
  let [openTimer, setOpenTimer] = useState({ isOpen: false, minutes: 5, seconds: 0 });
  let [isVerificationError, setIsVerificationError] = useState<boolean>(false);

  let [verificationCode, setVerificationCode] = useState<string>('');
  let [key, setKey] = useState<string | null>(null);

  return (
    <>
      {type === 1 ? (
        <>
          <label className='flex items-center gap-1.5 rounded border-solid border border-zinc-300 px-4 py-2 h-8'>
            <div className='text-xs text-zinc-400 w-fit shrink-0'>{scripts[languageMode][2]}</div>
            <div className='pb-1'>
              <CountryCode
                onCodeChange={(code: string) => {
                  setCodeNumber(code);
                }}
                codeNumber={codeNumber}
              />
            </div>
          </label>
          <TextInput
            title='휴대전화'
            type='tel'
            name='phonenumber'
            required={true}
            placeholder='01012345678'
            value={phone}
            onText={setPhone}
            error={!!validateError && validateError.code !== 400201 && validateError.field !== 'username'}
          />
        </>
      ) : (
        <label className='flex flex-row items-center gap-1.5 rounded border-solid border border-zinc-300 px-4 py-2 h-14'>
          <div className='pb-1 border-r-[1px] border-solid border-[#e0e0e0]'>
            <CountryCode
              onCodeChange={(code: string) => {
                setCodeNumber(code);
              }}
              codeNumber={codeNumber}
            />
          </div>
          <input
            className='pl-1 w-fit'
            type='tel'
            placeholder='전화번호'
            value={phone}
            onChange={(e) => setPhone(e.currentTarget.value)}
            error={!!validateError && validateError.code !== 400201}
          />
        </label>
      )}
      {!!validateError && (
        <div className='text-xs text-error mb-1'>
          {validateError.code === 400100 && validateError.field === 'phone' && scripts[languageMode][3]}
          {validateError.code === 400100 && validateError.field === 'phone_token' && scripts[languageMode][7]}
          {validateError.code === 400101 && scripts[languageMode][3]}
          {validateError.code === 400102 && validateError.field === 'phone' && scripts[languageMode][4]}
          {validateError.code === 400103 && 'The request specifies one or more invalid parameters.'}
          {validateError.code === 400202 && 'There is no counts left on the number of phone verifications..'}
        </div>
      )}

      <Button
        className={
          type === 1
            ? 'border border-pink-600 rounded text-pink-600 p-1 h-14 text-xs'
            : 'border border-pink-600 rounded text-pink-600 p-1 h-14 text-xs w-full'
        }
        type='button'
        text='인증번호 전송'
        color='primary'
        style='outlined'
        onClick={async () => {
          let phoneRegex = /^01([0|1|6|7|8|9])?([0-9]{3,4})?([0-9]{4})$/;
          if (!phoneRegex.test(phone)) setValidateError({ code: 400100, detail: '', field: 'phone' });
          else {
            try {
              let result = await getVerificationCode('+' + codeNumber + phone, type);
              if (!result.result) {
                setValidateError({ code: 400100, detail: '', field: 'phone' });
              } else {
                setValidateError(null);
                setKey(result.key);
                setOpenTimer({ isOpen: true, minutes: 5, seconds: 0 });
                setIsVerified(false);
              }
            } catch (error) {
              if (error instanceof APIError) setValidateError(error.issues);
            }
          }
        }}
      />
      <div className='flex gap-2 '>
        <div className='w-2/5'>
          <TextInput
            title='인증번호'
            type='text'
            name='verification-code'
            required={false}
            value={verificationCode}
            onText={setVerificationCode}
            error={isVerificationError}
          />
        </div>
        <div style={{ flex: '2' }}>
          <button
            type='button'
            className=' border border-pink-600 rounded text-xs text-pink-600 p-2 h-17 w-full h-full'
            onClick={async () => {
              try {
                let result = await verify('+' + codeNumber + phone, verificationCode, key, type);
                if (result.content) {
                  setIsVerificationError(false);
                  setIsVerified(true);
                  setOpenTimer({ isOpen: false, minutes: 0, seconds: 0 });
                  setPhoneToken(result.content.phoneToken);
                } else {
                  setIsVerificationError(true);
                  setIsVerified(false);
                }
              } catch (error) {
                if (error instanceof APIError) {
                  setIsVerificationError(true);
                  setIsVerified(false);
                }
              }
            }}>
            {scripts[languageMode][5]}
          </button>
        </div>
        <div style={{ flex: '3' }}>
          <button
            type='button'
            className=' border border-pink-600 rounded text-xs text-pink-600 p-2.5 h-17 h-full w-full'
            onClick={async () => {
              let phoneRegex = /^01([0|1|6|7|8|9])?([0-9]{3,4})?([0-9]{4})$/;
              if (!phoneRegex.test(phone)) setValidateError({ code: 400100, detail: '', field: 'phone' });
              else {
                try {
                  let result = await getVerificationCode('+' + codeNumber + phone, type);
                  if (!result.result) {
                    setValidateError({ code: 400100, detail: '', field: 'phone' });
                  } else {
                    setValidateError(null);
                    setKey(result.key);
                    setOpenTimer({ isOpen: true, minutes: 5, seconds: 0 });
                  }
                } catch (error) {
                  if (error instanceof APIError) setValidateError(error.issues);
                }
              }
            }}>
            {scripts[languageMode][6]}
          </button>
          {/* todo: 재전송 3번이상 할때 타이머 초기화 안됨 */}
        </div>
      </div>
      <div className='flex gap-2'>
        {openTimer.isOpen && <Timer minutes={openTimer.minutes} seconds={openTimer.seconds}></Timer>}
        {isVerificationError && <div className='text-xs text-error'>{scripts[languageMode][7]}</div>}
      </div>
      {isVerified && <div className='text-xs text-green-400'>{scripts[languageMode][8]}</div>}
    </>
  );
}
