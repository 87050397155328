import styled from 'styled-components';

import { BreakPoint, Color } from 'src/constants';

import CheckboxRectangleChecked from 'static/icons/checkbox_rectangle__checked.svg';
import CheckboxRectangleUnChecked from 'static/icons/checkbox_rectangle__unchecked.svg';

export const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${Color.white};
  color: ${Color.black};

  .clickable:hover {
    cursor: pointer;
  }
`;

export const SignUpForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 29.25rem;
  margin: 5rem 0 5rem;

  ${BreakPoint.mobile} {
    width: 87.2%;
    margin: 2.75rem 0 10.875rem;
  }
`;

export const SignUpTitle = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 2rem;
  letter-spacing: -0.015rem;
  margin-bottom: 3rem;

  ${BreakPoint.mobile} {
    margin-top: 5rem;
  }
`;

export const RequiredInformation = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: right;
  color: ${Color.grayScale4};
  margin-bottom: 1rem;

  span {
    color: ${Color.error};
  }
`;

export const SignUpInformation = styled.div.attrs({ className: 'clickable' })`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 0.625rem;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  letter-spacing: 0.0041rem;
  color: #b3b3b3;

  a {
    margin-right: 0.125rem;
  }

  a,
  a:hover,
  a:active {
    color: #00376b;
    text-decoration: none;
    outline: none;
    cursor: pointer;
  }

  ${BreakPoint.mobile} {
    margin-top: 1rem;
  }
`;

export const SignUpInformationText = styled.div`
  ${BreakPoint.mobile} {
    display: inline-block;
  }
`;

export const SignUpInformationCheckbox = styled.img.attrs<{ isChecked: boolean }>(
  ({ isChecked }) => ({
    src: isChecked ? CheckboxRectangleChecked : CheckboxRectangleUnChecked,
  })
)<{ isChecked: boolean }>`
  margin-right: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
`;

export const DivisionBorder = styled.div`
  width: 100%;
  border: 0.5px solid #cccccc;
  margin: 0.625rem 0;

  ${BreakPoint.mobile} {
    margin: 1rem 0;
  }
`;

const Button = styled.div.attrs<{ isActive: boolean }>(({ isActive }) => ({
  className: isActive ? 'clickable' : '',
}))<{
  isActive: boolean;
}>`
  margin-bottom: 0.5rem;
  width: 100%;
  font-weight: 700;
  line-height: 1.375rem;
  letter-spacing: -0.025rem;
  text-align: center;
  padding: 0.75rem 0;
  border-radius: 0.25rem;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};

  &:hover {
    opacity: ${({ isActive }) => (isActive ? 0.8 : 0.5)};
  }
`;

export const SignUpButton = styled(Button)`
  margin-top: 0;
  background-color: #db1f72;
  color: ${Color.white};

  ${BreakPoint.mobile} {
    margin-top: 0.25rem;
  }
`;

export const KakaoSignUpButton = styled(Button)`
  background-color: #ffe812;
  color: ${Color.black};
`;

export const NaverSignUpButton = styled(Button)`
  background-color: #00c73c;
  color: ${Color.white};
`;

export const FacebookSignUpButton = styled(Button)`
  background-color: #1877f2;
  color: ${Color.white};
`;

export const GoogleSignUpButton = styled(Button)`
  background-color: #f6f6f6;
  color: ${Color.black};
`;

export const AppleSignUpButton = styled(Button)`
  background-color: #000000;
  color: ${Color.white};
`;
