import { Space } from 'apis/index';
import {
  DescriptionButton,
  PostLink,
  PostLinkContainer,
  SpaceDescriptionContainer,
  SpaceDescriptionStyle,
  UserImage,
  UserName,
  UserNameContainer,
  Hashtag,
  HashtagButton,
  MusicContainer,
  MusicStyle,
  MusicName,
  DateTranslate,
} from './LayoutComponentStyle';
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MusicWhite } from 'components/Commons/icons';
import { languageModeAtom } from 'src/atoms';
import { useAtomValue } from 'jotai';

export default function LayOutComponent({ space }: { space: Space }) {
  const languageMode = useAtomValue(languageModeAtom);
  const [isDescOpen, setIsDescOpen] = useState<boolean>(true);
  const [isButton, setIsButton] = useState<boolean>(true);
  const descElRef = useRef<HTMLDivElement>(null);
  const postDate = space.created;
  const regex = /[^0-9]/g;
  const arrMix = [postDate.match(regex)];
  const strExtract = arrMix.join('').replace(/,/g, '');
  let navigate = useNavigate();
  useEffect(() => {
    if (descElRef.current) {
      let h = descElRef.current.clientHeight;
      if (h > 20) {
        setIsDescOpen(false);
      } else {
        setIsButton(false);
      }
    }
  }, []);

  const bgmNames = [
    'Violin Concerto No. 1 in A minor BWV 1041: II.',
    'Violin Concerto No. 1 in A minor BWV 1041: I.',
    'Violin Concerto No. 1 in A minor BWV 1041: III.',
    'Violin Concerto No. 2 in E major BWV 1042: III.',
    'Concerto for Two Violins in D minor BWV...',
    'Romance for Violin and Orchestra No. 2',
  ];

  const dateScripts = {
    KR: ['년 전', '달 전', '주 전', '일 전', '시간 전', '분 전'],
    EN: [' years ago', ' months ago', ' weeks ago', ' days ago', ' hours ago', ' minutes ago'],
    JP: [' 年前', ' 月前', ' 週間前', ' 日前', ' 時間前', ' 分前'],
  };

  function dateTranslate(qwe: string) {
    for (let i = 0; i < 6; i++) {
      if (strExtract == dateScripts.KR[i]) {
        switch (i) {
          case 0:
            return postDate.replace('년 전', dateScripts[languageMode][0]);
          case 1:
            return postDate.replace('달 전', dateScripts[languageMode][1]);
          case 2:
            return postDate.replace('주 전', dateScripts[languageMode][2]);
          case 3:
            return postDate.replace('일 전', dateScripts[languageMode][3]);
          case 4:
            return postDate.replace('시간 전', dateScripts[languageMode][4]);
          case 5:
            return postDate.replace('분 전', dateScripts[languageMode][5]);
        }
      }
    }
  }
  return (
    <>
      <PostLink>
        <PostLinkContainer>
          <UserImage
            space={space}
            to={`/u/${space.username}`}
            onClick={(e) => {
              e.stopPropagation();
            }}></UserImage>
          <UserNameContainer
            to={`/u/${space.username}`}
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <UserName>{space.userNickname}</UserName>@{space.username}
          </UserNameContainer>
        </PostLinkContainer>
      </PostLink>
      <SpaceDescriptionContainer
        isDescOpen={isDescOpen}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <SpaceDescriptionStyle isDescOpen={isDescOpen} ref={descElRef}>
          {space.noHashTagDescription.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index != 0 && <br />}
            </React.Fragment>
          ))}
          <Hashtag id='hashtag_area'>
            {space.hashTags.map((content, index) => (
              <HashtagButton
                key={index}
                onClick={() => navigate(`/search/tag/${content.slice(1)}/`)}
                text={content + ' '}></HashtagButton>
            ))}
          </Hashtag>
        </SpaceDescriptionStyle>
        {isButton && (
          <DescriptionButton
            onClick={() => {
              setIsDescOpen(!isDescOpen);
            }}
            text={isDescOpen ? '요약하기' : '더 보기'}></DescriptionButton>
        )}
      </SpaceDescriptionContainer>
      <MusicContainer>
        <MusicStyle>
          <MusicWhite />
          <MusicName>{bgmNames[space.bgm - 1]}</MusicName>
        </MusicStyle>
      </MusicContainer>
      <DateTranslate>{dateTranslate(strExtract)}</DateTranslate>
    </>
  );
}
