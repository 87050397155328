import styled from 'styled-components';

import { BreakPoint } from 'src/constants';

export const ContentText = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  font-size: 0.875rem;
  font-weight: 400;

  ${BreakPoint.mobile} {
    font-size: 0.75rem;
  }

  table,
  th,
  td {
    border: 1px solid #000000;
  }

  th,
  td {
    padding: 0.5rem;
  }

  th {
    opacity: 0.8;
    background-color: #db1f72;
    color: #ffffff;
    text-align: center;
  }
`;

export const PrivacyPolicyContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  li {
    padding-left: 0.5rem;
    display: list-item;
    list-style-position: inside;

    ol {
      list-style-type: lower-roman;
    }

    ${ContentText} {
      margin-top: 0.875rem;

      ${BreakPoint.mobile} {
        margin-top: 0.5rem;
      }
    }
  }
`;

export const PrivacyPolicyArea = styled.div`
  width: 64rem;
  margin-bottom: 3.75rem;

  ${BreakPoint.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 86.66%;
  }
`;

export const Title = styled.div`
  width: 100%;
  margin-top: 3.75rem;
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;

  ${BreakPoint.mobile} {
    font-size: 1.25rem;
  }
`;

export const SubTitle = styled.div`
  width: 100%;
  margin: 1.25rem 0 1rem;
  font-weight: 700;
  font-size: 1.25rem;

  ${BreakPoint.mobile} {
    font-size: 0.875rem;
  }
`;

export const DivisionBorder = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  border: 1px solid #db1f72;
`;

export const IndexText = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;
  font-weight: 500;

  ${BreakPoint.mobile} {
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
  }

  & > ol > li {
    font-size: 0.75rem;
    font-weight: 500;
    margin-bottom: 1rem;

    ${BreakPoint.mobile} {
      margin-bottom: 0.5rem;
    }
  }
`;

export const NewWindowLink = styled.div`
  display: inline-block;
  font-weight: 500;
  color: #db1f72;

  &:hover {
    cursor: pointer;
  }
`;
