import { SkeletonUtils } from 'three-stdlib';
import { useMemo, useEffect } from 'react';
import * as THREE from 'three';

import { useCompressedGLTF } from 'hooks/hooks';

const url2 = new URL('static/models/base.min.glb', import.meta.url);
const url = new URL('static/models/Default2.min.glb', import.meta.url);

function componentToHex(c: { toString: (arg0: number) => any }) {
  var hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
}

function rgbToHex(
  r: { toString: (arg0: number) => any },
  g: { toString: (arg0: number) => any },
  b: { toString: (arg0: number) => any }
) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

const toRgb = (hue: number, saturation: number, value: number) => {
  let d = 0.0166666666666666 * hue;
  let c = value * saturation;
  let x = c - c * Math.abs((d % 2.0) - 1.0);
  let m = value - c;
  c += m;
  x += m;
  switch (d >>> 0) {
    case 0:
      return { red: c, green: x, blue: m };
    case 1:
      return { red: x, green: c, blue: m };
    case 2:
      return { red: m, green: c, blue: x };
    case 3:
      return { red: m, green: x, blue: c };
    case 4:
      return { red: x, green: m, blue: c };
  }
  return { red: c, green: m, blue: x };
};

export default function Thema({
  color,
  saturation,
  brightness,
}: {
  color: number;
  saturation: number;
  brightness: number;
}) {
  const { scene } = useCompressedGLTF(url.href);
  const clonedScene = useMemo(() => SkeletonUtils.clone(scene), [scene]);
  let theme_color: THREE.ColorRepresentation | undefined;
  const disposeMesh: THREE.Mesh[] = [];
  const disposeTexture: THREE.Texture[] = [];
  const rgb = toRgb(color, saturation / 100, brightness / 100);

  theme_color = rgbToHex(
    Math.round(255 * rgb.red),
    Math.round(255 * rgb.green),
    Math.round(255 * rgb.blue)
  );

  clonedScene.traverse((object) => {
    if ((object as THREE.Mesh).isMesh) {
      const mesh = object as THREE.Mesh;
      mesh.material = mesh.material as THREE.Material;
      mesh.receiveShadow = true;
      mesh.material.toneMapped = false;

      const mat = mesh.material as THREE.Material;
      mat.color = new THREE.Color('white');
      mat.emissive = new THREE.Color('white');
      mat.emissiveIntensity = 0.5;
      disposeMesh.push(mesh);
      if (mat.map !== null) {
        disposeTexture.push(mat.map);
      }
    }
  });
  useEffect(() => {
    return () => {
      for (let i = 0; i < disposeMesh.length; i++) {
        if (disposeMesh[i]) disposeMesh[i].geometry.dispose();
      }
      for (let i = 0; i < disposeTexture.length; i++) {
        if (disposeTexture[i]) disposeTexture[i].dispose();
      }
    };
  }, []);
  return (
    <>
      <group position={[0, 0, 0.5]} scale={1} rotation={[0, Math.PI / 2, 0]}>
        <primitive object={clonedScene} />
      </group>
    </>
  );
}
