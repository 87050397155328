import * as THREE from 'three';
import { MeshReflectorMaterial } from '@react-three/drei';

export default function FrameMirror() {
  return (
    <>
      <pointLight args={['white', 4]} distance={11} position={[0, 0, 0]} />

      <mesh receiveShadow position={[0, 0, -0.99]} renderOrder={2}>
        <boxGeometry args={[2, 2, 0.1]} />
        <MeshReflectorMaterial
          mirror={0}
          blur={[300, 100]}
          resolution={256}
          mixBlur={1}
          mixStrength={40}
          roughness={1}
          depthScale={1.3}
          minDepthThreshold={0}
          maxDepthThreshold={1.4}
          color='#101010'
          metalness={0.5}
          toneMapped={true}
          stencilWrite
          stencilRef={1}
          stencilFunc={THREE.EqualStencilFunc}
          stencilFail={THREE.KeepStencilOp}
          stencilZFail={THREE.KeepStencilOp}
          stencilZPass={THREE.ReplaceStencilOp}
        />
      </mesh>
      <mesh receiveShadow position={[-1, 0, 0]} rotation={[0, Math.PI / 2, 0]} renderOrder={2}>
        <boxGeometry args={[2, 2, 0.1]} />
        <meshPhongMaterial
          toneMapped={true}
          stencilWrite
          stencilRef={1}
          stencilFunc={THREE.EqualStencilFunc}
          stencilFail={THREE.KeepStencilOp}
          stencilZFail={THREE.KeepStencilOp}
          stencilZPass={THREE.ReplaceStencilOp}
        />
      </mesh>
      <mesh receiveShadow position={[1, 0, 0]} rotation={[0, Math.PI / 2, 0]} renderOrder={2}>
        <boxGeometry args={[2, 2, 0.1]} />
        <meshPhongMaterial
          toneMapped={true}
          stencilWrite
          stencilRef={1}
          stencilFunc={THREE.EqualStencilFunc}
          stencilFail={THREE.KeepStencilOp}
          stencilZFail={THREE.KeepStencilOp}
          stencilZPass={THREE.ReplaceStencilOp}
        />
      </mesh>
      <mesh receiveShadow position={[0, 1, 0]} rotation={[Math.PI / 2, 0, 0]} renderOrder={2}>
        <boxGeometry args={[2, 2, 0.1]} />
        <meshPhongMaterial
          toneMapped={true}
          stencilWrite
          stencilRef={1}
          stencilFunc={THREE.EqualStencilFunc}
          stencilFail={THREE.KeepStencilOp}
          stencilZFail={THREE.KeepStencilOp}
          stencilZPass={THREE.ReplaceStencilOp}
        />
      </mesh>
      <mesh receiveShadow position={[0, -1, -0.01]} rotation={[-Math.PI / 2, 0, 0]} renderOrder={2}>
        <boxGeometry args={[2, 2, 0.01]} />
        <MeshReflectorMaterial
          mirror={0}
          blur={[300, 100]}
          resolution={256}
          mixBlur={1}
          mixStrength={40}
          roughness={1}
          depthScale={1.3}
          minDepthThreshold={0}
          maxDepthThreshold={1.4}
          color='#101010'
          metalness={0.5}
          toneMapped={true}
          stencilWrite
          stencilRef={1}
          stencilFunc={THREE.EqualStencilFunc}
          stencilFail={THREE.KeepStencilOp}
          stencilZFail={THREE.KeepStencilOp}
          stencilZPass={THREE.ReplaceStencilOp}
        />
      </mesh>
    </>
  );
}
