import { UseCertificateResources } from './types';

import { Scripts } from 'src/types';

const scripts: Scripts = {
  KR: [
    '휴대폰 인증하기',
    '가입을 위해 본인의 휴대폰 번호를 인증해 주세요.',
    '이벤트 알림을 신청하면 추가적으로 혜택을 받으실 수 있어요.',
    '휴대폰 번호',
    '-을 제외한 휴대폰 번호를 입력하세요.',
    '문자메세지로 받은 번호를 입력해 주세요.',
    '이벤트 및 할인 소식 알림 동의 (선택)',
    '인증하기',
  ],
  EN: [
    'Authenticate your mobile phone',
    'Please verify your mobile number to sign up.',
    'You can receive additional benefits by registering for event notifications.',
    'Mobile Phone Number',
    'Please enter a mobile phone number without -.',
    'Please enter the number you received via text message.',
    'Consent to notification of events and discounts (optional)',
    'Authenticate',
  ],
  JP: [
    '携帯電話を認証する',
    '加入のために自分の携帯電話番号を認証してください。',
    'イベントのお知らせを申請すると追加の特典を受け取ることができます。',
    '携帯電話番号',
    '- を除く携帯電話番号を入力してください。',
    'テキストメッセージで受け取った番号を入力してください。',
    'イベントと割引のお知らせの同意（オプション）',
    '認証する',
  ],
};

// const scripts: Scripts = {
//   KR: [
//     '인증시간이 만료되었습니다.',
//     '핸드폰 인증',
//     '국가선택',
//     '유효하지 않은 전화번호입니다.',
//     '입력하신 전화번호로 등록된 계정이 있는지 확인해주세요',
//     '확인',
//     '재전송',
//     '인증이 완료되지 않았습니다.',
//     '인증완료',
//     '서비스 정책',
//     '서비스 이용약관 동의 (필수)',
//     '내용보기',
//     '이용약관에 동의해 주세요.',
//     '휴대폰 인증에 실패했습니다. 다시 시도해주세요.',
//     '확인',
//     '임시 비밀번호 발급',
//     '회원가입',
//   ],
//   EN: [
//     'Certification time has expired',
//     'Mobile phone authentication',
//     'Select country',
//     'Invalid phone number',
//     'Please check if there is an account registered with the phone number you entered',
//     'Confirm',
//     'Resend',
//     'Authentication is not completed',
//     'Authentication completed',
//     'Service Policy',
//     'Consent to Terms of Service (Required)',
//     'View Contents',
//     'Please onsent to Terms of Use',
//     'Phone Authentication Failed. Please try again.',
//     'Check',
//     'Temporary password',
//     'Registration',
//   ],
//   JP: [
//     '認証時間が満了しました。',
//     '携帯電話認証',
//     '国の選択',
//     '有効でない電話番号です。',
//     '入力した電話番号に登録されたアカウントがあるか確認してください',
//     '確認',
//     '再送信',
//     '認証完了',
//     'サービスの利用約款の同意(必須)',
//     '内容を見る',
//     '利用約款に同意してください',
//     '携帯電話番号に失敗しました。',
//     '認証を入力した。 もう一度試してください。',
//     '確認',
//     '臨時パスワード発行',
//     '会員登録',
//   ],
// };

const useCertificateResources = (): UseCertificateResources => {
  return {
    scripts,
  };
};

export default useCertificateResources;
